import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import FallBackSpinner from '@components/spinner/FallBackSpinner';

const CalenderDiaryFormPage = lazy(() => import('@pages/calenderDiary/CalenderDiaryForm'));

const CalenderDiary = lazy(() => import('@pages/calenderDiary'));

const CalenderDiaryRouter = () => {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path="/" element={<CalenderDiary />} />
        <Route path="/form/:page" element={<CalenderDiaryFormPage />} />
      </Routes>
    </Suspense>
  );
};
export default CalenderDiaryRouter;
