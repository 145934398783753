import { atom } from 'recoil';
import { SearchResult } from '../types/searchResult';

export const searchKeywordState = atom<string>({
  key: 'searchKeywordState',
  default: '',
});

export const selectedSearchKeywordState = atom<string>({
  key: 'selectedSearchKeywordState',
  default: '',
});

export const searchResultState = atom<SearchResult[]>({
  key: 'searchResultState',
  default: [],
});

export const customFoodEditState = atom<boolean>({
  key: 'customFoodEditState',
  default: false,
});

export const toastMsgState = atom<boolean>({
  key: 'toastMsgState',
  default: false,
});

export const toastSecondMsgState = atom<boolean>({
  key: 'toastSecondMsgState',
  default: false,
});

export const toastMealMsgState = atom<boolean>({
  key: 'toastMealMsgState',
  default: false,
});

export const brandNameState = atom<string>({
  key: 'brandNameState',
  default: '',
});

export const customFoodRegisterState = atom<boolean>({
  key: '#customFoodRegisterState',
  default: false,
});

export const customFoodRegisterFetchState = atom<boolean>({
  key: '#customFoodRegisterFetchState',
  default: false,
});
