import { useCallback, useState } from 'react';

type UseSwitchStateType = [boolean, () => void];

export const useSwitchState = (initialValue: boolean): UseSwitchStateType => {
  const [value, setValue] = useState(initialValue);
  const handler = useCallback(() => {
    setValue(!value);
  }, []);
  return [value, handler];
};
