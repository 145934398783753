interface Props {
  color?: string;
  size?: 'small' | 'semi-medium' | 'medium' | 'semi-large' | 'large';
}
export default function Snack({ color = '#000', size = 'small' }: Props) {
  const fragment = (() => {
    switch (size) {
      case 'small':
        return {
          size: 51,
          icon: (
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 12.2109L18.5431 11.3625L8.69556 26.8028L41.3043 16.2423L31 14.0332"
                stroke={color}
                strokeWidth="2.1658"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.69556 26.8027V42.4325L41.3043 32.2035V16.2423L8.69556 26.8027Z"
                stroke={color}
                strokeWidth="2.1658"
                strokeLinejoin="round"
              />
              <path d="M8.69556 34.9679L41.3043 24.3506" stroke={color} strokeWidth="2.1658" />
              <path
                d="M32.7823 5.32605C31.7051 5.59114 30.7322 6.6552 30 7.71684"
                stroke={color}
                strokeWidth="2.1658"
                strokeLinecap="round"
              />
              <path
                d="M31.6424 11.6697C31.6424 14.1595 29.5845 16.209 27.0073 16.209C24.4301 16.209 22.3722 14.1595 22.3722 11.6697C22.3722 9.17987 24.4301 7.13039 27.0073 7.13039C29.5845 7.13039 31.6424 9.17987 31.6424 11.6697Z"
                stroke={color}
                strokeWidth="2.1658"
              />
            </svg>
          ),
        };
      case 'semi-medium':
        return {
          size: 56,
          icon: (
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.0002 13.6333L20.7685 12.7261L9.73926 30.0191L46.261 18.1915L35.0002 15.7773"
                stroke={color}
                strokeWidth="2.2"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.73926 30.0191V47.5244L46.261 36.068V18.1915L9.73926 30.0191Z"
                stroke={color}
                strokeWidth="2.2"
                strokeLinejoin="round"
              />
              <path d="M9.73926 39.164L46.261 27.2726" stroke={color} strokeWidth="2.2" />
              <path
                d="M36.7167 5.96521C35.4605 6.27434 34.331 7.55356 33.5005 8.78975"
                stroke={color}
                strokeWidth="2.2"
                strokeLinecap="round"
              />
              <path
                d="M35.5525 13.07C35.5525 15.9227 33.1954 18.2669 30.2484 18.2669C27.3014 18.2669 24.9442 15.9227 24.9442 13.07C24.9442 10.2173 27.3014 7.87316 30.2484 7.87316C33.1954 7.87316 35.5525 10.2173 35.5525 13.07Z"
                stroke={color}
                strokeWidth="2.2"
              />
            </svg>
          ),
        };
      case 'medium':
        return {
          size: 72,
          icon: (
            <svg
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32.5 17.6052L26.7022 16.3622L12.5217 38.5961L59.4782 23.3891L45 20.2851"
                stroke={color}
                strokeWidth="2.7"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5217 38.596V61.1029L59.4782 46.3731V23.389L12.5217 38.596Z"
                stroke={color}
                strokeWidth="2.7"
                strokeLinejoin="round"
              />
              <path d="M12.5217 50.3537L59.4782 35.0648" stroke={color} strokeWidth="2.7" />
              <path
                d="M47.2068 7.66956C45.5553 8.07599 44.074 9.78663 43 11.4087"
                stroke={color}
                strokeWidth="2.7"
                strokeLinecap="round"
              />
              <path
                d="M45.7745 16.8043C45.7745 20.5086 42.7141 23.5503 38.8906 23.5503C35.0672 23.5503 32.0067 20.5086 32.0067 16.8043C32.0067 13.1001 35.0672 10.0584 38.8906 10.0584C42.7141 10.0584 45.7745 13.1001 45.7745 16.8043Z"
                stroke={color}
                strokeWidth="2.7"
              />
            </svg>
          ),
        };
      case 'semi-large':
        return {
          size: 82,
          icon: (
            <svg
              width="82"
              height="82"
              viewBox="0 0 82 82"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.5 19.9399L30.4117 18.6346L14.2617 43.9566L67.74 26.6375L51 23.0486"
                stroke={color}
                strokeWidth="3.075"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2617 43.9565V69.5893L67.74 52.8138V26.6375L14.2617 43.9565Z"
                stroke={color}
                strokeWidth="3.075"
                strokeLinejoin="round"
              />
              <path d="M14.2617 57.3473L67.74 39.9349" stroke={color} strokeWidth="3.075" />
              <path
                d="M53.7636 8.73474C51.8968 9.19417 50.2209 11.1168 49 12.9518"
                stroke={color}
                strokeWidth="3.075"
                strokeLinecap="round"
              />
              <path
                d="M52.1328 19.1382C52.1328 23.357 48.6473 26.8211 44.2928 26.8211C39.9383 26.8211 36.4528 23.357 36.4528 19.1382C36.4528 14.9195 39.9383 11.4553 44.2928 11.4553C48.6473 11.4553 52.1328 14.9195 52.1328 19.1382Z"
                stroke={color}
                strokeWidth="3.075"
              />
            </svg>
          ),
        };
      case 'large':
        return {
          size: 100,
          icon: (
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44.5 24.3144L37.0867 22.7251L17.3916 53.6055L82.609 32.4847L62.5 28.1735"
                stroke={color}
                strokeWidth="3.4"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3916 53.6056V84.865L82.609 64.4071V32.4847L17.3916 53.6056Z"
                stroke={color}
                strokeWidth="3.4"
                strokeLinejoin="round"
              />
              <path d="M17.3916 69.9357L82.609 48.701" stroke={color} strokeWidth="3.4" />
              <path
                d="M65.5646 10.6521C63.4102 11.1823 61.4645 13.3103 60 15.4336"
                stroke={color}
                strokeWidth="3.4"
                strokeLinecap="round"
              />
              <path
                d="M63.7505 23.3392C63.7505 28.5835 59.4188 32.8836 54.0146 32.8836C48.6104 32.8836 44.2786 28.5835 44.2786 23.3392C44.2786 18.095 48.6104 13.7948 54.0146 13.7948C59.4188 13.7948 63.7505 18.095 63.7505 23.3392Z"
                stroke={color}
                strokeWidth="3.4"
              />
            </svg>
          ),
        };
    }
  })();
  return <span style={{ height: fragment.size }}>{fragment.icon}</span>;
}
