import { ButtonSetContainer } from './styles';
import { cx } from 'styles';

import { FormEventHandler, MouseEventHandler } from 'react';

interface Props {
  text: string;
  size: 'long' | 'medium' | 'half' | 'short';
  isActive?: boolean;
  onClick?: MouseEventHandler | FormEventHandler;
  type: 'submit' | 'button';
  color: 'black' | 'linearBlue' | 'gray' | 'isInActive' | 'blue';
}

const ButtonSet = ({ text, size, isActive, onClick, type, color }: Props) => {
  if (!isActive)
    return (
      <ButtonSetContainer className={cx([size])}>
        <button type={type} disabled>
          {text}
        </button>
      </ButtonSetContainer>
    );

  return (
    <ButtonSetContainer className={cx([size], [color])}>
      <button type={type} onClick={onClick}>
        {text}
      </button>
    </ButtonSetContainer>
  );
};

export default ButtonSet;
