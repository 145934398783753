import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import FallBackSpinner from '@components/spinner/FallBackSpinner';
import FastingRecordEditPage from '@pages/fasting/new/record/edit';
const FastingPage = lazy(() => import('@pages/fasting/new'));
const FastingTimerTypeSelectPage = lazy(() => import('@pages/fasting/new/fastingTimerTypeSelect'));
const FastingOneTimePostingPage = lazy(() => import('@pages/fasting/new/timer/onetime'));
const FastingRoutineListPage = lazy(() => import('@pages/fasting/new/timer/routine/list'));
const FastingRoutinePostingPage = lazy(() => import('@pages/fasting/new/timer/routine/posting'));

export default function FastingRouter() {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path="/:tabName" element={<FastingPage />} />
        <Route path="/:tabName/type" element={<FastingTimerTypeSelectPage />} />
        <Route path="/:tabName/onetime/posting" element={<FastingOneTimePostingPage />} />
        <Route path="/:tabName/routine/list" element={<FastingRoutineListPage />} />
        <Route path="/:tabName/routine/posting/:id?" element={<FastingRoutinePostingPage />} />
        <Route path="/:tabName/posting/:id?" element={<FastingRecordEditPage />} />
      </Routes>
    </Suspense>
  );
}
