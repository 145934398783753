interface Props {
  color?: string;
  size?: 'small' | 'semi-medium' | 'medium' | 'semi-large' | 'large';
}
export default function Breakfast({ color = '#000', size = 'small' }: Props) {
  const fragment = (() => {
    switch (size) {
      case 'small':
        return {
          size: 51,
          icon: (
            <svg width="51" height="51" viewBox="0 0 51 51" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(7.15 6.6)" stroke={color} fill="none" fillRule="evenodd">
                <path
                  strokeWidth="2.97"
                  strokeLinecap="round"
                  d="M18.014 0v3.397M18.014 34.003V37.4"
                />
                <g strokeLinecap="round" strokeWidth="2.97">
                  <path d="M3.3 19.28H0M36.3 19.28H33" />
                </g>
                <g strokeLinecap="round" strokeWidth="2.97">
                  <path d="M7.592 29.753 5.275 32.07M31.025 6.32l-2.317 2.318" />
                </g>
                <g strokeLinecap="round" strokeWidth="2.97">
                  <path d="M7.23 9.013 4.833 6.778M31.467 31.613l-2.397-2.235" />
                </g>
                <ellipse strokeWidth="2.75" cx="18.047" cy="18.644" rx="9.269" ry="9.625" />
              </g>
            </svg>
          ),
        };
      case 'semi-medium':
        return {
          size: 56,
          icon: (
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M27.8495 6V9.75941" stroke={color} strokeWidth="2.6" strokeLinecap="round" />
              <path
                d="M27.8495 43.6319V47.3913"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M11.5653 27.3373H7.91309"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M48.0872 27.3373H44.4351"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M16.3158 38.9282L13.7512 41.4927"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M42.2489 12.9949L39.6843 15.5594"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M15.9146 15.9743L13.2621 13.5008"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M42.7374 40.9868L40.0849 38.5133"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M38.5158 26.6335C38.5158 32.7578 33.7214 37.6574 27.8864 37.6574C22.0513 37.6574 17.2569 32.7578 17.2569 26.6335C17.2569 20.5092 22.0513 15.6096 27.8864 15.6096C33.7214 15.6096 38.5158 20.5092 38.5158 26.6335Z"
                stroke={color}
                strokeWidth="2.3"
              />
            </svg>
          ),
        };
      case 'medium':
        return {
          size: 72,
          icon: (
            <svg width="72" height="72" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(10.174 9.391)" stroke={color} fill="none" fillRule="evenodd">
                <path
                  strokeWidth="3.2"
                  strokeLinecap="round"
                  d="M25.633 0v4.834M25.633 48.384v4.833"
                />
                <g strokeLinecap="round" strokeWidth="3.2">
                  <path d="M4.696 27.434H0M51.652 27.434h-4.695" />
                </g>
                <g strokeLinecap="round" strokeWidth="3.2">
                  <path d="m10.804 42.336-3.298 3.297M44.146 8.993l-3.298 3.298" />
                </g>
                <g strokeLinecap="round" strokeWidth="3.2">
                  <path d="m10.288 12.825-3.41-3.18M44.774 44.983l-3.41-3.18" />
                </g>
                <ellipse strokeWidth="3" cx="25.68" cy="26.529" rx="13.645" ry="14.152" />
              </g>
            </svg>
          ),
        };
      case 'semi-large':
        return {
          size: 82,
          icon: (
            <svg
              width="82"
              height="82"
              viewBox="0 0 82 82"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.7797 10.6957V16.2005"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M40.7797 65.7996V71.3044"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M16.9347 41.9395H11.5869"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M70.4133 41.9395H65.0654"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M23.8908 58.9119L20.1356 62.6672"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M61.8644 20.9382L58.1092 24.6934"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M23.3042 25.3009L19.4202 21.679"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M62.5796 61.9263L58.6956 58.3044"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M56.3735 40.909C56.3735 49.864 49.3634 57.0267 40.8334 57.0267C32.3034 57.0267 25.2933 49.864 25.2933 40.909C25.2933 31.9539 32.3034 24.7912 40.8334 24.7912C49.3634 24.7912 56.3735 31.9539 56.3735 40.909Z"
                stroke={color}
                strokeWidth="3.41667"
              />
            </svg>
          ),
        };
      case 'large':
        return {
          size: 100,
          icon: (
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M49.7312 13.0435V19.7567"
                stroke="white"
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M49.7312 80.2432V86.9564"
                stroke="white"
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M20.6516 51.1456H14.1299"
                stroke="white"
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M85.8694 51.1456H79.3477"
                stroke="white"
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M29.1341 71.8441L24.5545 76.4237"
                stroke="white"
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M75.4437 25.5345L70.8641 30.1141"
                stroke="white"
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M28.4192 30.8545L23.6826 26.4376"
                stroke="white"
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M76.3157 75.5196L71.5791 71.1026"
                stroke="white"
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M69.0311 49.889C69.0311 60.9575 60.364 69.8282 49.7964 69.8282C39.2288 69.8282 30.5617 60.9575 30.5617 49.889C30.5617 38.8206 39.2288 29.9499 49.7964 29.9499C60.364 29.9499 69.0311 38.8206 69.0311 49.889Z"
                stroke="white"
                strokeWidth="3.6"
              />
            </svg>
          ),
        };
    }
  })();
  return <span style={{ height: fragment.size }}>{fragment.icon}</span>;
}
