import { getTimerRecordList } from '@apis/fastingV2Api';
import { useQuery } from 'react-query';

interface Props {
  memberIdx: number;
  createdAt: string;
}

const useGetTimerRecordListQuery = ({ memberIdx, createdAt }: Props) => {
  return useQuery(
    ['fasting', 'record', memberIdx, createdAt],
    () => getTimerRecordList({ memberIdx, createdAt }),
    {
      suspense: true,
    },
  );
};

export default useGetTimerRecordListQuery;
