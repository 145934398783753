import React, { PropsWithChildren } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

interface Props {
  background?: string;
}

export function Container({ background = '#fff', children }: PropsWithChildren<Props>) {
  const classes = useStyle();

  return (
    <div className={classes.wrapper} style={background ? { background: background } : {}}>
      {children}
    </div>
  );
}
const useStyle = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
      maxWidth: 428,
      width: '100%',
      minHeight: '100vh',
      margin: '0 auto',
      background: '#fff',
    },
  }),
);
