interface Props {
  color: string;
}

export default function ArrowBack({ color }: Props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.07104 19.0711L0.999977 12L8.07104 4.92896"
        stroke={color}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.5 12H22" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
    </svg>
  );
}
