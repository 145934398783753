import { DeleteIcon } from 'assets/svgs';
import { COLORS } from 'styles/constants/_colors';

export const SECURITY_KEYBOARD_LIST = [
  {
    id: 'security_keyboard_item_1',
    value: '1',
    component: <>1</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_2',
    value: '2',
    component: <>2</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_3',
    value: '3',
    component: <>3</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_4',
    value: '4',
    component: <>4</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_5',
    value: '5',
    component: <>5</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_6',
    value: '6',
    component: <>6</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_7',
    value: '7',
    component: <>7</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_8',
    value: '8',
    component: <>8</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_9',
    value: '9',
    component: <>9</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_10',
    value: 'null',
    component: <></>,
    color: 'transparent',
  },
  {
    id: 'security_keyboard_item_11',
    value: '0',
    component: <>0</>,
    color: COLORS.PRIMITIVES_GRAY_60,
  },
  {
    id: 'security_keyboard_item_12',
    value: 'delete',
    component: <DeleteIcon />,
    color: 'transparent',
  },
];
