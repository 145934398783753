import { keyframes } from 'styled-components';

export const swipeRight = keyframes`
from {
    transform: translateX(100%);
}
to {
    transform: translateX(0);
}
`;

export const swipeUp = keyframes`
0% {
    transform: translateY(100%);
}
100% {
    transform: translateY(0);
}
`;

export const swipeDown = keyframes`
0% {
    transform: translateY(0);
}
100% {
    transform: translateY(100%);
}
`;

export const bubbleUp = keyframes`
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }

`;

export const bubbleDown = keyframes`
    0%{
        transform: translateY(0%); 
        opacity: 0;
    }
    100%{
        transform: translateY(0%); 
        opacity: 1;
    }
`;

export const loading = keyframes`
    0% {
        transform: translateX(0);
    }
    50%,
    100%{
        transform: translateX(460px);
    }
`;
export const fadeIn = keyframes`
 from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
