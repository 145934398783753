import { atom } from 'recoil';
import {
  BattleDetailFavorite,
  BattleEnd,
  BattleFinalSubmitInfo,
  BattleProceeding,
} from '../types/battle';
import { GroupRegisterGroupForm, GroupRegisterMemberForm } from 'types/groupBattle';

export const battleFinalWeightChangeSubmitInfoState = atom<BattleFinalSubmitInfo>({
  key: 'battleFinalWeightChangeSubmitInfoState',
  default: {
    weightGoal: 0,
    weightStart: 0,
  },
});

export const battleDetailFavoriteState = atom<BattleDetailFavorite[]>({
  key: 'battleDetailFavoriteState',
  default: [],
});

export const groupBattleRankState = atom({
  key: '#groupBattleRankState',
  default: 1,
});

export const groupRegisterFormState = atom<GroupRegisterGroupForm>({
  key: '#groupRegisterFormState',
  default: {
    groupName: '',
    groupImg: '',
    groupDescription: '',
    groupTheme: '',
    groupAge: '',
    groupGender: '',
    groupBodyType: '',
    groupIntensity: '',
    groupIsLock: 99,
  },
});

export const groupRegisterMemberFormState = atom<GroupRegisterMemberForm>({
  key: '#groupRegisterMemberFormState',
  default: {
    startingWeight: 0,
  },
});

export const groupRegisterProfileFileState = atom<File | null>({
  key: '#groupRegisterProfileFile',
  default: null,
});

export const battleProceedList = atom<BattleProceeding[]>({
  key: '#battleProceedList',
  default: [],
});

export const battleEndList = atom<BattleEnd[]>({
  key: '#battleEndList',
  default: [],
});

export const battleRecruitCategoryState = atom<number>({
  key: '#battleRecruitCategoryState',
  default: 99,
});

export const battleExploreFilterState = atom<{
  id: 'new' | 'recommend';
  text: '최신순' | '추천순';
}>({
  key: '#battleExploreFilterState',
  default: {
    id: 'recommend',
    text: '추천순',
  },
});

export const battleExploreTagState = atom<{
  groupAge: Map<string, boolean>;
  groupGender: Map<string, boolean>;
  groupBodyType: Map<string, boolean>;
  groupIntensity: Map<string, boolean>;
}>({
  key: '#battleExploreTagState',
  default: {
    groupAge: new Map([
      ['allAge', true],
      ['teenager', true],
      ['twenties', true],
      ['thirties', true],
    ]),
    groupGender: new Map([
      ['allGender', true],
      ['woman', true],
      ['man', true],
    ]),
    groupBodyType: new Map([
      ['allBody', true],
      ['skinny', true],
      ['normal', true],
      ['overWeight', true],
      ['obesity', true],
      ['highObesity', true],
    ]),
    groupIntensity: new Map([
      ['lightly', true],
      ['suitably', true],
      ['strongly', true],
    ]),
  },
});

// 그룹 탐색 selected idx, 그룹 홈 navigation
export const selectedGroupIdx = atom({
  key: '#selectedGroupIdx',
  default: 0,
});

export const groupExploreSearchKeywordState = atom({
  key: '#groupExploreSearchKeyword',
  default: '',
});

export const photoPermissionState = atom<boolean>({
  key: '#photoPermissionState',
  default: false,
});
