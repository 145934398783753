import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { padNumber } from '@modules/date';

dayjs.extend(isoWeek);

// UTC 시간 배열을 현재 시간과의 차이를 기준으로 정렬
export function sortByClosestToCurrentTime<
  T extends { [key: string]: string | number | Date | any },
>(utcTimeArray: T[], key: keyof T) {
  const currentTime = new Date();

  return utcTimeArray.sort((a, b) => {
    const timeA = new Date(a[key]);
    const timeB = new Date(b[key]);

    // 현재 시간과의 차이 계산 (분 단위로 변환)
    const diffA = Math.abs((Number(currentTime) - Number(timeA)) / (1000 * 60));
    const diffB = Math.abs((Number(currentTime) - Number(timeB)) / (1000 * 60));

    // 차이가 적은 순으로 정렬 (오름차순)

    return diffA - diffB;
  });
}

/**
 * @param targetDate D-day를 목적으로 하는 날짜
 * @param expireDate 기준일 (따로 넘겨주지 않으면, default: 오늘)
 */
export const handleDueDate = (
  targetDate: string,
  expireDate?: string | undefined,
): number | 'expired' => {
  const target = dayjs(targetDate);
  const expire = dayjs(expireDate) ?? dayjs();
  const isExpired = target.isBefore(expire);

  if (isExpired) {
    return 'expired';
  } else {
    return dayjs(targetDate).diff(expire, 'day') + 1;
  }
};

/**
 * @param targetDate D-day를 목적으로 하는 날짜
 */
export const getDday = (targetDate: string) => {
  const today = dayjs().tz('Asia/Seoul').format('YYYY-MM-DD');
  const diffEndCount = dayjs(targetDate).tz('Asia/Seoul').diff(today, 'day');

  return padNumber(diffEndCount, 2);
};

/**
 * @description param data가 오늘, 이번주, 이번달에 해당 되는지 return
 */
export const handleLatestDate = (date: string) => {
  const isToday = dayjs().isSame(dayjs(date), 'day');
  const isThisWeek =
    dayjs().isoWeek() === dayjs(date).isoWeek() && dayjs().year() === dayjs(date).year();
  const isThisMonth = dayjs().isSame(dayjs(date), 'month');

  return { isToday, isThisWeek, isThisMonth };
};

/**
 *
 * @returns 현재 달이 이번 년도의 몇번째 분기인지 return
 */
export const getCurrentQuarter = () => {
  const now = dayjs().tz('Asia/Seoul');
  const currentMonth = now.month() + 1;
  return Math.ceil(currentMonth / 3);
};
