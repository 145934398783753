import useToastModal from 'hooks/useToastModal';
import { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const ToastModal = () => {
  const { toast, closeModal } = useToastModal();

  useEffect(() => {
    if (!toast.isPopup) return;

    const key = setTimeout(() => {
      closeModal();
    }, 2000);

    return () => {
      clearTimeout(key);
    };
  }, [toast]);

  return <>{toast.isPopup && <Container>{toast.props.children}</Container>}</>;
};

const opacity = keyframes`
0% {
    opacity: 0;
}
20% {
    opacity: 1;
}
90% {
    opacity: 1;
}
100% {
    opacity: 0;
}
`;

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  min-width: 230px;
  min-height: 40px;
  opacity: 0;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10000;
  animation: ${opacity} 2000ms ease;
  padding: 10px 15px;
  white-space: nowrap;

  & p {
    font-size: 14px;
    line-height: 1.3px;
    letter-spacing: -0.54;
    color: #fff;
    text-align: center;
  }
`;

export default ToastModal;
