import { COLORS } from '@styles/constants/_colors';
import LEVEL from '@styles/constants/_levels';
import { getChildren } from '@utils/compoundComponent';
import { motion, PanInfo, useDragControls, Variants } from 'framer-motion';
import { PointerEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { BottomSheetButtons, getBottomSheetButtons } from './BottomSheetButtons';
import { BottomSheetContents, getBottomSheetContents } from './BottomSheetContents';
import { BottomSheetHeaders, getBottomSheetHeaders } from './BottomSheetHeaders';

interface Props {
  children?: ReactNode;
  y?: number;
  onClose: () => void;
  showPanel?: boolean;
}

const BottomSheet = ({ onClose, children, y = 200, showPanel = true }: Props) => {
  const [isEnd, setIsEnd] = useState(true);
  const dragControls = useDragControls();
  const header = getChildren(children, getBottomSheetHeaders);
  const contents = getChildren(children, getBottomSheetContents);
  const buttons = getChildren(children, getBottomSheetButtons);

  const handlePointerDown = (e: PointerEvent<HTMLDivElement>) => {
    dragControls.start(e);
  };

  const handleDragEnd = (event: any, info: PanInfo) => {
    const isEnd = info.offset.y > y;
    if (isEnd) {
      setIsEnd(false);
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  return (
    <Container
      initial="hidden"
      animate={isEnd ? 'visible' : 'hidden'}
      variants={variants}
      drag="y"
      dragConstraints={{
        top: 0,
        bottom: 0,
      }}
      transition={{
        ease: 'ease-in',
        duration: 0.7,
        y: { duration: 0.7 },
      }}
      dragElastic={{ top: 0, bottom: 0.8 }}
      dragControls={dragControls}
      onPointerDown={handlePointerDown}
      onDragEnd={handleDragEnd}
    >
      {showPanel && <Panel />}
      {header}
      <Wrapper onPointerDownCapture={(e) => e.stopPropagation()}>
        {contents}
        {buttons}
      </Wrapper>
    </Container>
  );
};

const variants: Variants = {
  hidden: { y: 1000 },

  visible: {
    y: 0,
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 200,
    },
  },
};

const Container = styled(motion.div)`
  background: ${COLORS.WHITE};
  position: fixed;
  width: 100%;
  max-width: 428px;
  bottom: 0;
  z-index: ${LEVEL.MODAL_BASIC};
  border-radius: 24px 24px 0px 0px;
  user-select: none;
  padding-top: 16px;
  cursor: pointer;
`;

const Wrapper = styled(motion.div)`
  width: 100%;
  max-width: 428px;
`;

const Panel = styled.div`
  width: 50px;
  height: 6px;
  border-radius: 120px;
  background-color: ${COLORS.PRIMITIVES_GRAY_300};
  margin: 0 auto;
`;

export default Object.assign(BottomSheet, {
  Headers: BottomSheetHeaders,
  Contents: BottomSheetContents,
  Buttons: BottomSheetButtons,
});
