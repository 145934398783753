import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ACCESS_TOKEN } from '../../../shared/link';
import { deleteCookie, setCookie } from '../../../utils/cookie';
import { history } from '../../config/reduxRoot';
import {
  LOGINFAILURE,
  LOGINREQUEST,
  LOGINSUCCESS,
  GETUSERREQUEST,
  GETUSERSUCCESS,
  GETUSERFAILURE,
  REGISTERTERMREQUEST,
  REGISTERTERMFAILURE,
  REGISTERTERMSUCCESS,
  POINTCHECKREQUEST,
  POINTCHECKSUCCESS,
  POINTCHECKFAILURE,
} from './type';

const getUserInfo = () => {
  return axios({
    method: 'get',
    url: '/auth/check',
  });
};
interface dataForm {
  id: string;
  pwd: string;
}

const ketoLogin = (data: dataForm) => {
  return axios({
    method: 'post',
    url: '/auth/commonLogin',
    data: { id: data.id, pw: data.pwd },
  });
};
const registerTermUser = (data: string) => {
  return axios({
    method: 'post',
    url: '/auth/agreement',
    data: { marketingReceive: data },
  });
};

const pointCheckUser = () => {
  return axios({
    method: 'get',
    url: '/auth/pointCheck',
  });
};

function* login(info: { type: string; data: dataForm }) {
  try {
    const { data } = yield call(ketoLogin, info.data);
    if (data.msg === 'success') {
      setCookie(ACCESS_TOKEN, data.token);
      yield put({ type: LOGINSUCCESS, data: data.commonAuth });
      history.replace('/ketoRedirect/C');
    } else {
      yield put({ type: LOGINFAILURE, error: data.msg });
    }
  } catch (err) {
    window.alert('에러가 발생했습니다. 잠시후 다시 시도해주세요.');
    yield put({ type: LOGINFAILURE, err });
  }
}

function* getUser() {
  try {
    const { data } = yield call(getUserInfo);
    if (data.msg === 'not_login1') {
      deleteCookie(ACCESS_TOKEN);
      history.replace('/login');
      return;
    }
    yield put({ type: GETUSERSUCCESS, data });
    data.profile_check === 0 && history.replace('/login');
  } catch (err) {
    yield put({ type: GETUSERFAILURE, err });
    window.alert('에러가 발생했습니다. 잠시후 다시 시도해주세요.');
    history.replace('/login');
  }
}

function* registerTerms(marketingReceive: { type: string; data: string }) {
  try {
    const { data } = yield call(registerTermUser, marketingReceive.data);
    yield put({ type: REGISTERTERMSUCCESS, data });
    history.replace('/welcome');
  } catch (err) {
    yield put({ type: REGISTERTERMFAILURE, err });
  }
}

function* pointCheck() {
  try {
    yield call(pointCheckUser);
    yield put({ type: POINTCHECKSUCCESS });
  } catch (err) {
    yield put({ type: POINTCHECKFAILURE, err });
  }
}

function* watchLogin() {
  yield takeLatest<any>(LOGINREQUEST, login);
}

function* watchGetUser() {
  yield takeLatest(GETUSERREQUEST, getUser);
}

function* watchRegister() {
  yield takeLatest<any>(REGISTERTERMREQUEST, registerTerms);
}

function* watchPointCheck() {
  yield takeLatest(POINTCHECKREQUEST, pointCheck);
}

export function* loginSaga() {
  yield all([fork(watchLogin), fork(watchGetUser), fork(watchRegister), fork(watchPointCheck)]);
}
