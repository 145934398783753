import { getLoginHistory } from '@apis/signApi';
import { LoginHistoryResponse } from '@models/sign';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

const useGetLoginHistory = (
  deviceId?: string,
  options?: UseQueryOptions<LoginHistoryResponse, AxiosError, LoginHistoryResponse>,
) => {
  return useQuery<LoginHistoryResponse, AxiosError, LoginHistoryResponse>(
    ['#getLoginHistory', deviceId],
    () => getLoginHistory(deviceId),
    {
      enabled: !!deviceId,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetLoginHistory;
