import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  infoText?: string;
}

const ButtonWrapper = ({ children, infoText }: Props) => {
  return (
    <Wrapper isInfoText={!!infoText}>
      {children}
      {infoText && <Info>{infoText}</Info>}
    </Wrapper>
  );
};

export default ButtonWrapper;

const Wrapper = styled.div<{ isInfoText: boolean }>`
  position: fixed;
  max-width: 428px;
  width: 100%;
  height: ${({ isInfoText }) => (isInfoText ? 114 : 78)}px;
  padding: 12px 20px;
  bottom: 0;
  background: ${COLORS.WHITE};
`;

const Info = styled.p`
  color: ${FOUNDATION_COLOR.PRIMARY_GRAY};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.7px;

  padding: 15px 0 4px;
`;
