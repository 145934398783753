import { makeStyles, Grid, createStyles, Theme } from '@material-ui/core';
import SpinnerDot from './SpinnerDot';

interface SpinnerProps {
  height?: string;
  bgColor?: string;
  paddingTop?: number;
}

export default function SpinnerInBox({ height, bgColor, paddingTop }: SpinnerProps) {
  const classes = useStyles({ bgColor, height, paddingTop });
  const dotList = [0, 1, 2, 3];

  return (
    <Grid className={classes.container} container justifyContent={'center'} alignItems={'center'}>
      <Grid
        className={classes.content}
        container
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {dotList.map((item, index) => (
          <SpinnerDot index={item} key={index} />
        ))}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles<Theme, { bgColor?: string; height?: string; paddingTop?: number }>(
  () =>
    createStyles({
      container: {
        background: ({ bgColor }) => (bgColor ? bgColor : 'transparent'),
        height: ({ height }) => (height ? height : '100vh'),
        paddingTop: ({ paddingTop }) => (paddingTop ? paddingTop : ''),
      },
      content: {
        width: 96,
        height: 21,
      },
    }),
);
