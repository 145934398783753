import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ReactGA from 'react-ga4';
import { handleGA4Title, handleGA4URL, onMakeGA4Event } from 'shared/ga4MakeEvent';

const useGoogleAnalytics = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize('G-EP5R3JCQ9E');
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      /**
       * 아래 2개의 페이지는 해당 훅이 아닌, 각각의 컴포넌트에서 GA4 이벤트를 보여줘야한다. (이유는 아래와 같이 표현해야하기 때문에)
       * ex: 음식 상세: ${음식 이름}
       * @param BOARD_DETAIL_PAGE 게시글 상세 페이지
       * @param BOARD_COMMENT_PAGE 게시글 댓글
       */
      const BOARD_DETAIL_PAGE = /\/communication\/board\/detail\/\d+/.test(location.pathname);
      const BOARD_COMMENT_PAGE = /\/myPage\/comment\/\d+/.test(location.pathname);

      if (BOARD_DETAIL_PAGE || BOARD_COMMENT_PAGE) {
        return;
      }

      onMakeGA4Event({
        page: handleGA4URL(location.pathname),
        title: handleGA4Title(location.pathname),
      });
    }
  }, [initialized, location.pathname]);
};

export default useGoogleAnalytics;
