import { atom } from 'recoil';
import { FoodDetailForMealList } from '../types/dietDiary';
import { FoodDetail, RequestCustomFoodDetail } from '../types/searchResult';

export const foodDetailState = atom<FoodDetail>({
  key: 'foodDetailState',
  default: {
    food_idx: '',
    food_name: '',
    food_brand: null,
    food_type: 'Generic',
    creator: 0,
    measurement_description: '',
    metric_serving_unit: '',
    metric_serving_amount: '1',
    number_of_units: 0,
    created_at: '',
    updated_at: '',

    calories: 0,
    carbohydrate: 0,
    sugar: 0,
    fiber: 0,
    protein: 0,
    fat: 0,
    saturated_fat: 0,
    monounsaturated_fat: 0,
    polyunsaturated_fat: 0,
    trans_fat: 0,
    sodium: 0,
    cholesterol: 0,
    potassium: 0,
    net_carbohydrate: 0,
    sugar_sub: 0,
  } as FoodDetail,
});

export const foodDetailEditState = atom<FoodDetail>({
  key: 'foodDetailEditState',
  default: {} as FoodDetail,
});

export const customFoodRequestState = atom<RequestCustomFoodDetail>({
  key: 'customFoodRequestState',
  default: {
    food_name: '',
    food_type: 'Generic',
    food_brand: null,
    measurement_description: '인분',
    metric_serving_unit: 'g',
    metric_serving_amount: '',
    number_of_units: 1,
    calories: null,
    carbohydrate: null,
    sugar: null,
    sugar_sub: null,
    fiber: null,
    protein: null,
    fat: null,
    saturated_fat: null,
    monounsaturated_fat: null,
    polyunsaturated_fat: null,
    trans_fat: null,
    sodium: null,
    cholesterol: null,
    potassium: null,
    created_at: '',
  },
});

export const modifyFoodIdState = atom<number | null>({
  key: 'modifyFoodIdState',
  default: null,
});

// 식단 리스트 수정시 amount를 유지시키기 위한 state
export const mealListEditAmountState = atom({
  key: 'mealListEditAmountState',
  default: {
    save_type: 0,
    metric_serving_amount: '1',
    number_of_units: 1,
  },
});

export const mealListState = atom<FoodDetailForMealList[]>({
  key: 'mealListState',
  default: [],
});

export const foodDetailMetricTypeState = atom<'serving' | 'unit'>({
  key: '#foodDetailMetricTypeState',
  default: 'serving',
});

export const foodDetailAmountState = atom<number | string>({
  key: '#foodDetailAmountState',
  default: 1,
});
