import { tokenReissue } from 'apis/profileApi';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { isMaintainState } from 'states/MaintainState';
import { deleteCookie, setCookie } from 'utils/cookie';
import { ACCESS_TOKEN } from './link';

const RedirectHandler: React.FC = () => {
  const { type, token } = useParams() as { type: string; token: string };
  const setIsMaintain = useSetRecoilState(isMaintainState);

  /**
   * 1. 처음 로그인 했을 때, 로그인 성공 시 토큰 저장
   * 2. 디바이스 토큰을 통해 로그인 시도 했을 때(=쿠키에 토큰이 사라졌을 때),
   *    디바이스 토큰이 유효한지 확인하고 유효하다면 토큰 재발급 후 쿠키에 저장
   *    (로그인 페이지에서 무한 메인테인 호출을 막기 위해, 트리거(=isMaintain) 설정)
   * 3. 쿠키의 토큰을 통해 로그인 시도 한 경우(이때 type은 undefined 이다)
   */

  const tokenHandler = () => {
    if (type === 'M') {
      tokenReissue(token).then((refresh) => {
        if (!refresh?.status) {
          deleteCookie(ACCESS_TOKEN);
          setIsMaintain(true);
          return;
        }
        setCookie(ACCESS_TOKEN, refresh.token);
      });
      return;
    }
    setCookie(ACCESS_TOKEN, token);
  };

  if (token) {
    tokenHandler();
    return <Navigate to={{ pathname: `/KetoRedirect/${type}` }} />;
  } else {
    return <Navigate to={{ pathname: '/login' }} />;
  }
};

export default RedirectHandler;
