import { COLORS } from '@styles/constants/_colors';
import classNames from 'classnames';
import { MouseEvent, useState } from 'react';
import styled from 'styled-components';

interface Props {
  leftButtonName: string;
  rightButtonName: string;
  onLeftClick: () => void;
  onRightClick: () => void;
  defaultValue?: 'left' | 'right';
  disabledLeft?: boolean;
  disabledRight?: boolean;
  backgroundColor?: string;
  buttonColor?: string;
  buttonDisabledColor?: string;
  activeBackgroundColor?: string;
}

const ToggleButton = ({
  leftButtonName,
  rightButtonName,
  onLeftClick,
  onRightClick,
  defaultValue = 'left',
  disabledLeft,
  disabledRight,
  backgroundColor = '#1c3558',
  buttonColor = COLORS.WHITE,
  buttonDisabledColor = COLORS.PRIMITIVES_NAVY_350,
  activeBackgroundColor = '#36598a',
}: Props) => {
  const [status, setStatus] = useState(defaultValue);

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    const { name } = e.currentTarget;
    if (name === status) return;
    setStatus(name as 'left' | 'right');
    if (name === 'left') {
      onLeftClick();
      return;
    }
    onRightClick();
  };

  return (
    <Container backgroundColor={backgroundColor}>
      <ActiveStatus
        activeBackgroundColor={activeBackgroundColor}
        className={classNames([status])}
      />
      <Button
        onClick={onClick}
        className={classNames(['left', { disabled: disabledLeft }])}
        name="left"
        disabled={disabledLeft}
        buttonColor={buttonColor}
        buttonDisabledColor={buttonDisabledColor}
      >
        {leftButtonName}
      </Button>
      <Button
        className={classNames(['right', { disabled: disabledRight }])}
        onClick={onClick}
        name="right"
        disabled={disabledRight}
        buttonColor={buttonColor}
        buttonDisabledColor={buttonDisabledColor}
      >
        {rightButtonName}
      </Button>
    </Container>
  );
};

const Container = styled.div<{ backgroundColor: string }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-radius: 120px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Button = styled.button<{ buttonColor: string; buttonDisabledColor: string }>`
  width: 100%;
  padding: 0px 19px;

  font-family: Campton, 'Noto Sans KR';
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ buttonColor }) => buttonColor};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.left {
    margin: 8px 0px 8px 8px;
    padding: 0px 16.5px 0 19px;
    font-size: 15px;
  }

  &.right {
    margin: 8px 8px 8px 0px;
    padding-bottom: 3px;
    font-size: 17px;
  }

  &.disabled {
    color: ${({ buttonDisabledColor }) => buttonDisabledColor};
  }
`;

const ActiveStatus = styled.div<{ activeBackgroundColor: string }>`
  position: absolute;
  top: 8px;
  width: calc(50% - 8px);
  height: 44px;
  background-color: ${({ activeBackgroundColor }) => activeBackgroundColor};
  border-radius: 120px;
  transition: 0.5s;

  &.left {
    transform: translateX(8px);
  }

  &.right {
    transform: translateX(calc(100% + 8px));
  }
`;

export default ToggleButton;
