import AsyncBoundary from '@components/AsyncBoundary';
import EmptySkeleton from '@components/Atoms/Skeleton/EmptySkeleton';
import Layout from '@components/Layout/Layout';
import { useDate } from '@hooks/useDate';
import { useDidMountEffect } from '@hooks/useDidmountEffect';
import useQueryString from '@hooks/useQueryString';
import useFoodSearchQuery from '@pages/search/hooks/useFoodSearchQuery';
import BasketSearchBar from '@pages/search/molecules/BasketSearchBar';
import { foodBasketListState } from '@states/BasketState';
import { COLORS } from '@styles/constants/_colors';
import debounce from 'lodash/debounce';
import { ChangeEvent, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { createSearchParams, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import FoodResultContent from './FoodResultContent';
import FoodResultFilterButtons from './FoodResultFilterButtons';

const FoodResult = () => {
  const navigate = useNavigate();
  const navigateType = useNavigationType();
  const { mealId } = useParams() as { mealId: string };

  const inputRef = useRef<HTMLInputElement>(null);
  const { date } = useDate();

  const {
    queries: [query, filter],
    setSearchParams,
  } = useQueryString(['keyword', 'filter']);
  const { data } = useFoodSearchQuery({ staleTime: 300 });

  const basket = useRecoilValue(foodBasketListState);
  const [keyword, setKeyword] = useState(query);

  const onClickBasket = () => {
    navigate(`/basket/food/reg/${mealId}/${date}`);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setKeyword(value);
  };

  const onClear = () => {
    setKeyword('');
  };

  const onEnter = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur();
    }
  }, []);

  const debouncedSearchParams = useCallback(
    debounce((value: string) => {
      setSearchParams(
        createSearchParams({
          keyword: value,
          filter: filter,
        }),
        { replace: true },
      );
    }, 300),
    [],
  );

  const skeletons = new Array(7).fill(1).map((_, index) => {
    const skeletonKey = `FoodSearchResult-${index}`;
    return <EmptySkeleton height={83} key={skeletonKey} />;
  });

  useLayoutEffect(() => {
    if (navigateType === 'POP') return;
    inputRef.current?.focus();
  }, [inputRef, navigateType]);

  useDidMountEffect(() => {
    debouncedSearchParams(keyword);
  }, [keyword]);

  return (
    <Layout bgColor={COLORS.PRIMITIVES_GRAY_60}>
      <Layout.Headers bgColor={COLORS.WHITE}>
        <BasketSearchBar
          ref={inputRef}
          value={keyword}
          onChange={onChange}
          onClickBasket={onClickBasket}
          count={basket.length}
          placeholder="무슨 음식을 드셨나요?"
          onClear={onClear}
          onKeyUp={onEnter}
          borderBottomLineColor={COLORS.PRIMITIVES_GRAY_BLUE_100}
        />
        {query.length !== 0 && data && data?.pages?.[0]?.data?.length > 0 && (
          <FoodResultFilterButtons />
        )}
      </Layout.Headers>
      <Layout.Contents>
        <AsyncBoundary fallback={<SkeletonField>{skeletons}</SkeletonField>}>
          <FoodResultContent />
        </AsyncBoundary>
      </Layout.Contents>
    </Layout>
  );
};

const SkeletonField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 132px 20px 24px;

  overflow: scroll;
  width: 100%;
  height: 100%;
`;

export default FoodResult;
