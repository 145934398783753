import useTopModal from 'hooks/useTopModal';
import uniqueId from 'lodash/uniqueId';
import { ComponentProps, FunctionComponent, useEffect } from 'react';
import SecurityRecommendModal from '../SecurityRecommendModal';
import { useLocation, useNavigationType } from 'react-router-dom';
import AsyncBoundary from '@components/AsyncBoundary';

export const topModals = {
  securityRecommend: SecurityRecommendModal as FunctionComponent<
    ComponentProps<typeof SecurityRecommendModal>
  >,
};

const TopModal = () => {
  const navigationType = useNavigationType();
  const { modals, closeModal } = useTopModal();
  const { pathname } = useLocation();

  useEffect(() => {
    if (navigationType === 'POP') {
      // eslint-disable-next-line react/prop-types
      modals.map(({ Component, props }) => !props.isMaintain && closeModal(Component));
    }
  }, [pathname]);

  return (
    <AsyncBoundary>
      {modals.map(({ Component, props }, idx) => {
        const key = `top-modal-${uniqueId()}-${idx}`;
        return <Component key={key} {...props} />;
      })}
    </AsyncBoundary>
  );
};

export default TopModal;
