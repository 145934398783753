import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

const BottomSheetButtons = ({ children }: Props) => {
  return <>{children}</>;
};

const getBottomSheetButtons = (<BottomSheetButtons />).type;

export { BottomSheetButtons, getBottomSheetButtons };
