import useQueryString from '@hooks/useQueryString';
import { CircularProgress } from '@material-ui/core';
import useFoodSearchQuery from '@pages/search/hooks/useFoodSearchQuery';
import { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import FoodItem from '../FoodItem';
import FoodResultNone from './FoodResultNone';
import FoodResultRecently from './FoodResultRecently';
import FoodResultRegisterButton from './FoodResultRegisterButton';

const FoodResultContent = () => {
  const [ref, inView] = useInView();

  const { data, isLoading, isFetching, fetchNextPage, refetch } = useFoodSearchQuery({
    suspense: true,
  });
  const {
    queries: [query],
  } = useQueryString(['keyword']);

  const items = data?.pages.map((page, index) => {
    return (
      <Fragment key={`page-${index}`}>
        {page?.data.map((item, idx) => (
          <FoodItem key={`index-${idx}-${item.food_idx}`} food={item} />
        ))}
      </Fragment>
    );
  });

  useEffect(() => {
    if (!data) return;
    const pageLastIdx = data.pages.length - 1;
    const isMore = data.pages[pageLastIdx].isMore;
    if (isMore && inView && !isLoading) fetchNextPage();
  }, [inView]);

  if (query.length === 0) return <FoodResultRecently />;

  if (!data || data?.pages?.[0]?.data?.length === 0) {
    return (
      <EmptyWrapper>
        <FoodResultRegisterButton onRefetch={refetch} />
        <FoodResultNone paddingTop={254} text="검색 결과가 없어요" />
      </EmptyWrapper>
    );
  }

  return (
    <ListField>
      <ListBox>
        {items}
        <ProgressBarWrapper ref={ref}>
          {isFetching && <CircularProgress color="inherit" size={20} />}
        </ProgressBarWrapper>
      </ListBox>
    </ListField>
  );
};

const ListField = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 120px;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
  padding: 0px 20px 24px;
`;

const ProgressBarWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 65px;
`;

export default FoodResultContent;
