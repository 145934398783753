interface Props {
  color: string;
  weight?: 'default' | 'bold' | 'small';
}

export default function PlusBox({ color, weight = 'default' }: Props) {
  const DEFAULT_PLUS_BOX = (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 25H32" stroke={color} strokeWidth="1.8" strokeLinecap="round" />
      <path d="M25 32L25 18" stroke={color} strokeWidth="1.8" strokeLinecap="round" />
    </svg>
  );
  const BOLD_PLUS_BOX = (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.4399 27.0015H34.5599" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M27 34.5615L27 19.4415" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  );
  const SMALL_PLUS_BOX = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 8.0002H14.5" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
      <path d="M8.00051 14.5V1.5" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
    </svg>
  );

  const PLUS_BOX = {
    default: DEFAULT_PLUS_BOX,
    bold: BOLD_PLUS_BOX,
    small: SMALL_PLUS_BOX,
  }[weight];

  return PLUS_BOX;
}
