import { Grid, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  active: boolean;
  size: 'medium' | 'small';
}

export default function GreenSmile({ active, size }: Props) {
  const classes = useStyles();

  const fragment = (() => {
    switch (size) {
      case 'medium':
        return {
          icon: active ? (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="29.5" cy="29.5" r="27.5" fill="#4FE685" />
              <path
                d="M23.549 34.8057C24.1922 38.0554 26.8094 39.7828 29.5508 39.7828C32.5887 39.7828 34.9795 38.0654 35.5665 34.8115"
                stroke="black"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <ellipse cx="20.425" cy="26.75" rx="2.75" ry="2.75" fill="black" />
              <ellipse cx="38.5376" cy="26.75" rx="2.75" ry="2.75" fill="black" />
            </svg>
          ) : (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="29.5" cy="29.5" r="27.5" fill="#E6E7EA" />
              <path
                d="M23.549 34.8057C24.1922 38.0554 26.8094 39.7828 29.5508 39.7828C32.5887 39.7828 34.9795 38.0654 35.5665 34.8115"
                stroke="#999FAB"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <ellipse cx="20.425" cy="26.75" rx="2.75" ry="2.75" fill="#999FAB" />
              <ellipse cx="38.5376" cy="26.75" rx="2.75" ry="2.75" fill="#999FAB" />
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.0002" cy="16.0002" r="14.9153" fill="#4FE685" />
              <path
                d="M12.7725 18.8774C13.1213 20.64 14.5409 21.5769 16.0277 21.5769C17.6754 21.5769 18.9721 20.6454 19.2904 18.8806"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="11.078" cy="14.5086" rx="1.49152" ry="1.49153" fill="black" />
              <ellipse cx="20.9015" cy="14.5086" rx="1.49152" ry="1.49153" fill="black" />
            </svg>
          ),
        };
      default:
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.0002" cy="16.0002" r="14.9153" fill="#4FE685" />
              <path
                d="M12.7725 18.8774C13.1213 20.64 14.5409 21.5769 16.0277 21.5769C17.6754 21.5769 18.9721 20.6454 19.2904 18.8806"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="11.078" cy="14.5086" rx="1.49152" ry="1.49153" fill="black" />
              <ellipse cx="20.9015" cy="14.5086" rx="1.49152" ry="1.49153" fill="black" />
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}

const useStyles = makeStyles(() => createStyles({}));
