import { createGlobalStyle } from 'styled-components';

import cx from 'classnames';
export { cx };

import font from './base/_font';

const GlobalStyle = createGlobalStyle`
  ${font}
`;

export default GlobalStyle;
