import { deviceOs } from 'modules/platformCheck';
import { useEffect } from 'react';

const usePostMessageReceiver = (postMessageListener: any) => {
  useEffect(() => {
    if (deviceOs === 'ios') {
      window.addEventListener('message', postMessageListener);
      return () => {
        window.removeEventListener('message', postMessageListener);
      };
    }

    document.addEventListener('message', postMessageListener);
    return () => {
      document.removeEventListener('message', postMessageListener);
    };
  }, []);
};

export default usePostMessageReceiver;
