import useQueryString from '@hooks/useQueryString';
import { COLORS } from '@styles/constants/_colors';
import { createSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const FoodResultFilterButtons = () => {
  const {
    queries: [query, filter],
    setSearchParams,
  } = useQueryString(['keyword', 'filter']);

  const onClickFilter = (value: string) => {
    setSearchParams(
      createSearchParams({
        keyword: query,
        filter: value,
      }),
      { replace: true },
    );
  };

  const filterList = [
    {
      name: '전체',
      value: '',
    },
    { name: '유저 등록 제외', value: 'userExclude' },
  ];

  const filters = filterList.map((item, index) => (
    <FilterBox
      key={index}
      isActive={filter === item.value}
      onClick={() => onClickFilter(item.value)}
    >
      {item.name}
    </FilterBox>
  ));

  return <FilterField>{filters}</FilterField>;
};

const FilterField = styled.div`
  display: flex;
  padding: 12px 16px;
  gap: 8px;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
`;

const FilterBox = styled.button<{ isActive: boolean }>`
  height: 32px;
  padding: 9px 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: ${({ isActive }) => (isActive ? COLORS.WHITE : COLORS.BLACK)};
  background-color: ${({ isActive }) => (isActive ? COLORS.BLACK : COLORS.WHITE)};
  border-radius: 120px;
`;

export default FoodResultFilterButtons;
