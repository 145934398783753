import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { RecipePath } from '@constants/internalPath';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const RecipeResisterPage = lazy(() => import('@pages/recipe/RecipeResisterPage'));
const RecipeIngredientDetailPage = lazy(() => import('@pages/recipe/RecipeIngredientDetailPage'));
const RecipeDetailPage = lazy(() => import('@pages/recipe/RecipeDetailPage'));

export default function RecipeRouter() {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path={RecipePath.RECIPE_REGISTRATION} element={<RecipeResisterPage />} />;
        <Route path={RecipePath.INGREDIENT_DETAIL} element={<RecipeIngredientDetailPage />} />
        <Route path={RecipePath.RECIPE_DETAIL} element={<RecipeDetailPage />} />
      </Routes>
    </Suspense>
  );
}
