import { MouseEventHandler } from 'react';

interface Props {
  children: React.ReactNode;
  onClick?: MouseEventHandler;
}

export default function IconButtonWrapper({ children, onClick }: Props) {
  return <button onClick={onClick}>{children}</button>;
}
