import dayjs, { Dayjs } from 'dayjs';
import { atom } from 'recoil';
import {
  CalenderType,
  DashboardCategoryType,
  DiaryForm,
  StickerCategoryType,
} from '@models/sticker';

export const diaryFormState = atom<DiaryForm>({
  key: '#stickerFormState',
  default: {
    date: '',
    stickerList: [],
    memo: '',
  },
});

export const isCalenderModifyState = atom<boolean>({
  key: '#isCalenderModifyState',
  default: false,
});

export const selectDateState = atom<Dayjs>({
  key: '#selectDateState',
  default: dayjs(),
});

export const calenderTabState = atom<CalenderType>({
  key: '#calenderTabState',
  default: 'calender',
});

export const stickerCategoryState = atom<StickerCategoryType>({
  key: '#stickerCategoryState',
  default: 'mood',
});

export const dashboardCategoryState = atom<DashboardCategoryType>({
  key: '#calenderCategoryState',
  default: 'food',
});
