import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';
import { ChangeEvent, KeyboardEvent, useCallback, useRef } from 'react';

type convertHandlers = {
  [key: string]: () => void;
  default: () => void;
};

interface Props {
  pickerTime: { hour: string; minute: string };
  setPickerTime: React.Dispatch<React.SetStateAction<{ hour: string; minute: string }>>;
}

const DurationPicker = ({ pickerTime, setPickerTime }: Props) => {
  const hourRef = useRef<HTMLInputElement>(null);
  const minRef = useRef<HTMLInputElement>(null);

  const handleChangeTime = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const name = event.target.name;
      const convert: convertHandlers = {
        '.': () => {
          return '';
        },
        '00': () => {
          setPickerTime((prev) => {
            return {
              ...prev,
              [name]: name === 'minute' ? '00' : '',
            };
          });
        },
        '': () => {
          setPickerTime((prev) => {
            return {
              ...prev,
              [name]: '',
            };
          });
        },
        default: () => {
          if (name === 'hour') {
            const regExp = /^[0-9]{0,2}$/;
            if (regExp.test(value)) {
              if (Number(value) > 100) {
                setPickerTime((prev) => {
                  return {
                    ...prev,
                    hour: '99',
                  };
                });
                return;
              }
              setPickerTime((prev) => {
                return {
                  ...prev,
                  hour: value,
                };
              });
            }
            return;
          } else {
            const regExp = /^[0-9]{0,2}$/;
            if (regExp.test(value)) {
              if (Number(value) > 59) {
                setPickerTime((prev) => {
                  return {
                    ...prev,
                    minute: '59',
                  };
                });
                return;
              }

              setPickerTime((prev) => {
                return {
                  ...prev,
                  minute: value,
                };
              });
            }
          }
        },
      };

      if (name === 'hour' && Number(value) > 0 && value.length === 2) {
        minRef.current?.focus();
      }
      (convert[value] || convert.default)();
    },
    [setPickerTime],
  );

  const hourValueHadler = () => {
    if (
      pickerTime.hour.length === 1 &&
      Number(pickerTime.hour) === 0 &&
      pickerTime.minute !== '00'
    ) {
      setPickerTime((prev) => {
        return {
          ...prev,
          hour: '00',
        };
      });
    } else if (
      pickerTime.hour.length === 1 &&
      !(pickerTime.hour === '0' && pickerTime.minute === '00')
    ) {
      setPickerTime((prev) => {
        return {
          ...prev,
          hour: `0${pickerTime.hour}`,
        };
      });
    } else if (Number(pickerTime.hour) > 100) {
      setPickerTime((prev) => {
        return {
          ...prev,
          hour: `99`,
        };
      });
    } else if (
      (pickerTime.hour === '00' || pickerTime.hour === '0') &&
      pickerTime.minute === '00'
    ) {
      setPickerTime((prev) => {
        return {
          ...prev,
          hour: '',
          minute: '',
        };
      });
    }
  };

  const minValueHadler = () => {
    if (
      pickerTime.minute.length === 1 &&
      !(pickerTime.hour === '00' && pickerTime.minute === '0')
    ) {
      setPickerTime((prev) => {
        return {
          ...prev,
          minute: `0${pickerTime.minute}`,
        };
      });
    } else if (
      pickerTime.hour === '00' &&
      (pickerTime.minute === '0' || pickerTime.minute === '00')
    ) {
      setPickerTime((prev) => {
        return {
          ...prev,
          minute: '',
          hour: '',
        };
      });
    }
  };

  const minToHourWithBackspace = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && pickerTime.minute.length === 0) {
      hourRef.current?.focus();
    }
  };

  return (
    <Wrapper>
      <TimeInputField>
        <TimeBox>
          <Time>시간</Time>
          <TimeInput
            ref={hourRef}
            name="hour"
            onChange={handleChangeTime}
            onBlur={hourValueHadler}
            placeholder="00"
            inputMode="numeric"
            pattern="[0-9]*"
            value={pickerTime.hour}
          />
          <Line />
        </TimeBox>
        <Text isColorFill={!!pickerTime.minute}>:</Text>
        <TimeBox>
          <Time>분</Time>
          <TimeInput
            ref={minRef}
            name="minute"
            onChange={handleChangeTime}
            onBlur={minValueHadler}
            onKeyDown={minToHourWithBackspace}
            placeholder="00"
            inputMode="numeric"
            value={pickerTime.minute}
          />
          <Line />
        </TimeBox>
      </TimeInputField>
    </Wrapper>
  );
};

export default DurationPicker;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 59px 0px 50px;
  position: relative;
`;

const TimeInputField = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 30px;
  position: relative;
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 54px;
  position: relative;
`;

const Time = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.5px;
  text-align: left;
  color: ${COLORS.PRIMITIVES_GRAY_500};
`;

const TimeInput = styled.input`
  font-family: Campton;
  width: 100%;
  font-size: 38px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.BLACK};

  ::placeholder {
    font-family: Campton;
    font-size: 38px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -0.5px;
    text-align: left;
    color: ${COLORS.PRIMITIVES_GRAY_400};
  }
`;

const Line = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 1px;
  background-color: ${COLORS.PRIMITIVES_GRAY_400};
`;

const Text = styled.p<{ isColorFill: boolean }>`
  font-family: Campton;
  font-size: 38px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.5px;
  color: ${({ isColorFill }) => (isColorFill ? COLORS.BLACK : COLORS.PRIMITIVES_GRAY_400)};
  position: absolute;
  bottom: 0px;
  left: 50%;
  bottom: 8px;
  transform: translateX(-50%);
`;
