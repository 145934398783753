import { get, patch, post } from '../modules/HttpWebClient/default';
import {
  AdKind,
  AdContent,
  EventType,
  EventBoardDetail,
  EventBoardLikeRequest,
  EventBoardCommentRequest,
  EventBoardReplyRequest,
} from '@models/ad';

/** 광고 배너*/
export async function getAdContents(kind: AdKind): Promise<AdContent[]> {
  const url = `/v2/ads/${kind}`;
  const { data } = await get<AdContent[]>(url);
  return data;
}

export async function countViewBanner(ad_idx: number) {
  const url = `/v2/ads/${ad_idx}:view`;
  await patch(url);
}

export async function countClickBanner(ad_idx: number) {
  const url = `/v2/ads/${ad_idx}:click`;
  await patch(url);
}

/** 이벤트 */
export async function getEventList() {
  const url = `v2/events`;
  const { data } = await get<EventType[]>(url);
  return data;
}

export async function getEventDetail(
  board_idx: string,
  member_idx: number,
): Promise<EventBoardDetail> {
  const url = `/v2/events/${board_idx}?member_idx=${member_idx}`;
  const { data } = await get<EventBoardDetail>(url);
  return data;
}

export async function patchEventDetailView(board_idx: string) {
  const url = `/v2/events/${board_idx}:view`;
  await patch(url);
}

export async function postEventDetailLike({ board_idx, request }: EventBoardLikeRequest) {
  const url = `/v2/events/${board_idx}:rate`;
  await patch(url, { ...request });
}

export async function postEventComment({ board_idx, request }: EventBoardCommentRequest) {
  const url = `/v2/events/${board_idx}/comments`;
  await post(url, { ...request });
}

export async function postEventReplyComment({
  board_idx,
  comment_idx,
  request,
}: EventBoardReplyRequest) {
  const url = `/v2/events/${board_idx}/comments/${comment_idx}`;
  await post(url, { ...request });
}
