import { makeStyles, Grid, createStyles } from '@material-ui/core';
import classNames from 'classnames';

interface Props {
  headText: string | React.ReactNode;
  subText1?: string;
  subText2?: string;
  buttonText1: string;
  buttonText2?: string;
  onClick?(): void;
  cancel?(): void;
  popupOpen: boolean;
  setPopupOpen(state: boolean): void;
}

export default function PopUpPage({
  headText,
  subText1,
  subText2,
  buttonText1,
  buttonText2,
  onClick = () => undefined,
  cancel = () => undefined,
  popupOpen,
  setPopupOpen,
}: Props) {
  const classes = useStyle();
  // const [popUpOpen, setPopUpOpen] = useRecoilState(updatePopUpState);

  const action = () => {
    onClick();
    setPopupOpen(false);
  };

  const closePopUp = () => {
    cancel();
    setPopupOpen(false);
  };

  return (
    <>
      {popupOpen && <div className={classes.wrap} onClick={closePopUp} />}
      <Grid
        className={classNames([classes.modalContent, popupOpen && classes.open])}
        container
        direction={'column'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Grid
          container
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          className={classes.contentContainer}
        >
          <p>{headText}</p>
          {(subText1 || subText2) && (
            <span>
              {subText1}
              <br />
              {subText2}
            </span>
          )}
        </Grid>

        <Grid
          className={classes.buttonContainer}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <p onClick={closePopUp}>{buttonText1}</p>
          {buttonText2 && (
            <>
              <div />
              <p onClick={action}>{buttonText2}</p>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() =>
  createStyles({
    wrap: {
      position: 'fixed',
      top: 0,
      width: '100%',
      height: '100vh',
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 3000,
    },
    modalContent: {
      position: 'fixed',
      zIndex: 3001,
      top: '100%',
      left: 30,
      width: 'calc(100vw - 60px)',
      height: 200,
      borderRadius: 10,
      background: '#fff',
      transition: 'all 200ms ease',
    },
    open: {
      top: 'calc(50vh - 104px)',
    },
    contentContainer: {
      height: 150,
      paddingTop: 15,
      '& p': {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: 1.3,
        letterSpacing: -0.58,
        textAlign: 'center',
      },
      '& span': {
        fontSize: 13,
        color: '#999fab',
        margin: '15px auto 0px',
        textAlign: 'center',
        lineHeight: 1.33,
        letterSpacing: -0.46,
      },
    },
    buttonContainer: {
      height: 16,
      marginBottom: 34,
      '& p': {
        width: 'calc(50% - 1px)',
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'center',
      },
      '& div': {
        width: 1,
        height: 16,
        background: '#d3dbe0',
      },
    },
  }),
);
