import React from 'react';
import styled from 'styled-components';
import CalenderPicker from './CalenderPicker';
import { DashboardCategoryType } from '@models/sticker';
import dayjs from 'dayjs';
import { COLORS } from '@styles/constants/_colors';
import { calenderCategories } from '@structures/calender';
import { useRecoilValue } from 'recoil';
import { selectDateState } from '@pages/calenderDiary/states';

interface Props {
  category: DashboardCategoryType;
  day: number;
  onClick?: () => void;
  value?: number;
  selected?: boolean;
}

const DashboardItem = ({ category, value, day, selected, onClick }: Props) => {
  const todayDate = dayjs();
  const selectedDate = useRecoilValue(selectDateState);
  const isToday = todayDate.format('YYYY-MM-D') === selectedDate.format('YYYY-MM-') + day;
  const isOtherSelected = todayDate.format('DD') !== selectedDate.format('DD');

  const usedCategoryInfo = calenderCategories.find(({ type }) => type === category);

  const displayValue = () => {
    if (value === undefined) return '-';
    if (value === 0) return value;
    if (category === 'weight') return value;

    if (category === 'water') {
      let formattedNumber = (value / 1000).toFixed(2);

      // 소수점 이하의 0 제거
      formattedNumber = formattedNumber.replace(/\.?0+$/, '');

      return formattedNumber;
    }

    return (
      {
        food: '+',
        exercise: '-',
      }[category] + Math.round(value)
    );
  };

  const displayUnit = () => {
    if (!value) return '';
    if (category === 'food' || category === 'exercise') return '';

    return {
      weight: 'kg',
      water: 'L',
    }[category];
  };

  return (
    <Wrapper onClick={onClick}>
      <CalenderItemBox>
        <CalenderBox $isActive={value !== undefined} $activeColor={usedCategoryInfo?.color}>
          <CalenderDateUnit>{day}</CalenderDateUnit>
        </CalenderBox>
      </CalenderItemBox>
      <ValueBox>
        <ValueUnit>{displayValue()}</ValueUnit>
        <SuffixUnit>{displayUnit()}</SuffixUnit>
      </ValueBox>
      <CalenderPicker isToday={isToday} selected={selected} otherSelected={isOtherSelected} />
    </Wrapper>
  );
};

export const Wrapper = styled.td`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 7px;
`;

const CalenderItemBox = styled.div`
  width: 44px;
  height: 44px;
  padding: 1px;
`;

const CalenderBox = styled.div<{ $isActive: boolean; $activeColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  color: ${({ $isActive }) => ($isActive ? COLORS.WHITE : COLORS.BLACK)};
  background-color: ${({ $isActive, $activeColor }) =>
    $isActive ? $activeColor : COLORS.PRIMITIVES_GRAY_70};

  border-radius: 50%;
`;

const CalenderDateUnit = styled.div`
  padding-bottom: 1px;
  font-family: Campton;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.07px;
`;

const ValueBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 14px;
  gap: 1px;

  color: ${COLORS.PRIMITIVES_GRAY_450};
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.437px;
  text-align: center;
`;

const ValueUnit = styled.div``;

const SuffixUnit = styled.div``;

export default DashboardItem;
