import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import useTopModal from '@hooks/useTopModal';
import Popup from '@components/elements/Popup/Popup';

interface Props {
  headText: string;
  subText1?: string | React.ReactNode;
  subText2?: string;
  buttonText1: string;
  buttonText2?: string;
  isOneLineSubText?: boolean;
  onClick?(): void;
  cancel?(): void;
}

export default function PopupBase({
  headText,
  subText1,
  subText2,
  isOneLineSubText = false,
  buttonText1,
  buttonText2,
  onClick = () => undefined,
  cancel = () => undefined,
}: Props) {
  const { closeModal } = useTopModal();
  const isNoSubText = !subText1 && !subText2;
  const isTwoLineHeadText = headText.includes('\n');
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyle({ isTwoLineHeadText, isNoSubText });

  const handleClick = () => {
    onClick();
    closeModal(Popup);
  };

  const handleCancel = () => {
    cancel();
    closeModal(Popup);
  };

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  return (
    <Grid
      className={classNames([classes.modalContent, isOpen && classes.open])}
      container
      direction={'column'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Grid
        container
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        className={classes.contentContainer}
      >
        <p>{headText}</p>
        {(subText1 || subText2) && (
          <span className={classNames(['subText', isOneLineSubText && 'oneLine'])}>
            {subText1}
            <br />
            {subText2}
          </span>
        )}
      </Grid>

      <Grid
        className={classes.buttonContainer}
        container
        justifyContent={'center'}
        alignItems={'center'}
      >
        <p onClick={handleCancel}>{buttonText1}</p>
        {buttonText2 && (
          <>
            <div />
            <p onClick={handleClick}>{buttonText2}</p>
          </>
        )}
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles<Theme, { isTwoLineHeadText: boolean; isNoSubText: boolean }>(() =>
  createStyles({
    wrap: {
      position: 'fixed',
      top: 0,
      width: '100%',
      height: '100vh',
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 3000,
    },
    modalContent: {
      position: 'fixed',
      zIndex: 3001,
      paddingTop: 10,
      top: '100%',
      left: 30,
      width: 'calc(100vw - 60px)',
      height: ({ isTwoLineHeadText }) => (isTwoLineHeadText ? 225 : 200),
      borderRadius: 10,
      background: '#fff',
      transition: 'all 200ms ease',
      color: 'black',
    },
    open: {
      top: 'calc(50vh - 104px)',
    },
    contentContainer: {
      height: 'calc(100% - 50px)',
      paddingTop: ({ isNoSubText }) => (isNoSubText ? 15 : 10),
      '& p': {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: '21px',
        letterSpacing: -0.58,
        whiteSpace: 'pre-line',
        textAlign: 'center',
      },
      '& .subText': {
        fontSize: 13,
        color: '#999fab',
        margin: '13px auto 0px',
        textAlign: 'center',
        lineHeight: 1.6,
        letterSpacing: -0.46,
      },
      '& .oneLine': {
        marginTop: 10,
      },
      '& span': {
        whiteSpace: 'pre-wrap',
      },
    },
    buttonContainer: {
      height: 16,
      marginBottom: 34,
      '& p': {
        width: 'calc(50% - 1px)',
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'center',
      },
      '& div': {
        width: 1,
        height: 16,
        background: '#d3dbe0',
      },
    },
  }),
);
