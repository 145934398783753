import { ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
  fallbackError?: string | ReactNode;
}

const AsyncBoundary = ({ fallbackError, fallback, children }: Props) => {
  return (
    <ErrorBoundary fallback={<>{fallbackError}</>}>
      <Suspense fallback={fallback ?? <></>}>{children}</Suspense>
    </ErrorBoundary>
  );
};

export default AsyncBoundary;
