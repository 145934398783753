import React from 'react';
import styled from 'styled-components';
import { SelectPickerIcon, TodayInActivePickerIcon, TodayPickerIcon } from '@assets/svgs/sticker';

interface Props {
  isToday?: boolean;
  otherSelected?: boolean;
  selected?: boolean;
}
const CalenderPicker = ({ isToday, otherSelected, selected }: Props) => {
  const getDisplayPicker = () => {
    if (isToday) {
      if (otherSelected) return <TodayInActivePickerIcon />;

      return <TodayPickerIcon />;
    }
    if (selected) return <SelectPickerIcon />;
  };

  return <Wrapper>{getDisplayPicker()}</Wrapper>;
};

const Wrapper = styled.div`
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
`;

export default CalenderPicker;
