import axios, { AxiosRequestConfig } from 'axios';
import { ACCESS_TOKEN, BACKURL } from '../../shared/link';
import { deleteCookie, getCookie } from '../../utils/cookie';

export const defaultAxios = axios.create({
  baseURL: BACKURL,
});

export async function get<T>(url: string, config?: AxiosRequestConfig) {
  return defaultAxios.get<T>(url, config);
}

export async function post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return defaultAxios.post<T>(url, data, config);
}

export async function put<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return defaultAxios.put<T>(url, data, config);
}

export async function patch<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return defaultAxios.patch<T>(url, data, config);
}

export async function del<T>(url: string, config?: AxiosRequestConfig) {
  return defaultAxios.delete<T>(url, config);
}

defaultAxios.interceptors.request.use((config) => {
  const token = getCookie('is_login');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

defaultAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errCode = error.response.status;
    if (errCode === 409) {
      deleteCookie(ACCESS_TOKEN);
      window.location.href = '/#/login';
    } else if (errCode === 500) {
      //
    }

    return Promise.reject(error);
  },
);

interface SuccessWebResponse<T> {
  data: T;
  error: false;
}
interface ErrorWebResponse {
  error: true;
  message: string;
  code: string;
}
export const cancelSource = axios.CancelToken.source();
export const handlerPreventApiOverlap = () => cancelSource.cancel();
export type WebResponse<T> = SuccessWebResponse<T> | ErrorWebResponse;
