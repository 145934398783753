import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';
import { KakaoLogo, AppleLogo, NormalLogo, NaverLogo, GoogleLogo } from '@assets/svgs/login';

interface Props {
  platform: 'kakao' | 'naver' | 'apple' | 'google' | 'keto';
  onClick?: () => void;
}

const LoginButton = ({ platform, onClick }: Props) => {
  const config = {
    kakao: {
      background: '#FFEA2F',
      color: '#2D1C0F',
      text: '카카오로 로그인',
      logo: <KakaoLogo />,
    },
    apple: {
      background: COLORS.BLACK,
      color: COLORS.WHITE,
      text: '로 로그인',
      logo: <AppleLogo />,
    },
    google: {
      background: COLORS.WHITE,
      color: COLORS.BLACK,
      text: '구글로 로그인',
      border: `1px solid ${COLORS.PRIMITIVES_GRAY_350}`,
      logo: <GoogleLogo />,
    },
    naver: {
      background: '#09CE5D',
      color: COLORS.WHITE,
      text: '네이버로 로그인',
      logo: <NaverLogo />,
    },
    keto: {
      background: COLORS.PRIMITIVES_GRAY_70,
      color: COLORS.BLACK,
      text: '아이디 로그인',
      logo: <NormalLogo />,
    },
  };
  return (
    <Button
      onClick={onClick}
      bgColor={config[platform].background}
      border={platform === 'google' ? config[platform].border : undefined}
    >
      {config[platform].logo}
      <Text color={config[platform].color}>
        {platform === 'apple' && <span>Apple</span>}
        {config[platform].text}
      </Text>
    </Button>
  );
};

export default LoginButton;

const Button = styled.button<{ bgColor?: string; border?: string }>`
  width: 100%;
  height: 58px;
  border-radius: 120px;
  background-color: ${({ bgColor }) => bgColor};
  border: ${({ border }) => border && border};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    position: absolute;
    left: 20px;
  }
`;

const Text = styled.p<{ color: string }>`
  font-family: Campton, Noto Sans KR;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.5px;
  color: ${({ color }) => color || COLORS.BLACK};

  span {
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.6px;
  }
`;
