import produce from 'immer';
import {
  LOGINREQUEST,
  LOGINSUCCESS,
  LOGINFAILURE,
  initialStateType,
  initialActionType,
  GETUSERREQUEST,
  GETUSERSUCCESS,
  GETUSERFAILURE,
  SOCIALLOGINSUCCESS,
  REGISTERTERMREQUEST,
  REGISTERTERMSUCCESS,
  REGISTERTERMFAILURE,
  POINTCHECKREQUEST,
  POINTCHECKSUCCESS,
  POINTCHECKFAILURE,
} from './type';

const initialState: initialStateType = {
  loginLoading: false,
  loginDone: false,
  loginError: undefined,
  loginData: {
    join_route: 999,
    member_email: '',
    member_idx: undefined,
    member_name: '',
    member_phone: '',
    profile_check: 999,
    point_yn: 999,
  },
  loginType: undefined,
  registerDone: false,
  registerLoading: false,
  registerError: undefined,
  pointCheckDone: false,
  pointCheckLoading: false,
  pointCheckError: undefined,
};

export const loginReducer = (state: initialStateType = initialState, action: initialActionType) =>
  produce<initialStateType>(state, (draft) => {
    switch (action.type) {
      case GETUSERREQUEST:
        draft.loginLoading = true;
        draft.loginDone = false;
        draft.loginError = undefined;
        break;
      case GETUSERSUCCESS:
        draft.loginLoading = false;
        draft.loginDone = true;
        draft.loginError = undefined;
        draft.loginData = action.data;
        break;
      case GETUSERFAILURE:
        draft.loginLoading = false;
        draft.loginDone = false;
        draft.loginError = action.error;
        break;
      case LOGINREQUEST:
        draft.loginLoading = true;
        draft.loginDone = false;
        draft.loginError = undefined;
        break;
      case LOGINSUCCESS:
        draft.loginLoading = false;
        draft.loginDone = true;
        draft.loginError = undefined;
        draft.loginData.profile_check = action.data.profile_check;
        draft.loginType = action.data.member_join_type;
        break;
      case LOGINFAILURE:
        draft.loginLoading = false;
        draft.loginDone = false;
        draft.loginError = action.error;
        break;
      case SOCIALLOGINSUCCESS:
        draft.loginType = action.data.data;
        break;
      case REGISTERTERMREQUEST:
        draft.registerLoading = true;
        draft.registerDone = false;
        draft.registerError = undefined;
        break;
      case REGISTERTERMSUCCESS:
        draft.registerLoading = false;
        draft.registerDone = true;
        draft.registerError = undefined;
        draft.loginData.join_route = action.data.join_route;
        break;
      case REGISTERTERMFAILURE:
        draft.registerLoading = false;
        draft.registerDone = false;
        draft.registerError = action.error;
        break;
      case POINTCHECKREQUEST:
        draft.pointCheckLoading = true;
        draft.pointCheckDone = false;
        draft.pointCheckError = undefined;
        break;
      case POINTCHECKSUCCESS:
        draft.pointCheckLoading = false;
        draft.pointCheckDone = true;
        draft.pointCheckError = undefined;
        draft.loginData.point_yn = 1;
        break;
      case POINTCHECKFAILURE:
        draft.pointCheckLoading = false;
        draft.pointCheckDone = false;
        draft.pointCheckError = action.error;
        break;
      default:
        break;
    }
  });
