import Add from '@assets/images/icon/Add';
import { XCircleIcon } from '@assets/svgs';
import { StarIcon } from '@assets/svgs/_Icons';
import useToastModal from '@hooks/useToastModal';
import { handleLimitAndSetToast } from '@modules/imgS3';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import { ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import foodEditEx1 from '@assets/images/png/foodEdit/foodEditEx1.png';
import foodEditEx2 from '@assets/images/png/foodEdit/foodEditEx2.png';
import IllustFront from '@assets/images/png/foodEdit/foodEditIllustFront.png';
import IllustBack from '@assets/images/png/foodEdit/foodEditIllustBack.png';
import usePhotoPermissionCheck from '@hooks/usePhotoPermissionCheck';

interface Props {
  images: { index: number; file: File; path: string }[];
  setImages: React.Dispatch<
    React.SetStateAction<
      {
        index: number;
        file: File;
        path: string;
      }[]
    >
  >;
}

const FoodEditImage = ({ images, setImages }: Props) => {
  const { openModal } = useToastModal();
  const { isPhotoDisabled, openPhotoPermissionModal, requestPhotoPermission } =
    usePhotoPermissionCheck();

  const imageIndex = { img0: 0, img1: 1 };
  const imageIllust = [
    { img: IllustFront, name: '제품명' },
    { img: IllustBack, name: '영양정보' },
  ];

  const onDeletePreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { name } = e.currentTarget;
    const index = imageIndex[name as 'img0' | 'img1'] ?? 0;
    setImages(
      images.map((img, i) =>
        i === index
          ? {
              index,
              file: new File([], ''),
              path: '',
            }
          : img,
      ),
    );
  };

  const onPreviewUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { id, files } = e.target;
    const file = files?.[0];
    const buffer = await file?.arrayBuffer();

    if (file && buffer) {
      const cloneFile = new File([buffer], file.name, { type: file.type });

      handleUploadPreview(id, cloneFile);
    }
  };

  const handleUploadPreview = async (id: string, file: File) => {
    if (file === null) return;

    try {
      await handleLimitAndSetToast(file);
      const path = URL.createObjectURL(file);
      const index = imageIndex[id as 'img0' | 'img1'] ?? 0;
      setImages(
        images.map((img, i) =>
          i === index
            ? {
                index,
                file,
                path,
              }
            : img,
        ),
      );
    } catch (error: any) {
      console.log('PREVIEW UPLOAD ERROR');
      const errCode = error?.code || 0;
      if (errCode === 4000) {
        openModal({ children: <p>10MB 이하 이미지만 업로드할 수 있어요</p> });
      }
    }
  };

  const previews = images.map((image, index) => {
    const { path } = image;
    /* eslint-disable no-extra-boolean-cast */
    if (!!path) {
      return (
        <PreviewBox key={index}>
          <img src={path} />
          <PreviewDeleteButton name={`img${index}`} onClick={onDeletePreview}>
            <XCircleIcon />
          </PreviewDeleteButton>
        </PreviewBox>
      );
    }
    return (
      <ImageBox key={index} htmlFor={`img${index}`}>
        <PlusBox>
          <Add size="semiLarge" color={COLORS.PRIMITIVES_GRAY_400} />
        </PlusBox>
        <IllustBox>
          <img src={imageIllust[index].img} />
          <p>{imageIllust[index].name}</p>
        </IllustBox>
      </ImageBox>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    requestPhotoPermission();
  }, []);

  return (
    <Container>
      <Title>{'정확한 확인을 위해\n사진을 올려주세요'}</Title>
      <ImageWrapper>
        <ImageField>
          {isPhotoDisabled && <PermissionCheckArea onClick={openPhotoPermissionModal} />}
          {previews}
        </ImageField>
      </ImageWrapper>
      <TooltipField>
        <TooltipIconBox>
          <StarIcon />
          <p>사진 가이드</p>
        </TooltipIconBox>
        <TooltipImage>
          <img src={foodEditEx1} />
          <img src={foodEditEx2} />
        </TooltipImage>
        <TooltipDescriptionBox>
          <p>{`최대한 정보가 잘 읽히도록\n빛 반사나 왜곡 없이 올려주세요`}</p>
        </TooltipDescriptionBox>
      </TooltipField>
      <input value={''} type="file" accept="image/*" id="img0" onChange={onPreviewUpload} />
      <input value={''} type="file" accept="image/*" id="img1" onChange={onPreviewUpload} />
    </Container>
  );
};

export default FoodEditImage;

const Container = styled.div`
  input[type='file'] {
    display: none;
  }
`;

const Title = styled.div`
  font-family: 'Noto Sans KR';
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.5px;
  white-space: pre-line;

  padding-bottom: 32px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  max-width: 100%;
`;

const ImageField = styled.div`
  display: flex;
  position: relative;
  column-gap: 13px;
`;

const PermissionCheckArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

const ImageBox = styled.label`
  width: 50%;
  aspect-ratio: 1/1.4;
  padding: 18px 18px 36px 18px;
  border-radius: 16px;
  background: ${COLORS.PRIMITIVES_GRAY_60};
`;

const PlusBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 7px;
`;

const IllustBox = styled.div`
  max-width: 125px;
  max-height: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
  }

  p {
    color: ${COLORS.PRIMITIVES_GRAY_450};
    text-align: center;
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.312px;
    margin-top: 9px;
  }
`;

const PreviewBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;
  aspect-ratio: 1/1.4;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
`;

const PreviewDeleteButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 12px;
  width: 28px;
  height: 28px;
`;

const TooltipField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 18px;
  padding: 42px 0px;
`;

const TooltipIconBox = styled.div`
  display: flex;
  column-gap: 6px;

  p {
    color: ${COLORS.BLACK};
    text-align: center;
    font-family: Cafe24 Ssurround;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.485px;
  }
`;

const TooltipImage = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
`;

const TooltipDescriptionBox = styled.div`
  p {
    color: ${FOUNDATION_COLOR.PRIMARY_GRAY};
    text-align: center;
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.7px;
    white-space: pre-line;
  }
`;
