import { ComponentProps, FunctionComponent, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { topModalState } from 'states/modalState';

const useTopModal = () => {
  const [modals, setModals] = useRecoilState(topModalState);

  const openModal = useCallback(
    <T extends FunctionComponent<any>>(
      Component: T,
      props: Omit<ComponentProps<T>, 'open'> & { isMaintain?: boolean },
    ) => {
      setModals((modals) => [
        ...modals,
        { Component, props: { ...props, open: true, isMaintain: props?.isMaintain } },
      ]);
    },
    [setModals],
  );

  const closeModal = useCallback(
    <T extends FunctionComponent<any>>(Component: T) => {
      setModals((modals) => modals.filter((modal) => modal.Component !== Component));
    },
    [setModals],
  );

  return {
    modals,
    openModal,
    closeModal,
  };
};

export default useTopModal;
