import { GNBCloseIcon } from 'assets/svgs';
import { MalrangWithFood } from 'assets/svgs/food';
import ButtonSet from 'components/Atoms/Button/ButtonSet';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #a3be78;
`;

const Header = styled.header`
  padding: 14px 0 7px 20px;
  background: ${COLORS.WHITE};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;

  & .title {
    color: ${COLORS.WHITE};
    font-family: 'Cafe24 Ssurround';
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.5px;
    white-space: pre-line;
    text-align: center;
  }
`;

const SVGWrapper = styled.div`
  margin-bottom: -42px;
  position: relative;
  z-index: 2;
`;

const Content = styled.div`
  padding: 50px 0px 32px;
  width: 100%;
  background: #8da862;
  border-radius: 20px;

  font-family: 'Noto Sans KR';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.5px;
  text-align: center;
  white-space: pre-line;
  color: ${COLORS.WHITE};

  b {
    color: #feff92;
  }

  & .mainContent {
    margin-bottom: 15px;
  }
`;

const Footer = styled.footer`
  background-color: ${COLORS.WHITE};
  padding: 12px 20px;
`;

const FoodRegisterIntro = ({ setStep, onClose }: Props) => {
  const onClick = () => {
    setStep(1);
  };

  return (
    <Container>
      <Header>
        <GNBCloseIcon onClick={onClose} />
      </Header>
      <Body>
        <p className="title">{`직접 등록한 음식은 \n 모든 유저에게 공개!`}</p>
        <SVGWrapper>
          <MalrangWithFood />
        </SVGWrapper>
        <Content>
          <p className="mainContent">
            {`잘못된 정보로 유저들의 신고를 받을 경우 \n`} <b>등록 음식이 삭제</b>될 수 있습니다
          </p>
          <p>
            직접 만든 음식은 <b>나만의 레시피</b>로 등록해 주세요
          </p>
        </Content>
      </Body>
      <Footer>
        <ButtonSet
          text="동의하고 직접 등록 시작"
          size="long"
          type="button"
          color="black"
          isActive
          onClick={onClick}
        />
      </Footer>
    </Container>
  );
};

export default FoodRegisterIntro;
