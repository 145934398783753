import { RegisteredFoodList } from '@models/dietDiary';
import { FoodBasketCartItem } from '@models/searchResult';
import { atom } from 'recoil';

export const foodBasketListState = atom<FoodBasketCartItem[]>({
  key: '#foodBasketListState',
  default: [],
});

export const foodBasketPrevListState = atom<RegisteredFoodList>({
  key: '#foodBasketPrevListState',
  default: {} as RegisteredFoodList,
});
