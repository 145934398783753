import Breakfast from '../assets/images/icon/Breakfast';
import Dinner from '../assets/images/icon/Dinner';
import Lunch from '../assets/images/icon/Lunch';
import Snack from '../assets/images/icon/Snack';
import KetogramCategoryDaily from '../assets/images/png/ketogram-daily.png';
import KetogramCategoryFollowing from '../assets/images/png/ketogram-following.png';
import KetogramCategoryMeal from '../assets/images/png/ketogram-meal.png';
import KetogramCategoryRecipe from '../assets/images/png/ketogram-recipe.png';
import KetogramCategoryRecommend from '../assets/images/png/ketogram-recommend.png';
import { CommunityCategoryItem } from '../types/community';

import BoardCategoryAll from '../assets/images/png/board-all.png';
import BoardCategoryFree from '../assets/images/png/board-free.png';
import BoardCategoryMy from '../assets/images/png/board-my.png';
import BoardCategoryOpinion from '../assets/images/png/board-opinion.png';
import BoardCategoryQuestion from '../assets/images/png/board-question.png';

import Bad from '../assets/images/emoji/Bad';
import Best from '../assets/images/emoji/Best';
import Good from '../assets/images/emoji/Good';
import KetoOut from '../assets/images/emoji/KetoOut';
import Weak from '../assets/images/emoji/Weak';
import GreenSmile from '../assets/images/icon/characters/GreenSmile';
import GreenSmileWithStar from '../assets/images/icon/characters/GreenSmileWithStar';
import LightGreenSmile from '../assets/images/icon/characters/LightGreenSmile';
import LightGreenSoso from '../assets/images/icon/characters/LightGreenSoso';
import PurpleSmileWithStar from '../assets/images/icon/characters/PurpleSmileWithStar';
import RedSad from '../assets/images/icon/characters/RedSad';
import RedSmileWithFire from '../assets/images/icon/characters/RedSmileWithFire';
import InoutCharacterGreen from '../assets/images/inoutScore/InoutCharacterGreen';
import InoutCharacterLightGreen from '../assets/images/inoutScore/InoutCharacterLightGreen';
import InoutCharacterLightPink from '../assets/images/inoutScore/InoutCharacterLightPink';
import InoutCharacterPink from '../assets/images/inoutScore/InoutCharacterPink';
import OnBoardingImage01 from '../assets/images/png/battleOnBoardingPng/battle-onboarding-01.png';
import OnBoardingImage02 from '../assets/images/png/battleOnBoardingPng/battle-onboarding-02.png';
import OnBoardingImage03 from '../assets/images/png/battleOnBoardingPng/battle-onboarding-03.png';
import OnBoardingImage04 from '../assets/images/png/battleOnBoardingPng/battle-onboarding-04.png';
import OnBoardingImage05 from '../assets/images/png/battleOnBoardingPng/battle-onboarding-05.png';
import { DietModeItems } from '../types/profile';

import {
  Angry,
  BigSmile,
  Empathy,
  Fighting,
  Heart,
  Like,
  Mad,
  Sad,
  SmallSmile,
  Smile,
  Straight,
  Surprise,
  Sympathy,
  Tear,
  Wink,
} from 'assets/svgs';
import { COLORS, FOUNDATION_COLOR } from 'styles/constants/_colors';
import { ICategoryItem } from 'types/category';
import { EmojiLikeButton, IEmoji } from 'types/emoji';

interface FragmentProps {
  time: number;
  size?: 'small' | 'semi-medium' | 'medium' | 'semi-large' | 'large';
  color?: string;
}

const fragmentKetogram = ({ time, size = 'large', color = '#000' }: FragmentProps) => {
  switch (time) {
    case 0:
      return {
        icon: <Breakfast size={size} color={color} />,
        background: '#82C8D2',
        color: '#69B9C4',
        name: '아침',
      };
    case 1:
      return {
        icon: <Lunch size={size} color={color} />,
        background: '#FFA064',
        color: '#FA8C50',
        name: '점심',
      };
    case 2:
      return {
        icon: <Dinner size={size} color={color} />,
        background: '#252F40',
        color: '#192332',
        name: '저녁',
      };
    case 3:
      return {
        icon: <Snack size={size} color={color} />,
        background: '#8796D7',
        color: '#7283CB',
        name: '간식',
      };
    default:
      return {
        icon: <Breakfast size={size} color={color} />,
        background: '#82C8D2',
        color: '#69B9C4',
        name: '아침',
      };
  }
};

const ketogramCategory: ICategoryItem[] = [
  {
    index: 99,
    content: '전체',
  },
  {
    index: 98,
    content: 'Best',
    specific: {
      type: 'background',
      color: '#82C8D2',
    },
  },
  {
    index: 3,
    content: '팔로잉',
    specific: {
      type: 'both',
      color: FOUNDATION_COLOR.BASIC_BLUE,
    },
  },
  {
    index: 0,
    content: '식단',
  },
  {
    index: 4,
    content: '운동',
  },
  {
    index: 2,
    content: '일상',
  },
  {
    index: 5,
    content: '눈바디',
  },
  {
    index: 6,
    content: '자극짤',
  },
  {
    index: 1,
    content: '레시피',
  },
];

const boardCategory: ICategoryItem[] = [
  {
    index: 99,
    content: '전체',
  },

  {
    index: 4,
    content: '칭찬하기',
    specific: {
      type: 'both',
      color: FOUNDATION_COLOR.BASIC_BLUE,
    },
  },
  {
    index: 1,
    content: '기능제안',
  },
  {
    index: 2,
    content: '오류신고',
  },
  {
    index: 3,
    content: '불편해요',
  },
];

const anonymityCategory: ICategoryItem[] = [
  {
    index: 99,
    content: '전체',
  },
  {
    index: 98,
    content: 'Best',
    specific: {
      type: 'background',
      color: ' #56BA64',
    },
  },
  {
    index: 11,
    content: '다이어트',
  },
  {
    index: 20,
    content: '성공후기',
  },
  {
    index: 21,
    content: '운동토크',
  },
  {
    index: 22,
    content: '꿀팁공유',
  },
  {
    index: 13,
    content: '직장생활',
  },
  {
    index: 14,
    content: '학교생활',
  },
  {
    index: 15,
    content: '연애고민',
  },
  {
    index: 12,
    content: '패션뷰티',
  },
  {
    index: 23,
    content: '취미활동',
  },
  {
    index: 16,
    content: '자유수다',
  },
];

const ketogramCategoryList: CommunityCategoryItem[] = [
  {
    type: '추천',
    index: 4,
    img: KetogramCategoryRecommend,
  },
  {
    type: '팔로잉',
    index: 3,
    img: KetogramCategoryFollowing,
  },
  {
    type: '식단',
    index: 0,
    img: KetogramCategoryMeal,
  },
  {
    type: '레시피',
    index: 1,
    img: KetogramCategoryRecipe,
  },
  {
    type: '일상',
    index: 2,
    img: KetogramCategoryDaily,
  },
];

const BoardCategoryList: CommunityCategoryItem[] = [
  {
    type: '모두',
    index: 4,
    img: BoardCategoryAll,
  },
  {
    type: '자유',
    index: 1,
    img: BoardCategoryFree,
  },
  {
    type: '질문',
    index: 2,
    img: BoardCategoryQuestion,
  },
  {
    type: '의견',
    index: 0,
    img: BoardCategoryOpinion,
  },
  {
    type: '내 글',
    index: 3,
    img: BoardCategoryMy,
  },
];

interface CategoryProps {
  type: 'free' | 'question' | 'suggestion' | 'notice';
  text: string;
  icon: string;
}

const boardDetailCategoryHandler = (index: number): CategoryProps => {
  switch (index) {
    case 1:
      return {
        type: 'free',
        text: '자유',
        icon: '<span>&#x270F;</span>',
      };
    case 2:
      return {
        type: 'question',
        text: '질문',
        icon: '<span>&#x1F64B;</span>',
      };
    case 0:
      return {
        type: 'suggestion',
        text: '의견',
        icon: '<span>&#x1F4A1;</span>',
      };
    case 9:
      return {
        type: 'notice',
        text: '공지',
        icon: '<span>&#x1F4E2;</span>',
      };
    default:
      return {
        type: 'free',
        text: '자유',
        icon: '<span>&#x270F;</span>',
      };
  }
};

const findInfoByScore = (score: number) => {
  if (score >= 8 || score === 0) {
    return {
      content: '다이어트할 때 강추',
      color: '#50dc82',
      character: <InoutCharacterGreen />,
      comment: 'Best !',
    };
  } else if (score >= 6) {
    return {
      content: '다이어트에 좋아요',
      color: '#9ce376',
      character: <InoutCharacterLightGreen />,
      comment: 'Good ~',
    };
  } else if (score >= 4) {
    return {
      content: '가끔씩만 드세요',
      color: '#fc8aa6',
      character: <InoutCharacterLightPink />,
      comment: 'No....',
    };
  } else {
    return {
      content: '가급적 피하세요',
      color: '#fa5064',
      character: <InoutCharacterPink />,
      comment: 'Bad !',
    };
  }
};

const ketosisStatusList = [
  {
    emoji: <Bad backgroundColor={'#aaa5fa'} color={'black'} size={'semiLarge'} />,
    value: '과다해요',
    color: '#aaa5fa',
  },
  {
    emoji: <Best backgroundColor={'#50dc82'} color={'black'} size={'semiLarge'} />,
    value: '최고예요',
    color: '#50dc82',
  },
  {
    emoji: <Good backgroundColor={'#afeb8c'} color={'black'} size={'semiLarge'} />,
    value: '적당해요',
    color: '#afeb8c',
  },
  {
    emoji: <Weak backgroundColor={'#d2e682'} color={'black'} size={'semiLarge'} />,
    value: '약해요',
    color: '#d2e682',
  },
  {
    emoji: <KetoOut backgroundColor={'#fa5064'} color={'black'} size={'semiLarge'} />,
    value: '키토아웃',
    color: '#fa5064',
  },
];

const dietModeList: DietModeItems[] = [
  {
    name: '일반 식단',
    nameWithEng: 'General',
    content: (
      <>
        탄단지 균형을 유지하고
        <br />
        칼로리를 제한해요
      </>
    ),
  },
  {
    name: '운동 식단',
    nameWithEng: 'Fitness',
    content: (
      <>
        단백질 섭취량을 늘려
        <br />
        근육 생성에 집중해요
      </>
    ),
  },
  {
    name: '키토 식단',
    nameWithEng: 'Ketogenic',
    content: (
      <>
        탄수화물을 제한하고
        <br />
        건강한 지방을 섭취해요
      </>
    ),
  },
  {
    name: '비건 식단',
    nameWithEng: 'Vegan',
    content: (
      <>
        동물성 음식을 피하고
        <br />
        채식 위주의 식단을 지향해요
      </>
    ),
  },
];

// meal list
const mealTimeInfoList = (mealTime: number) => {
  switch (mealTime) {
    case 0:
      return {
        color: '#82c8d2',
        imgColor: '#69B9C4',
        icon: <Breakfast color={'#fff'} size={'medium'} />,
        name: '아침',
      };
    case 1:
      return {
        color: '#ffa064',
        imgColor: '#FA8C50',
        icon: <Lunch color={'#fff'} size={'medium'} />,
        name: '점심',
      };
    case 2:
      return {
        color: '#252f40',
        imgColor: '#192332',
        icon: <Dinner color={'#fff'} size={'medium'} />,
        name: '저녁',
      };
    case 3:
      return {
        color: '#8796d7',
        imgColor: '#7283CB',
        icon: <Snack color={'#fff'} size={'medium'} />,
        name: '간식',
      };
    default:
      return {
        color: '#82c8d2',
        imgColor: '#69B9C4',
        icon: <Breakfast size={'medium'} />,
        name: '아침',
      };
  }
};

const fastingMethodList = [
  {
    fastingTime: 12,
    icon: <LightGreenSoso size={'medium'} active />,
    subIcon: <LightGreenSoso size={'small'} active />,
    text: '부담없이',
    color: '#C1D865',
  },
  {
    fastingTime: 14,
    icon: <LightGreenSmile size={'medium'} active />,
    subIcon: <LightGreenSmile size={'small'} active />,
    text: '초보자용',
    color: '#C1D865',
  },
  {
    fastingTime: 16,
    icon: <GreenSmile size={'medium'} active />,
    subIcon: <GreenSmile size={'small'} active />,
    text: '가장 인기',
    color: '#30CE7C',
  },
  {
    fastingTime: 18,
    icon: <GreenSmileWithStar size={'medium'} active />,
    subIcon: <GreenSmileWithStar size={'small'} active />,
    text: '중급자용',
    color: '#30CE7C',
  },
  {
    fastingTime: 20,
    icon: <PurpleSmileWithStar size={'medium'} active />,
    subIcon: <PurpleSmileWithStar size={'small'} active />,
    text: '고수들만',
    color: '#867FF8',
  },
  {
    fastingTime: 24,
    icon: <RedSmileWithFire size={'medium'} active />,
    subIcon: <RedSmileWithFire size={'small'} active />,
    text: '단식 전문가',
    color: '#F2455A',
  },
];

const fastingDifficultyList = [
  {
    difficulty: 0,
    text: '쉬움',
    icon: <LightGreenSmile size={'medium'} active />,
    subIcon: <LightGreenSmile size={'small'} active />,
    color: '#C1D865',
  },
  {
    difficulty: 1,
    text: ' 적당',
    icon: <GreenSmile size={'medium'} active />,
    subIcon: <GreenSmile size={'small'} active />,
    color: '#30CE7C',
  },
  {
    difficulty: 2,
    text: '무리',
    icon: <RedSad size={'medium'} active />,
    subIcon: <RedSad size={'small'} active />,
    color: '#FA5064',
  },
];

const battleOnBoardingSlideList = [
  {
    subText: '목표, 기간, 내기 금액 등',
    img: OnBoardingImage01,
    text: (
      <>
        <span>배틀 계약서</span> 작성하기
      </>
    ),
  },
  {
    subText: '카톡으로 보내기도 가능!',
    img: OnBoardingImage02,
    text: (
      <>
        친구에게 <span>1:1 배틀 신청</span>
      </>
    ),
  },
  {
    subText: '초대받은 친구는?',
    img: OnBoardingImage03,
    text: (
      <>
        초대 코드 입력하고 <span>배틀 시작</span>
      </>
    ),
  },
  {
    subText: '체중, 식단, 단식 기록 자동 공유',
    img: OnBoardingImage04,
    text: (
      <>
        서로 응원하며 <span>다이어트 도전</span>
      </>
    ),
  },
  {
    subText: 'D-Day가 되면?',
    img: OnBoardingImage05,
    text: (
      <>
        체중 인증하고 <span>내기 상금 받기</span>
      </>
    ),
  },
];

const mealTimeHandler = (time: number) => {
  switch (time) {
    case 0:
      return '아침';
    case 1:
      return '점심';
    case 2:
      return '저녁';
    case 3:
      return '간식';
    default:
      return '아침';
  }
};

const signOutReasons = [
  {
    text: '기대했던 앱이 아니에요',
    index: 1,
  },
  {
    text: '이용이 번거롭고 불편해요',
    index: 2,
  },
  {
    text: '다이어트를 그만뒀어요',
    index: 3,
  },
  {
    text: '재가입해서 다시 기록하고 싶어요',
    index: 4,
  },
  {
    text: '오류가 너무 많아요',
    index: 5,
  },
  {
    text: '직접 입력',
    index: 6,
  },
];

const recordsThatWillBeErasedWithSignOut = [
  {
    text: '계정 및 개인 정보',
    index: 0,
    emoji: '<span>&#x1F60A;</span>',
  },
  {
    text: '식단,체중,물 섭취 등 개인 기록',
    index: 1,
    emoji: '<span>&#x270D;</span>',
  },
  {
    text: '모은 포인트, 구매한 아이템 내역',
    index: 2,
    emoji: '<span>&#x1F34E;</span>',
  },
  {
    text: '진행중인 다이어트 배틀',
    index: 3,
    emoji: '<span>&#x1F525;</span>',
  },
  {
    text: '작성한 모든 피드와 게시물',
    index: 4,
    emoji: '<span>&#x270F;</span>',
  },
];

const boardLikeEmojiList: IEmoji[] = [
  {
    index: 0,
    component: <Empathy />,
    color: COLORS.PRIMITIVES_YELLOW_GREEN_400,
    desc: '공감해요',
  },
  {
    index: 1,
    component: <Smile />,
    color: COLORS.PRIMITIVES_LIGHT_GREEN_500,
    desc: '도움돼요',
  },
  {
    index: 2,
    component: <Like />,
    color: COLORS.PRIMITIVES_GREEN_350,
    desc: '좋아요',
  },
  {
    index: 3,
    component: <Sad />,
    color: COLORS.PRIMITIVES_LIGHT_PURPLE_400,
    desc: '글쎄요',
  },
  {
    index: 4,
    component: <Angry />,
    color: COLORS.PRIMITIVES_PINK_400,
    desc: '별로에요',
  },
];

const anonymityLikeEmojiList: IEmoji[] = [
  {
    index: 6,
    component: <BigSmile />,
    color: COLORS.PRIMITIVES_YELLOW_500,
    desc: 'ㅋㅋㅋ',
  },
  {
    index: 7,
    component: <Surprise />,
    color: '#EEC390',
    desc: '대박',
  },
  {
    index: 10,
    component: <Fighting />,
    color: '#7A9CBA',
    desc: '화이팅!',
  },
  {
    index: 11,
    component: <Wink />,
    color: COLORS.PRIMITIVES_LIGHT_PURPLE_400,
    desc: '멋져',
  },
  {
    index: 12,
    component: <SmallSmile />,
    color: COLORS.PRIMITIVES_YELLOW_GREEN_500,
    desc: 'ㅇㅈ',
  },
  {
    index: 13,
    component: <Sympathy />,
    color: COLORS.PRIMITIVES_GREEN_400,
    desc: '공감해',
  },
  {
    index: 8,
    component: <Tear />,
    color: '#6CCADB',
    desc: 'ㅠㅠ',
  },
  {
    index: 14,
    component: <Heart />,
    color: COLORS.PRIMITIVES_PINK_200,
    desc: '설레',
  },
  {
    index: 5,
    component: <Straight />,
    color: COLORS.PRIMITIVES_NAVY_250,
    desc: '글쎄',
  },
  {
    index: 9,
    component: <Mad />,
    color: FOUNDATION_COLOR.PRIMARY_PINK,
    desc: '킹받음',
  },
];

const eventEmojiList: EmojiLikeButton[] = [
  {
    text: '참여 완료',
    emoji: <Empathy />,
    color: '#c1d865',
  },
  {
    text: '또 해줘요',
    emoji: <Smile />,
    color: '#97e27a',
  },
  {
    text: '좋아요',
    emoji: <Like />,
    color: '#30ce7c',
  },
  {
    text: '아쉬워요',
    emoji: <Sad />,
    color: '#867ff8',
  },
  {
    text: ' 별로예요',
    emoji: <Angry />,
    color: '#f2455a',
  },
];

export {
  fragmentKetogram,
  ketogramCategory,
  ketogramCategoryList,
  boardCategory,
  BoardCategoryList,
  anonymityCategory,
  boardDetailCategoryHandler,
  findInfoByScore,
  dietModeList,
  mealTimeInfoList,
  fastingMethodList,
  fastingDifficultyList,
  battleOnBoardingSlideList,
  mealTimeHandler,
  signOutReasons,
  recordsThatWillBeErasedWithSignOut,
  boardLikeEmojiList,
  anonymityLikeEmojiList,
  eventEmojiList,
};
