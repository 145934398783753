import Portal from '@components/portal';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import TitleAndCloseHeader from '@components/modal/bottomSheet/TitleAndCloseHeader';
import { useState } from 'react';
import DurationPicker from './DurationPicker';
import BottomButton from '@components/BottomButton';
import styled from 'styled-components';

interface Props {
  onClose: () => void;
  onClickConfirm: (pickerTime: { hour: string; minute: string }) => void;
  initialTimeForm: { hour: string; minute: string };
}

const TimeDurationBottomSheet = ({ onClose, initialTimeForm, onClickConfirm }: Props) => {
  const [pickerTime, setPickerTime] = useState<{ hour: string; minute: string }>(initialTimeForm);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <BottomSheet onClose={onClose} showPanel={false}>
          <BottomSheet.Headers>
            <TitleAndCloseHeader onClose={onClose} title="단식 유지 시간" />
          </BottomSheet.Headers>
          <BottomSheet.Contents>
            <DurationPicker pickerTime={pickerTime} setPickerTime={setPickerTime} />
          </BottomSheet.Contents>
          <BottomSheet.Buttons>
            <ButtonBox>
              <BottomButton
                isDisabled={pickerTime.minute === ''}
                color="black"
                onClickEvent={() => onClickConfirm(pickerTime)}
              >
                완료
              </BottomButton>
            </ButtonBox>
          </BottomSheet.Buttons>
        </BottomSheet>
      </DimmedWrapper>
    </Portal>
  );
};

export default TimeDurationBottomSheet;

const ButtonBox = styled.div`
  padding: 12px 20px;
`;
