import { atom } from 'recoil';
import { toStringByFormatting } from '../modules/helper';

export const dateState = atom<string>({
  key: 'dateState',
  default: toStringByFormatting(new Date()),
});

export const mealTimeState = atom<number>({
  key: 'mealTimeState',
  default: 0,
});

/** @description api로 전달할 기준이 되는 날짜 recoil */
export const apiStandardDate = atom<string>({
  key: 'apiStandardDate',
  default: '',
});
