import { ReactNode, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import LEVEL from 'styles/constants/_levels';
import { swipeUp } from 'styles/mixins/_animations';

interface Props {
  children: ReactNode;
}

const SplashModalContainer = ({ children }: Props) => {
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return <Container>{children}</Container>;
};

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 428px;
  z-index: ${LEVEL.SPLASH_MODAL};
  animation: ${swipeUp} 500ms ease;

  background-color: ${COLORS.WHITE};
`;

export default SplashModalContainer;
