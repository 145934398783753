import { EntryNameIcon, EntryTimeIcon } from '@assets/svgs/_Icons';
import { deviceOs } from '@modules/platformCheck';
import { COLORS } from '@styles/constants/_colors';
import { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

const IconList = {
  name: <EntryNameIcon />,
  time: <EntryTimeIcon />,
} as const;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  icon?: keyof typeof IconList;
  suffix?: string;
  label?: {
    left: {
      name: string | React.ReactNode;
      color?: string;
      isRequired?: boolean;
    };
    right?: {
      name?: string | React.ReactNode;
      color?: string;
    };
  };
}

const Entry = ({ icon, label, suffix, onClick, ...props }: Props) => {
  return (
    <Wrapper>
      <LabelBox>
        {label?.left && (
          <Label $color={label.left.color || COLORS.PRIMITIVES_GRAY_500} align="left">
            {label.left.name} {label.left.isRequired && <span className="required">(필수)</span>}
          </Label>
        )}
        {label?.right && (
          <Label $color={label.right.color || COLORS.PRIMITIVES_GRAY_500} align="right">
            {label.right.name}
          </Label>
        )}
      </LabelBox>
      <InputBox onClick={onClick} isIos={deviceOs === 'ios'}>
        <Left>
          {icon && IconList[icon]}
          <input {...props} />
        </Left>
        {suffix && <Suffix>{suffix}</Suffix>}
      </InputBox>
    </Wrapper>
  );
};

export default Entry;

const Wrapper = styled.div<{ marginTop?: number; width?: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: ${({ width }) => (width ? width : '100%')};
  margin-top: ${({ marginTop }) => marginTop && marginTop}px;
`;

const LabelBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Label = styled.label<{ $color: string; align?: string }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.5px;
  color: ${({ $color }) => $color && $color};
  font-feature-settings: 'clig' off, 'liga' off;
  text-align: ${({ align }) => align || 'left'};

  span {
    margin-left: 2px;
    color: ${({ $color }) => ($color ? $color : COLORS.PRIMITIVES_GRAY_500)};
  }

  .required {
    color: ${COLORS.BLUE};
  }
`;

const InputBox = styled.div<{ isIos?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
  border-radius: 10px;
  width: 100%;
  height: 60px;
  padding: 15px 22px 16px;

  svg {
    min-width: 24px;
    min-height: 24px;
  }

  input {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.5px;
  }

  input::placeholder {
    font-family: Campton, Noto Sans KR;
    color: ${COLORS.PRIMITIVES_GRAY_450};
    font-size: 18px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.5px;

    ${({ isIos }) =>
      isIos &&
      css`
        transform: translateY(-1px);
      `}
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  height: 60px;
`;

const Suffix = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: right;
  color: ${COLORS.PRIMITIVES_GRAY_450};
  white-space: nowrap;
`;
