import { FoodBasketCartItem, Nutrients } from '@models/searchResult';

export const handleRound = (value: number) => {
  if (Number.isInteger(value)) {
    return value;
  }
  return Math.round(value * 10) / 10;
};

export const handleTotalNutrition = (item: FoodBasketCartItem[]) => {
  const initialNutritionValues = {
    calories: 0,
    carbohydrate: 0,
    net_carbohydrate: 0,
    sugar: 0,
    sugar_sub: 0,
    fiber: 0,
    protein: 0,
    fat: 0,
    saturated_fat: 0,
    monounsaturated_fat: 0,
    polyunsaturated_fat: 0,
    trans_fat: 0,
    sodium: 0,
    cholesterol: 0,
    potassium: 0,
  };

  const totalNutritionValues = item.reduce((accumulator, item) => {
    for (const nutrient in accumulator) {
      if (nutrient in item) {
        accumulator[nutrient as keyof Nutrients] += Number(item[nutrient as keyof Nutrients] ?? 0);
      }
    }

    return accumulator;
  }, initialNutritionValues);

  return totalNutritionValues;
};
