import ReactS3Client from 'react-aws-s3-typescript';
import imageCompression from 'browser-image-compression';
import { S3CONFIG } from '../shared/link';
import RuntimeError from 'utils/RuntimeError';
import dayjs from 'dayjs';

type Path =
  | 'recipe'
  | 'community'
  | 'ketogram'
  | 'profile'
  | 'ketogram-title'
  | 'chat'
  | 'summary'
  | 'group-profile'
  | 'food-report'
  | 'message';

const s3 = (path: Path) => new ReactS3Client({ ...S3CONFIG, dirName: path });
const date = dayjs().format('YYYY-MM-DD');
const REVERT_MINE_TYPE = ['heic', 'HEIC', 'heif', 'HEIF'];

interface UploadType {
  blob: File;
  name: string;
  path: Path;
  isSmall?: boolean;
  isResize?: boolean;
}

const handleLimitAndSetToast = async (file: File) => {
  if (file.size > 10485760) {
    throw new RuntimeError({
      code: 4000,
      name: 'exceed error',
      message: 'Image limit size exceeded',
    });
  }
};

const uploadS3 = async ({ blob, name, path }: UploadType) => {
  const file = await revertToJpg(blob);
  const data = await s3(path).uploadFile(file, name);

  return data.location;
};

const deleteS3 = async (name: string, path: Path) => {
  const data = await s3(path).deleteFile(name);
  return data;
};

const altNameS3 = () =>
  date + `${'inout-posting-imgs'}` + Date.now() + parseInt(`${Math.random() * (99 - 10) + 10}`);

const revertToJpg = async (imgFile: File) => {
  let file = imgFile;
  const nameList = file.name.split('.');
  const revertTarget = REVERT_MINE_TYPE.find(
    (mineType) => nameList[nameList.length - 1] === mineType,
  );

  if (revertTarget) {
    const options = {
      fileType: 'image/jpeg', // 변환할 파일 형식
    };
    const compressedFile = await imageCompression(imgFile, options);
    file = new File([compressedFile], file.name.split('.').slice(0, -1).join('.') + '.jpg', {
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    });
  }

  return file;
};

export { uploadS3, deleteS3, altNameS3, revertToJpg, handleLimitAndSetToast };
