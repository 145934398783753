import { countViewBanner } from '@apis/adApi';
import SubBanner from '@components/SubBanner';
import useGetAdContents from '@hooks/queries/useGetAdContents';
import { useDate } from '@hooks/useDate';
import { foodSearchAdSubscribe } from '@states/adState';
import { COLORS } from '@styles/constants/_colors';
import { ReactNode } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}

const FoodResultRecentlyWrapper = ({ children }: Props) => {
  const { date } = useDate();
  const { mealId } = useParams() as { mealId: string };

  const [foodSearchAdSubscription, setFoodSearchAdSubscription] =
    useRecoilState(foodSearchAdSubscribe);

  const { mutate: postPromotionView } = useMutation((ad_idx: number) => countViewBanner(ad_idx));
  const { data: subBanner } = useGetAdContents('음식 검색 배너', {
    onSuccess: (data) => {
      if (data.length === 0) return;

      const foodSearchMapKey = `${date}-${mealId}`;
      const isViewedAd = foodSearchAdSubscription.get(foodSearchMapKey) || [];
      const isViewed = isViewedAd?.find((ad_idx) => ad_idx === data[0].ad_idx);

      if (isViewed || !data) return;
      postPromotionView(data[0].ad_idx);
      setFoodSearchAdSubscription((prev) =>
        new Map(prev).set(foodSearchMapKey, [...isViewedAd, data[0].ad_idx]),
      );
    },
  });

  return (
    <Wrapper>
      {subBanner && subBanner.length > 0 && (
        <AdContents>
          <SubBanner contents={subBanner[0]} />
        </AdContents>
      )}
      <p className="title">최근에 먹었어요</p>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 0 24px;
  height: 100vh;

  .title {
    padding: 20px;

    color: ${COLORS.BLACK};
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.5px;
  }
`;

const AdContents = styled.div`
  width: 100%;
`;

export default FoodResultRecentlyWrapper;
