import classNames from 'classnames';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';

interface Props {
  focusedIndex: number;
  length?: number;
  unfixed?: boolean;
  padding?: string;
  paddingTop?: number;
}
export default function Stepper({
  focusedIndex,
  length = 3,
  unfixed = false,
  padding = '25px 20px 10px',
  paddingTop,
}: Props) {
  const classes = useStyles({
    padding,
  });
  const steps = [...new Array(length)].map((_, i) => i + 1);
  return (
    // unfixed ? classes.unfixed : classes.steps
    <Grid
      container
      className={unfixed ? classes.unfixed : classes.steps}
      style={{ paddingTop: paddingTop }}
    >
      {steps.map((step) => {
        return (
          <Grid
            key={step}
            container
            justifyContent={'center'}
            alignItems={'center'}
            className={classNames([classes.step, focusedIndex === step && classes.focus])}
          >
            {step}
          </Grid>
        );
      })}
    </Grid>
  );
}

const useStyles = makeStyles<Theme, { padding: string }>(() =>
  createStyles({
    steps: {
      padding: '75px 0 25px',
      maxWidth: 130,
      height: 25,
    },
    unfixed: {
      padding: ({ padding }) => padding,
    },
    step: {
      width: 22,
      height: 22,
      marginRight: 7,
      boxSizing: 'border-box',
      borderRadius: '50%',
      background: '#e6e7ea',
      color: '#999fab',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.08,
      textAlign: 'center',
    },
    focus: {
      background: '#000',
      color: '#fff',
    },
  }),
);
