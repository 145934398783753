import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import FallBackSpinner from '@components/spinner/FallBackSpinner';
import AsyncBoundary from '@components/AsyncBoundary';
import GroupBattleSeasonEndCheckWrapper from '@pages/battle/groupBattle/components/GroupBattleSeasonEndCheckWrapper';

const GroupBattlePage = lazy(() => import('@pages/battle/groupBattle'));
const GroupBattleExplore = lazy(
  () => import('@pages/battle/groupBattle/explore/GroupBattleExplore'),
);
const GroupBattleExploreSearch = lazy(
  () => import('@pages/battle/groupBattle/explore/GroupBattleExploreSearch'),
);
const GroupBattleHeader = lazy(
  () => import('@pages/battle/groupBattle/organism/GroupBattleHeader'),
);
const GroupBattleRank = lazy(() => import('@pages/battle/groupBattle/rank/GroupBattleRank'));
const GroupBattleRegister = lazy(
  () => import('@pages/battle/groupBattle/register/GroupBattleRegister'),
);
const GroupBattleTimeline = lazy(
  () => import('@pages/battle/groupBattle/timeline/GroupBattleTimeline'),
);
const GroupHome = lazy(() => import('@pages/battle/groupBattle/groupHome/GroupHome'));
const GroupNotice = lazy(() => import('@pages/battle/groupBattle/groupSetting/GroupNotice'));
const GroupNoticePosting = lazy(
  () => import('@pages/battle/groupBattle/groupSetting/GroupNoticePosting'),
);
const GroupRequest = lazy(() => import('@pages/battle/groupBattle/groupSetting/GroupRequestPage'));
const GroupDetail = lazy(() => import('@pages/battle/groupBattle/groupSetting/GroupDetail'));
const GroupSetting = lazy(() => import('@pages/battle/groupBattle/groupSetting/GroupSettingPage'));
const GroupMasterDeligation = lazy(
  () => import('@pages/battle/groupBattle/groupSetting/GroupMasterDeligation'),
);
const GroupKickOutPage = lazy(
  () => import('@pages/battle/groupBattle/groupSetting/GroupKickOutPage'),
);
const JoinGroupBattle = lazy(() => import('@pages/battle/groupBattle/groupJoin/JoinGroupBattle'));
const GroupInfoEditPage = lazy(
  () => import('@pages/battle/groupBattle/groupSetting/GroupInfoEditPage'),
);
const GroupBattleTopRank = lazy(
  () => import('@pages/battle/groupBattle/explore/GroupBattleTopRank'),
);
const GroupBattleStatus = lazy(() => import('@pages/battle/groupBattle/status'));
const GroupBattleChat = lazy(() => import('@pages/battle/groupBattle/chat/GroupBattleChat'));
const GroupBattleErrorFallback = lazy(
  () => import('@pages/battle/groupBattle/components/GroupBattleErrorFallback'),
);

const GroupBattleRouter = () => {
  return (
    <AsyncBoundary fallback={<FallBackSpinner />} fallbackError={<GroupBattleErrorFallback />}>
      <GroupBattleSeasonEndCheckWrapper>
        <Routes>
          <Route path="/" element={<GroupBattlePage />} />
          <Route path="/notice/:groupIdx" element={<GroupNotice />} />
          <Route path="/notice/posting/:groupIdx" element={<GroupNoticePosting />} />
          <Route path="/groupDetail/:groupIdx" element={<GroupDetail />} />
          <Route path="/groupSetting/:groupIdx" element={<GroupSetting />} />
          <Route path="/setting/request/:groupIdx" element={<GroupRequest />} />
          <Route path="/setting/delegation/:groupIdx" element={<GroupMasterDeligation />} />
          <Route path="/setting/kickout/:groupIdx" element={<GroupKickOutPage />} />

          <Route path="/register" element={<GroupBattleRegister />} />
          <Route path="/setting/edit/:groupIdx" element={<GroupInfoEditPage />} />

          <Route path="/explore/search" element={<GroupBattleExploreSearch />} />
          <Route path="/explore/groupRanking100" element={<GroupBattleTopRank />} />
          <Route path="/explore/:category" element={<GroupBattleExplore />} />
          <Route path="/groupPreview/:groupIdx" element={<JoinGroupBattle />} />
          <Route path="/chat/:groupIdx/memberIdx/:memberIdx" element={<GroupBattleChat />} />

          <Route element={<GroupBattleHeader />}>
            <Route path="/home/:groupIdx" element={<GroupHome />} />
            <Route path="/timeline/:groupIdx" element={<GroupBattleTimeline />} />
            <Route path="/memberRanking/:type/:groupIdx" element={<GroupBattleRank />} />
            <Route path="/status/:groupIdx" element={<GroupBattleStatus />} />
          </Route>
        </Routes>
      </GroupBattleSeasonEndCheckWrapper>
    </AsyncBoundary>
  );
};

export default GroupBattleRouter;
