import { checkAuthButton } from '@apis/analyticsApi';
import Popup from '@components/elements/Popup/Popup';
import useTopModal from '@hooks/useTopModal';
import { APPLEURL, KAKAOURL, NAVERURL } from '@shared/link';

const useSocialLogin = () => {
  const { openModal, closeModal } = useTopModal();
  const analyticsToken = localStorage.getItem('analyticsCheck');
  const analyticsTokenJoin = localStorage.getItem('analyticsCheckJoin');

  const analyticsCheckButton = async () => {
    if (analyticsTokenJoin === null && analyticsToken !== null) {
      localStorage.setItem('analysticsCheckJoin', analyticsToken);
      await checkAuthButton(analyticsToken);
    }
  };

  const onKakaoLogin = () => {
    analyticsCheckButton();
    try {
      (window as any).ReactNativeWebView.postMessage('kakao');
    } catch (err) {
      setTimeout(() => {
        window.location.href = KAKAOURL;
      }, 500);
    }
  };

  const onNaverLogin = () => {
    analyticsCheckButton();
    try {
      (window as any).ReactNativeWebView.postMessage('naver');
    } catch (err) {
      setTimeout(() => {
        window.location.href = NAVERURL;
      }, 500);
    }
  };

  const onAppleLogin = () => {
    analyticsCheckButton();
    try {
      (window as any).ReactNativeWebView.postMessage('apple');
    } catch (err) {
      setTimeout(() => {
        window.location.href = APPLEURL;
      }, 500);
    }
  };

  const onGoogleLogin = () => {
    analyticsCheckButton();
    try {
      (window as any).ReactNativeWebView.postMessage('google');
    } catch (err) {
      openModal(Popup, {
        headText: '일시적으로 해당 로그인 이용이 불가능해요',
        subText1: '문제가 반복될 경우 다른 로그인 방법을 사용해 주세요',
        buttonText1: '확인',
        cancel: () => {
          closeModal(Popup);
        },
        onClose: () => {
          closeModal(Popup);
        },
      });
    }
  };

  return { onKakaoLogin, onNaverLogin, onAppleLogin, onGoogleLogin };
};

export default useSocialLogin;
