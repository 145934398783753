import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBack from '../../assets/images/icon/GNB/ArrowBack';
import IconButtonWrapper from '../WrapperAtoms/IconButtonWrapper';

interface Props {
  color?: string;
  onClickEvent?: () => void;
}
export default function BackArrowBtn({ color = '#000', onClickEvent }: Props) {
  const navigate = useNavigate();
  const onClick = () => {
    onClickEvent ? onClickEvent() : navigate(-1);
  };
  return (
    <IconButtonWrapper onClick={onClick}>
      <ArrowBack color={color} />
    </IconButtonWrapper>
  );
}
