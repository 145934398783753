/**
 * @param versionA 업데이트 하고자하는 버전
 * @param versionB 현재 버전
 * @returns true: 업데이트 하지않아도 됨, false: 업데이트 해야됨
 */
const compareVersionNumber = (versionA: string, versionB: string) => {
  let compareResult = true;

  const versionAList = versionA.split('.');
  const versionBList = versionB.split('.');

  const length = Math.max(versionAList.length, versionBList.length);

  if (versionA === versionB) return compareResult;

  for (let i = 0; i < length; i += 1) {
    const a = versionAList[i] ? parseInt(versionAList[i], 10) : 0;
    const b = versionBList[i] ? parseInt(versionBList[i], 10) : 0;

    if (a < b) {
      break;
    }

    if (a > b) {
      compareResult = false;
      break;
    }
  }
  return compareResult;
};

export { compareVersionNumber };
