import Lock from 'assets/images/png/icon/Lock.png';
import Portal from 'components/portal';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS, FOUNDATION_COLOR } from 'styles/constants/_colors';
import LEVEL from 'styles/constants/_levels';
import { setCookie } from 'utils/cookie';

interface Props {
  onClose: () => void;
}
const LOCK_MODAL_KEY = 'is_lock_modal_key';

const SecurityRecommendModal = ({ onClose }: Props) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/myPage/setting');
  };
  useLayoutEffect(() => {
    setCookie(LOCK_MODAL_KEY, 'opened');
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Portal>
      <Container onClick={onClose}>
        <Box>
          <LockImage src={Lock} />
          <Title>{`앱 잠금 기능을 설정하고\n 더 안전하게 사용해 보세요!`}</Title>
          <AdditionalExplanation>‘설정' 페이지에 변경 가능해요</AdditionalExplanation>
          <ConfirmButton onClick={onClick}>설정하러 가기</ConfirmButton>
          <CancelText onClick={onClose}>다음에 할게요</CancelText>
        </Box>
      </Container>
    </Portal>
  );
};

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 428px;
  z-index: ${LEVEL.SPLASH_MODAL};

  background-color: rgba(0, 0, 0, 0.5);
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 315px;

  padding: 42px 25px 37.9px 25px;

  border-radius: 20px;
  background: ${COLORS.WHITE};
`;

const LockImage = styled.img`
  width: 70px;
  height: 87px;
`;

const Title = styled.b`
  margin: 16.64px 0px 11.84px;
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.5px;
  text-align: center;
  white-space: pre-wrap;
`;

const AdditionalExplanation = styled.p`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;

  color: ${FOUNDATION_COLOR.PRIMARY_GRAY};
`;

const ConfirmButton = styled.button`
  margin: 29.58px 0px 26.14px;

  width: 100%;
  padding: 18px 0px 21px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.6153846383094788px;
  text-align: center;
  color: ${COLORS.WHITE};

  background: #007aff;
  border-radius: 120px;
`;

const CancelText = styled.p`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.5px;
  text-align: center;
`;

export default SecurityRecommendModal;
