import { FoodBasketCartItem } from '@models/searchResult';

const handleDuplicated = (oldFoods: FoodBasketCartItem[], newFoods: FoodBasketCartItem[]) => {
  const overlappingFoods: FoodBasketCartItem[] = [];
  const oldFoodsSet = new Set(oldFoods.map((item) => item.food_idx));
  const newFoodsSet = new Set(newFoods.map((item) => item.food_idx));

  const uniqueOldFoods = oldFoods.filter((item) => !newFoodsSet.has(item.food_idx));
  const uniqueNewFoods = newFoods.filter((item) => !oldFoodsSet.has(item.food_idx));

  for (const item of newFoods) {
    if (oldFoodsSet.has(item.food_idx)) {
      overlappingFoods.push(item);
    }
  }
  return [...uniqueOldFoods, ...uniqueNewFoods, ...overlappingFoods];
};

const handleRecentFoods = (foods: FoodBasketCartItem[]) => {
  const MAX_RECENT_FOODS = 10;

  if (foods.length > MAX_RECENT_FOODS) {
    const diff = foods.length - MAX_RECENT_FOODS;
    return foods.slice(diff);
  }
  return foods;
};

export const setRecentRegisterFood = async (memberIdx: number, foods: FoodBasketCartItem[]) => {
  const KEY = `RECENT_FOODS_${memberIdx}`;
  const prevFoods = JSON.parse(localStorage.getItem(KEY) || '[]');
  const uniqueFoods = handleDuplicated(prevFoods, foods);
  const recentFoods = handleRecentFoods(uniqueFoods);
  localStorage.setItem(KEY, JSON.stringify(recentFoods));
};

export const getRecentRegisterFood = async (memberIdx: number) => {
  const KEY = `RECENT_FOODS_${memberIdx}`;
  const foods = JSON.parse(localStorage.getItem(KEY) || '[]') as FoodBasketCartItem[];
  return foods.reverse();
};
