interface Props {
  color?: string;
  size?: 'small' | 'semi-medium' | 'medium' | 'semi-large' | 'large';
}
export default function Lunch({ color = '#000', size = 'small' }: Props) {
  const fragment = (() => {
    switch (size) {
      case 'small':
        return {
          size: 51,
          icon: (
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.8447 5.43481V8.39743"
                stroke={color}
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M10.2114 20.5435H7.28271"
                stroke={color}
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M42.6708 20.5435H39.8721"
                stroke={color}
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M14.5226 11.773L12.3525 9.83423"
                stroke={color}
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M41.2365 31.5257L38.6226 29.9811"
                stroke={color}
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M9.36426 31.5257L11.9381 29.9811"
                stroke={color}
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M37.6213 9.83423L35.4309 11.773"
                stroke={color}
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M30.5675 31C32.7758 29.2688 34.2 26.5413 34.2 23.4732C34.2 18.2413 30.0586 14 24.95 14C19.8413 14 15.7 18.2413 15.7 23.4732C15.7 26.5413 17.1241 29.2688 19.3324 31"
                stroke={color}
                strokeWidth="2.4"
              />
              <path
                d="M10.6404 41.4405L24.8559 25.08L39.1203 41.4405"
                stroke={color}
                strokeWidth="2.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
      case 'semi-medium':
        return {
          size: 56,
          icon: (
            // <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            //   <path d="M27.826 6.08698V9.4051" stroke={color} strokeWidth="2.6" strokeLinecap="round"/>
            //   <path d="M11.4369 23.0087H8.15674" stroke={color} strokeWidth="2.6" strokeLinecap="round"/>
            //   <path d="M47.7913 23.0087H44.6567" stroke={color} strokeWidth="2.6" strokeLinecap="round"/>
            //   <path d="M16.2655 13.1859L13.835 11.0144" stroke={color} strokeWidth="2.6" strokeLinecap="round"/>
            //   <path d="M46.1854 35.3089L43.2578 33.5789" strke={color} strokeWidth="2.6" strokeLinecap="round"/>
            //   <path d="M10.4883 35.3089L13.371 33.5789" stroke={color} strokeWidth="2.6" strokeLinecap="round"/>
            //   <path d="M42.1358 11.0144L39.6826 13.1859" stroke={color} strokeWidth="2.6" strokeLinecap="round"/>
            //   <path d="M34.2356 34.7201C36.7089 32.7811 38.304 29.7263 38.304 26.29C38.304 20.4303 33.6657 15.6801 27.944 15.6801C22.2223 15.6801 17.584 20.4303 17.584 26.29C17.584 29.7263 19.1791 32.7811 21.6523 34.7201" stroke={color} strokeWidth="2.3"/>
            //   <path d="M11.917 46.4135L27.8384 28.0896L43.8145 46.4135" stroke={color} strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
            // </svg>
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.826 6.08698V9.4051"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M11.4369 23.0087H8.15674"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M47.7913 23.0087H44.6567"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M16.2655 13.1859L13.835 11.0144"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M46.1854 35.3089L43.2578 33.5789"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M10.4883 35.3089L13.371 33.5789"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M42.1358 11.0144L39.6826 13.1859"
                stroke={color}
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <path
                d="M34.2356 34.7201C36.7089 32.7811 38.304 29.7263 38.304 26.29C38.304 20.4303 33.6657 15.6801 27.944 15.6801C22.2223 15.6801 17.584 20.4303 17.584 26.29C17.584 29.7263 19.1791 32.7811 21.6523 34.7201"
                stroke={color}
                strokeWidth="2.3"
              />
              <path
                d="M11.917 46.4135L27.8384 28.0896L43.8145 46.4135"
                stroke={color}
                strokeWidth="2.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
      case 'medium':
        return {
          size: 72,
          icon: (
            <svg
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.7763 7.82605V12.0922"
                stroke={color}
                strokeWidth="3.2"
                strokeLinecap="round"
              />
              <path
                d="M14.7044 29.0584H10.4871"
                stroke={color}
                strokeWidth="3.2"
                strokeLinecap="round"
              />
              <path
                d="M61.4459 29.0584H57.4158"
                stroke={color}
                strokeWidth="3.2"
                strokeLinecap="round"
              />
              <path
                d="M20.9125 16.9532L17.7876 14.1613"
                stroke={color}
                strokeWidth="3.2"
                strokeLinecap="round"
              />
              <path
                d="M59.3808 43.397L55.5366 41.5704"
                stroke={color}
                strokeWidth="3.2"
                strokeLinecap="round"
              />
              <path
                d="M12.4846 43.397L16.1593 41.5704"
                stroke={color}
                strokeWidth="3.2"
                strokeLinecap="round"
              />
              <path
                d="M54.1744 14.1613L51.0203 16.9532"
                stroke={color}
                strokeWidth="3.2"
                strokeLinecap="round"
              />
              <path
                d="M44.1017 45C47.3799 42.466 49.5002 38.4322 49.5002 33.8889C49.5002 26.2183 43.4561 20 36.0002 20C28.5444 20 22.5002 26.2183 22.5002 33.8889C22.5002 38.4322 24.6206 42.466 27.8988 45"
                stroke={color}
                strokeWidth="3"
              />
              <path
                d="M15.322 59.6744L35.7924 36.1151L56.3332 59.6744"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
      case 'semi-large':
        return {
          size: 82,
          icon: (
            <svg
              width="82"
              height="82"
              viewBox="0 0 82 82"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.7448 8.91296V13.7717"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M16.7459 33.0943H11.9429"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M69.9793 33.0943H65.3894"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M23.8163 19.3077L20.2573 16.1281"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M67.6273 49.4244L63.2493 47.3441"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M14.218 49.4244L18.4031 47.3441"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M61.6979 16.1281L58.1057 19.3077"
                stroke={color}
                strokeWidth="3.64444"
                strokeLinecap="round"
              />
              <path
                d="M50.2261 51.2499C53.9596 48.364 56.3745 43.77 56.3745 38.5957C56.3745 29.8597 49.4909 22.7777 40.9995 22.7777C32.5081 22.7777 25.6245 29.8597 25.6245 38.5957C25.6245 43.77 28.0394 48.364 31.7729 51.2499"
                stroke={color}
                strokeWidth="3.41667"
              />
              <path
                d="M17.4495 67.9625L40.763 41.1311L64.1566 67.9625"
                stroke={color}
                strokeWidth="3.41667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
      case 'large':
        return {
          size: 100,
          icon: (
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M49.6893 10.8696V16.7949"
                stroke={color}
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M20.4223 40.3591H14.5649"
                stroke={color}
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M85.3413 40.3591H79.7439"
                stroke={color}
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M29.0445 23.5462L24.7043 19.6686"
                stroke={color}
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M82.4724 60.2739L77.1333 57.7369"
                stroke={color}
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M17.3394 60.2739L22.4431 57.7369"
                stroke={color}
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M75.2418 19.6686L70.8611 23.5462"
                stroke={color}
                strokeWidth="4.18182"
                strokeLinecap="round"
              />
              <path
                d="M61.252 62.5001C65.805 58.9806 68.75 53.3782 68.75 47.068C68.75 36.4143 60.3553 27.7778 50 27.7778C39.6447 27.7778 31.25 36.4143 31.25 47.068C31.25 53.3782 34.195 58.9806 38.748 62.5001"
                stroke={color}
                strokeWidth="3.6"
              />
              <path
                d="M21.2803 82.8812L49.7114 50.16L78.2402 82.8812"
                stroke={color}
                strokeWidth="3.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
    }
  })();
  return <span style={{ height: fragment.size }}>{fragment.icon}</span>;
}
