import { Grid, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  active: boolean;
  size: 'small' | 'medium';
}

export default function LightGreenSoso({ active, size }: Props) {
  const classes = useStyles();

  const fragment = (() => {
    switch (size) {
      case 'medium':
        return {
          icon: active ? (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="29.5" cy="29.5" r="27.5" fill="#D2E682" />
              <ellipse cx="20.3297" cy="25.7214" rx="2.93964" ry="2.94352" fill="black" />
              <ellipse cx="38.6591" cy="25.7214" rx="2.93964" ry="2.94352" fill="black" />
              <ellipse cx="29.7883" cy="37.5357" rx="2.93964" ry="3.54839" fill="black" />
            </svg>
          ) : (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="29.5" cy="29.5" r="27.5" fill="#E6E7EA" />
              <path d="M24 35.5H35" stroke="#999FAB" strokeWidth="2.8" strokeLinecap="round" />
              <ellipse cx="20.425" cy="26.75" rx="2.75" ry="2.75" fill="#999FAB" />
              <ellipse cx="38.5376" cy="26.75" rx="2.75" ry="2.75" fill="#999FAB" />
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="15.9997" r="14.9153" fill="#D2E682" />
              <ellipse cx="11.0262" cy="13.9505" rx="1.59438" ry="1.59648" fill="black" />
              <ellipse cx="20.9677" cy="13.9505" rx="1.59438" ry="1.59648" fill="black" />
              <ellipse cx="16.1563" cy="20.3586" rx="1.59438" ry="1.92455" fill="black" />
            </svg>
          ),
        };
      default:
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="15.9997" r="14.9153" fill="#D2E682" />
              <ellipse cx="11.0262" cy="13.9505" rx="1.59438" ry="1.59648" fill="black" />
              <ellipse cx="20.9677" cy="13.9505" rx="1.59438" ry="1.59648" fill="black" />
              <ellipse cx="16.1563" cy="20.3586" rx="1.59438" ry="1.92455" fill="black" />
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}

const useStyles = makeStyles(() => createStyles({}));
