import React, { lazy, Suspense } from 'react';
import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { Route, Routes } from 'react-router-dom';
import { ApplePath } from '@constants/internalPath';

const AppleRouter = () => {
  const ApplePage = lazy(() => import('@pages/apple'));
  const StoreDetail = lazy(() => import('pages/apple/store/storeDetail'));
  const MyRoomStoreDetail = lazy(() => import('pages/apple/store/myRoomStoreDetail'));
  const ThemeWishlist = lazy(() => import('pages/apple/store/wishlist'));

  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path={`${ApplePath.MAIN}/:menu?/:tab?`} element={<ApplePage />} />
        <Route path={ApplePath.STORE_THEME_DETAIL} element={<StoreDetail />} />
        <Route path={ApplePath.STORE_MY_ROOM_DETAIL} element={<MyRoomStoreDetail />} />
        <Route path={ApplePath.THEME_WISHLIST} element={<ThemeWishlist />} />
      </Routes>
    </Suspense>
  );
};

export default AppleRouter;
