import { Grid, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  active: boolean;
  size: 'medium' | 'small';
}

export default function LightGreenSmile({ active, size }: Props) {
  const classes = useStyles();

  const fragment = (() => {
    switch (size) {
      case 'medium':
        return {
          icon: active ? (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="29.5" cy="29.5" r="27.5" fill="#D2E682" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.4166 33.1667C20.4961 38.5535 24.8881 41.4167 29.4883 41.4167C34.5863 41.4167 38.5984 38.57 39.5833 33.1765"
                fill="black"
              />
              <ellipse cx="20.425" cy="26.75" rx="2.75" ry="2.75" fill="black" />
              <ellipse cx="38.5375" cy="26.75" rx="2.75" ry="2.75" fill="black" />
            </svg>
          ) : (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="29.5" cy="29.5" r="27.5" fill="#E6E7EA" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.4166 33.1667C20.4961 38.5535 24.8881 41.4167 29.4883 41.4167C34.5863 41.4167 38.5984 38.57 39.5833 33.1765"
                fill="#999FAB"
              />
              <ellipse cx="20.425" cy="26.75" rx="2.75" ry="2.75" fill="#999FAB" />
              <ellipse cx="38.5375" cy="26.75" rx="2.75" ry="2.75" fill="#999FAB" />
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16.0002" r="14.9153" fill="#D2E682" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5309 17.9886C11.1164 20.9102 13.4985 22.4632 15.9936 22.4632C18.7586 22.4632 20.9346 20.9192 21.4688 17.9939"
                fill="black"
              />
              <ellipse cx="11.0779" cy="14.5086" rx="1.49152" ry="1.49153" fill="black" />
              <ellipse cx="20.9014" cy="14.5086" rx="1.49152" ry="1.49153" fill="black" />
            </svg>
          ),
        };
      default:
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16.0002" r="14.9153" fill="#D2E682" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5309 17.9886C11.1164 20.9102 13.4985 22.4632 15.9936 22.4632C18.7586 22.4632 20.9346 20.9192 21.4688 17.9939"
                fill="black"
              />
              <ellipse cx="11.0779" cy="14.5086" rx="1.49152" ry="1.49153" fill="black" />
              <ellipse cx="20.9014" cy="14.5086" rx="1.49152" ry="1.49153" fill="black" />
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}

const useStyles = makeStyles(() => createStyles({}));
