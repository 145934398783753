import React from 'react';
import { makeStyles, Grid, createStyles } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  title: string;
  isVisible: boolean;
  onVisible: () => void;
}

export const LoginModal: React.FC<Props> = ({ title, isVisible, onVisible }) => {
  const classes = useStyle();

  return (
    <>
      {isVisible && (
        <>
          <Grid className={classes.loginModalBack} onClick={onVisible} />
          <Grid
            className={classes.loginModalContent}
            container
            direction="column"
            alignItems="center"
          >
            <p>기존 계정으로 로그인 되었어요</p>
            <span>가입 유형 : {title}</span>
            <button onClick={onVisible}>확인</button>
          </Grid>
        </>
      )}
    </>
  );
};

const useStyle = makeStyles(() =>
  createStyles({
    loginModalBack: {
      position: 'absolute',
      width: '100%',
      height: '100vh',
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 2000,
    },

    loginModalContent: {
      position: 'absolute',
      zIndex: 2001,
      width: '84%',
      top: 244,
      left: '8%',
      background: '#fff',
      borderRadius: 7,
      padding: '47px 0 30px 0',
      '& p': {
        fontSize: 15,
        lineHeight: 0.93,
        letterSpacing: -0.58,
        textAlign: 'center',
        marginBottom: 15,
      },
      '& span': {
        fontSize: 13,
        lineHeight: 1.33,
        letterSpacing: -0.46,
        textAlign: 'center',
        color: '#999fab',
        marginBottom: 38,
      },
      '& button': {
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: 0.93,
        letterSpacing: -0.58,
        textAlign: 'center',
        background: 'none',
        border: 'none',
        margin: 0,
      },
    },
  }),
);
