export const calorieCalculator = (netCarbo: number, protein: number, fat: number) => {
  return Math.round((netCarbo * 4 + protein * 4 + fat * 9) * 10) / 10;
};

export const calculateNumberPerUnit = (amount: number, value: number) => {
  return Math.round(value * amount * 10) / 10;
};

export const fixFloatingPoint = (value: number) => {
  return Math.round(value * 10) / 10;
};
