interface Props {
  size?: number;
}

export default function SearchColorIcon({ size = 24 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.5 12L20.5 20.5" stroke="#8796D7" strokeWidth="3.2" strokeLinecap="round" />
      <circle cx="11" cy="10.5" r="7.75" fill="#E6E7EA" stroke="#EF898B" strokeWidth="2.5" />
      <path
        d="M12.5 6.60156C13.5 7.10156 14.5 8.10156 15.1797 10.2082"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
}
