import Portal from 'components/portal';
import { deviceOs } from 'modules/platformCheck';
import styled from 'styled-components';
import LEVEL from 'styles/constants/_levels';
import BigIconForceUpdateModal from './BigIconForceUpdateModal';
import { ExternalPath } from '@constants/externalPath';

const ForceUpdateModal = () => {
  const handleMoveToAppStore = () => {
    if (deviceOs === 'android') {
      window.location.href = ExternalPath.GOOGLE_PLAY_FOR_KAKAO_SHARE;
      return;
    }
    window.location.href = ExternalPath.APP_STORE_FOR_KAKAO_SHARE;
    return;
  };

  return (
    <Portal>
      <ForceModalBackGround>
        <BigIconForceUpdateModal onClick={handleMoveToAppStore} />
      </ForceModalBackGround>
    </Portal>
  );
};

export default ForceUpdateModal;

const ForceModalBackGround = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${LEVEL.FORCE_MODAL};
  background: rgba(0, 0, 0, 0.5);
`;
