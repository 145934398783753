import Portal from 'components/portal';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import LEVEL from 'styles/constants/_levels';
import { swipeDown, swipeUp } from 'styles/mixins/_animations';
import { RequestCustomFoodDetail } from 'types/searchResult';
import { default as FoodRegisterInto, default as FoodRegisterIntro } from './FoodRegisterIntro';
import FoodRegisterStepFour from './FoodRegisterStepFour';
import FoodRegisterStepThree from './FoodRegisterStepThree';
import FoodRegisterStepTwoBrand from './FoodRegisterStepTwoBrand';

interface Props {
  onClose: () => void;
}

const Modal = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 428px;
  z-index: ${LEVEL.MODAL_BASIC};
  overflow: scroll;

  background: ${COLORS.WHITE};
  animation: ${(props) => (props.isVisible ? swipeUp : swipeDown)} 600ms ease;
`;

const FoodRegisterModal = ({ onClose: closeModal }: Props) => {
  const [isVisible, setIsVisible] = useState(true);
  const [step, setStep] = useState(0);
  const [food, setFood] = useState<RequestCustomFoodDetail>({
    food_name: '',
    food_type: 'Brand',
    food_brand: null,
    measurement_description: '인분',
    metric_serving_unit: 'g',
    metric_serving_amount: '',
    number_of_units: 1,
    calories: null,
    carbohydrate: null,
    sugar: null,
    sugar_sub: null,
    fiber: null,
    protein: null,
    fat: null,
    saturated_fat: null,
    monounsaturated_fat: null,
    polyunsaturated_fat: null,
    trans_fat: null,
    sodium: null,
    cholesterol: null,
    potassium: null,
    created_at: '',
  });

  const onInitialized = () => {
    setFood({
      food_name: '',
      food_type: 'Brand',
      food_brand: null,
      measurement_description: '인분',
      metric_serving_unit: 'g',
      metric_serving_amount: '',
      number_of_units: 1,
      calories: null,
      carbohydrate: null,
      sugar: null,
      sugar_sub: null,
      fiber: null,
      protein: null,
      fat: null,
      saturated_fat: null,
      monounsaturated_fat: null,
      polyunsaturated_fat: null,
      trans_fat: null,
      sodium: null,
      cholesterol: null,
      potassium: null,
      created_at: '',
    });
  };

  const onClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      closeModal();
    }, 600);
  };

  const page = (() => {
    const currentPage = {
      0: <FoodRegisterIntro setStep={setStep} onClose={onClose} />,
      1: (
        <FoodRegisterStepTwoBrand
          setStep={setStep}
          food={food}
          setFood={setFood}
          onClose={onClose}
        />
      ),
      2: <FoodRegisterStepThree setStep={setStep} food={food} setFood={setFood} />,
      3: <FoodRegisterStepFour setStep={setStep} setFood={setFood} food={food} onClose={onClose} />,
    }[step] ?? <FoodRegisterInto setStep={setStep} onClose={onClose} />;
    return currentPage;
  })();

  useEffect(() => {
    return () => {
      setStep(1);
      onInitialized();
    };
  }, []);

  return (
    <Portal>
      <Modal isVisible={isVisible}>{page}</Modal>
    </Portal>
  );
};

export default FoodRegisterModal;
