import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
}
const TabHeaderForSpinner = ({ children }: Props) => {
  return <TabHeaderWrapper>{children}</TabHeaderWrapper>;
};

const getTabHeaderType = (<TabHeaderForSpinner />).type;

export { TabHeaderForSpinner, getTabHeaderType };

const TabHeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  height: 68px;
  padding: 30px 0px 10px 20px;

  display: flex;
  gap: 14px;

  font-family: 'Noto Sans KR';
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.5;
  color: ${COLORS.PRIMITIVES_GRAY_350};

  .active {
    color: ${COLORS.BLACK};
  }
`;
