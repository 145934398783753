import SearchColorIcon from '@assets/svgs/atomic/searchColorIcon';
import FoodRegisterModal from '@components/modal/FoodRegisterModal/FoodRegisterModal';
import useTopModal from '@hooks/useTopModal';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';

interface Props {
  onRefetch: () => void;
}

const FoodResultRegisterButton = ({ onRefetch }: Props) => {
  const { openModal, closeModal } = useTopModal();

  const onClick = () => {
    openModal(FoodRegisterModal, {
      onClose: () => {
        onRefetch();
        closeModal(FoodRegisterModal);
      },
    });
  };

  return (
    <Wrapper>
      <IconWrapper>
        <SearchColorIcon size={20} />
        <p>찾는 음식이 없나요?</p>
      </IconWrapper>
      <RegisterButton onClick={onClick}>직접 등록</RegisterButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1px;
  letter-spacing: -0.54px;
  color: ${COLORS.PRIMITIVES_GRAY_600};
`;

const RegisterButton = styled.button`
  height: 32px;
  border-radius: 120px;
  background-color: ${COLORS.BLACK};
  padding: 1px 15px 0px 15px;

  font-size: 12px;
  font-weight: 700;
  line-height: 1px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.WHITE};
`;

export default FoodResultRegisterButton;
