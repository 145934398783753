import React, { ReactNode } from 'react';

import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import StickerItem from '@components/calender/StickerItem';
import DashboardItem from '@components/calender/DashboardItem';

const DAY = [
  {
    name: '일',
    id: 'sun',
  },
  {
    name: '월',
    id: 'mon',
  },
  {
    name: '화',
    id: 'tue',
  },
  {
    name: '수',
    id: 'wed',
  },
  {
    name: '목',
    id: 'thu',
  },
  {
    name: '금',
    id: 'fri',
  },
  {
    name: '토',
    id: 'sat',
  },
];

interface Props {
  blankCount: number;
  children?: ReactNode;
}

const Calender = ({ blankCount, children }: Props) => {
  return (
    <Wrapper>
      <CalenderField>
        <CalenderHeadBox>
          <tr>
            {DAY.map((item) => (
              <th key={item.id}>{item.name}</th>
            ))}
          </tr>
        </CalenderHeadBox>
        <CalenderBodyBox>
          <tr>
            {new Array(blankCount).fill(0).map((_, i) => (
              <div key={i} />
            ))}
            {children}
          </tr>
        </CalenderBodyBox>
      </CalenderField>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
`;

const CalenderField = styled.table`
  width: 100%;
`;

const CalenderHeadBox = styled.thead`
  > tr {
    display: grid;
    grid-template-columns: repeat(7, 44px);
    justify-content: space-between;
    margin-bottom: 17px;

    font-family: 'Noto Sans KR';
    font-size: 13px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.388px;
    color: ${COLORS.PRIMITIVES_GRAY_450};
  }
`;
const CalenderBodyBox = styled.tbody`
  > tr {
    display: grid;
    gap: 15px 0;
    grid-template-columns: repeat(7, 44px);
    justify-content: space-between;
  }
`;

export default Object.assign(Calender, {
  Dashboard: DashboardItem,
  Sticker: StickerItem,
});
