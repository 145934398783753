import weak from '../png/ico_good_color_2x.png';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import bubble from '../png/img-bubble-goal@2x.png';
import classNames from 'classnames';

interface Props {
  backgroundColor?: string;
  color?: string;
  size?: 'small' | 'medium' | 'semiLarge' | 'large' | 'posting';
}
export default function Weak({ backgroundColor, color, size = 'small' }: Props) {
  const classes = useStyle();
  switch (size) {
    case 'posting':
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <circle fill="#D2E682" cx="16" cy="16" r="16" />
            <g transform="translate(8.954 12.089)" fill="#000">
              <ellipse cx="1.71" cy="1.713" rx="1.71" ry="1.713" />
              <ellipse cx="12.375" cy="1.713" rx="1.71" ry="1.713" />
              <ellipse cx="7.214" cy="8.586" rx="1.71" ry="2.065" />
            </g>
          </g>
        </svg>
        // <Grid className={classNames([classes.medium, classes.img])} />
      );
    case 'medium':
      return (
        <svg width="31" height="31" viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <circle fill="#D2E682" cx="15.5" cy="15.5" r="15.5" />
            <g transform="translate(8.674 11.711)">
              <ellipse fill="#000" cx="1.657" cy="1.659" rx="1.657" ry="1.659" />
              <ellipse fill="#000" cx="11.988" cy="1.659" rx="1.657" ry="1.659" />
              <path
                stroke="#000"
                strokeWidth="1.736"
                strokeLinecap="round"
                d="M3.125 7.571h7.343"
              />
            </g>
          </g>
        </svg>
        // <Grid className={classNames([classes.medium, classes.img])} />
      );
    case 'semiLarge':
      return (
        <svg width="37" height="37" viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <circle fill="#D2E682" cx="18.5" cy="18.5" r="18.5" />
            <g transform="translate(10.353 13.978)">
              <ellipse fill="#000" cx="1.978" cy="1.98" rx="1.978" ry="1.98" />
              <ellipse fill="#000" cx="14.308" cy="1.98" rx="1.978" ry="1.98" />
              <path stroke="#000" strokeWidth="2.15" strokeLinecap="round" d="M3.73 9.036h8.764" />
            </g>
          </g>
        </svg>
        // <Grid className={classNames([classes.semiLarge, classes.img])} />
      );
    case 'large':
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <circle fill="#D2E682" cx="25" cy="25" r="25" />
            <g transform="translate(13.99 18.889)">
              <ellipse fill="#000" cx="2.672" cy="2.676" rx="2.672" ry="2.676" />
              <ellipse fill="#000" cx="19.336" cy="2.676" rx="2.672" ry="2.676" />
              <path
                stroke="#000"
                strokeWidth="2.8"
                strokeLinecap="round"
                d="M5.041 12.211h11.843"
              />
            </g>
          </g>
        </svg>
        // <Grid className={classNames([classes.large, classes.img])} />
      );
    default:
      return (
        <svg width="27" height="26" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(.5)" fill="none" fillRule="evenodd">
            <circle fill={backgroundColor} cx="13" cy="13" r="13" />
            <ellipse fill={color} cx="8.694" cy="11.13" rx="1.381" ry="1.38" />
            <ellipse fill={color} cx="17.306" cy="11.13" rx="1.381" ry="1.38" />
            <path stroke={color} strokeWidth="1.6" strokeLinecap="round" d="M9.918 16.053h6.121" />
          </g>
        </svg>
      );
  }
}

const useStyle = makeStyles(() =>
  createStyles({
    img: {
      backgroundImage: `url(${weak})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundSize: 'contain',
    },
    medium: {
      width: 31,
      height: 31,
    },
    semiLarge: {
      width: 37,
      height: 37,
    },
    large: {
      width: 50,
      height: 50,
    },
  }),
);
