interface Params {
  measurement_description: string;
  metric_serving_unit: string;
  metric_serving_amount: string;
  number_of_units: number;
}

export const handleFoodDescription = (food: Required<Params>) => {
  const { measurement_description, number_of_units, metric_serving_amount, metric_serving_unit } =
    food;

  const newMeasurementDescription = handleMeasurementDescription(measurement_description);
  const newServingAmount = mathRound(Number(metric_serving_amount));

  return `${number_of_units}${newMeasurementDescription} (${newServingAmount}${metric_serving_unit})`;
};

const handleMeasurementDescription = (measurement_description: string) => {
  return measurement_description.split(' ')[1] === '(00)'
    ? measurement_description.split(' ')[0]
    : measurement_description.replace('.0', '');
};

const mathRound = (value: number) => {
  return (Math.round(value * 10) / 10).toString();
};
