import { Grid, createStyles, makeStyles } from '@material-ui/core';

interface Props {
  index: number;
}

export default function SpinnerDot({ index }: Props) {
  const classes = useStyles();
  const backgroundColor = (() => {
    switch (index) {
      case 0:
        return '#ff5367';
      case 1:
        return '#ff97b9';
      case 2:
        return '#acf982';
      case 3:
        return '#58f28f';
      default:
        return '#ff5367';
    }
  })();

  return (
    <Grid className={classes.dot} container justifyContent={'center'} alignItems={'center'}>
      <div style={{ background: backgroundColor, animationDelay: `${index / 6}s` }} />
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    dot: {
      width: 21,
      height: 21,
      '& div': {
        width: 13,
        height: 13,
        borderRadius: '50%',
        background: '#000',
        animation: '$wave 1.2s infinite linear',
      },
    },
    '@keyframes wave': {
      '0%': {
        width: 13,
        height: 13,
      },
      '12.5%': {
        width: 21,
        height: 21,
      },
      '25%': {
        width: 13,
        height: 13,
      },
    },
  }),
);
