import { ErrorMessage } from '../../../shared/type';

interface loginType {
  data: string;
}
interface loginInfo {
  id: string;
  pwd: string;
}
export interface loginData {
  member_idx: number | undefined;
  member_name: string;
  member_phone: string;
  member_email: string;
  profile_check: number;
  join_route: number;
  point_yn: number;
}

export interface ketoLoginData {
  join_route: number;
  member_idx: number;
  member_join_type: string;
  member_name: string;
  point_yn: number;
  profile_check: number;
}

export const GETUSERREQUEST = 'GETUSERREQUEST' as const;
export const GETUSERSUCCESS = 'GETUSERSUCCESS' as const;
export const GETUSERFAILURE = 'GETUSERFAILURE' as const;

export const SOCIALLOGINSUCCESS = 'SOCIALLOGINSUCCESS' as const;

export const LOGINREQUEST = 'LOGINREQUEST' as const;
export const LOGINSUCCESS = 'LOGINSUCCESS' as const;
export const LOGINFAILURE = 'LOGINFAILURE' as const;

export const REGISTERTERMREQUEST = 'REGISTERTERMREQUEST' as const;
export const REGISTERTERMSUCCESS = 'REGISTERTERMSUCCESS' as const;
export const REGISTERTERMFAILURE = 'REGISTERTERMFAILURE' as const;

export const POINTCHECKREQUEST = 'POINTCHECKREQUEST' as const;
export const POINTCHECKSUCCESS = 'POINTCHECKSUCCESS' as const;
export const POINTCHECKFAILURE = 'POINTCHECKFAILURE' as const;

export const getUserRequest = () => ({ type: GETUSERREQUEST });
export const getUserSuccess = (data: loginData) => ({ type: GETUSERSUCCESS, data: data });
export const getUserFailure = (error: ErrorMessage) => ({ type: GETUSERFAILURE, error: error });

export const socialLoginSuccess = (data: loginType) => ({ type: SOCIALLOGINSUCCESS, data: data });

export const loginRequest = (data: loginInfo) => ({ type: LOGINREQUEST, data: data });
export const loginSuccess = (data: ketoLoginData) => ({ type: LOGINSUCCESS, data: data });
export const loginFailure = (error: ErrorMessage) => ({ type: LOGINFAILURE, error: error });

export const registerTermRequest = (data: string) => ({ type: REGISTERTERMREQUEST, data: data });
export const registerTermSuccess = (data: { join_route: number }) => ({
  type: REGISTERTERMSUCCESS,
  data: data,
});
export const registerTermFailure = (error: ErrorMessage) => ({
  type: REGISTERTERMFAILURE,
  error: error,
});

export const pointCheckRequest = () => ({ type: POINTCHECKREQUEST });
export const pointCheckSuccess = () => ({ type: POINTCHECKSUCCESS });
export const pointCheckFailure = (error: ErrorMessage) => ({
  type: POINTCHECKFAILURE,
  error: error,
});

export interface initialStateType {
  loginLoading: boolean;
  loginDone: boolean;
  loginError: ErrorMessage;
  loginData: loginData;
  loginType: string | undefined;
  registerLoading: boolean;
  registerDone: boolean;
  registerError: ErrorMessage;
  pointCheckDone: boolean;
  pointCheckLoading: boolean;
  pointCheckError: ErrorMessage;
}

export type initialActionType =
  | ReturnType<typeof loginRequest>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof loginFailure>
  | ReturnType<typeof socialLoginSuccess>
  | ReturnType<typeof getUserRequest>
  | ReturnType<typeof getUserSuccess>
  | ReturnType<typeof getUserFailure>
  | ReturnType<typeof registerTermRequest>
  | ReturnType<typeof registerTermSuccess>
  | ReturnType<typeof registerTermFailure>
  | ReturnType<typeof pointCheckRequest>
  | ReturnType<typeof pointCheckSuccess>
  | ReturnType<typeof pointCheckFailure>;
