import { getNotionPage } from '@apis/notionApi';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

export default function useEmbedNotion(notionPageId: string) {
  const navigate = useNavigate();

  const { data } = useQuery(['notion', 'embed', notionPageId], () => getNotionPage(notionPageId), {
    refetchOnWindowFocus: false,
    onError: () => {
      handleErrorPage();
    },
  });

  const handleErrorPage = useCallback(() => {
    const handler = {
      loginInquiry: () => {
        window.open('https://pf.kakao.com/_QWdNb/chat', '_blank');
        navigate('/login');
      },
      privacy: () => {
        navigate('/privacy');
      },
      terms: () => {
        navigate('/terms');
      },
    }[notionPageId];

    handler && handler();
  }, [notionPageId]);

  return data;
}
