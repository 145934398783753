interface Props {
  children?: React.ReactNode;
}

const LayoutButtons = ({ children }: Props) => {
  return <>{children}</>;
};

const getLayoutButtons = (<LayoutButtons />).type;

export { LayoutButtons, getLayoutButtons };
