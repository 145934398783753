import { Route, Routes } from 'react-router-dom';
import { FoodBasketEditPage, FoodBasketPage } from '@pages/basket/components/food';
import { Suspense, lazy } from 'react';
import FallBackSpinner from '@components/spinner/FallBackSpinner';
const ExerciseBasketPage = lazy(() => import('@pages/exercise/new/exerciseBasket'));

const BasketRouter = () => {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path="/food/reg/:mealId/:createdAt" element={<FoodBasketPage />} />
        <Route path="/food/edit/:mealId/:createdAt" element={<FoodBasketEditPage />} />
        <Route path="/exercise/list/:createdAt" element={<ExerciseBasketPage />} />
      </Routes>
    </Suspense>
  );
};

export default BasketRouter;
