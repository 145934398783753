import { atom } from 'recoil';

export const snackbarState = atom<boolean>({
  key: 'snackbarState',
  default: false,
});

export type textNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const toastMsgTextState = atom<textNumber>({
  key: 'toastMsgTextState',
  default: 0,
});

export const skeletonState = atom<boolean>({
  key: 'skeletonState',
  default: false,
});

export const updatePopUpState = atom<boolean>({
  key: 'updatePopUpState',
  default: false,
});

export const indexPopUpState = atom<boolean>({
  key: 'indexPopUpState',
  default: false,
});

// export const postMessageState = atom<string>({
//   key: 'postMessageState',
//   default: '',
// })

export const spinnerState = atom<boolean>({
  key: 'spinnerState',
  default: true,
});

export const cautionPopupState = atom<boolean>({
  key: 'cautionPopupState',
  default: false,
});

export const weightOpenState = atom<'close' | 'input' | 'response'>({
  key: 'weightOpenState',
  default: 'close',
});

// 임시 다이어트 모드 과도기 기간동안만
export const dietModeSelectedIndexState = atom<number>({
  key: 'dieModeSelectedIndexState',
  default: 0,
});

export const appStatusState = atom<string>({
  key: 'appStatusState',
  default: 'foreground',
});

export const forceState = atom<boolean>({
  key: '#forceState',
  default: false,
});
