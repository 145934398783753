import styled from 'styled-components';
import BackArrowGnb from '@components/elements/BackArrowGnb';
import useEmbedNotion from '@hooks/useEmbedNotion';
import { NOTION_PAGE_ID } from '@shared/link';
import { COLORS } from '@styles/constants/_colors';
import { swipeUp } from '@styles/mixins/_animations';
import { NotionRenderer } from 'react-notion';
import { useNavigationType, useParams } from 'react-router-dom';
import 'react-notion/src/styles.css';
import { useEffect } from 'react';

const NotionEmbedPage = () => {
  const navigationType = useNavigationType();
  const { pageId } = useParams() as { pageId: string };
  const blockMap = useEmbedNotion(NOTION_PAGE_ID[pageId as keyof typeof NOTION_PAGE_ID]);

  useEffect(() => {
    if (navigationType === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Container>
      <BackArrowGnb />
      <NotionContainer>
        {blockMap !== undefined && <NotionRenderer blockMap={blockMap} fullPage={false} />}
      </NotionContainer>
    </Container>
  );
};

export default NotionEmbedPage;

const Container = styled.div`
  position: fixed;
  width: 100vw;
  max-width: 428px;
  height: 100vh;
  overflow-y: scroll;
  background-color: ${COLORS.WHITE};
  animation: ${swipeUp} 500ms ease;
`;

const NotionContainer = styled.div`
  padding: 30px 20px 50px 20px;
  user-select: none;
`;
