/**
 * 이전 RN 통신 객체
 * @param type: 함수 호출 메시지 Key 값
 * @param payload: 전달하려는 데이터
 * @param service: 특정 함수에서 선택적으로 사용 (ex. 헬스 커넥트)
 *
 * 변경된 RN 통신 객체
 * @param controller: 큰 단위(도메인)의 사용처 분리
 * @param service: 컨트롤러 내부 호출하려고 하는 함수
 * @param payload: 전달하려는 데이터
 */

interface BeforeMessage {
  type: string;
  payload?: any;
  service?: string;
}

interface Message {
  controller: string;
  service: string;
  payload?: any;
}

type MessageProps = BeforeMessage | Message;

export const sendReactNativeMessage = (props: MessageProps) => {
  let sendData = props.payload;

  if (typeof props.payload === 'object') {
    sendData = JSON.stringify(props.payload);
  }

  try {
    (window as any).ReactNativeWebView.postMessage(
      JSON.stringify({
        ...props,
        payload: sendData,
      }),
    );
  } catch (error) {
    console.log(`PostMessage Error: ${JSON.stringify({ ...props })}`);
  }
};
