import styled from 'styled-components';
import { COLORS, FOUNDATION_COLOR } from 'styles/constants/_colors';

const ButtonSetContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  border-radius: 120px;
  background: ${COLORS.PRIMITIVES_GRAY_70};

  button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 15px;
  }

  &.black {
    background: ${FOUNDATION_COLOR.BASIC_BLACK};
    button {
      color: ${FOUNDATION_COLOR.BASIC_WHITE};
    }
  }

  &.linearBlue {
    background: ${COLORS.PRIMITIVES_LINEAR_BLUE};
    button {
      color: ${FOUNDATION_COLOR.BASIC_WHITE};
    }
  }

  &.gray {
    background: ${COLORS.PRIMITIVES_GRAY_70};
    button {
      color: ${FOUNDATION_COLOR.BASIC_BLACK};
    }
  }

  &.isInActive {
    background: ${COLORS.PRIMITIVES_GRAY_70};
    button {
      color: ${COLORS.PRIMITIVES_GRAY_500};
    }
  }

  &.blue {
    background: ${COLORS.PRIMITIVES_BLUE_400};
    button {
      color: ${COLORS.WHITE};
    }
  }

  &.long {
    width: 100%;
  }

  &.medium {
    width: 100%;
    min-width: 250px;
  }

  &.half {
    width: 100%;
    min-width: 160px;
  }

  &.short {
    width: 100%;
    min-width: 92px;
  }
`;

export { ButtonSetContainer };
