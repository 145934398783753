import {
  LoginRequest,
  LoginResponse,
  SignOutReasons,
  SignUpRequest,
  SignResponse,
  LoginHistoryResponse,
} from '../types/sign';
import { post, del, get } from '../modules/HttpWebClient/default';

export async function SignUp(request: SignUpRequest): Promise<SignResponse> {
  const url = '/auth/commonJoin';
  const { data } = await post<SignResponse>(url, {
    ...request,
  });
  return data;
}

export async function ReduplicationCheck(request: string): Promise<SignResponse> {
  const url = '/auth/reduplicationCheckId';
  const { data } = await post<SignResponse>(url, {
    id: request,
  });
  return data;
}

export async function PostSignOutReasons(request: SignOutReasons): Promise<SignResponse> {
  const url = '/auth/member/out/reason';
  const { data } = await post<SignResponse>(url, {
    ...request,
  });
  return data;
}

export async function SignOutMember(): Promise<SignResponse> {
  const url = '/auth/member';
  const { data } = await del<SignResponse>(url);
  return data;
}

export async function disagreement() {
  const url = '/auth/agreement';
  await del(url);
}

export async function CommonLogin(request: LoginRequest): Promise<LoginResponse> {
  const url = '/auth/commonLogin';
  const { data } = await post<LoginResponse>(url, {
    ...request,
  });
  return data;
}

export async function initializeData(memberIdx: number): Promise<SignResponse> {
  const url = `/v2/members/${memberIdx}/reset`;
  const { data } = await del<SignResponse>(url);
  return data;
}

export async function getLoginHistory(deviceId?: string) {
  const url = `/auth/${deviceId}`;
  const { data } = await get<LoginHistoryResponse>(url);
  return data;
}
