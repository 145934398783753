import { CircleSelectIcon } from '@assets/svgs';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import Portal from '@components/portal';
import { BothSideRightArrowIcon } from '@assets/svgs/_Icons';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import BottomButton from '@components/BottomButton';
import useTopModal from '@hooks/useTopModal';
import InoutPrivacyViewModal from './InoutPrivacyViewModal';
import InoutTermsViewModal from './InoutTermsViewModal';
import { useLayoutEffect } from 'react';
import InoutSensitiveInfoViewModal from './InoutSensitiveInfoViewModal';

interface Props {
  onClose: () => void;
  onLogin: () => void;
}

const ServiceAgreementBottomSheet = ({ onClose, onLogin }: Props) => {
  const { openModal, closeModal } = useTopModal();

  const onClickTerms = () => {
    openModal(InoutTermsViewModal, {
      onClose: () => {
        closeModal(InoutTermsViewModal);
      },
    });
  };

  const onClickPrivacy = () => {
    openModal(InoutPrivacyViewModal, {
      onClose: () => {
        closeModal(InoutPrivacyViewModal);
      },
    });
  };

  const onClickSensitive = () => {
    openModal(InoutSensitiveInfoViewModal, {
      onClose: () => {
        closeModal(InoutSensitiveInfoViewModal);
      },
    });
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <BottomSheet onClose={onClose}>
          <BottomSheet.Headers>
            <Title>서비스 이용 필수 동의</Title>
          </BottomSheet.Headers>
          <BottomSheet.Contents>
            <Wrapper>
              <Row>
                <TextBox>
                  <CircleSelectIcon />
                  <Text>이용 약관 동의</Text>
                </TextBox>
                <BothSideRightArrowIcon onClick={onClickTerms} />
              </Row>
              <Row>
                <TextBox>
                  <CircleSelectIcon />
                  <Text>개인정보 보호방침 동의</Text>
                </TextBox>
                <BothSideRightArrowIcon onClick={onClickPrivacy} />
              </Row>
              <Row>
                <TextBox>
                  <CircleSelectIcon />
                  <Text>민감정보수집 및 이용동의</Text>
                </TextBox>
                <BothSideRightArrowIcon onClick={onClickSensitive} />
              </Row>
              <Row>
                <TextBox>
                  <CircleSelectIcon />
                  <Text>
                    만 <span>14</span>세 이상입니다
                  </Text>
                </TextBox>
              </Row>
            </Wrapper>
          </BottomSheet.Contents>
          <BottomSheet.Buttons>
            <ButtonBox>
              <BottomButton
                color="black"
                onClickEvent={() => {
                  onClose();
                  onLogin();
                }}
              >
                네, 모두 동의해요
              </BottomButton>
            </ButtonBox>
          </BottomSheet.Buttons>
        </BottomSheet>
      </DimmedWrapper>
    </Portal>
  );
};

export default ServiceAgreementBottomSheet;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 24px 20px;
  row-gap: 18px;
  width: 100%;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.8px;
  text-align: center;
  margin-top: 14px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  path {
    stroke: ${COLORS.PRIMITIVES_GRAY_400};
  }
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;

  path {
    stroke: ${COLORS.WHITE};
  }
`;

const Text = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: left;

  span {
    font-family: Campton;
  }
`;

const ButtonBox = styled.div`
  padding: 12px 20px;
`;
