import { Grid, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  active: boolean;
  size: 'medium' | 'small';
}

export default function RedSad({ active, size }: Props) {
  const classes = useStyles();

  const fragment = (() => {
    switch (size) {
      case 'medium':
        return {
          icon: active ? (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="29.5" cy="29.5" r="27.5" fill="#FA5064" />
              <path
                d="M23.5388 37.4798C23.5388 37.4798 26.331 35.5 29.4607 35.5C32.5904 35.5 35.3825 37.4798 35.3825 37.4798"
                stroke="black"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <path
                d="M18.0771 24.3575L23.635 29.9153"
                stroke="black"
                strokeWidth="2.7"
                strokeLinecap="round"
              />
              <path
                d="M18.0735 29.9187L23.6387 24.3536"
                stroke="black"
                strokeWidth="2.7"
                strokeLinecap="round"
              />
              <path
                d="M35.3691 24.3575L40.927 29.9153"
                stroke="black"
                strokeWidth="2.7"
                strokeLinecap="round"
              />
              <path
                d="M35.3655 29.9187L40.9307 24.3536"
                stroke="black"
                strokeWidth="2.7"
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="29.5" cy="29.5" r="27.5" fill="#E6E7EA" />
              <path
                d="M23.5388 37.4798C23.5388 37.4798 26.331 35.5 29.4607 35.5C32.5904 35.5 35.3825 37.4798 35.3825 37.4798"
                stroke="#999FAB"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <path
                d="M18.0771 24.3575L23.635 29.9153"
                stroke="#999FAB"
                strokeWidth="2.7"
                strokeLinecap="round"
              />
              <path
                d="M18.0735 29.9187L23.6387 24.3536"
                stroke="#999FAB"
                strokeWidth="2.7"
                strokeLinecap="round"
              />
              <path
                d="M35.3691 24.3575L40.927 29.9153"
                stroke="#999FAB"
                strokeWidth="2.7"
                strokeLinecap="round"
              />
              <path
                d="M35.3655 29.9187L40.9307 24.3536"
                stroke="#999FAB"
                strokeWidth="2.7"
                strokeLinecap="round"
              />
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16.0002" r="14.9153" fill="#FA5064" />
              <path
                d="M12.7668 20.3282C12.7668 20.3282 14.2812 19.2544 15.9787 19.2544C17.6762 19.2544 19.1906 20.3282 19.1906 20.3282"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <path
                d="M9.80458 13.2108L12.819 16.2252"
                stroke="black"
                strokeWidth="1.46441"
                strokeLinecap="round"
              />
              <path
                d="M9.80262 16.2273L12.821 13.2088"
                stroke="black"
                strokeWidth="1.46441"
                strokeLinecap="round"
              />
              <path
                d="M19.1832 13.2108L22.1977 16.2252"
                stroke="black"
                strokeWidth="1.46441"
                strokeLinecap="round"
              />
              <path
                d="M19.1813 16.2273L22.1997 13.2088"
                stroke="black"
                strokeWidth="1.46441"
                strokeLinecap="round"
              />
            </svg>
          ),
        };
      default:
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16.0002" r="14.9153" fill="#FA5064" />
              <path
                d="M12.7668 20.3282C12.7668 20.3282 14.2812 19.2544 15.9787 19.2544C17.6762 19.2544 19.1906 20.3282 19.1906 20.3282"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <path
                d="M9.80458 13.2108L12.819 16.2252"
                stroke="black"
                strokeWidth="1.46441"
                strokeLinecap="round"
              />
              <path
                d="M9.80262 16.2273L12.821 13.2088"
                stroke="black"
                strokeWidth="1.46441"
                strokeLinecap="round"
              />
              <path
                d="M19.1832 13.2108L22.1977 16.2252"
                stroke="black"
                strokeWidth="1.46441"
                strokeLinecap="round"
              />
              <path
                d="M19.1813 16.2273L22.1997 13.2088"
                stroke="black"
                strokeWidth="1.46441"
                strokeLinecap="round"
              />
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}

const useStyles = makeStyles(() => createStyles({}));
