import { FirstNumberIcon, FourthNumberIcon, SecondNumberIcon, ThirdNumberIcon } from 'assets/svgs';
import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import styled, { keyframes } from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import { SECURITY_KEYBOARD_LIST } from 'structures/security';

interface Props {
  title: string;
  password: number[];
  setPassword: Dispatch<SetStateAction<number[]>>;
  isFail?: boolean;
}
const SecurityView = ({ title, password, setPassword, isFail = false }: Props) => {
  const isMax = password.length === 4;

  const onPostMessage = (message: any) => {
    try {
      (window as any).ReactNativeWebView.postMessage(message);
    } catch (error) {
      console.log(`PostMessage Error: security login`);
    }
  };

  const onClick = (value: string) => {
    const isUndefined = value === 'null';

    if (isUndefined || isMax) return;
    onPostMessage('vibration-touch');
    setPassword([...password, Number(value)]);
  };

  const onDelete = () => {
    const isBlank = password.length === 0;
    if (isBlank) return;
    const copy = [...password];
    copy.pop();
    setPassword([...copy]);
  };

  const handleClick = (value: string) => {
    const isDelete = value === 'delete';
    if (isDelete) {
      onDelete();
      return;
    }
    onClick(value);
  };

  const handleDisabled = (value: string) => {
    const isNull = value === 'null';
    return isNull;
  };

  const keyboardItemList = SECURITY_KEYBOARD_LIST.map((item) => {
    return (
      <KeyBoardItem
        key={item.id}
        color={item.color}
        isDisabled={handleDisabled(item.value)}
        onClick={() => {
          handleClick(item.value);
        }}
      >
        {item.component}
      </KeyBoardItem>
    );
  });

  return (
    <Container>
      <Title>{title}</Title>
      <Password className={classNames({ fail: isFail })}>
        <PasswordItem isVisible={password.length >= 1}>
          <FirstNumberIcon />
        </PasswordItem>
        <PasswordItem isVisible={password.length >= 2}>
          <SecondNumberIcon />
        </PasswordItem>
        <PasswordItem isVisible={password.length >= 3}>
          <ThirdNumberIcon />
        </PasswordItem>
        <PasswordItem isVisible={password.length >= 4}>
          <FourthNumberIcon />
        </PasswordItem>
      </Password>
      <Keyboard>{keyboardItemList}</Keyboard>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  font-family: Cafe24 Ssurround;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.005em;
`;

const vibration = keyframes`
  from {
    transform: translateX(-4px);
  }
  to {
    transform: translateX(4px);
  }
  `;

const Password = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 40px;

  &.fail {
    animation: ${vibration} 0.1s;
    animation-iteration-count: 2;
  }
`;

const PasswordItem = styled.div<{ isVisible: boolean }>`
  position: relative;
  width: 18px;
  height: 18px;
  background-color: ${COLORS.PRIMITIVES_GRAY_200};
  border-radius: 50%;

  svg {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  }
`;

const Keyboard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 22px;
  margin-top: 50px;
  div:nth-last-child(1) {
    padding-top: 0px;
  }
`;

const KeyBoardItem = styled.button<{ color: string; isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 78px;
  height: 78px;
  padding-top: 4px;
  background-color: ${(props) => props.color};
  border-radius: 50%;

  font-family: Cafe24 Ssurround;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.005em;
  color: ${COLORS.BLACK};

  &:active {
    background-color: ${(props) => (props.isDisabled ? 'transparent' : COLORS.PRIMITIVES_GRAY_300)};
  }
`;

export default SecurityView;
