import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

export default function FolderRouter() {
  const FolderSettingPage = lazy(() => import('@pages/search/components/FoodFolder/FolderSetting'));
  const FolderFormPage = lazy(() => import('@pages/search/components/FoodFolder/FolderForm'));
  const FolderFormDirectPage = lazy(
    () => import('@pages/search/components/FoodFolder/FolderFormDirect'),
  );
  const FolderFoodSettingPage = lazy(
    () => import('@pages/search/components/FoodFolder/FolderFoodSetting'),
  );
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path={'/setting'} element={<FolderSettingPage />} />;
        <Route path={'/setting/form'} element={<FolderFormPage />} />;
        <Route path={'/setting/formDirect'} element={<FolderFormDirectPage />} />;
        <Route path={'/folder/:folderName'} element={<FolderFoodSettingPage />} />;
      </Routes>
    </Suspense>
  );
}
