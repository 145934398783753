import { motion } from 'framer-motion';

interface Props {
  isAnimate?: boolean;
  color?: string;
}

const AdditionActive = ({ isAnimate, color = '#007AFF' }: Props) => {
  return (
    <motion.svg
      width="34"
      height="34"
      fill="none"
      animate={isAnimate && svgAnimate}
      transition={svgTransition}
    >
      <motion.circle
        fill={color}
        cx={17}
        cy={17}
        r={17}
        animate={isAnimate && circleAnimate}
        transition={circleTransition}
      />
      <motion.path
        d="M10.8548 17L14.8729 20.9152L23.6304 13.0849"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        animate={isAnimate && checkboxAnimate}
        transition={checkboxTransition}
      />
    </motion.svg>
  );
};

const svgAnimate = {
  width: [34, 48, 34],
  height: [34, 48, 34],
};

const svgTransition = {
  duration: 0.5,
};

const circleAnimate = {
  opacity: [0, 0.5, 1],
  cx: [17, 24, 17],
  cy: [17, 24, 17],
  r: [17, 24, 17],
};

const circleTransition = {
  duration: 0.5,
};

const checkboxAnimate = {
  opacity: [0, 1],
  pathLength: [0, 1],
};

const checkboxTransition = {
  delay: 0.25,
  duration: 0.3,
};

export default AdditionActive;
