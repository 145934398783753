import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

const BottomSheetContents = ({ children }: Props) => {
  return <>{children}</>;
};

const getBottomSheetContents = (<BottomSheetContents />).type;

export { BottomSheetContents, getBottomSheetContents };
