import { getUserInfo } from '@apis/profileApi';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { selectedGroupIdx } from '@states/BattleState';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

type PushData = {
  head: string;
  body: string;
};

type AlertHandler = {
  [key: string]: () => void;
};

const usePushAlarmRedirect = () => {
  const navigate = useNavigate();
  const [groupIdx, setGroupIdx] = useRecoilState(selectedGroupIdx);

  const handleGroupBattleChat = async (pushGroupIdx: number) => {
    if (groupIdx !== pushGroupIdx) {
      let option = {};
      setGroupIdx(pushGroupIdx);
      if (window.location.hash.includes('/battle/group/home')) {
        option = { replace: true };
      }
      navigate(`/battle/group/home/${pushGroupIdx}`, { ...option });
    }

    const user = await getUserInfo();
    if (user) {
      sendReactNativeMessage({
        type: 'groupChat',
        payload: JSON.stringify({
          groupIdx: pushGroupIdx,
          memberIdx: user.member_idx,
        }),
      });
    }
  };

  const handleRedirectPage = (body: string) => {
    let option = {};
    const page = body ?? '';

    if (page === window.location.hash) {
      option = { replace: true };
    }

    if (page !== '') {
      navigate(page, option);
    } else {
      navigate('/');
    }
  };

  const handlePushAlarmRedirect = ({ head, body }: PushData) => {
    if (!head) return;
    const alertHandler: AlertHandler = {
      'redirect-page': () => {
        handleRedirectPage(body);
      },
      'group-battle-chat': () => {
        handleGroupBattleChat(Number(body));
      },
    };

    alertHandler[head]();
    (window as any).ReactNativeWebView.postMessage('pushConfirm');
  };

  return { handlePushAlarmRedirect };
};

export default usePushAlarmRedirect;
