import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { NotionPath } from '@constants/internalPath';
import NotionEmbedPage from '@pages/notionEmbed/NotionEmbedPage';
import SettingFaq from '@pages/notionEmbed/FaqNotionPage';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const NotionRouter = () => {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path={NotionPath.FAQ} element={<SettingFaq />} />
        <Route path="/:pageId" element={<NotionEmbedPage />} />
      </Routes>
    </Suspense>
  );
};

export default NotionRouter;
