import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import GroupBattleRouter from '@routes/GroupBattleRouter';
import FallBackSpinner from '@components/spinner/FallBackSpinner';

const ContractPage = lazy(() => import('@pages/battle/ContractPage'));
const ContractPageForRecipient = lazy(() => import('@pages/battle/ContractPageForRecipient'));
const FinalWeightResultSubmitPage = lazy(() => import('@pages/battle/FinalWeightResultSubmitPage'));
const OneOnOneBattlePage = lazy(() => import('@pages/battle/OneOnOneBattlePage'));
const BattleDetail = lazy(() => import('@pages/battle/battleDetail/BattleDetail'));
const BattleDetailCommunityPage = lazy(
  () => import('@pages/battle/battleDetailPages/BattleDetailCommunityPage'),
);
const BattleDetailFastingPage = lazy(
  () => import('@pages/battle/battleDetailPages/BattleDetailFastingPage'),
);
const BattleDetailFavoritePage = lazy(
  () => import('@pages/battle/battleDetailPages/BattleDetailFavoritePage'),
);
const BattleDetailKcalPage = lazy(
  () => import('@pages/battle/battleDetailPages/BattleDetailKcalPage'),
);
const BattleDetailExercisePage = lazy(
  () => import('@pages/battle/battleDetailPages/BattleDetailExercisePage'),
);
const BattleDetailWeightPage = lazy(
  () => import('@pages/battle/battleDetailPages/BattleDetailWeightPage'),
);
const BattleExerciseRecordPage = lazy(
  () => import('@pages/battle/battleDetailPages/BattleExerciseRecordPage'),
);
const BattleMealRecordPage = lazy(
  () => import('@pages/battle/battleDetailPages/BattleMealRecordPage'),
);
const BattleRecruit = lazy(() => import('@pages/battle/battleRecruit/BattleRecruit'));
const ChatPage = lazy(() => import('@pages/battle/chatPage/ChatPage'));
const ResolutionPage = lazy(() => import('@pages/battle/chatPage/ResolutionPage'));

const PvpBattleOnboardingPage = lazy(
  () => import('@pages/battle/PvpBattle/PvpBattleOnboardingPage'),
);
const PvpBattleRegisterPage = lazy(() => import('@pages/battle/PvpBattle/PvpBattleRegisterPage'));

const BattleRouter = () => {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path="/1on1" element={<OneOnOneBattlePage />} />
        <Route path="/1on1/onboarding" element={<PvpBattleOnboardingPage />} />
        <Route path="/1on1/register/:step" element={<PvpBattleRegisterPage />} />
        <Route path="/recruit/:category" element={<BattleRecruit />} />

        <Route path="/contract/:battleStatus/:battleCode" element={<ContractPage />} />
        <Route
          path="/invitation/:battleStatus/:battleCode"
          element={<ContractPageForRecipient />}
        />
        <Route path="/detail/:battleCode" element={<BattleDetail />} />
        <Route path="/detail/:battleCode/kcal" element={<BattleDetailKcalPage />} />
        <Route path="/detail/:battleCode/favorite" element={<BattleDetailFavoritePage />} />
        <Route path="/detail/:battleCode/fasting" element={<BattleDetailFastingPage />} />
        <Route path="/detail/:battleCode/community" element={<BattleDetailCommunityPage />} />
        <Route path="/detail/:battleCode/exercise" element={<BattleDetailExercisePage />} />
        <Route path="/detail/:battleCode/weight" element={<BattleDetailWeightPage />} />
        <Route path="/meal/:memberIdx/:createdAt/:time" element={<BattleMealRecordPage />} />
        <Route
          path="/exercise/:groupIdx/:memberIdx/:createdAt"
          element={<BattleExerciseRecordPage />}
        />
        <Route path="/detail/:battleCode/submit" element={<FinalWeightResultSubmitPage />} />
        <Route path="/chat/:battleCode" element={<ChatPage />} />
        <Route path="/resolution/:battleCode" element={<ResolutionPage />} />

        <Route path="/group/*" element={<GroupBattleRouter />} />
      </Routes>
    </Suspense>
  );
};

export default BattleRouter;
