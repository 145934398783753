import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { HealthPath } from '@constants/internalPath';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
const AndroidHealthConnectGuidePage = lazy(
  () => import('@components/health/AndroidHealthConnectGuidePage'),
);
const HealthAppAuthGuidePage = lazy(() => import('@components/health/HealthAppAuthGuidePage'));

const HealthRouter = () => {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route
          path={HealthPath.ANDROID_HEALTH_CONNECT}
          element={<AndroidHealthConnectGuidePage />}
        />
        <Route path={HealthPath.HEALTH_APP_AUTH} element={<HealthAppAuthGuidePage />} />
      </Routes>
    </Suspense>
  );
};

export default HealthRouter;
