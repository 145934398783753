import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';

interface Props {
  title?: string;
  children: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .label {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: ${COLORS.PRIMITIVES_GRAY_500};
  }
`;

const Box = styled.div`
  background: ${COLORS.PRIMITIVES_GRAY_60};
  border-radius: 10px;
`;

const FoodRegisterInputWrapper = ({ title, children }: Props) => {
  return (
    <Container>
      {title !== undefined && <p className="label">{title}</p>}
      <Box>{children}</Box>
    </Container>
  );
};

export default FoodRegisterInputWrapper;
