import { UserProfileForm } from '@models/profile';
import { dietModeList } from '@modules/inoutFragment';
import { BACKURL } from '@shared/link';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { handleAgeGroup } from '@utils/handleAgeGroup';

const PRODUCT_URL = 'https://keserv.com';

type PreDefinedEvent = 'login' | 'signUp' | 'setUserProperty';
type Service = PreDefinedEvent | 'customEvent';
interface AdBrixPayload {
  eventKey?: string;
  property?: any;
}

type customPVList = {
  [key: string]: (pathname: string) => void;
};

const useAdBrix = () => {
  // 실제 product에서만 수집하기 위한 변수
  const isProd = BACKURL === PRODUCT_URL;

  /**
   * @param service : 'login' | 'signup' | 'setUserProperty' | 'customEvent'
   * @param payload : { eventKey: 디파이너리 이벤트 이름, property: 이벤트 관련 속성 }
   */
  const sendAdBrixData = (service: Service, payload?: AdBrixPayload) => {
    if (!isProd) return;

    sendReactNativeMessage({
      type: 'adBrix',
      service,
      payload,
    });
  };

  const sendUserProperty = (userProfile: UserProfileForm, memberIdx: number) => {
    const { gender, diet_mode, member_age, start_weight, weight_goal } = userProfile;
    const adBrixGender = gender ? 1 : 2;
    const adBrixDietMode = dietModeList[(diet_mode as 1 | 2 | 3 | 4) - 1]?.name ?? '미등록';
    const ageGroup = handleAgeGroup(member_age);

    sendAdBrixData('setUserProperty', {
      property: {
        member_idx: memberIdx.toString(),
        member_age: ageGroup,
        gender: adBrixGender,
        start_weight: Number(start_weight),
        weight_goal: Number(weight_goal),
        diet_mode: adBrixDietMode,
      },
    });
  };

  /** router에서 자동으로 수집하는 PV 외 mapping이 필요한 router */
  const customPV: customPVList = {
    groupRanking100: () => {
      sendAdBrixData('customEvent', {
        eventKey: `view_groupbattle_top100`,
      });
    },
  };

  const handleCustomPV = (pathname: string) => {
    if (!isProd) return;

    Object.keys(customPV).map((customPage) => {
      if (pathname.includes(customPage)) {
        customPV[customPage](pathname);
        return;
      }
    });
  };

  return {
    sendAdBrixData,
    handleCustomPV,
    sendUserProperty,
  };
};

export default useAdBrix;
