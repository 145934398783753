import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { FoodDetailMealPage, FoodDetailPage } from '@pages/search/components/FoodDetail';
import { FoodResultPage } from '@pages/search/components/foodResult';

const EvaluationPage = lazy(() => import('@pages/EvaluationPage'));
const FoodMyPage = lazy(() => import('@pages/search/components/FoodMyPage'));
const UserFoodEdit = lazy(() => import('@pages/search/components/UserFoodEdit'));
const SelectedFoodListPage = lazy(() => import('@pages/SelectedFoodListPage'));

export default function SearchRouter() {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        {/* 삭제될 페이지 */}
        <Route path="/main/:mealId" element={<FoodMyPage />} />
        <Route path="/result/:mealId" element={<FoodResultPage />} />

        <Route path="/meal/:mealId/:id" element={<FoodDetailMealPage />} />

        <Route path="/detail/:mealId/:id" element={<FoodDetailPage />} />
        <Route path="/edit/user-food/:mealId/:id" element={<UserFoodEdit />} />
        <Route path="/edit/:mealId/:id" element={<FoodDetailPage />} />

        <Route path="/evaluation/mypage/:id" element={<EvaluationPage />} />
        <Route path="/evaluation/inout/:id" element={<EvaluationPage />} />
        <Route path="/evaluation/:mealId/:id" element={<EvaluationPage />} />

        <Route path="/myList" element={<SelectedFoodListPage />} />
      </Routes>
    </Suspense>
  );
}
