import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HomePath } from '@constants/internalPath';

import FallBackSpinner from '@components/spinner/FallBackSpinner';

const Main = lazy(() => import('@pages/main/Main'));

const Statistics = lazy(() => import('@pages/statistics'));
const BodyCondition = lazy(() => import('@pages/main/BodyCondition'));
const Supplements = lazy(() => import('@pages/main/Supplements'));
const WaterDiaryDetail = lazy(() => import('@pages/main/components/WaterDiaryDetail'));
const DietDetailRenewal = lazy(() => import('@pages/ketosis/records/components/DietDetailRenewal'));
const MainThemePage = lazy(() => import('@pages/main/mainTheme'));
const PurchasedThemeDetailPage = lazy(() => import('@pages/main/mainTheme/ThemeDetail'));

export default function MainRouter() {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/home/mealDetail/daily" element={<DietDetailRenewal />} />
        <Route path="/tags/bodyCondition" element={<BodyCondition />} />
        <Route path="/tags/water" element={<WaterDiaryDetail />} />
        <Route path="/tags/supplements" element={<Supplements />} />
        <Route path={HomePath.THEME_SELECT} element={<MainThemePage />} />
        <Route path={HomePath.THEME_DETAIL} element={<PurchasedThemeDetailPage />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
}
