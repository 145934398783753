import { Grid, makeStyles, createStyles } from '@material-ui/core';
import GreenSmile from '../../../assets/images/icon/characters/GreenSmile';
import LightGreenSmile from '../../../assets/images/icon/characters/LightGreenSmile';
import RedSad from '../../../assets/images/icon/characters/RedSad';
import { fastingDifficultyList } from '../../../modules/inoutFragment';

interface Props {
  difficulty: number | null;
  setDifficulty(state: number | null): void;
}

export default function DifficultySelectBox({ difficulty, setDifficulty }: Props) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.selectContainer}
      container
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Grid container direction={'column'} alignItems={'center'} onClick={() => setDifficulty(0)}>
        <LightGreenSmile size={'medium'} active={difficulty === 0} />
        <p
          style={difficulty === 0 ? { color: fastingDifficultyList[0].color, fontWeight: 700 } : {}}
        >
          {fastingDifficultyList[0].text}
        </p>
      </Grid>

      <Grid container direction={'column'} alignItems={'center'} onClick={() => setDifficulty(1)}>
        <GreenSmile size={'medium'} active={difficulty === 1} />
        <p
          style={difficulty === 1 ? { color: fastingDifficultyList[1].color, fontWeight: 700 } : {}}
        >
          {fastingDifficultyList[1].text}
        </p>
      </Grid>

      <Grid container direction={'column'} alignItems={'center'} onClick={() => setDifficulty(2)}>
        <RedSad size={'medium'} active={difficulty === 2} />
        <p
          style={difficulty === 2 ? { color: fastingDifficultyList[2].color, fontWeight: 700 } : {}}
        >
          {fastingDifficultyList[2].text}
        </p>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    selectContainer: {
      width: 269,
      margin: 'auto',
      '& div': {
        width: 'auto',
      },
      '& p': {
        paddingTop: 10,
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 1,
        letterSpacing: -0.41,
        color: '#999FAb',
      },
    },
  }),
);
