import Portal from '@components/portal';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import BottomButton from '@components/BottomButton';
import DatePicker from './DatePicker';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TitleAndCloseHeader from '@components/modal/bottomSheet/TitleAndCloseHeader';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  onClose: () => void;
  onClickDateConfirm: (pickerDate: Dayjs) => void;
  startAt: Dayjs | string;
}

const DatePickerBottomSheet = ({ onClose, onClickDateConfirm, startAt }: Props) => {
  const [date, setDate] = useState(startAt !== '' ? (startAt as Dayjs) : dayjs());

  const onClickDate = (date: number) => {
    setDate((prev) => {
      return dayjs(prev).set('date', date);
    });
  };
  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <BottomSheet onClose={onClose} showPanel={false}>
          <BottomSheet.Headers>
            <TitleAndCloseHeader onClose={onClose} title="단식 시작 날짜" />
          </BottomSheet.Headers>
          <BottomSheet.Contents>
            <DatePicker onClickDate={onClickDate} setDate={setDate} date={date} />
          </BottomSheet.Contents>
          <BottomSheet.Buttons>
            <ButtonBox>
              <BottomButton color="black" onClickEvent={() => onClickDateConfirm(date)}>
                완료
              </BottomButton>
            </ButtonBox>
          </BottomSheet.Buttons>
        </BottomSheet>
      </DimmedWrapper>
    </Portal>
  );
};

export default DatePickerBottomSheet;

const ButtonBox = styled.div`
  padding: 12px 20px;
`;
