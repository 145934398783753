import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import MainRouter from './MainRouter';

const Login = lazy(() => import('@pages/login/Login'));
const KetoRedirect = lazy(() => import('@pages/KetoRedirect'));
const RedirectHandler = lazy(() => import('@shared/RedirectHandler'));
const NewKetoLogin = lazy(() => import('@pages/login/NewKetoLogin'));
const Welcome = lazy(() => import('@pages/Welcome'));

/**
 * 밑에 라우터 없으면 로그인 기능이 작동안됩니다.
 */
const ForceRouter = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/ketoLogin" element={<NewKetoLogin />} />
      <Route path="/KetoRedirect/:type" element={<KetoRedirect />} />
      <Route path="/token/:type/:token" element={<RedirectHandler />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/" element={<MainRouter />} />
    </Routes>
  );
};

export default ForceRouter;
