import { ReactNode } from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import CalenderPicker from '@components/calender/CalenderPicker';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import { selectDateState } from '@pages/calenderDiary/states';

interface Props {
  day: number;
  sticker?: ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

const StickerItem = ({ day, sticker, selected, onClick }: Props) => {
  const todayDate = dayjs();
  const selectedDate = useRecoilValue(selectDateState);
  const isToday = todayDate.format('YYYY-MM-D') === selectedDate.format('YYYY-MM-') + day;
  const isOtherSelected = todayDate.format('DD') !== selectedDate.format('DD');

  return (
    <Wrapper onClick={onClick}>
      {sticker ?? (
        <CalenderItemBox>
          <DefaultCalenderBox>
            <DefaultDateUnit>{day}</DefaultDateUnit>
          </DefaultCalenderBox>
        </CalenderItemBox>
      )}
      <CalenderPicker selected={selected} isToday={isToday} otherSelected={isOtherSelected} />
    </Wrapper>
  );
};

export const Wrapper = styled.td`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  > svg {
    width: 44px;
    height: 44px;
  }
`;

const CalenderItemBox = styled.div`
  width: 44px;
  height: 44px;
  padding: 1px;
`;

const DefaultCalenderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background-color: ${COLORS.PRIMITIVES_GRAY_70};
  border-radius: 50%;
`;

const DefaultDateUnit = styled.div`
  padding-bottom: 1px;
  color: ${COLORS.PRIMITIVES_GRAY_500};
  font-family: Campton;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.07px;
`;

export default StickerItem;
