import { css } from 'styled-components';

const font = css`
  body,
  button,
  input,
  textarea {
    font-family: Campton, Noto Sans KR;
  }
  input[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
    }
  }
`;

export default font;
