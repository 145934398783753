import { ComponentProps, FunctionComponent, ReactNode, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { toastModalState } from 'states/modalState';

const useToastModal = () => {
  const [toast, setToast] = useRecoilState(toastModalState);

  const openModal = useCallback(
    (props: ComponentProps<FunctionComponent<{ children: ReactNode }>>) => {
      if (toast.isPopup) return;
      setToast({
        isPopup: true,
        props: props,
      });
    },
    [toast, setToast],
  );

  const closeModal = useCallback(() => {
    setToast({
      isPopup: false,
      props: {
        children: null,
      },
    });
  }, [setToast]);

  return {
    toast,
    openModal,
    closeModal,
  };
};

export default useToastModal;
