import { COLORS } from '@styles/constants/_colors';

export const handleTagColor = (hitCount: number) => {
  if (hitCount >= 100000) {
    return {
      background: '#FCB3B3',
      color: '#DE1313',
      element: (
        <>
          <span>10</span>
          <p>만회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 50000) {
    return {
      background: '#FFD2A8',
      color: '#EA7000',
      element: (
        <>
          <span>5</span>
          <p>만회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 10000) {
    return {
      background: '#C1BFED',
      color: '#5B53DC',
      element: (
        <>
          <span>1</span>
          <p>만회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 5000) {
    return {
      background: '#A6D6B8',
      color: '#149255',
      element: (
        <>
          <span>5</span>
          <p>천회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 1000) {
    return {
      background: '#A6D6B8',
      color: '#149255',
      element: (
        <>
          <span>1</span>
          <p>천회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 500) {
    return {
      background: '#AAC1DE',
      color: '#025ABA',
      element: (
        <>
          <span>500</span>
          <p>회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 100) {
    return {
      background: '#AAC1DE',
      color: '#025ABA',
      element: (
        <>
          <span>100</span>
          <p>회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 50) {
    return {
      background: '#AAC1DE',
      color: '#025ABA',
      element: (
        <>
          <span>50</span>
          <p>회 이상 기록</p>
        </>
      ),
    };
  }

  return {
    background: '#A4ABB9',
    color: '#4C5057',
    element: (
      <>
        <span>50</span>
        <p>회 미만 기록</p>
      </>
    ),
  };
};

export const handleFoodItemTag = (food_hit: number) => {
  const hitCount = food_hit;

  if (hitCount >= 100000) {
    return {
      background: '#FFEAE1',
      color: '#FF2E00',
      element: (
        <>
          <span>10</span>
          <p>만회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 50000) {
    return {
      background: '#FFEFE7',
      color: '#FF8D23',
      element: (
        <>
          <span>5</span>
          <p>만회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 10000) {
    return {
      background: '#EEEDFE',
      color: COLORS.PRIMITIVES_LIGHT_PURPLE_500,
      element: (
        <>
          <span>1</span>
          <p>만회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 5000) {
    return {
      background: '#DFFAEC',
      color: '#1BCD71',
      element: (
        <>
          <span>5</span>
          <p>천회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 1000) {
    return {
      background: '#DFFAEC',
      color: '#1BCD71',
      element: (
        <>
          <span>1</span>
          <p>천회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 500) {
    return {
      background: COLORS.PRIMITIVES_BLUE_50,
      color: '#1C88FF',
      element: (
        <>
          <span>500</span>
          <p>회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 100) {
    return {
      background: COLORS.PRIMITIVES_BLUE_50,
      color: '#1C88FF',
      element: (
        <>
          <span>100</span>
          <p>회 이상 기록</p>
        </>
      ),
    };
  }

  if (hitCount >= 50) {
    return {
      background: COLORS.PRIMITIVES_BLUE_50,
      color: '#1C88FF',
      element: (
        <>
          <span>50</span>
          <p>회 이상 기록</p>
        </>
      ),
    };
  }

  return {
    background: COLORS.PRIMITIVES_GRAY_200,
    color: COLORS.PRIMITIVES_GRAY_500,
    element: (
      <>
        <span>50</span>
        <p>회 미만 기록</p>
      </>
    ),
  };
};
