import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { loginSaga } from '../reducer/user/action';
import { loginReducer } from '../reducer/user/reducer';

export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  login: loginReducer,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([fork(loginSaga)]);
}
