import { InfinityResponse } from '@models/groupBattle';
import { FoodReview } from '@models/main';
import {
  BookmarkFolder,
  BrandNameList,
  FoodDetail,
  RequestCustomFoodDetail,
  SearchAutoComplete,
  SearchParams,
  SearchResult,
} from '@models/searchResult';
import { del, get, patch, post } from '../modules/HttpWebClient/default';
import { RequestDate, ResponseMsg } from '../types/dietDiary';
import { UserSearchResultResponseType } from '@models/myPage';

export const getSearchFood = async ({
  query,
  from,
  size,
  includeByUser,
}: SearchParams): Promise<Omit<InfinityResponse<FoodDetail[]>, 'lastIndex'>> => {
  const url = `foods?query=${encodeURIComponent(
    query,
  )}&from=${from}&size=${size}&include_by_user=${Boolean(includeByUser)}`;
  const { data } = await get<FoodDetail[]>(url);
  return {
    data,
    isMore: data.length === 20,
  };
};

export async function getAddedFoodCount({
  time,
  created_at,
}: RequestDate): Promise<{ cartCount: number }> {
  const url = `/food/cart/counting/${time}/${created_at}`;
  const { data } = await get<{ cartCount: number }>(url);
  return data;
}

export async function searchFood(word: string, lastIndex: number): Promise<SearchResult[]> {
  const url = `/fsapi/search/food?page=${lastIndex}`;
  const { data } = await post<SearchResult[]>(url, {
    foodName: word,
  });
  return data;
}

export async function searchAutoComplete(word: string): Promise<SearchAutoComplete> {
  const url = `/fsapi/searchAutoComplete`;
  const { data } = await post<SearchAutoComplete>(url, {
    word: word,
  });
  return data;
}

export async function getRecentlyEatenFood(): Promise<SearchResult[]> {
  const url = `/search/recently`;
  const { data } = await get<SearchResult[]>(url);
  return data;
}

export async function setBookmarkFolderFood(
  member_idx: number,
  food_bookmark_folder_idx: number,
  food_idx: string,
) {
  const url = `/v2/members/${member_idx}/bookmark/foods/folders/${food_bookmark_folder_idx}  `;
  await post(url, {
    food_idx,
  });
}

export async function setBookmarkFolder(member_idx: number, folders: BookmarkFolder[]) {
  const url = `v2/members/${member_idx}/bookmark/foods/folders`;
  await post(url, folders);
}

export async function getBookmarkFolder(member_idx: number): Promise<BookmarkFolder[]> {
  const url = `v2/members/${member_idx}/bookmark/foods/folders`;
  const { data } = await get<BookmarkFolder[]>(url);
  return data;
}

export async function deleteBookmarkFolder(member_idx: number, indexes: string[]) {
  const url = `v2/members/${member_idx}/bookmark/foods`;
  const { data } = await del(url, {
    data: {
      food_idxes: indexes,
    },
  });
  return data;
}

export async function moveBookmarkFolder(
  member_idx: number,
  food_bookmark_folder_idx: number,
  indexes: string[],
) {
  const url = `/v2/members/${member_idx}/bookmark/foods/`;
  const { data } = await patch(url, {
    food_bookmark_folder_idx,
    food_idxes: indexes,
  });
  return data;
}

export async function getBookmarkFoodList(
  member_idx: number,
  food_bookmark_folder_idx: number,
): Promise<FoodDetail[]> {
  const url = `/v2/members/${member_idx}/bookmark/foods/folders/${food_bookmark_folder_idx}`;
  const { data } = await get<FoodDetail[]>(url);
  return data;
}

export async function getRegisteredFood(member_idx: number): Promise<FoodDetail[]> {
  const url = `/members/${member_idx}/foods`;
  const { data } = await get<FoodDetail[]>(url);
  return data;
}

export async function getBookmarkFood(member_idx: number, food_idx: string): Promise<boolean> {
  const url = `/v2/members/${member_idx}/bookmark/foods/${food_idx}`;

  const { data } = await get<boolean>(url);
  return data;
}

export async function addBookmarkFood(member_idx: number, food_idx: string): Promise<ResponseMsg> {
  const url = `members/${member_idx}/bookmark/foods`;
  const { data } = await post<ResponseMsg>(url, {
    food_idx,
  });
  return data;
}

export async function delBookmarkFood(member_idx: number, food_idx: string): Promise<ResponseMsg> {
  const url = `/v2/members/${member_idx}/bookmark/foods/${food_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function existFood(
  food_name: string,
  brand_name: string | 0 | null,
): Promise<boolean> {
  const query = `food_name=${food_name}`.concat(
    brand_name === 0 || brand_name === null ? '' : `&brand_name=${brand_name}`,
  );
  const url = `/foods/available?${query}`;
  const { data } = await get<boolean>(url);
  return data;
}

export async function getFoodDetail(foodIdx: string): Promise<FoodDetail> {
  const url = `/foods/${foodIdx}`;
  const { data } = await get<FoodDetail>(url);
  return data;
}

export async function setCustomFoodDetail(
  member_idx: number,
  request: RequestCustomFoodDetail,
): Promise<ResponseMsg> {
  const url = `/members/${member_idx}/foods`;
  const { data } = await post<ResponseMsg>(url, { ...request });
  return data;
}

export async function getAvailableFoodModify(
  member_idx: number,
  food_idx: string,
): Promise<boolean> {
  const url = `/members/${member_idx}/report/foods/${food_idx}`;
  const { data } = await get<boolean>(url);
  return data;
}

export async function getMyFoodCheck(member_idx: number, food_idx: string): Promise<boolean> {
  const url = `/members/${member_idx}/foods/${food_idx}`;
  const { data } = await get<boolean>(url);
  return data;
}

export async function modifyFoodDetail({
  member_idx,
  food,
  paths,
}: {
  member_idx: number;
  food: FoodDetail;
  paths: string[];
}) {
  const url = `/members/${member_idx}/report/foods/${food.food_idx}`;
  await patch(url, {
    ...food,
    img1: paths[0],
    img2: paths[1],
  });
}

export async function modifyMyFood(member_idx: number, food: FoodDetail) {
  const url = `/members/${member_idx}/foods/${food.food_idx}`;
  await patch(url, {
    ...food,
  });
}

export async function deleteMyFood(member_idx: number, food_idx: string) {
  const url = `/members/${member_idx}/foods/${food_idx}`;
  await del(url);
}

export async function getBrandList(word: string): Promise<BrandNameList> {
  const url = `/edit/brandSearch/${word}`;
  const { data } = await get<BrandNameList>(url);
  return data;
}

export async function getRegisteredFoodForEvaluation(
  yn: 'Y' | 'N',
  lastIdx: number,
): Promise<FoodReview[]> {
  const url = `/inoutTalk/main/myReview/${yn}/${lastIdx}`;
  const { data } = await get<FoodReview[]>(url);
  return data;
}

export async function getRegisteredFoodForEvaluation2(lastIdx: number): Promise<{
  myPageReviews: FoodReview[];
  isMore: boolean;
  lastIndex: number;
}> {
  const url = `/inoutTalk/main/myReview/Y/${lastIdx}`;
  const { data } = await get<FoodReview[]>(url);
  return {
    myPageReviews: data,
    isMore: data.length === 10,
    lastIndex: data[data.length - 1].infinity_idx,
  };
}

export async function getUserSearchResult(memberIdx: number, name: string) {
  const url = `/members/${memberIdx}/search?name=${name}`;
  const { data } = await get<UserSearchResultResponseType[]>(url);

  return data;
}
