interface Props {
  size: 'large' | 'small';
  color: string;
  innerColor?: string;
}

export default function XCircleDefault({ size, color, innerColor = '#fff' }: Props) {
  const fragment = (() => {
    switch (size) {
      case 'large':
        return {
          icon: (
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.3" cx="15" cy="15" r="15" fill={color} />
              <path
                d="M10.5533 10.5537L19.5533 19.5537"
                stroke={innerColor}
                strokeWidth="1.6"
                strokeLinecap="round"
              />
              <path
                d="M10.5533 19.5537L19.5533 10.5537"
                stroke={innerColor}
                strokeWidth="1.6"
                strokeLinecap="round"
              />
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="9" cy="9" r="9" fill={color} />
              <path
                d="M6.33197 6.33203L11.732 11.732"
                stroke={innerColor}
                strokeWidth="1.2"
                strokeLinecap="round"
              />
              <path
                d="M6.33197 11.732L11.732 6.33203"
                stroke={innerColor}
                strokeWidth="1.2"
                strokeLinecap="round"
              />
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}
