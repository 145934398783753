import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  bgColor?: string;
  padding?: string;
}

const LayoutHeaders = ({ bgColor, padding, children }: Props) => {
  return (
    <Wrapper bgColor={bgColor} padding={padding}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ bgColor?: string; padding?: string }>`
  padding: ${({ padding }) => padding && padding};
  background-color: ${({ bgColor }) => bgColor && bgColor};
`;

const getLayoutHeaders = (<LayoutHeaders />).type;

export { LayoutHeaders, getLayoutHeaders };
