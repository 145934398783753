import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import BasketRouter from './BasketRouter';
import BattleRouter from './BattleRouter';
import CalenderDiaryRouter from './CalenderDiaryRouter';
import CommunicationRouter from './CommunicationRouter';
import ExerciseRouter from './ExerciseRouter';
import FastingRouter from './FastingRouter';
import MainRouter from './MainRouter';
import MyPageRouter from './MyPageRouter';
import SearchRouter from './SearchRouter';

import KetoRedirect from '@pages/KetoRedirect';
import Login from '@pages/login/Login';
import RedirectHandler from '@shared/RedirectHandler';
import RecipeRouter from './RecipeRouter';
import NotionRouter from './NotionRouter';
import HealthRouter from './HealthRouter';
import FolderRouter from './FolderRouter';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import FoodEdit from '@pages/search/components/foodEdit';
import AppleRouter from '@routes/AppleRouter';

const NewKetoLogin = lazy(() => import('@pages/login/NewKetoLogin'));
const UserProfileSetting = lazy(() => import('@pages/userProfileSetting'));
const Welcome = lazy(() => import('@pages/Welcome'));
const InoutPrivacyViewPage = lazy(() => import('@pages/login/InoutPrivacyViewPage'));
const InoutTermsViewPage = lazy(() => import('@pages/login/InoutTermsViewPage'));
const SignUp = lazy(() => import('@pages/signUp/SignUp'));

const Router = () => {
  const { pathname } = useLocation();
  const { handleCustomPV } = useAdBrix();

  useEffect(() => {
    handleCustomPV(pathname);
  }, [pathname]);

  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/ketoLogin" element={<NewKetoLogin />} />
        <Route path="/token/:type/:token" element={<RedirectHandler />} />
        <Route path="/KetoRedirect/:type" element={<KetoRedirect />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/userProfile/:step" element={<UserProfileSetting />} />
        <Route path="/search/*" element={<SearchRouter />} />
        <Route path="/basket/*" element={<BasketRouter />} />
        <Route path="/recipe/*" element={<RecipeRouter />} />
        <Route path="/custom/:step/:food_id" element={<FoodEdit />} />
        <Route path="/privacy" element={<InoutPrivacyViewPage />} />
        <Route path="/terms" element={<InoutTermsViewPage />} />

        <Route path="/apple/*" element={<AppleRouter />} />
        <Route path="/myPage/*" element={<MyPageRouter />} />
        <Route path="/signup/:id" element={<SignUp />} />
        <Route path="/communication/*" element={<CommunicationRouter />} />
        <Route path="/fasting/*" element={<FastingRouter />} />
        <Route path="/folders/*" element={<FolderRouter />} />
        <Route path="/battle/*" element={<BattleRouter />} />
        <Route path="/exercise/*" element={<ExerciseRouter />} />
        <Route path="/calenderDiary/*" element={<CalenderDiaryRouter />} />
        <Route path="/notion/*" element={<NotionRouter />} />
        <Route path="/health/*" element={<HealthRouter />} />
        <Route path="/*" element={<MainRouter />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
