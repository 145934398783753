export default function InoutCharacterGreen() {
  return (
    <svg width="61" height="45" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g fill="#4FE685">
          <path d="M49.499 39.967c3.555-4.218 5.687-9.602 5.687-15.467C55.186 10.969 43.84 0 29.843 0 15.847 0 4.5 10.969 4.5 24.5c0 5.899 2.156 11.31 5.748 15.539l39.25-.072z" />
          <path d="M43.352 23.8c-4.085 10.73-6.585 17.371-7.5 19.922-.915 2.552 4.301 1.244 15.648-3.922l-8.148-16zM8.352 23.8C4.267 34.53 1.767 41.171.852 43.722-.063 46.274 5.153 44.966 16.5 39.8l-8.148-16z" />
        </g>
        <path
          d="M17.7 24.533c0 4.004 3.68 7.25 8.22 7.25 4.539 0 8.219-3.246 8.219-7.25H17.7z"
          fill="#000"
        />
        <path
          stroke="#000"
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m34.315 17.216-3.288 2.637 5.35 1.318M16.876 17.216l3.433 2.637-5.48 1.318"
        />
        <path fill="#4FE685" d="M54.543 18.957 60.5 40.04H47.423z" />
      </g>
    </svg>
  );
}
