import { foodDetailAmountState, foodDetailMetricTypeState } from '@states/FoodState';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

const useFoodQuickAmount = (metricServingAmount: number) => {
  const type = useRecoilValue(foodDetailMetricTypeState);
  const count = useRecoilValue(foodDetailAmountState);

  const amount = useMemo(() => {
    const AMOUNT = {
      serving: count,
      unit: (count as number) / metricServingAmount,
    }[type];
    return AMOUNT as number;
  }, [type, count]);

  const servingAmount = useMemo(() => {
    const AMOUNT = {
      serving: (count as number) * Number(metricServingAmount),
      unit: count,
    }[type];
    return AMOUNT.toString();
  }, [type, count]);

  return { amount, servingAmount };
};

export default useFoodQuickAmount;
