export const recommendedCalories = (height: number, isWoman: boolean, status: number) => {
  const heightRatio = height / 100;
  const genderVariable = isWoman ? 21 : 22;
  let activeStatus = 0;
  if (status === 0) {
    activeStatus = 27;
  }
  if (status === 1) {
    activeStatus = 30;
  }
  if (status === 2) {
    activeStatus = 35;
  }

  const recommendedCalories = heightRatio ** 2 * genderVariable * activeStatus;
  return Math.round(recommendedCalories);
};

export const calculateMajorNutrients = (carbohydrate: number, protein: number, fat: number) => {
  const carbohydrateCalories = carbohydrate * 4;
  const proteinCalories = protein * 4;
  const fatCalories = fat * 9;
  const totalCalories = carbohydrateCalories + proteinCalories + fatCalories;

  const calculateRatio = (calories: number) => {
    const ratioPart = ((calories / totalCalories) * 100).toFixed(1);
    return parseInt(ratioPart);
  };

  return {
    carbohydrateRatio: calculateRatio(carbohydrate),
    proteinRatio: calculateRatio(protein),
    fatRatio: calculateRatio(fat),
  };
};

const leftPad = (value: number) => {
  if (value >= 10) {
    return value;
  }
  return `0${value}`;
};

export const toStringByFormatting = (source: Date, delimiter = '-') => {
  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());
  return [year, month, day].join(delimiter);
};

export const nameCountHandler = (name: string, length: number) => {
  if ((name?.length || 0) > length) {
    return `${name.slice(0, length)}. . .`;
  } else {
    return name;
  }
};
