import MinusBox from '@assets/images/icon/Calculation/MinusBox';
import PlusBox from '@assets/images/icon/Calculation/PlusBox';
import ToggleButton from '@components/elements/Button/ToggleButton';
import { FoodDetail } from '@models/searchResult';
import { calculateNumberPerUnit } from '@modules/calorieCalculator';
import useFoodQuickAmount from '@pages/search/hooks/useFoodQuickAmount';
import { handleMetricButtonName } from '@pages/search/utils/handleMetricButtonName';
import { foodDetailAmountState, foodDetailMetricTypeState } from '@states/FoodState';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import { ChangeEvent, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

interface Props {
  item: FoodDetail;
}

const FoodQuickContents = ({ item }: Props) => {
  const { amount } = useFoodQuickAmount(Number(item.metric_serving_amount ?? 0));
  const { measurementAdditional, metricUnit } = handleMetricButtonName(item);
  const setType = useSetRecoilState(foodDetailMetricTypeState);
  const [count, setCount] = useRecoilState(foodDetailAmountState);
  const isMax = useMemo(() => Number(count) >= 9999, [count]);
  const isMin = useMemo(() => Number(count) <= 0.5, [count]);

  const onClickMeasurement = () => {
    setType('serving');
    setCount(1);
  };

  const onClickMetricUnit = () => {
    const metricAmount = Number(item.metric_serving_amount || 0);
    setType('unit');
    setCount(metricAmount);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const replace = value.replace(',', '.');

    const isDecimal =
      replace.includes('.') && !replace.split('.')[1] && replace.split('.')[2] !== '';
    const roundRegex = /^\d*(.)?\d{0,1}?$/;

    /* 1.x 케이스를 위한 경우 */
    if (isDecimal) {
      setCount(replace);
      return;
    }

    /* 공백 케이스 */
    if (replace === '') {
      setCount(replace);
      return;
    }

    /* 최대값 케이스 */
    if (Number(replace) >= 9999) {
      setCount(9999);
      return;
    }

    /* 소수점 첫 번째 자리만 저장 */
    if (roundRegex.test(replace)) {
      setCount(Number(replace));
    }
  };

  const handleMinus = () => {
    const value =
      Number(count) % 0.5 === 0 ? Number(count) - 0.5 : Math.floor(Number(count) / 0.5) * 0.5;
    return value;
  };

  const onClickMinus = () => {
    if (isMin) return;
    const format = handleMinus();
    setCount(format);
  };

  const handlePlus = () => {
    const value =
      Number(count) % 0.5 === 0 ? Number(count) + 0.5 : Math.ceil(Number(count) / 0.5) * 0.5;
    return value;
  };

  const onClickPlus = () => {
    if (isMax) {
      setCount(9999);
      return;
    }
    const format = handlePlus();
    setCount(format);
  };

  const nutrientList = useMemo(
    () => [
      {
        title: '탄',
        value: item.net_carbohydrate ?? 0,
        color: FOUNDATION_COLOR.PRIMARY_NAVY,
        background: COLORS.WHITE,
      },
      {
        title: '단',
        value: item.protein ?? 0,
        color: FOUNDATION_COLOR.PRIMARY_NAVY,
        background: FOUNDATION_COLOR.PRIMARY_YELLOW,
      },
      {
        title: '지',
        value: item.fat ?? 0,
        color: COLORS.WHITE,
        background: COLORS.PRIMITIVES_BLUE_NAVY_400,
      },
    ],
    [item],
  );

  const nutrientElements = nutrientList.map((item, index) => (
    <NutrientBox key={index} color={item.color} background={item.background}>
      <div className="circle">
        <p>{item.title}</p>
      </div>
      <p className="value">
        {calculateNumberPerUnit(amount, item.value)}
        <span>g</span>
      </p>
    </NutrientBox>
  ));

  return (
    <>
      <DetailField>
        <NutrientField>{nutrientElements}</NutrientField>
        <KcalUnit>
          {calculateNumberPerUnit(amount, item.calories ?? 0)}
          <span>kcal</span>
        </KcalUnit>
      </DetailField>
      <UnitField>
        <ToggleButton
          leftButtonName={measurementAdditional}
          rightButtonName={metricUnit}
          onLeftClick={onClickMeasurement}
          onRightClick={onClickMetricUnit}
          disabledRight={item.metric_serving_unit === '0'}
          backgroundColor={COLORS.PRIMITIVES_GRAY_60}
          activeBackgroundColor={COLORS.WHITE}
          buttonColor={COLORS.BLACK}
          buttonDisabledColor={COLORS.PRIMITIVES_GRAY_450}
        />
        <AmountField>
          <IconButton onClick={onClickMinus} disabled={isMin}>
            <MinusBox color={isMin ? COLORS.PRIMITIVES_GRAY_400 : COLORS.BLACK} weight="bold" />
          </IconButton>
          <InputBox>
            <input onChange={onChange} value={count} inputMode="decimal" />
          </InputBox>
          <IconButton onClick={onClickPlus} disabled={isMax}>
            <PlusBox color={isMax ? COLORS.PRIMITIVES_GRAY_400 : COLORS.BLACK} weight="bold" />
          </IconButton>
        </AmountField>
      </UnitField>
    </>
  );
};

const DetailField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  margin: 0 20px;
  padding: 24px 0 21px;

  background-color: ${COLORS.PRIMITIVES_GRAY_60};
  border-radius: 16px;
`;

const NutrientField = styled.div`
  display: flex;
  gap: 16px;
`;

const NutrientBox = styled.div<{ color: string; background: string }>`
  display: flex;
  gap: 10px;
  align-items: center;

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;

    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;

    background-color: ${({ background }) => background};

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 900;
    color: ${({ color }) => color};
  }

  .value {
    font-family: Campton;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
  }

  span {
    margin-left: 1px;
  }
`;

const KcalUnit = styled.p`
  text-align: right;
  font-family: Campton;
  font-size: 22px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;

  span {
    margin-left: 1px;
  }
`;

const UnitField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 18px 20px 14px;
`;

const AmountField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
  border-radius: 120px;
  padding: 3px 12px;
`;

const InputBox = styled.div`
  max-width: 100%;

  input {
    width: 100%;
    font-family: Campton;
    font-size: 18px;
    font-weight: 600 !important;
    line-height: 27px;
    letter-spacing: -0.5px;
    text-align: center;
    color: ${COLORS.BLACK};
  }
`;

const IconButton = styled.button``;

export default FoodQuickContents;
