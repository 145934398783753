import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import FallBackSpinner from '@components/spinner/FallBackSpinner';

const CommunicationPage = lazy(() => import('@pages/communication/CommunicationPage'));
const InoutReview = lazy(() => import('@pages/communication/ketoInoutTalk/InoutReview'));
const BoardDetailPage = lazy(() => import('@pages/communication/board/BoardDetailPage'));
const PostingKetogram = lazy(() => import('@pages/communication/FeedPosting/FeedCreate'));
const EditKetogram = lazy(() => import('@pages/communication/FeedPosting/FeedEdit'));
const KetogramLikeInfoPage = lazy(
  () => import('@pages/communication/ketogram/KetogramLikeInfoPage'),
);
const KetogramCommentPage = lazy(() => import('@pages/communication/ketogram/KetogramCommentPage'));
const MealDetailPageKetogram = lazy(
  () => import('@pages/communication/ketogram/MealDetailPageKetogram'),
);
const RecipeDetailPageKetogram = lazy(
  () => import('@pages/communication/ketogram/RecipeDetailPageKetogram'),
);
const KetogramNewUserPage = lazy(() => import('@pages/communication/ketogram/KetogramNewUserPage'));
const FeedDetail = lazy(() => import('@pages/communication/ketogram/FeedDetail'));
const KetogramFollowInfoPage = lazy(
  () => import('@pages/communication/ketogram/KetogramFollowInfoPage'),
);

const CommunicationSearch = lazy(
  () => import('@pages/communication/communicationSearch/CommunicationSearch'),
);
const NoticePage = lazy(() => import('@pages/communication/notice/index'));
const AnonymityPage = lazy(() => import('@pages/communication/anonymity/index'));
const BoardPage = lazy(() => import('@pages/communication/board'));
const KetogramList = lazy(() => import('@pages/communication/Feed'));
const KetogramSearch = lazy(() => import('@pages/communication/FeedSearch'));
const PopularFeedDetail = lazy(() => import('@pages/communication/popular/feed/PopularFeedDetail'));
const PostingPage = lazy(() => import('@pages/communication/components/PostingPage'));
const Reply = lazy(() => import('@pages/communication/board/components/comment/Reply'));
const EventReply = lazy(() => import('@pages/communication/eventBoard/eventDetail/EventReply'));
const EventBoardPage = lazy(() => import('@pages/communication/eventBoard/eventList'));
const EventDetailPage = lazy(() => import('@pages/communication/eventBoard/eventDetail'));

const MyMessagePage = lazy(() => import('@pages/communication/message/MyMessagePage'));
const MessageDetailPage = lazy(() => import('@pages/communication/message/MessageDetailPage'));
const MessageWritePage = lazy(() => import('@pages/communication/message/MessageWritePage'));

export default function CommunicationRouter() {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path="/:tabName" element={<CommunicationPage />} />

        <Route path="/ketogram/posting" element={<PostingKetogram />} />
        <Route path="/ketogram/edit/:ketogram_idx" element={<EditKetogram />} />
        <Route path="/ketogram/postingImmediately" element={<PostingKetogram />} />
        <Route path="/ketogram/user/:member_idx" element={<KetogramNewUserPage />} />

        <Route path="/ketogram/follow/:member_idx/:index" element={<KetogramFollowInfoPage />} />

        <Route path="/ketogram/feed/:ketogram_idx" element={<FeedDetail />} />
        <Route path="/ketogram/posting/:ketogram_idx" element={<PostingKetogram />} />
        <Route path="/ketogram/like/:ketogram_idx" element={<KetogramLikeInfoPage />} />
        <Route path="/ketogram/comment/:ketogram_idx" element={<KetogramCommentPage />} />
        <Route
          path="/ketogram/:ketogram_idx/:created_at/:time/:memberIdx"
          element={<MealDetailPageKetogram />}
        />
        <Route
          path="/ketogram/:ketogram_idx/:ketogram_recipe_idx"
          element={<RecipeDetailPageKetogram />}
        />
        <Route
          path="/ketogram/postingDetail/:createdAt/:time"
          element={<MealDetailPageKetogram />}
        />
        <Route
          path="/ketogram/postingDetail/:ketogram_recipe_idx"
          element={<RecipeDetailPageKetogram />}
        />

        <Route path="/anonymity/search" element={<CommunicationSearch />} />
        <Route path="/anonymity/posting" element={<PostingPage />} />
        <Route path="/board/posting" element={<PostingPage />} />
        <Route path="/battleRecruit/posting" element={<PostingPage />} />
        <Route path="/board/posting/:board_idx" element={<PostingPage />} />
        <Route path="/board/detail/:board_idx" element={<BoardDetailPage />} />
        <Route path="/ketogram/:categoryNum" element={<KetogramList />} />
        <Route path="/notice/:noticeType" element={<NoticePage />} />
        <Route path="/board/:categoryNum" element={<BoardPage />} />
        <Route path="/anonymity/:viewType" element={<AnonymityPage />} />
        <Route path="/ketogram/search" element={<KetogramSearch />} />
        <Route path="/board/:boardIdx/reply/:commentIdx" element={<Reply />} />
        <Route path="/anonymity/:boardIdx/reply/:commentIdx" element={<Reply />} />
        <Route path="/event/:boardIdx/reply/:commentIdx" element={<EventReply />} />

        <Route path="/popular/ketogram" element={<PopularFeedDetail />} />

        <Route path="/review" element={<InoutReview />} />
        <Route path="/event" element={<EventBoardPage />} />
        <Route path="/event/detail/:board_idx" element={<EventDetailPage />} />

        <Route path="/message" element={<MyMessagePage />} />
        <Route path="/message/:type/detail/:idx" element={<MessageDetailPage />} />
        <Route path="/message/write/:sendIdx" element={<MessageWritePage />} />
      </Routes>
    </Suspense>
  );
}
