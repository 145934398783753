interface Props {
  color: string;
  weight?: 'default' | 'bold';
}

export default function MinusBox({ color, weight = 'default' }: Props) {
  const DEFAULT_MINUS_BOX = (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 25H31" stroke={color} strokeWidth="1.8" strokeLinecap="round" />
    </svg>
  );

  const BOLD_MINUS_BOX = (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.52 27.0009H33.48H20.52Z" fill="white" />
      <path d="M20.52 27.0009H33.48" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  );

  const MINUS_BOX = {
    default: DEFAULT_MINUS_BOX,
    bold: BOLD_MINUS_BOX,
  }[weight];

  return MINUS_BOX;
}
