import { GNBCloseIcon } from 'assets/svgs';
import useToastModal from 'hooks/useToastModal';
import SecurityView from 'pages/security/SecurityView';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import useTopModal from '@hooks/useTopModal';
import Popup from '@components/elements/Popup/Popup';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import usePostMessageReceiver from '@hooks/usePostMessageReceiver';
import { NativeMessage } from '@models/native';

interface Props {
  onClose: () => void;
}

const SecurityUnlockModal = ({ onClose }: Props) => {
  const [originPassword, setOriginPassword] = useState<number>();
  const [password, setPassword] = useState<number[]>([]);
  const [isFail, setIsFail] = useState(false);

  const { openModal, closeModal } = useTopModal();
  const { openModal: openToast } = useToastModal();

  const isMax = password.length === 4;

  const onPostMessage = (message: any) => {
    try {
      (window as any).ReactNativeWebView.postMessage(message);
    } catch (error) {
      console.log(`PostMessage Error: security login`);
    }
  };

  const onFail = () => {
    setIsFail(true);
    onPostMessage('vibration-fail');
    const reset = setTimeout(() => {
      setPassword([]);
      setIsFail(false);
      clearTimeout(reset);
    }, 400);
  };

  const onPopup = () => {
    openModal(Popup, {
      headText: '비밀번호를 잊어버렸다면?',
      subText1: '앱을 삭제 후 재설치하면 비밀번호가 초기화됩니다',
      buttonText1: '확인',
      cancel: () => {
        closeModal(Popup);
      },
      onClose: () => {
        closeModal(Popup);
      },
    });
  };

  const handleUnlock = async () => {
    if (!isMax) return;

    if (String(originPassword) === password.join('')) {
      sendReactNativeMessage({
        type: 'security-lock-delete',
      });
      return;
    }
    onFail();
  };

  usePostMessageReceiver((event: NativeMessage) => {
    const { type, data } = JSON.parse(event.data);

    if (type === 'security-password') {
      const { password } = data;
      setOriginPassword(password);
    }
    if (type === 'security-lock-delete-finish') {
      openToast({ children: <p>앱 잠금 설정이 해제되었어요</p> });
      onClose();
    }
  });

  useEffect(() => {
    handleUnlock();
  }, [password]);

  useEffect(() => {
    sendReactNativeMessage({
      type: 'get-security-password',
    });
  }, []);

  return (
    <>
      <Header>
        <GNBCloseIcon onClick={onClose} />
      </Header>
      <SecurityView
        title="비밀번호 입력"
        password={password}
        setPassword={setPassword}
        isFail={isFail}
      />
      <Footer onClick={onPopup}>비밀번호를 잊어버렸다면?</Footer>
    </>
  );
};

const Header = styled.header`
  position: fixed;
  width: 100%;
  max-width: 428px;
  top: 0px;
  padding: 17px 20px;
`;

const Footer = styled.footer`
  width: 100%;
  max-width: 428px;
  margin-top: 52px;

  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.PRIMITIVES_GRAY_600};
`;

export default SecurityUnlockModal;
