import { BothSideLeftArrowIcon, BothSideRightArrowIcon } from '@assets/svgs/_Icons';
import Calender from '@components/calender';
import { COLORS } from '@styles/constants/_colors';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  onClickDate: (date: number) => void;
  date: Dayjs;
  setDate: React.Dispatch<React.SetStateAction<Dayjs>>;
}

const DatePicker = ({ onClickDate, date, setDate }: Props) => {
  const currentMonthLastDay = new Date(date.get('year'), date.get('month') + 1, 0).getDate();

  const onClickMoveToNextMonth = () => {
    const nextDate = dayjs(date).add(1, 'month');
    setDate(nextDate);
  };
  const onClickMoveToPrevMonth = () => {
    const prevDate = dayjs(date).subtract(1, 'month');
    setDate(prevDate);
  };

  const isToday = useCallback(
    (newDate: number) => {
      return dayjs(date).get('date') === newDate;
    },
    [date],
  );

  const currentMonthDateList = Array.from({ length: currentMonthLastDay }, (_, index) => 1 + index);

  const prevBlankCount = useMemo(
    () => (date.subtract(1, 'month').endOf('month').get('day') + 1) % 7,
    [date],
  );

  const dayMargin =
    (currentMonthDateList.length === 31 && prevBlankCount >= 5) ||
    (currentMonthDateList.length === 30 && prevBlankCount >= 6)
      ? 5
      : 14;

  const dateMargin =
    (currentMonthDateList.length === 31 && prevBlankCount >= 5) ||
    (currentMonthDateList.length === 30 && prevBlankCount >= 6)
      ? 11
      : 19;

  return (
    <Wrapper margin={dayMargin}>
      <CurrentMonthBox>
        <BothSideLeftArrowIcon onClick={onClickMoveToPrevMonth} stroke="black" />
        <CurrentMonthText>{date.format('YYYY. MM')}</CurrentMonthText>
        <BothSideRightArrowIcon onClick={onClickMoveToNextMonth} stroke="black" />
      </CurrentMonthBox>
      <Calender blankCount={prevBlankCount}>
        {currentMonthDateList.map((date, index) => (
          <DateNumber
            key={index}
            isToday={isToday(date)}
            margin={dateMargin}
            onClick={() => onClickDate(date)}
          >
            {date}
          </DateNumber>
        ))}
      </Calender>
    </Wrapper>
  );
};

export default DatePicker;

const Wrapper = styled.div<{ margin?: number }>`
  width: 100%;
  padding: 36px 0px 32px;
  height: 471px;

  thead {
    width: calc(100% / 7);
  }

  thead > tr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    margin: 0;
    width: 100%;
    margin-bottom: ${({ margin }) => margin}px;
  }

  thead > tr > th {
    width: 100%;
  }

  tbody > tr {
    gap: 0px;
  }
`;

const DateNumber = styled.div<{ isToday?: boolean; margin?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  height: 41px;
  font-family: Campton;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.005em;
  text-align: center;
  color: ${COLORS.BLACK};
  margin-bottom: ${({ margin }) => margin}px;

  ::before {
    content: '';
    position: absolute;
    width: 41px;
    height: 41px;
    border-radius: 100%;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ isToday }) => isToday && COLORS.PRIMITIVES_GRAY_300};
    z-index: -1;
  }
`;

const CurrentMonthBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 38px;
`;

const CurrentMonthText = styled.p`
  font-family: Campton;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.BLACK};
  margin: 0px 36px;
`;
