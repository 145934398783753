import { AdditionInActiveIcon } from '@assets/svgs';
import useTopModal from '@hooks/useTopModal';
import { foodBasketListState } from '@states/BasketState';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import { FoodDetail } from 'types/searchResult';
import AdditionActive from '../atomic/AdditionActive';
import FoodTag from '../atomic/Tag/FoodTag';
import { handleFoodItemTag } from '../atomic/Tag/handleTagColor';
import { FoodQuickModal } from './quick';
import { handleFoodDescription } from '@pages/basket/components/utils/handlFoodDescription';

interface Props {
  food: FoodDetail;
}
export default function FoodItem({ food }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { openModal, closeModal } = useTopModal();
  const [isAnimate, seIsAnimate] = useState(false);
  const [basket, setBasket] = useRecoilState(foodBasketListState);

  const path = location.pathname.split('/')[3];
  const { food_idx, food_name, food_brand, food_hit, calories, creator } = food;
  const description = handleFoodDescription(food);
  const hasBrand = food_brand !== '0' && food_brand !== null && food_brand !== '';
  const isUser = creator === 2;

  const onClickAdd = () => {
    seIsAnimate(true);
  };

  const onClickQuick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const isExist = basket.some((item) => item.food_idx === food_idx);

    if (isExist) {
      handleFilterBasket();
      return;
    }
    onOpen();
    return;
  };

  const onOpen = () => {
    openModal(FoodQuickModal, {
      food,
      onClickAdd,
      onClose: () => {
        closeModal(FoodQuickModal);
      },
    });
  };

  const handleFilterBasket = () => {
    setBasket((prev) => prev.filter((item) => item.food_idx !== food.food_idx));
    seIsAnimate(false);
  };

  const moveToFoodDetailPage = () => {
    navigate(`/search/detail/${path}/${food_idx}`);
  };

  const status = useMemo(() => {
    const isExist = basket.some((item) => item.food_idx === food_idx);
    if (isExist) {
      return (
        <IconBox>
          <AdditionActive isAnimate={isAnimate} />
        </IconBox>
      );
    }
    return <AdditionInActiveIcon />;
  }, [basket, isAnimate]);

  const tags = (
    <>
      <FoodTag
        color={handleFoodItemTag(food_hit ?? 0).color}
        background={handleFoodItemTag(food_hit ?? 0).background}
      >
        {handleFoodItemTag(food_hit ?? 0).element}
      </FoodTag>
      {isUser && (
        <FoodTag color={COLORS.PRIMITIVES_NAVY_250} background="#D7DDE5">
          <p>유저등록</p>
        </FoodTag>
      )}
    </>
  );

  const brand = (
    <>
      {hasBrand && <span className="brand">{food_brand}</span>}
      <span className="unit">{description}</span>
    </>
  );

  return (
    <Container onClick={moveToFoodDetailPage}>
      <Content>
        <TagBox>{tags}</TagBox>
        <Description>
          <p className="food-name">{food_name}</p>
          <p className="metric">{brand}</p>
        </Description>
      </Content>
      <SubContent>
        <Button onClick={onClickQuick}>{status}</Button>
        <p className="kcal">
          <span>{calories}</span>kcal
        </p>
      </SubContent>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 17px;
  width: 100%;
  height: 113px;
  padding: 20px;
  background-color: ${COLORS.WHITE};
  border-radius: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: calc(100% - 77px);
`;

const TagBox = styled.ul`
  display: flex;
  gap: 8px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-family: 'Campton', Noto Sans KR;

  .food-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.6px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .metric {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .brand {
    font-size: 14px;
    margin-right: 5px;
  }

  .unit {
    font-size: 15px;
    color: ${COLORS.PRIMITIVES_GRAY_500};
  }
`;

const SubContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  font-family: 'Campton';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: ${COLORS.PRIMITIVES_GRAY_500};

  span {
    margin-right: 1px;
  }
`;

const Button = styled.button`
  width: 34px;
  min-height: 34px;
`;

const IconBox = styled.div`
  position: relative;
  width: 34px;
  height: 34px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
`;
