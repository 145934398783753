import ArrowBack from 'assets/images/icon/GNB/ArrowBack';
import ButtonSet from 'components/Atoms/Button/ButtonSet';
import Stepper from 'components/Stepper';
import { RequestCustomFoodDetail } from 'types/searchResult';
import FoodRegisterUnitInput from 'pages/search/atomic/input/FoodRegisterUnitInput';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import { swipeRight } from 'styles/mixins/_animations';
import { handleDecimalInputChange } from 'utils/numberInputControl';
import useToastModal from '@hooks/useToastModal';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  food: RequestCustomFoodDetail;
  setFood: React.Dispatch<React.SetStateAction<RequestCustomFoodDetail>>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${COLORS.WHITE};
  overflow-y: scroll;
`;

const Header = styled.header`
  padding: 17px 0px 0px 20px;
`;

const Body = styled.div`
  margin: 28px 20px 98px;

  & .title {
    margin: 20px 0px 32px;
    font-family: 'Noto Sans KR';
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.5px;
    white-space: pre-line;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  animation: ${swipeRight} 600ms ease;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  label {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;

    color: ${COLORS.PRIMITIVES_GRAY_500};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 11px;
`;

const Footer = styled.footer`
  position: fixed;
  max-width: 428px;
  width: 100%;
  height: 78px;
  padding: 12px 20px;
  bottom: 0;
  background: ${COLORS.WHITE};
`;

const FoodRegisterStepThree = ({ setStep, setFood, food }: Props) => {
  const { openModal } = useToastModal();
  const onChangeAmount = (e: ChangeEvent<any>) => {
    const inputValue = handleDecimalInputChange(e, 9999.1);
    if (inputValue === undefined) return;
    setFood({
      ...food,
      metric_serving_amount: inputValue,
    });
  };

  const onBack = () => {
    setStep(1);
    return;
  };

  const onToggle = (unit: 'g' | 'ml') => {
    setFood({
      ...food,
      metric_serving_unit: unit,
    });
  };

  const onClick = () => {
    if (food.metric_serving_amount.toString() === '0') {
      openModal({ children: <p>{`내용량은 0으로 입력할 수 없어요`}</p> });
      return;
    }
    setStep(3);
    return;
  };

  return (
    <Container>
      <Header>
        <span onClick={onBack}>
          <ArrowBack color={COLORS.BLACK} />
        </span>
      </Header>
      <Body>
        <Stepper focusedIndex={2} length={3} paddingTop={0} />
        <p className="title">{`기본 단위를\n 입력해 주세요`}</p>
        <Content>
          <Box>
            <label>단위</label>
            <ButtonContainer>
              <ButtonSet
                text="그램(g)"
                size="half"
                type="button"
                color={food.metric_serving_unit === 'g' ? 'black' : 'isInActive'}
                isActive
                onClick={() => {
                  onToggle('g');
                }}
              />
              <ButtonSet
                text="밀리리터(ml)"
                size="half"
                type="button"
                color={food.metric_serving_unit === 'ml' ? 'black' : 'isInActive'}
                isActive
                onClick={() => {
                  onToggle('ml');
                }}
              />
            </ButtonContainer>
          </Box>
          <FoodRegisterUnitInput
            title="내용량"
            placeholder="0"
            value={food.metric_serving_amount}
            onChange={onChangeAmount}
            unit={food.metric_serving_unit}
            mode="decimal"
          />
        </Content>
      </Body>
      <Footer>
        <ButtonSet
          text="다음"
          size="long"
          type="button"
          color="black"
          isActive={food.metric_serving_amount !== ''}
          onClick={onClick}
        />
      </Footer>
    </Container>
  );
};

export default FoodRegisterStepThree;
