import { ChangeEvent } from 'react';

type convertHandlers = {
  [key: string]: () => string | undefined;
};

const handleMaxDecimal = (inputValue: string, maxNum: number, isAuto?: boolean) => {
  const MAX_NUMBER = maxNum;
  if (parseFloat(inputValue) >= MAX_NUMBER) {
    return isAuto ? maxNum.toString() : inputValue.slice(0, -1);
  }
  return inputValue;
};

const handleMaxNum = (inputValue: string, maxNum: number, isAuto?: boolean) => {
  const MAX_NUMBER = maxNum;
  if (parseFloat(inputValue) >= MAX_NUMBER) {
    return isAuto ? maxNum.toString() : inputValue.slice(0, -1);
  }
  return Number(inputValue).toString();
};

const handleDecimalInputChange = (
  event: ChangeEvent<HTMLInputElement>,
  maxNum: number,
  option?: { isAuto: boolean },
) => {
  const inputValue = event.target.value.replace(',', '.');
  const handleConvert: convertHandlers = {
    '.': () => {
      return undefined;
    },
    '00': () => {
      return undefined;
    },
    '': () => {
      return '';
    },
    default: () => {
      let data = inputValue;
      /* 0으로 시작하는 수 ex) 012 */
      const zeroRegExp = /^0[0-9]{1,2}$/;
      if (zeroRegExp.test(data)) {
        data = Number(data).toString();
      }
      /* 소수점 0으로 끝나는 수 ex) 120.0 */
      const decimalToNumRegExp = /(^\d+$)|(^\d+\.0$)/;
      if (decimalToNumRegExp.test(data)) {
        data = handleMaxNum(data, maxNum, option?.isAuto);
      }
      /* 정수, 소수점 1자리 수 */
      const decimalRegExp = /(^\d+$)|(^\d+\.\d{0,1}$)/;
      if (decimalRegExp.test(inputValue)) {
        data = handleMaxDecimal(data, maxNum, option?.isAuto);
        return data;
      }
    },
  };
  return (handleConvert[inputValue] || handleConvert.default)();
};

const handleNumericInputChange = (
  event: ChangeEvent<HTMLInputElement>,
  maxNum: number,
  option?: { isAuto: boolean },
) => {
  const handleConvert: convertHandlers = {
    '.': () => {
      return undefined;
    },
    '00': () => {
      return undefined;
    },
    '': () => {
      return '';
    },
    default: () => {
      let data = event.target.value;
      const zeroRegExp = /^[0-9]{0,3}$/;
      if (zeroRegExp.test(data)) {
        data = handleMaxNum(data, maxNum, option?.isAuto);
        return data;
      }
    },
  };
  return (handleConvert[event.target.value] || handleConvert.default)();
};

const handleNumericKcalInputChange = (event: ChangeEvent<HTMLInputElement>, maxNum: number) => {
  const handleConvert: convertHandlers = {
    '.': () => {
      return '';
    },
    '00': () => {
      return '0';
    },
    '': () => {
      return '';
    },
    default: () => {
      let data = event.target.value;
      const zeroRegExp = /^[0-9]{0,4}$/;

      if (zeroRegExp.test(data)) {
        data = handleMaxNum(data, maxNum);
        return data;
      }
    },
  };
  return (handleConvert[event.target.value] || handleConvert.default)();
};

export { handleDecimalInputChange, handleNumericInputChange, handleNumericKcalInputChange };
