import { CloseIcon } from '@assets/svgs';
import { COLORS } from '@styles/constants/_colors';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  onClose: () => void;
  title?: string;
  subTitle?: ReactNode;
  padding?: string;
}

const TitleAndCloseHeader = ({ onClose, title, subTitle, padding }: Props) => {
  return (
    <Wrapper padding={padding} isStartAline={!!subTitle}>
      <TextBox>
        <Title>{title}</Title>
        {subTitle && subTitle}
      </TextBox>
      <Button onClick={onClose}>
        <CloseIcon />
      </Button>
    </Wrapper>
  );
};

export default TitleAndCloseHeader;

const Wrapper = styled.div<{ padding: string | undefined; isStartAline: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ isStartAline }) => (isStartAline ? 'flex-start' : 'center')};
  padding: ${({ padding }) => (padding ? padding : '4px 20px 0px')};
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const Title = styled.div`
  color: ${COLORS.BLACK};
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const Button = styled.button`
  width: 36px;
  height: 36px;
  cursor: pointer;
`;
