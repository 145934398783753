import { getChildren } from '@utils/compoundComponent';
import { LayoutButtons, getLayoutButtons } from './LayoutButtons';
import { LayoutContents, getLayoutContents } from './LayoutContents';
import { LayoutHeaders, getLayoutHeaders } from './LayoutHeaders';
import styled, { css } from 'styled-components';
import { swipeUp } from '@styles/mixins/_animations';
import { COLORS } from '@styles/constants/_colors';
import LEVEL from '@styles/constants/_levels';

interface Props {
  children?: React.ReactNode;
  isPop?: boolean;
  isSwipeUp?: boolean;
  bgColor?: string;
}

const Layout = ({ children, isPop, isSwipeUp = false, bgColor }: Props) => {
  const header = getChildren(children, getLayoutHeaders);
  const contents = getChildren(children, getLayoutContents);
  const buttons = getChildren(children, getLayoutButtons);

  return (
    <Wrapper isSwipeUp={isSwipeUp} isPop={isPop} bgColor={bgColor}>
      <HeaderBox>{header}</HeaderBox>
      <ContentBox>{contents}</ContentBox>
      <ButtonBox>{buttons}</ButtonBox>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isSwipeUp?: boolean; isPop?: boolean; bgColor?: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor || COLORS.WHITE};

  animation: ${({ isSwipeUp, isPop }) =>
    isSwipeUp && !isPop
      ? css`
          ${swipeUp} 500ms ease
        `
      : css`
          none
        `};
`;

const HeaderBox = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  max-width: 428px;
  z-index: ${LEVEL.COMMUNICATIONS_HEADER};
`;

const ContentBox = styled.div`
  height: 100%;
`;

const ButtonBox = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  max-width: 428px;
  z-index: ${LEVEL.COMMUNICATIONS_HEADER};
`;

export default Object.assign(Layout, {
  Headers: LayoutHeaders,
  Contents: LayoutContents,
  Buttons: LayoutButtons,
});
