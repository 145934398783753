import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';
import LoginButton from './components/LoginButton';
import AnotherLoginBottomSheet from './components/AnotherLoginBottomSheet';
import useTopModal from '@hooks/useTopModal';
import Tutorial1 from '@assets/images/png/tutorial_1.png';
import Tutorial2 from '@assets/images/png/tutorial_2.png';
import Tutorial3 from '@assets/images/png/tutorial_3.png';
import Tutorial4 from '@assets/images/png/tutorial_4.png';
import Tutorial5 from '@assets/images/png/tutorial_5.png';
import { useEffect, useRef, useState } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss/pagination';
import { SwiperProps } from 'swiper/swiper-react';
import { deviceOs } from '@modules/platformCheck';
import ServiceAgreementBottomSheet from './components/ServiceAgreementBottomSheet';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import { isMaintainState } from 'states/MaintainState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useSocialLogin from './hooks/useSocialLogin';
import { getCookie } from '@utils/cookie';
import { ACCESS_TOKEN } from '@shared/link';
import { useNavigate } from 'react-router-dom';
import { userInfoState } from '@states/UserInfoState';
import { checkAuthLogin } from '@apis/analyticsApi';
import { getUserInfo } from '@apis/profileApi';
import useGetLoginHistory from './hooks/useGetLoginHistory';
import { deviceIdState } from '@states/deviceIdState';
import LoginHistory from './components/LoginHistory';

const tutorial = [
  {
    id: 1,
    subTitle: '검색만 하면 칼로리 자동 계산',
    title: '간편한 식단, 운동 기록',
    img: Tutorial1,
    gradient: 'linear-gradient(180deg, #7fabd4 75%, #ffffff 100%)',
  },
  {
    id: 2,
    subTitle: '오늘 내 랭킹은? 함께해서 더 즐거운',
    title: '꿀잼 다이어트 배틀',
    img: Tutorial2,
    gradient: 'linear-gradient(180deg, #F86952 75%, #FFFFFF 100%)',
  },
  {
    id: 3,
    subTitle: '익명 수다부터 꿀팁 공유까지',
    title: '다이어트 커뮤니티',
    img: Tutorial3,
    gradient: 'linear-gradient(180deg, #56BA64 75%, #FFFFFF 100%)',
  },
  {
    id: 4,
    subTitle: '한눈에 보는 체중, 물, 수면, 기분 기록',
    title: '나만의 스티커 일기장',
    img: Tutorial4,
    gradient: 'linear-gradient(180deg, #FFC369 75%, #FFFFFF 100%)',
  },
  {
    id: 5,
    subTitle: '맞춤 스케줄 자동 관리',
    title: '간헐적 단식 타이머',
    img: Tutorial5,
    gradient: 'linear-gradient(180deg, #4F7955 75%, #FFFFFF 100%)',
  },
];

const Login: React.FC = () => {
  const path = location.hash;

  const token = getCookie(ACCESS_TOKEN);
  const navigate = useNavigate();
  const { sendAdBrixData } = useAdBrix();
  const { openModal, closeModal } = useTopModal();
  const { onKakaoLogin, onNaverLogin, onAppleLogin, onGoogleLogin } = useSocialLogin();
  const deviceId = useRecoilValue(deviceIdState);
  const { data: loginType, refetch } = useGetLoginHistory(deviceId);
  const isMaintain = useRecoilValue(isMaintainState);
  const setUserInfo = useSetRecoilState(userInfoState);
  const analyticsToken = localStorage.getItem('analyticsCheck');
  const fixedFieldRef = useRef<HTMLDivElement>(null);
  const [slideFieldHeight, setSlideFieldHeight] = useState(0);
  const [fixedFieldHeight, setFixedFieldHeight] = useState(0);
  const swiperOptions: SwiperProps = {
    slidesPerView: 1,
    modules: [Pagination, Autoplay],
    pagination: {
      enabled: tutorial.length > 1,
      type: 'bullets',
    },
    loop: true,
    autoplay: {
      delay: 1500,
    },
  };

  const loginTypeFunction: { [key: string]: () => void } = {
    G: onGoogleLogin,
    A: onAppleLogin,
    K: onKakaoLogin,
    N: onNaverLogin,
  };

  const handleLogin = (platform: 'G' | 'A' | 'K' | 'N') => {
    if (loginType?.result && loginType.result !== 'C' && loginType.result === platform) {
      loginTypeFunction[platform]();
    } else {
      openModal(ServiceAgreementBottomSheet, {
        onClose: () => {
          closeModal(ServiceAgreementBottomSheet);
        },
        onLogin: loginTypeFunction[platform],
      });
    }
  };

  const handleClickAnotherLogin = () => {
    openModal(AnotherLoginBottomSheet, {
      onClose: () => {
        closeModal(AnotherLoginBottomSheet);
      },
      handleLogin,
    });
  };
  useEffect(() => {
    if (fixedFieldRef.current) {
      const FIXED_FIELD_HEIGHT = fixedFieldRef.current.getBoundingClientRect().height ?? 0;
      setFixedFieldHeight(FIXED_FIELD_HEIGHT);
      setSlideFieldHeight(window.innerHeight - FIXED_FIELD_HEIGHT);
    }
  }, []);

  useEffect(() => {
    if (deviceId) {
      (async () => {
        const { data } = await refetch();

        if (path === '/login' && !isMaintain && (token || data?.result)) {
          sendAdBrixData('customEvent', {
            eventKey: 'view_login',
          });
        }
      })();
    }
  }, [deviceId]);

  useEffect(() => {
    (async () => {
      if (token) {
        const data = await getUserInfo();
        if (data) {
          setUserInfo(data);
          if (data.profile_check === 0) {
            navigate('/welcome', { replace: true });
          } else navigate('/', { replace: true });
        }
      }
      if (analyticsToken === null) {
        const analyValue =
          `${'analyticsCheck'}` + Date.now() + parseInt(`${Math.random() * (99 - 10) + 10}`);
        localStorage.setItem('analyticsCheck', analyValue);
        await checkAuthLogin(analyValue, deviceOs);
      }
    })();
  }, []);

  useEffect(() => {
    try {
      if (isMaintain) return;
      (window as any).ReactNativeWebView.postMessage('maintainlogin');
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Wrapper bottom={fixedFieldHeight}>
      <Swiper {...swiperOptions}>
        {tutorial.map((data) => (
          <SwiperSlide key={data.id}>
            <Gradient gradient={data.gradient}>
              <ContentField height={slideFieldHeight}>
                <ImgBox>
                  <Img src={data.img} height={window.innerHeight <= 667 ? '45vh' : '100%'} />
                </ImgBox>
                <TextBox>
                  <SubTitle>{data.subTitle}</SubTitle>
                  <Title>{data.title}</Title>
                </TextBox>
              </ContentField>
            </Gradient>
          </SwiperSlide>
        ))}
      </Swiper>

      <FixedField ref={fixedFieldRef}>
        <LoginButtonBox>
          <LoginButton platform="kakao" onClick={() => handleLogin('K')} />
          {deviceOs === 'ios' && <LoginButton platform="apple" onClick={() => handleLogin('A')} />}
          {/* 구글 로그인 심사 통과하면 주석 풀 예정 */}
          {/* {deviceOs === 'ios' ? (
              <LoginButton platform="apple" onClick={() => handleLogin('A')} />
            ) : (
              <LoginButton platform="google" onClick={() => handleLogin('G')} />
            )} */}
        </LoginButtonBox>
        <AnotherLogin onClick={handleClickAnotherLogin}>다른 방법으로 로그인</AnotherLogin>
        {!!loginType?.result && <LoginHistory loginType={loginType.result} loginUiType="B" />}
      </FixedField>
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div<{ bottom: number }>`
  position: relative;
  max-width: 428px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${COLORS.WHITE};
  overflow: hidden;

  .swiper-pagination-bullet {
    background-color: ${COLORS.WHITE};
    opacity: 0.4;
  }

  .swiper-pagination-bullet-active {
    background-color: ${COLORS.WHITE};
    opacity: 1;
  }

  .swiper-pagination-bullets {
    bottom: ${({ bottom }) => `calc(${bottom}px + 30px)`};
  }
`;

const FixedField = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0px 28px 28px;
  display: flex;
  flex-direction: column;
`;

const LoginButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const AnotherLogin = styled.button`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.PRIMITIVES_GRAY_550};
  margin-top: 24px;
`;

const ContentField = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 68px;
  height: ${({ height }) => height}px;
`;

const Gradient = styled.div<{ gradient: string }>`
  width: 100%;
  height: 90vh;
  background: ${({ gradient }) => gradient};
`;

const ImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vmin;
  height: 100vmin;
  max-width: 428px;
  object-fit: contain;
  flex: 1;
`;

const Img = styled.img<{ height: string }>`
  width: 100vmin;
  height: ${({ height }) => height};
  max-width: 428px;
  object-fit: contain;
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 7px;
`;

const SubTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.WHITE};
`;

const Title = styled.h1`
  font-family: Noto Sans KR;
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.WHITE};
  margin: 0;
`;
