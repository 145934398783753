import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isMaintainState } from 'states/MaintainState';
import { getUserInfo } from '../apis/profileApi';
import { Container } from '../components/elements/Wrapper/Container';
import { LoginModal } from '../components/modal/LoginModal';
import { useSwitchState } from '../hooks/useSwitchState';
import { ACCESS_TOKEN } from '../shared/link';
import { userInfoState } from '../states/UserInfoState';
import { getCookie } from '../utils/cookie';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import useAdBrix from '@hooks/adBrix/useAdBrix';

const KetoRedirect: React.FC = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = getCookie(ACCESS_TOKEN);

  const [isVisible, onVisible] = useSwitchState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const setIsMaintain = useSetRecoilState(isMaintainState);
  const { sendAdBrixData } = useAdBrix();

  const { type } = useParams() as { type: string };

  const onMaintainPostMessage = (memberIdx: number) => {
    if (!memberIdx) return;
    try {
      sendReactNativeMessage({
        type: 'maintain-login',
        payload: {
          memberIdx,
          accessToken: token,
        },
      });
    } catch (error) {
      console.log(location.pathname);
      console.log('Failed to save token');
    }
  };

  useEffect(() => {
    if (token) {
      (async () => {
        const data = await getUserInfo().catch(() => {
          setIsMaintain(true);
          navigate('/login', { replace: true });
        });

        if (!data) return;

        await setUserInfo(data);

        sendAdBrixData('login', {
          property: data.member_idx.toString(),
        });

        onMaintainPostMessage(data.member_idx);
        setIsMaintain(false);

        if (data.profile_check === 0) {
          navigate('/welcome', { replace: true });

          sendAdBrixData('signUp', {
            property: type,
          });
          return;
        }

        navigate('/', { replace: true });
        return;
      })();
    } else navigate('/login', { replace: true });
  }, []);

  const onVisibleFunc = () => {
    onVisible();
  };

  return (
    <Container>
      {type === 'AC' && (
        <LoginModal title={'일반회원'} isVisible={false} onVisible={onVisibleFunc} />
      )}
      {type === 'AN' && <LoginModal title={'네이버'} isVisible={false} onVisible={onVisibleFunc} />}
      {type === 'AK' && <LoginModal title={'카카오'} isVisible={false} onVisible={onVisibleFunc} />}
    </Container>
  );
};

export default KetoRedirect;
