import { deepLinkEventDataState } from '@states/DeepLinkState';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

type Handler = {
  [key: string]: (data: any) => void;
};

const useWebviewDeepLink = () => {
  const navigate = useNavigate();
  const setDeepLinkEventData = useSetRecoilState(deepLinkEventDataState);

  const handleNavigation = (data: any) => {
    const url = data.url ?? '';
    const isReplace = window.location.pathname === url;
    setTimeout(() => {
      navigate(url, {
        replace: isReplace,
        state: {
          ...data,
          isDeepLink: true,
        },
      });
    }, 700);
  };

  const handleDeepLink = (data: any) => {
    const section = data?.section ?? 'default';

    const handler: Handler = {
      walk: (data: any) => {
        setDeepLinkEventData(data);
        handleNavigation(data);
      },
      weight: (data: any) => {
        setDeepLinkEventData(data);
        handleNavigation(data);
      },
      default: (data: any) => {
        handleNavigation(data);
      },
    };

    handler[section](data);
  };

  return {
    handleDeepLink,
  };
};

export default useWebviewDeepLink;
