import { NoResultIcon } from '@assets/svgs';
import { FOUNDATION_COLOR } from '@styles/constants/_colors';
import styled from 'styled-components';

interface Props {
  text: string;
  paddingTop: number;
}

const FoodResultNone = ({ text, paddingTop }: Props) => {
  return (
    <Wrapper paddingTop={paddingTop}>
      <NoResultIcon />
      <p>{text}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ paddingTop: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  height: 100vh;
  padding-top: ${({ paddingTop }) => `${paddingTop}px`};

  p {
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.5px;
    color: ${FOUNDATION_COLOR.PRIMARY_GRAY};
  }
`;

export default FoodResultNone;
