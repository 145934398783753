import useTimeoutModal from '@hooks/useTimeoutModal';
import { useEffect } from 'react';

const TimeoutModal = () => {
  const { modal, closeModal } = useTimeoutModal();

  useEffect(() => {
    if (!modal.isPopup) return;

    const key = setTimeout(() => {
      closeModal();
    }, modal.props.millisecond);

    return () => {
      clearTimeout(key);
    };
  }, [modal]);

  return <>{modal.isPopup && modal.props.children}</>;
};

export default TimeoutModal;
