import { makeStyles, createStyles } from '@material-ui/core';
import { useEffect, useLayoutEffect } from 'react';
import BackArrowGnb from 'components/elements/BackArrowGnb';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';

interface Props {
  onClose: () => void;
}

export default function InoutSensitiveInfoViewModal({ onClose }: Props) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    document.body.style.removeProperty('overflow');
    return () => {
      document.body.style.overflow = 'hidden';
    };
  }, []);

  return (
    <Wrapper>
      <BackArrowGnb onClick={onClose} />
      <p className={classes.title}>민감정보 수집 및 이용약관</p>

      <p className={classes.content}>
        (주)마이노멀컴퍼니(이하 “회사”)는 개인정보보호법, 민감정보보호법, 통신비밀보호법,
        전기통신사업법 등 정보통신서비스 제공자가 준수하여야 할 관련 법령상의 민감정보 처리제한
        규정을 준수하며, 관련 법령에 의거한 개인 민감정보 취급방침을 정하여 이용자 권익 보호에
        최선을 다하고 있습니다.
        <br />
        <br />본 개인 민감정보 취급방침은 회사가 제공하는 인아웃 어플리케이션(이하 “서비스”)에
        적용되며 다음과 같은 내용을 담고 있습니다.
        <br />
        <br />
        <b>민감정보 수집 및 이용 목적</b>
        <br />
        회사는 민감정보를 다음 목적을 위해 취급합니다. 취급한 정보는 다음의 목적 이외의 용도로는
        사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.
        <br />
        <br />
        <table className={classes.tablePolicy}>
          <thead>
            <tr>
              <th style={{ width: '18%' }}>구분</th>
              <th style={{ width: '18%' }}>필수여부</th>
              <th>이용 목적</th>
              <th>수집 항목</th>
              <th>보유 기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>개인 정보</td>
              <td>필수</td>
              <td>회원 가입 및 맞춤 목표 설정 서비스 제공</td>
              <td>성별, 나이, 키, 체중</td>
              <td>회원 탈퇴 시까지 보유</td>
            </tr>
            <tr>
              <td>건강정보</td>
              <td>선택</td>
              <td>맞춤 건강 서비스 제공, 서비스 이용에 대한 분석 및 통계 제공</td>
              <td>식단, 물, 영양제 섭취 기록, 체중 기록, 컨디션 기록, 운동 및 걸음 수 기록</td>
              <td>개인정보를 제외하여 보관</td>
            </tr>
            <tr>
              <td>기타 정보</td>
              <td>선택</td>
              <td>개인 기록 및 조회</td>
              <td>눈바디(신체) 사진</td>
              <td>이용자 휴대기기에서 데이터를 조회하며 회사가 보관하지 않음</td>
            </tr>
          </tbody>
        </table>
        <br />
        회사는 위 정보를 이용자 동의 없이 제 3자에게 제공하지 않습니다. 귀하께서는 회사의 민감정보
        수집 및 이용에 대한 동의 거부권이 있으며 동의 거부 시에는 인아웃 어플리케이션 이용이
        불가능합니다.
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  max-width: 428px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: ${COLORS.WHITE};
  z-index: 90000;
`;

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      padding: '75px 21px 20px',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: -0.77,
    },
    content: {
      fontSize: 14,
      lineHeight: 1.8,
      letterSpacing: -0.58,
      padding: '0 20px 20px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      textAlign: 'left',
    },
    tablePolicy: {
      width: '100%',
      tableLayout: 'fixed',
      wordBreak: 'break-all',
      height: 'auto',
      border: '1px solid black',
      borderCollapse: 'collapse',

      '& tr, td, th': {
        border: '1px solid black',
        fontSize: '12px',
      },
    },
    img: {
      width: '100%',
      padding: '0 20px',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
  }),
);
