interface Props {
  children?: React.ReactNode;
}

const LayoutContents = ({ children }: Props) => {
  return <>{children}</>;
};

const getLayoutContents = (<LayoutContents />).type;

export { LayoutContents, getLayoutContents };
