import { MouseEventHandler } from 'react';
import XCircleDefault from '../../assets/images/icon/Atoms/XCircleDefault';
import IconButtonWrapper from '../WrapperAtoms/IconButtonWrapper';

interface Props {
  onClick?: MouseEventHandler;
  size: 'large' | 'small';
  color: string;
}

export default function DeleteCircleButton({ onClick, size, color }: Props) {
  return (
    <IconButtonWrapper onClick={onClick}>
      <XCircleDefault size={size} color={color} />
    </IconButtonWrapper>
  );
}
