import { CheckMsg, ErrorLog } from '../types/app';
import { get, post } from '../modules/HttpWebClient/default';

export async function checkAuthLogin(key: string, device_os: 'ios' | 'android'): Promise<CheckMsg> {
  const url = '/auth/session/open';
  const { data } = await post<CheckMsg>(url, {
    session_number: key,
    device_os: device_os,
  });
  return data;
}

export async function checkAuthMain(key: string): Promise<CheckMsg> {
  const url = '/auth/session/main';
  const { data } = await post<CheckMsg>(url, {
    session_number: key,
  });
  return data;
}

export async function checkAuthButton(key: string): Promise<CheckMsg> {
  const url = '/auth/session/joinButton';
  const { data } = await post<CheckMsg>(url, {
    session_number: key,
  });
  return data;
}

export async function checkDeviceStart(): Promise<CheckMsg> {
  const url = '/auth/device/start';
  const { data } = await get<CheckMsg>(url);
  return data;
}

export async function checkErrorLoging(request: ErrorLog): Promise<CheckMsg> {
  const url = '/log/error';
  const { data } = await post<CheckMsg>(url, {
    ...request,
  });
  return data;
}

export async function countingPromotionClickAccess(banner_idx: number) {
  const url = `/banner/${banner_idx}`;
  post(url);
}

export async function countTotalMember(): Promise<number> {
  const url = '/count';
  const { data } = await get<number>(url);
  return data;
}
