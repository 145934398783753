import { NotionRenderer } from 'react-notion';
import 'react-notion/src/styles.css';
import styled from 'styled-components';

import { Container } from '@components/elements/Wrapper/Container';
import useEmbedNotion from '@hooks/useEmbedNotion';
import BackArrowGnb from '@components/elements/BackArrowGnb';
import { NOTION_PAGE_ID } from '@shared/link';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import { useEffect } from 'react';

export default function FaqNotionPage() {
  const getPage = useEmbedNotion(NOTION_PAGE_ID.faq);
  const { sendAdBrixData } = useAdBrix();

  useEffect(() => {
    sendAdBrixData('customEvent', {
      eventKey: 'view_faq',
    });
  }, []);

  return (
    <Container>
      <BackArrowGnb />
      <NotionContainer>
        {getPage !== undefined && <NotionRenderer blockMap={getPage} fullPage={false} />}
      </NotionContainer>
    </Container>
  );
}

const NotionContainer = styled.div`
  padding: 30px 20px 50px 20px;
  user-select: none;
`;
