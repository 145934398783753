import {
  ApricotChipIcon,
  CategoryExrciseIcon,
  ColorAllNightIcon,
  ColorAngryIcon,
  ColorBadDreamIcon,
  ColorBadSleepIcon,
  ColorBingeIcon,
  ColorBloodPoopIcon,
  ColorCheatingIcon,
  ColorCompleteExerciseIcon,
  ColorCompleteFastingIcon,
  ColorConstipationIcon,
  ColorCoreIcon,
  ColorDeepSleepIcon,
  ColorDepressionIcon,
  ColorDiarrheaIcon,
  ColorDreamIcon,
  ColorEscapeIcon,
  ColorExcitedIcon,
  ColorExerciseDoneIcon,
  ColorExerciseRestIcon,
  ColorFeelingGoodIcon,
  ColorFullBodyIcon,
  ColorGainWeightIcon,
  ColorGoalIcon,
  ColorGoldPoopIcon,
  ColorGoodSleepIcon,
  ColorHappinessIcon,
  ColorHardPoopIcon,
  ColorHomeTrainingIcon,
  ColorIrregularBleedingIcon,
  ColorKeepWeightIcon,
  ColorLittlePeriodIcon,
  ColorLoseWeightIcon,
  ColorLoveLoveIcon,
  ColorLowerIcon,
  ColorMuchPeriodIcon,
  ColorMusclePainIcon,
  ColorNoPeriodIcon,
  ColorNormalPeriodIcon,
  ColorOversleepingIcon,
  ColorPeacefulIcon,
  ColorPeriodPainIcon,
  ColorPilatesIcon,
  ColorPoopSuccessIcon,
  ColorRainbowIcon,
  ColorRunningIcon,
  ColorSadnessIcon,
  ColorSecretionIcon,
  ColorSosoIcon,
  ColorTasteExplodeIcon,
  ColorUpperIcon,
  ColorVaginitisIcon,
  ColorWalkingIcon,
  ColorWorryIcon,
  ColorWorstIcon,
  DisableAllNightIcon,
  DisableAngryIcon,
  DisableBadDreamIcon,
  DisableBadSleepIcon,
  DisableBingeIcon,
  DisableBloodPoopIcon,
  DisableCheatingIcon,
  DisableCompleteExerciseIcon,
  DisableCompleteFastingIcon,
  DisableConstipationIcon,
  DisableCoreIcon,
  DisableDeepSleepIcon,
  DisableDepressionIcon,
  DisableDiarrheaIcon,
  DisableDreamIcon,
  DisableEscapeIcon,
  DisableExcitedIcon,
  DisableExerciseDoneIcon,
  DisableExerciseRestIcon,
  DisableFeelingGoodIcon,
  DisableGainWeightIcon,
  DisableGoalIcon,
  DisableGoldPoopIcon,
  DisableGoodSleepIcon,
  DisableHappinessIcon,
  DisableHardPoopIcon,
  DisableHomeTrainingIcon,
  DisableIrregularBleedingIcon,
  DisableKeepWeightIcon,
  DisableLittlePeriodIcon,
  DisableLoseWeightIcon,
  DisableLoveLoveIcon,
  DisableLowerIcon,
  DisableMuchPeriodIcon,
  DisableMusclePainIcon,
  DisableNoPeriodIcon,
  DisableNormalPeriodIcon,
  DisableOversleepingIcon,
  DisablePeacefulIcon,
  DisablePeriodPainIcon,
  DisablePilatesIcon,
  DisablePoopSuccessIcon,
  DisableRainbowIcon,
  DisableRunningIcon,
  DisableSadnessIcon,
  DisableSecretionIcon,
  DisableSosoIcon,
  DisableTasteExplodeIcon,
  DisableUpperIcon,
  DisableVaginitisIcon,
  DisableWalkingIcon,
  DisableWorryIcon,
  DisableWorstIcon,
  GreenChipIcon,
  PinkChipIcon,
  SkyBlueChipIcon,
} from 'assets/svgs/sticker';
import { DashboardCategory, StickerCategory, StickerDetail } from 'types/sticker';
import { DisableFullBodyIcon } from '@assets/svgs/sticker';
import { COLORS } from '@styles/constants/_colors';

export const moodStickerList: StickerDetail[] = [
  {
    stickerIdx: 1,
    category: 'mood',
    stickerName: '기분 최고',
    activeIcon: <ColorFeelingGoodIcon />,
    disableIcon: <DisableFeelingGoodIcon />,
  },
  {
    stickerIdx: 2,
    category: 'mood',
    stickerName: '행복해',
    activeIcon: <ColorHappinessIcon />,
    disableIcon: <DisableHappinessIcon />,
  },
  {
    stickerIdx: 3,
    category: 'mood',
    stickerName: '신나',
    activeIcon: <ColorExcitedIcon />,
    disableIcon: <DisableExcitedIcon />,
  },
  {
    stickerIdx: 10,
    category: 'mood',
    stickerName: '평온해',
    activeIcon: <ColorPeacefulIcon />,
    disableIcon: <DisablePeacefulIcon />,
  },
  {
    stickerIdx: 4,
    category: 'mood',
    stickerName: '그냥 그래',
    activeIcon: <ColorSosoIcon />,
    disableIcon: <DisableSosoIcon />,
  },
  {
    stickerIdx: 5,
    category: 'mood',
    stickerName: '슬픔',
    activeIcon: <ColorSadnessIcon />,
    disableIcon: <DisableSadnessIcon />,
  },
  {
    stickerIdx: 9,
    category: 'mood',
    stickerName: '우울해',
    activeIcon: <ColorDepressionIcon />,
    disableIcon: <DisableDepressionIcon />,
  },
  {
    stickerIdx: 11,
    category: 'mood',
    stickerName: '걱정돼',
    activeIcon: <ColorWorryIcon />,
    disableIcon: <DisableWorryIcon />,
  },
  {
    stickerIdx: 7,
    category: 'mood',
    stickerName: '최악',
    activeIcon: <ColorWorstIcon />,
    disableIcon: <DisableWorstIcon />,
  },
  {
    stickerIdx: 8,
    category: 'mood',
    stickerName: '킹받음',
    activeIcon: <ColorAngryIcon />,
    disableIcon: <DisableAngryIcon />,
  },
];

export const dietStickerList: StickerDetail[] = [
  {
    stickerIdx: 101,
    category: 'diet',
    stickerName: '살빠짐',
    activeIcon: <ColorLoseWeightIcon />,
    disableIcon: <DisableLoseWeightIcon />,
  },
  {
    stickerIdx: 102,
    category: 'diet',
    stickerName: '유지어터',
    activeIcon: <ColorKeepWeightIcon />,
    disableIcon: <DisableKeepWeightIcon />,
  },
  {
    stickerIdx: 103,
    category: 'diet',
    stickerName: '살쪘어',
    activeIcon: <ColorGainWeightIcon />,
    disableIcon: <DisableGainWeightIcon />,
  },
  {
    stickerIdx: 104,
    category: 'diet',
    stickerName: '오운완',
    activeIcon: <ColorCompleteExerciseIcon />,
    disableIcon: <DisableCompleteExerciseIcon />,
  },
  {
    stickerIdx: 105,
    category: 'diet',
    stickerName: '목표 달성',
    activeIcon: <ColorGoalIcon />,
    disableIcon: <DisableGoalIcon />,
  },
  {
    stickerIdx: 106,
    category: 'diet',
    stickerName: '단식 성공',
    activeIcon: <ColorCompleteFastingIcon />,
    disableIcon: <DisableCompleteFastingIcon />,
  },
  {
    stickerIdx: 107,
    category: 'diet',
    stickerName: '치팅데이',
    activeIcon: <ColorCheatingIcon />,
    disableIcon: <DisableCheatingIcon />,
  },
  {
    stickerIdx: 108,
    category: 'diet',
    stickerName: '폭식함',
    activeIcon: <ColorBingeIcon />,
    disableIcon: <DisableBingeIcon />,
  },
];

export const exerciseStickerList: StickerDetail[] = [
  {
    stickerIdx: 501,
    category: 'exercise',
    stickerName: '오운완',
    activeIcon: <ColorExerciseDoneIcon />,
    disableIcon: <DisableExerciseDoneIcon />,
  },
  {
    stickerIdx: 502,
    category: 'exercise',
    stickerName: '쉬는날',
    activeIcon: <ColorExerciseRestIcon />,
    disableIcon: <DisableExerciseRestIcon />,
  },
  {
    stickerIdx: 503,
    category: 'exercise',
    stickerName: '전신운동',
    activeIcon: <ColorFullBodyIcon />,
    disableIcon: <DisableFullBodyIcon />,
  },
  {
    stickerIdx: 504,
    category: 'exercise',
    stickerName: '상체 뿌셔',
    activeIcon: <ColorUpperIcon />,
    disableIcon: <DisableUpperIcon />,
  },
  {
    stickerIdx: 505,
    category: 'exercise',
    stickerName: '하체 뿌셔',
    activeIcon: <ColorLowerIcon />,
    disableIcon: <DisableLowerIcon />,
  },
  {
    stickerIdx: 506,
    category: 'exercise',
    stickerName: '복근 뿌셔',
    activeIcon: <ColorCoreIcon />,
    disableIcon: <DisableCoreIcon />,
  },
  {
    stickerIdx: 507,
    category: 'exercise',
    stickerName: '근육통',
    activeIcon: <ColorMusclePainIcon />,
    disableIcon: <DisableMusclePainIcon />,
  },
  {
    stickerIdx: 508,
    category: 'exercise',
    stickerName: '러닝',
    activeIcon: <ColorRunningIcon />,
    disableIcon: <DisableRunningIcon />,
  },
  {
    stickerIdx: 509,
    category: 'exercise',
    stickerName: '걷기',
    activeIcon: <ColorWalkingIcon />,
    disableIcon: <DisableWalkingIcon />,
  },
  {
    stickerIdx: 510,
    category: 'exercise',
    stickerName: '홈트',
    activeIcon: <ColorHomeTrainingIcon />,
    disableIcon: <DisableHomeTrainingIcon />,
  },
  {
    stickerIdx: 511,
    category: 'exercise',
    stickerName: '필라테스',
    activeIcon: <ColorPilatesIcon />,
    disableIcon: <DisablePilatesIcon />,
  },
];

export const poopStickerList: StickerDetail[] = [
  {
    stickerIdx: 201,
    category: 'poop',
    stickerName: '응가 성공',
    activeIcon: <ColorPoopSuccessIcon />,
    disableIcon: <DisablePoopSuccessIcon />,
  },
  {
    stickerIdx: 202,
    category: 'poop',
    stickerName: '황금똥',
    activeIcon: <ColorGoldPoopIcon />,
    disableIcon: <DisableGoldPoopIcon />,
  },
  {
    stickerIdx: 203,
    category: 'poop',
    stickerName: '딱딱',
    activeIcon: <ColorHardPoopIcon />,
    disableIcon: <DisableHardPoopIcon />,
  },
  {
    stickerIdx: 204,
    category: 'poop',
    stickerName: '설사',
    activeIcon: <ColorDiarrheaIcon />,
    disableIcon: <DisableDiarrheaIcon />,
  },
  {
    stickerIdx: 205,
    category: 'poop',
    stickerName: '피똥',
    activeIcon: <ColorBloodPoopIcon />,
    disableIcon: <DisableBloodPoopIcon />,
  },
  {
    stickerIdx: 206,
    category: 'poop',
    stickerName: '꾸룩꾸룩',
    activeIcon: <ColorRainbowIcon />,
    disableIcon: <DisableRainbowIcon />,
  },
  {
    stickerIdx: 207,
    category: 'poop',
    stickerName: '변비',
    activeIcon: <ColorConstipationIcon />,
    disableIcon: <DisableConstipationIcon />,
  },
  {
    stickerIdx: 208,
    category: 'poop',
    stickerName: '변비 탈출',
    activeIcon: <ColorEscapeIcon />,
    disableIcon: <DisableEscapeIcon />,
  },
];

export const sleepStickerList: StickerDetail[] = [
  {
    stickerIdx: 301,
    category: 'sleep',
    stickerName: '잘 잤어',
    activeIcon: <ColorGoodSleepIcon />,
    disableIcon: <DisableGoodSleepIcon />,
  },
  {
    stickerIdx: 302,
    category: 'sleep',
    stickerName: '아주 잘잠',
    activeIcon: <ColorDeepSleepIcon />,
    disableIcon: <DisableDeepSleepIcon />,
  },
  {
    stickerIdx: 303,
    category: 'sleep',
    stickerName: '늦잠잤어',
    activeIcon: <ColorOversleepingIcon />,
    disableIcon: <DisableOversleepingIcon />,
  },
  {
    stickerIdx: 304,
    category: 'sleep',
    stickerName: '꿈 많이 꿈',
    activeIcon: <ColorDreamIcon />,
    disableIcon: <DisableDreamIcon />,
  },
  {
    stickerIdx: 305,
    category: 'sleep',
    stickerName: '악몽꿨어',
    activeIcon: <ColorBadDreamIcon />,
    disableIcon: <DisableBadDreamIcon />,
  },
  {
    stickerIdx: 306,
    category: 'sleep',
    stickerName: '잘 못잤어',
    activeIcon: <ColorBadSleepIcon />,
    disableIcon: <DisableBadSleepIcon />,
  },
  {
    stickerIdx: 307,
    category: 'sleep',
    stickerName: '밤 샜어',
    activeIcon: <ColorAllNightIcon />,
    disableIcon: <DisableAllNightIcon />,
  },
];

export const periodStickerList: StickerDetail[] = [
  {
    stickerIdx: 401,
    category: 'period',
    stickerName: '생리 중',
    activeIcon: <ColorNormalPeriodIcon />,
    disableIcon: <DisableNormalPeriodIcon />,
  },
  {
    stickerIdx: 402,
    category: 'period',
    stickerName: '양 많음',
    activeIcon: <ColorMuchPeriodIcon />,
    disableIcon: <DisableMuchPeriodIcon />,
  },
  {
    stickerIdx: 403,
    category: 'period',
    stickerName: '양 적음',
    activeIcon: <ColorLittlePeriodIcon />,
    disableIcon: <DisableLittlePeriodIcon />,
  },
  {
    stickerIdx: 404,
    category: 'period',
    stickerName: '윽 생리통',
    activeIcon: <ColorPeriodPainIcon />,
    disableIcon: <DisablePeriodPainIcon />,
  },
  {
    stickerIdx: 405,
    category: 'period',
    stickerName: '부정출혈',
    activeIcon: <ColorIrregularBleedingIcon />,
    disableIcon: <DisableIrregularBleedingIcon />,
  },
  {
    stickerIdx: 406,
    category: 'period',
    stickerName: '분비물',
    activeIcon: <ColorSecretionIcon />,
    disableIcon: <DisableSecretionIcon />,
  },
  {
    stickerIdx: 407,
    category: 'period',
    stickerName: '질염',
    activeIcon: <ColorVaginitisIcon />,
    disableIcon: <DisableVaginitisIcon />,
  },
  {
    stickerIdx: 408,
    category: 'period',
    stickerName: '왜 안해?',
    activeIcon: <ColorNoPeriodIcon />,
    disableIcon: <DisableNoPeriodIcon />,
  },
  {
    stickerIdx: 409,
    category: 'period',
    stickerName: '입맛터져',
    activeIcon: <ColorTasteExplodeIcon />,
    disableIcon: <DisableTasteExplodeIcon />,
  },
  {
    stickerIdx: 410,
    category: 'period',
    stickerName: '러브러브',
    activeIcon: <ColorLoveLoveIcon />,
    disableIcon: <DisableLoveLoveIcon />,
  },
];

export const mainTabCategories = ['calender', 'sticker'];

export const calenderCategories: DashboardCategory[] = [
  {
    type: 'food',
    icon: <GreenChipIcon />,
    content: '먹었어요',
    color: COLORS.PRIMITIVES_GREEN_400,
  },
  {
    type: 'exercise',
    icon: <PinkChipIcon />,
    content: '태웠어요',
    color: COLORS.PRIMITIVES_PINK_400,
  },
  {
    type: 'weight',
    icon: <ApricotChipIcon />,
    content: '몸무게',
    color: COLORS.PRIMITIVES_APRICOT_400,
  },
  {
    type: 'water',
    icon: <SkyBlueChipIcon />,
    content: '물 섭취',
    color: COLORS.PRIMITIVES_SKY_BLUE_400,
  },
];

export const stickerCategories: StickerCategory[] = [
  {
    type: 'mood',
    icon: <ColorExcitedIcon />,
    content: '기분',
    list: moodStickerList,
  },
  {
    type: 'diet',
    icon: <ColorCheatingIcon />,
    content: '다이어트',
    list: dietStickerList,
  },
  {
    type: 'exercise',
    icon: <CategoryExrciseIcon />,
    content: '운동',
    list: exerciseStickerList,
  },
  {
    type: 'poop',
    icon: <ColorPoopSuccessIcon />,
    content: '응가',
    list: poopStickerList,
  },
  {
    type: 'sleep',
    icon: <ColorGoodSleepIcon />,
    content: '수면',
    list: sleepStickerList,
  },
  {
    type: 'period',
    icon: <ColorNormalPeriodIcon />,
    content: '생리',
    list: periodStickerList,
  },
];
