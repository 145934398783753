import { COLORS } from '@styles/constants/_colors';
import { useMemo } from 'react';
import styled from 'styled-components';
import {
  CircleKakaoIcon,
  CircleAppleIcon,
  CircleCommonIcon,
  CircleGoogleIcon,
  CircleNaverIcon,
} from '@assets/svgs/_Icons';

interface Props {
  loginType: string;
  isBorder?: boolean;
  loginUiType: 'A' | 'B';
}

const LoginHistory = ({ loginType, isBorder, loginUiType }: Props) => {
  const type: { [key: string]: string } = useMemo(
    () => ({
      K: '카카오',
      N: '네이버',
      A: '애플',
      G: '구글',
      C: '아이디',
    }),
    [],
  );

  const logoImage: { [key: string]: JSX.Element } = useMemo(
    () => ({
      K: <CircleKakaoIcon />,
      N: <CircleNaverIcon />,
      A: <CircleAppleIcon />,
      G: <CircleGoogleIcon />,
      C: <CircleCommonIcon />,
    }),
    [],
  );

  return (
    <Wrapper isBorder={isBorder} loginUiType={loginUiType}>
      {logoImage[loginType]}
      <Text>
        <span>{type[loginType]}</span>로 로그인 한 적 있어요
      </Text>
    </Wrapper>
  );
};

export default LoginHistory;

const Wrapper = styled.div<{ isBorder?: boolean; loginUiType: 'A' | 'B' }>`
  position: absolute;
  left: 50%;
  top: ${({ loginUiType }) => (loginUiType === 'A' ? '43px' : '-48px')};
  opacity: 1;
  transform: translateX(-50%);
  height: 30px;
  border-radius: 120px;
  background-color: ${COLORS.WHITE};
  border: ${({ isBorder }) => (isBorder ? `1px solid ${COLORS.PRIMITIVES_GRAY_300}` : 'none')};
  display: flex;
  align-items: center;
  column-gap: 4px;
  padding: 0px 12px;
  z-index: 10;
  white-space: nowrap;
  pointer-events: none;
`;

const Text = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.5px;
  transform: translateY(-1px);

  span {
    font-weight: 700;
  }
`;
