import { cx } from 'styles';
import { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';

interface Props {
  unit: string;
  name: string;
  placeholder?: string;
  value: any;
  onChange: ChangeEventHandler<HTMLInputElement>;
  mode?: 'none' | 'text' | 'search' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined;
  size?: 'full' | 'half';
  max?: number;
}

const Container = styled.div`
  width: 50%;
  height: 52px;
  padding: 14px 22px 9px;
  border-radius: 10px;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};

  input {
    border: none;
    outline: none;
    resize: none;
    padding: 0;
    width: calc(100% - 20px);

    font-family: 'Campton';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: -0.576923px;

    &::placeholder {
      font-family: 'Campton';
      color: ${COLORS.PRIMITIVES_GRAY_450};
    }
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 16px;

  span {
    font-family: 'Campton';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: -0.576923px;
    color: ${COLORS.PRIMITIVES_GRAY_450};
  }
`;

const FoodRegisterEditInput = ({
  name,
  unit,
  placeholder,
  value,
  onChange,
  mode,
  size,
  max,
}: Props) => {
  return (
    <Container className={cx([size && size])}>
      <Box>
        <input
          name={name}
          inputMode={mode}
          onChange={onChange}
          placeholder={placeholder}
          value={value ?? ''}
          maxLength={max}
        />
        <span>{unit}</span>
      </Box>
    </Container>
  );
};

export default FoodRegisterEditInput;
