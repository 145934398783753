import Layout from '@components/Layout/Layout';
import BackArrowBtn from '@components/Molecules/BackArrowBtn';
import Entry from '@components/entry/Entry';
import useToastModal from '@hooks/useToastModal';
import useTopModal from '@hooks/useTopModal';
import DatePickerBottomSheet from '@pages/fasting/components/DatePickerBottomSheet';
import { COLORS } from '@styles/constants/_colors';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import useGetTimerRecordListQuery from '@pages/fasting/hooks/useGetTimerRecordListQuery';
import { dateForRecordState } from '../../state';
import { useRecoilValue } from 'recoil';
import { TimeStateType } from '@models/fasintg';
import BottomButton from '@components/BottomButton';
import TimePickerBottomSheet from '@pages/fasting/components/TimePickerBottomSheet';
import { useMutation } from 'react-query';
import { deleteFastingRecord, postingFastingRecord, updateFastingRecord } from '@apis/fastingV2Api';
import TimeDurationBottomSheet from '@pages/fasting/components/TimeDurationBottomSheet';
import Popup from '@components/elements/Popup/Popup';
import DifficultySelectBox from '@pages/fasting/components/DifficultySelectBox';
import useAdBrix from '@hooks/adBrix/useAdBrix';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const FastingRecordEditPage = () => {
  const { sendAdBrixData } = useAdBrix();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { data: userData } = useUserInfoQuery();
  const memberIdx = useMemo(() => userData?.member_idx ?? 0, [userData?.member_idx]);
  const dateForRecord = useRecoilValue(dateForRecordState);

  const { data: fastingRecordList } = useGetTimerRecordListQuery({
    memberIdx,
    createdAt: dateForRecord,
  });
  const recordDataById = fastingRecordList?.find((record) => record.fasting_reg_idx === Number(id));
  const { openModal, closeModal } = useTopModal();
  const { openModal: openToastModal } = useToastModal();

  const { mutate: postingRecordMutate } = useMutation(postingFastingRecord);
  const { mutate: deleteRecordMutate } = useMutation(deleteFastingRecord);
  const { mutate: updateRecordMutate } = useMutation(updateFastingRecord);
  const [difficulty, setDifficulty] = useState<null | number>(null);
  const [form, setForm] = useState<{
    start: Dayjs | string;
    end: Dayjs | string;
  }>({
    start: '',
    end: '',
  });

  const [timeForm, setTimeForm] = useState({
    time: '오전',
    hour: '',
    minute: '',
  });

  const [durationTime, setDurationTime] = useState({
    hour: '',
    minute: '',
  });

  // 시작 날짜 value
  const selectStartDateInputValue = useMemo(() => {
    if (form.start !== '') {
      return dayjs(form.start).format('M월 D일');
    }
    return '';
  }, [form.start]);

  // 시작 시간 value
  const selectStartTimeInputValue = useMemo(() => {
    if (timeForm.hour !== '' && timeForm.minute !== '') {
      return dayjs(form.start).format('A h:mm');
    }
    return '';
  }, [form.start, timeForm.hour, timeForm.minute]);

  // 종료 시간 value
  const endTimeObject = useMemo(
    () => ({
      month: form.end !== '' ? dayjs(form.end).get('month') + 1 : '0',
      date: form.end !== '' ? dayjs(form.end).get('date') : '0',
      time: form.end !== '' ? dayjs(form.end).format('A') : dayjs().format('A'),
      hour: form.end !== '' ? dayjs(form.end).format('h') : '0',
      minute: form.end !== '' ? dayjs(form.end).format('mm') : '00',
    }),
    [form.end],
  );

  const durationValue = useMemo(() => {
    if (durationTime.hour !== '' && durationTime.minute !== '') {
      return `${Number(durationTime.hour)}시간 ${
        Number(durationTime.minute) === 0 ? '0' : Number(durationTime.minute)
      }분`;
    }

    if (durationTime.minute !== '') {
      return `${Number(durationTime.minute)}분`;
    }
    return '';
  }, [durationTime.hour, durationTime.minute]);

  const onClickDateConfirm = (pickerDate: Dayjs) => {
    const hour =
      timeForm.time === '오후' && Number(timeForm.hour) < 12
        ? Number(timeForm.hour) + 12
        : Number(timeForm.hour);

    setForm((prev) => {
      return {
        ...prev,
        start: dayjs(prev.start !== '' ? prev.start : undefined)
          .set('year', pickerDate.get('year'))
          .set('month', pickerDate.get('month'))
          .set('date', pickerDate.get('date'))
          .set('hour', timeForm.time === '오전' && hour === 12 ? 0 : hour)
          .set('minute', Number(timeForm.minute || 0)),
        end: dayjs(prev.start !== '' ? prev.start : undefined)
          .set('year', pickerDate.get('year'))
          .set('month', pickerDate.get('month'))
          .set('date', pickerDate.get('date'))
          .set('hour', timeForm.time === '오전' && hour === 12 ? 0 : hour)
          .set('minute', Number(timeForm.minute || 0))
          .add(Number(durationTime.hour || 0), 'hour')
          .add(Number(durationTime.minute || 0), 'minute'),
      };
    });

    closeModal(DatePickerBottomSheet);
  };

  const handleOpenDatePickerBottomSheet = () => {
    openModal(DatePickerBottomSheet, {
      onClose: () => {
        closeModal(DatePickerBottomSheet);
      },
      onClickDateConfirm,
      startAt: form.start,
    });
  };
  const onChangeTimeConfirm = (timeForm: TimeStateType) => {
    const hour =
      timeForm.time === '오후' && Number(timeForm.hour) < 12
        ? Number(timeForm.hour) + 12
        : Number(timeForm.hour);

    setTimeForm(timeForm);
    setForm((prev) => {
      return {
        ...prev,
        start: dayjs(prev.start ? prev.start : undefined)
          .set('hour', timeForm.time === '오전' && hour === 12 ? 0 : hour)
          .set('minute', timeForm.minute === '' ? 0 : Number(timeForm.minute))
          .set('second', 0),
        end: dayjs(prev.start !== '' ? prev.start : undefined)
          .set('hour', timeForm.time === '오전' && hour === 12 ? 0 : hour)
          .set('minute', timeForm.minute === '' ? 0 : Number(timeForm.minute))
          .set('second', 0)
          .add(Number(durationTime.hour || 0), 'hour')
          .add(Number(durationTime.minute || 0), 'minute'),
      };
    });

    closeModal(TimePickerBottomSheet);
  };

  const handleOpenTimePickerBottomSheet = () => {
    openModal(TimePickerBottomSheet, {
      onClose: () => {
        closeModal(TimePickerBottomSheet);
      },
      onClickConfirm: onChangeTimeConfirm,
      initialTimeForm: {
        time: timeForm.time,
        hour: timeForm.hour !== '' ? timeForm.hour : '',
        minute: timeForm.minute !== '' ? timeForm.minute : '',
      },
    });
  };

  const onClickDurationConfirm = (durationTimeForm: { hour: string; minute: string }) => {
    setDurationTime(durationTimeForm);

    setForm((prev) => {
      return {
        ...prev,
        end: dayjs(prev.start !== '' ? prev.start : undefined)
          .add(Number(durationTimeForm.hour || 0), 'hour')
          .add(Number(durationTimeForm.minute || 0), 'minute'),
      };
    });

    closeModal(TimeDurationBottomSheet);
  };

  const handleOpenTimeDurationBottomSheet = () => {
    openModal(TimeDurationBottomSheet, {
      onClose: () => {
        closeModal(TimeDurationBottomSheet);
      },
      onClickConfirm: onClickDurationConfirm,
      initialTimeForm: {
        hour: durationTime.hour !== '' ? durationTime.hour : '',
        minute: durationTime.minute !== '' ? durationTime.minute : '',
      },
    });
  };

  const handlePostingRecord = () => {
    postingRecordMutate(
      {
        memberIdx,
        recordForm: {
          start_at: dayjs(form.start).format('YYYY-MM-DD HH:mm:ss'),
          end_at: dayjs(form.end).format('YYYY-MM-DD HH:mm:ss'),
          fasting_difficulty: difficulty as number,
          fasting_method: 'user',
        },
      },
      {
        onSuccess: () => {
          sendAdBrixData('customEvent', {
            eventKey: 'register_fasting_record',
          });
          navigate(`/fasting/record`, {
            replace: true,
          });
          openToastModal({ children: <p>기록이 완료되었어요</p> });
        },
      },
    );
  };

  const onDeleteRecord = () => {
    deleteRecordMutate(
      {
        memberIdx,
        regIdx: id,
      },
      {
        onSuccess: () => {
          openToastModal({ children: <p>기록이 삭제되었어요</p> });
          navigate(-1);
        },
      },
    );

    closeModal(Popup);
  };

  const handleDeleteRecord = () => {
    openModal(Popup, {
      headText: '이 단식 기록을 삭제하시겠어요?',
      buttonText1: '아니요',
      buttonText2: '네. 삭제할래요',
      onClick: onDeleteRecord,
      onClose: () => closeModal(Popup),
      cancel: () => {
        navigate(-1);
        closeModal(Popup);
      },
    });
  };

  const handleUpdateRecord = () => {
    updateRecordMutate(
      {
        memberIdx,
        regIdx: id,
        recordForm: {
          start_at: dayjs(form.start).format('YYYY-MM-DD HH:mm:ss'),
          end_at: dayjs(form.end).format('YYYY-MM-DD HH:mm:ss'),
          fasting_difficulty: difficulty as number,
        },
      },
      {
        onSuccess: () => {
          navigate(-1);
          openToastModal({ children: <p>기록이 수정되었어요</p> });
        },
      },
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (recordDataById) {
      setTimeForm({
        time: dayjs(recordDataById.start_at).format('A'),
        hour: dayjs(recordDataById.start_at).format('h'),
        minute: dayjs(recordDataById.start_at).format('mm'),
      });
      setDurationTime({
        hour: String(recordDataById.fasting_hour),
        minute: String(recordDataById.fasting_min),
      });
      setForm({
        start: dayjs(recordDataById.start_at),
        end: dayjs(recordDataById.end_at),
      });
      setDifficulty(recordDataById.fasting_difficulty);
    }
  }, [recordDataById]);

  const isDisabled = useMemo(() => {
    return !(
      form.start !== '' &&
      form.end !== '' &&
      difficulty !== null &&
      durationTime.minute !== ''
    );
  }, [form.start, form.end, difficulty, durationTime.hour, durationTime.minute]);

  return (
    <Layout>
      <Layout.Headers>
        <HeaderField>
          <BackArrowBtn />
          <Title>{id ? '단식 수정하기' : '단식 기록하기'}</Title>
        </HeaderField>
      </Layout.Headers>
      <Layout.Contents>
        <ContentsField>
          <Section>
            <Entry
              icon="name"
              readOnly
              label={{ left: { name: '시작 날짜' } }}
              value={selectStartDateInputValue}
              placeholder="단식을 시작한 날짜"
              onClick={handleOpenDatePickerBottomSheet}
            />
            <Entry
              icon="time"
              readOnly
              label={{ left: { name: '시작 시간' } }}
              value={selectStartTimeInputValue}
              placeholder="단식을 시작한 시간"
              onClick={handleOpenTimePickerBottomSheet}
            />
            <Entry
              icon="time"
              readOnly
              label={{ left: { name: '단식 유지 시간' } }}
              value={durationValue}
              placeholder="단식을 유지한 시간"
              onClick={handleOpenTimeDurationBottomSheet}
            />
          </Section>
          <InformationField>
            <Information>단식 종료 시간은</Information>
            <Row>
              <Information className="blue">
                <span>{endTimeObject.month}</span>월 <span>{endTimeObject.date}</span>일{' '}
                {endTimeObject.time}
                <span>
                  {form.end === '' ? '00' : endTimeObject.hour}:{endTimeObject.minute}
                </span>
              </Information>
              <Information> 예요</Information>
            </Row>
          </InformationField>
          <DifficultySelectField>
            <Text>느낌이 어땠나요?</Text>
            <DifficultySelectBox difficulty={difficulty} setDifficulty={setDifficulty} />
          </DifficultySelectField>
        </ContentsField>
      </Layout.Contents>
      <Layout.Buttons>
        {id ? (
          <ButtonBox>
            <Button>
              <BottomButton color="gray" onClickEvent={handleDeleteRecord}>
                삭제하기
              </BottomButton>
            </Button>
            <Button>
              <BottomButton isDisabled={isDisabled} color="black" onClickEvent={handleUpdateRecord}>
                수정하기
              </BottomButton>
            </Button>
          </ButtonBox>
        ) : (
          <ButtonBox>
            <BottomButton isDisabled={isDisabled} color="black" onClickEvent={handlePostingRecord}>
              기록 완료
            </BottomButton>
          </ButtonBox>
        )}
      </Layout.Buttons>
    </Layout>
  );
};

export default FastingRecordEditPage;

const HeaderField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 20px 12px 19px;
  background-color: ${COLORS.WHITE};
`;

const Title = styled.p`
  color: ${COLORS.BLACK};
  flex: 1;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.5px;
  transform: translateX(-12px);
`;

const ContentsField = styled.div`
  padding: 85px 20px 50px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const InformationField = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  margin-bottom: 50px;
`;

const Information = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.4px;
  text-align: center;
  color: ${COLORS.PRIMITIVES_GRAY_500};

  &.blue {
    color: ${COLORS.PRIMITIVES_BLUE_400};
  }

  span {
    font-family: Campton;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%; /* 16px */

    :nth-last-of-type(1) {
      margin-left: 3px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1px;
`;

const Text = styled.p`
  color: ${COLORS.PRIMITIVES_GRAY_500};
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 100% */
  letter-spacing: -0.437px;
  margin-bottom: 25px;
`;

const ButtonBox = styled.div`
  display: flex;
  column-gap: 7px;
  padding: 12px;
  background-color: ${COLORS.WHITE};
`;

const DifficultySelectField = styled.div`
  margin-top: 50px;
  margin-bottom: 77px;
`;

const Button = styled.div`
  width: 50%;
`;
