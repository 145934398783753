import { makeStyles, Grid, createStyles, Theme } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { spinnerState } from '../states/globalAlert';
import SpinnerDot from './SpinnerDot';

interface SpinnerProps {
  onSpinnerState?: boolean;
  transparent?: boolean;
  dimmedColor?: string;
  zIndex?: number;
}

export default function Spinner({
  onSpinnerState = false,
  transparent = true,
  dimmedColor = '#0000006c',
  zIndex,
}: SpinnerProps) {
  const classes = useStyles({ dimmedColor, zIndex });
  const dotList = [0, 1, 2, 3];
  const onSpinner = useRecoilValue(spinnerState);

  return (
    <>
      {(!!onSpinnerState || !onSpinner) && (
        <Grid
          className={transparent ? classes.container : classes.whiteContainer}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid
            className={classes.content}
            container
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {dotList.map((item, index) => (
              <SpinnerDot index={item} key={index} />
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles<Theme, { dimmedColor?: string; zIndex?: number }>(() =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      width: '100%',
      maxWidth: 428,
      height: '100vh',
      background: ({ dimmedColor }) => dimmedColor,
      zIndex: ({ zIndex }) => (zIndex ? zIndex : 5002),
      '& img': {
        width: '30%',
        objectFit: 'cover',
      },
    },
    whiteContainer: {
      position: 'fixed',
      top: 0,
      width: '100%',
      maxWidth: 428,
      height: '100vh',
      background: '#fff',
    },
    content: {
      width: 96,
      height: 21,
    },
  }),
);
