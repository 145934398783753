import { existFood } from 'apis/searchApi';
import ArrowBack from 'assets/images/icon/GNB/ArrowBack';
import ButtonSet from 'components/Atoms/Button/ButtonSet';
import ToastPageMsg from 'components/elements/ToastPageMsg';
import Stepper from 'components/Stepper';
import { RequestCustomFoodDetail } from 'types/searchResult';
import FoodRegisterIconInput from 'pages/search/atomic/input/FoodRegisterIconInput';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import { swipeRight } from 'styles/mixins/_animations';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  food: RequestCustomFoodDetail;
  setFood: React.Dispatch<React.SetStateAction<RequestCustomFoodDetail>>;
  onClose: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${COLORS.WHITE};
  overflow-y: scroll;
`;

const Header = styled.header`
  padding: 17px 0px 0px 20px;
`;

const Body = styled.div`
  margin: 28px 20px 98px;

  & .title {
    margin: 20px 0px 32px;
    font-family: 'Noto Sans KR';
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.5px;
    white-space: pre-line;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  animation: ${swipeRight} 600ms ease;
`;

const Footer = styled.footer`
  position: fixed;
  max-width: 428px;
  width: 100%;
  height: 78px;
  padding: 12px 20px;
  bottom: 0;
  background: ${COLORS.WHITE};
`;

const FoodRegisterStepTwoBrand = ({ setStep, food, setFood, onClose }: Props) => {
  const [isToast, setIsToast] = useState(false);

  const onChangeBrandName = (e: ChangeEvent<any>) => {
    if (e.target.value?.length === 21) return;
    setFood({
      ...food,
      food_brand: e.target.value,
    });
  };

  const onChangeFoodName = (e: ChangeEvent<any>) => {
    if (e.target.value?.length === 31) return;
    setFood({
      ...food,
      food_name: e.target.value,
    });
  };

  const onBack = () => {
    onClose();
    return;
  };

  const onClick = () => {
    existFood(food.food_name, food.food_brand?.toString() ?? '').then((data) => {
      if (!data) {
        setIsToast(true);
        return;
      }
      setStep(2);
    });
  };

  return (
    <Container>
      <ToastPageMsg onToast={isToast} setOnToast={setIsToast}>
        {`같은 이름의 음식이\n 이미 등록되어 있어요`}
      </ToastPageMsg>
      <Header>
        <span onClick={onBack}>
          <ArrowBack color={COLORS.BLACK} />
        </span>
      </Header>
      <Body>
        <Stepper focusedIndex={1} length={3} paddingTop={0} />
        <p className="title">{`음식의 이름을\n 알려주세요`}</p>
        <Content>
          <FoodRegisterIconInput
            icon="foodBrandIcon"
            title="브랜드 이름"
            value={food.food_brand}
            onChange={onChangeBrandName}
            placeholder="브랜드 이름 (최대20자)"
            max={20}
          />
          <FoodRegisterIconInput
            icon="foodBrandIcon"
            title="음식 이름"
            value={food.food_name}
            onChange={onChangeFoodName}
            max={30}
            placeholder="음식 이름 (최대30자)"
          />
        </Content>
      </Body>
      <Footer>
        <ButtonSet
          text="다음"
          size="long"
          type="button"
          color="black"
          isActive={food.food_brand !== '' && food.food_brand !== null && food.food_name !== ''}
          onClick={onClick}
        />
      </Footer>
    </Container>
  );
};

export default FoodRegisterStepTwoBrand;
