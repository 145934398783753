interface Contructor {
  code: number;
  name: string;
  message: string;
}
class RuntimeError extends Error {
  public code: number;

  constructor({ code, name, message }: Contructor) {
    super(message);
    this.code = code;
    this.name = name;
  }
}

export default RuntimeError;
