import { Grid, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  active: boolean;
  size: 'small' | 'medium';
}

export default function RedSmileWithFire({ active, size }: Props) {
  const classes = useStyles();

  const fragment = (() => {
    switch (size) {
      case 'medium':
        return {
          icon: active ? (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="27.5" cy="29.3438" r="27.5" fill="#FA5064" />
              <path
                d="M21.6713 34.8354C22.3182 38.1037 24.9503 39.8409 27.7072 39.8409C30.7624 39.8409 33.1668 38.1137 33.757 34.8414"
                stroke="black"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <path d="M33 22L38 19" stroke="black" strokeWidth="2.8" strokeLinecap="round" />
              <ellipse cx="18.5137" cy="27.7344" rx="2.75" ry="2.76563" fill="black" />
              <ellipse cx="36.55" cy="27.75" rx="2.75" ry="2.75" fill="black" />
              <path
                d="M58.48 43.2539C56.91 39.1739 51.32 38.9539 52.67 33.0239C52.77 32.5839 52.3 32.2439 51.92 32.4739C48.29 34.6139 45.68 38.9039 47.87 44.5239C48.05 44.9839 47.51 45.4139 47.12 45.1139C45.31 43.7439 45.12 41.7739 45.28 40.3639C45.34 39.8439 44.66 39.5939 44.37 40.0239C43.69 41.0639 43 42.7439 43 45.2739C43.38 50.8739 48.11 52.594 49.81 52.8139C52.24 53.1239 54.87 52.674 56.76 50.944C58.84 49.014 59.6 45.9339 58.48 43.2539Z"
                fill="black"
              />
            </svg>
          ) : (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="27.5" cy="29.3438" r="27.5" fill="#E6E7EA" />
              <path
                d="M21.6713 34.8354C22.3182 38.1037 24.9503 39.8409 27.7072 39.8409C30.7624 39.8409 33.1668 38.1137 33.757 34.8414"
                stroke="#999FAB"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <path d="M33 22L38 19" stroke="#999FAB" strokeWidth="2.8" strokeLinecap="round" />
              <ellipse cx="18.5137" cy="27.7344" rx="2.75" ry="2.76563" fill="#999FAB" />
              <ellipse cx="36.55" cy="27.75" rx="2.75" ry="2.75" fill="#999FAB" />
              <path
                d="M58.48 43.2539C56.91 39.1739 51.32 38.9539 52.67 33.0239C52.77 32.5839 52.3 32.2439 51.92 32.4739C48.29 34.6139 45.68 38.9039 47.87 44.5239C48.05 44.9839 47.51 45.4139 47.12 45.1139C45.31 43.7439 45.12 41.7739 45.28 40.3639C45.34 39.8439 44.66 39.5939 44.37 40.0239C43.69 41.0639 43 42.7439 43 45.2739C43.38 50.8739 48.11 52.594 49.81 52.8139C52.24 53.1239 54.87 52.674 56.76 50.944C58.84 49.014 59.6 45.9339 58.48 43.2539Z"
                fill="#999FAB"
              />
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14.9153" cy="15.9153" r="14.9153" fill="#FA5064" />
              <path
                d="M11.7539 18.894C12.1048 20.6666 13.5323 21.6089 15.0276 21.6089C16.6847 21.6089 17.9888 20.6721 18.3089 18.8973"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <path
                d="M17.8983 11.9323L20.6102 10.3052"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="10.0413" cy="15.0425" rx="1.49153" ry="1.5" fill="black" />
              <ellipse cx="19.8238" cy="15.0506" rx="1.49152" ry="1.49153" fill="black" />
              <g clipPath="url(#clip0_790_16295)">
                <path
                  d="M31.718 23.4597C30.8664 21.2468 27.8346 21.1275 28.5668 17.9112C28.621 17.6726 28.3661 17.4882 28.16 17.6129C26.1912 18.7736 24.7756 21.1004 25.9634 24.1485C26.061 24.398 25.7681 24.6312 25.5566 24.4685C24.5749 23.7255 24.4719 22.657 24.5586 21.8922C24.5912 21.6102 24.2224 21.4746 24.0651 21.7078C23.6963 22.2719 23.322 23.1831 23.322 24.5553C23.5281 27.5926 26.0935 28.5255 27.0156 28.6448C28.3335 28.8129 29.76 28.5688 30.7851 27.6305C31.9132 26.5838 32.3254 24.9133 31.718 23.4597Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_790_16295">
                  <rect
                    width="8.8"
                    height="11.9429"
                    fill="white"
                    transform="translate(23.2677 17.356)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
      default:
        return {
          icon: (
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14.9153" cy="15.9153" r="14.9153" fill="#FA5064" />
              <path
                d="M11.7539 18.894C12.1048 20.6666 13.5323 21.6089 15.0276 21.6089C16.6847 21.6089 17.9888 20.6721 18.3089 18.8973"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <path
                d="M17.8983 11.9323L20.6102 10.3052"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="10.0413" cy="15.0425" rx="1.49153" ry="1.5" fill="black" />
              <ellipse cx="19.8238" cy="15.0506" rx="1.49152" ry="1.49153" fill="black" />
              <g clipPath="url(#clip0_790_16295)">
                <path
                  d="M31.718 23.4597C30.8664 21.2468 27.8346 21.1275 28.5668 17.9112C28.621 17.6726 28.3661 17.4882 28.16 17.6129C26.1912 18.7736 24.7756 21.1004 25.9634 24.1485C26.061 24.398 25.7681 24.6312 25.5566 24.4685C24.5749 23.7255 24.4719 22.657 24.5586 21.8922C24.5912 21.6102 24.2224 21.4746 24.0651 21.7078C23.6963 22.2719 23.322 23.1831 23.322 24.5553C23.5281 27.5926 26.0935 28.5255 27.0156 28.6448C28.3335 28.8129 29.76 28.5688 30.7851 27.6305C31.9132 26.5838 32.3254 24.9133 31.718 23.4597Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_790_16295">
                  <rect
                    width="8.8"
                    height="11.9429"
                    fill="white"
                    transform="translate(23.2677 17.356)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}

const useStyles = makeStyles(() => createStyles({}));
