import { atom } from 'recoil';

/**
 * @description 음식 검색대 광고 ad 조회수 집계 중복 방지를 위한 state
 * 날짜, mealId가 다른 칸반 별로 수집
 * { ${날짜}-${mealId} : ad_idx[] }
 */
export const foodSearchAdSubscribe = atom<Map<string, number[]>>({
  key: 'foodSearchAdSubscribe',
  default: new Map<string, number[]>(),
});

/**
 * @description 게시판 상세 ad 조회수 집계 중복 방지를 위한 state
 * board_idx별로 수집
 * { ${board_idx} : ad_idx[] }
 */
export const boardAdSubscribe = atom<Map<string, number[]>>({
  key: 'boardAdSubscribe',
  default: new Map<string, number[]>(),
});
