import { SearchBarIcon } from '@assets/svgs';
import BackArrowBtn from '@components/Molecules/BackArrowBtn';
import DeleteCircleButton from '@components/Molecules/DeleteCircleButton';
import { deviceOs } from '@modules/platformCheck';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onClear?: MouseEventHandler<HTMLButtonElement>;
  onClickBack?: () => void;
  onClickBasket?: () => void;
  onClickInput?: () => void;
  count?: number;
  borderBottomLineColor?: string;
}

const BasketSearchBar = forwardRef<HTMLInputElement, Props>(
  (
    {
      onClear,
      onClickBack,
      onClickBasket,
      onClickInput,
      count = 0,
      borderBottomLineColor,
      ...props
    },
    ref,
  ) => {
    return (
      <Container borderBottomLineColor={borderBottomLineColor}>
        <BackArrowBtn onClickEvent={onClickBack} />
        <Wrapper onClick={onClickInput} isIos={deviceOs === 'ios'}>
          <SearchBarIcon />
          <input ref={ref} {...props} />
          {props.value && <DeleteCircleButton onClick={onClear} size={'small'} color={'#D0D2D6'} />}
        </Wrapper>
        <BasketCircle onClick={onClickBasket} className={classNames([{ active: count > 0 }])}>
          {count}
        </BasketCircle>
      </Container>
    );
  },
);

BasketSearchBar.displayName = 'BasketSearchBar';

const Container = styled.div<{ borderBottomLineColor?: string }>`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 12px 20px;
  max-width: 428px;
  background: ${COLORS.WHITE};
  border-bottom: ${({ borderBottomLineColor }) =>
    borderBottomLineColor ? `1px solid ${borderBottomLineColor}` : 'none'};
`;

const Wrapper = styled.div<{ isIos?: boolean }>`
  display: flex;
  align-items: center;
  gap: 7px;
  width: 88%;
  height: 40px;
  margin: 0px 12px 0px 16px;
  padding: 10px 14px;
  background: ${COLORS.PRIMITIVES_GRAY_50};
  border-radius: 20px;

  input {
    width: 100%;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.5px;
    color: ${COLORS.BLACK};
  }

  input::placeholder {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.5px;
    color: #abaeb4;

    ${({ isIos }) =>
      isIos &&
      css`
        top: 10px;
        transform: translateY(-50%);
        position: relative;
      `}
  }

  svg {
    min-width: 20px;
  }
`;

const BasketCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-top: 2px;
  padding-bottom: 1px;
  background-color: ${COLORS.PRIMITIVES_GRAY_50};

  font-family: Campton;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.5384615659713745px;
  text-align: center;
  color: #abaeb4;

  &.active {
    background-color: ${FOUNDATION_COLOR.BASIC_BLUE};
    color: ${COLORS.WHITE};
  }
`;

export default BasketSearchBar;
