import { FoodBrandIcon } from 'assets/svgs/food';
import { ChangeEvent, ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import FoodRegisterInputWrapper from './FoodRegisterInputWrapper';

interface Props {
  icon: string;
  title: string;
  value: any;
  mode?: 'none' | 'text' | 'search' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  max?: number;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 18.5px 23px 19.5px;

  input {
    border: none;
    outline: none;
    resize: none;
    padding: 0;
    width: calc(100% - 40px);

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.576923px;
    line-height: 1.5;
    &::placeholder {
      color: ${COLORS.PRIMITIVES_GRAY_450};
    }
  }
`;

const FoodRegisterIconInput = ({
  icon,
  title,
  value,
  placeholder = '',
  max,
  onChange,
  mode,
}: Props) => {
  const foodIcon = (() => {
    const iconName = {
      foodBrandIcon: <FoodBrandIcon />,
    }[icon] ?? <FoodBrandIcon />;
    return iconName;
  })();

  return (
    <FoodRegisterInputWrapper title={title}>
      <Container>
        {foodIcon}
        <input value={value ?? ''} onChange={onChange} placeholder={placeholder} maxLength={max} />
      </Container>
    </FoodRegisterInputWrapper>
  );
};

export default FoodRegisterIconInput;
