import { makeStyles, createStyles } from '@material-ui/core';
import { COLORS } from '@styles/constants/_colors';
import BackArrowGnb from 'components/elements/BackArrowGnb';
import { useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';

interface Props {
  onClose: () => void;
}

export default function InoutPrivacyViewModal({ onClose }: Props) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    document.body.style.removeProperty('overflow');
    return () => {
      document.body.style.overflow = 'hidden';
    };
  }, []);

  return (
    <Wrapper>
      <BackArrowGnb onClick={onClose} />
      <p className={classes.title}>
        개인정보
        <br />
        처리방침
      </p>

      <p className={classes.content}>
        주식회사 마이노멀 컴퍼니(이하 "회사")는 정보 통신망 이용촉진 및 정보보호 등에 관한 법률(이하
        “정보통신망법”) 등 정보통신서비스 제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을
        준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고
        있습니다.
        <br />
        <br />
        회사는 ‘개인정보보호법’ 및 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 등 관련 법률을
        준수하고, 법률에 따라 다음과 같이 「개인정보처리방침」을 수립하고 이를 홈페이지에 공개하여
        고객이 언제든지 열람할 수 있도록 하고 있습니다. 또한 「개인정보처리방침」은 정부의 법률 및
        지침변경이나 회사의 내부방침 변경 등으로 인하여 개정될 수 있으므로, 홈페이지 공지사항 등을
        통해 수시로 확인하여 주시기 바랍니다.
        <br />
        <br />
        <b>제 1장. 개인정보 수집 및 이용목적</b>
        <br />
        회사는 회원가입 및 원활한 서비스 제공에 따른 개인정보를 어플리케이션 및 웹페이지를 통해
        수집하고 있으며, 설문조사나 이벤트 시에 집단적인 통계 분석을 위해서나 경품 발송을 위한
        목적으로도 개인정보 기재를 요청할 수 있습니다.
        <br />
        <br />
        <table className={classes.tablePolicy}>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>정보</th>
              <th style={{ width: '20%' }}>구분</th>
              <th>수집항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>이용약관</td>
              <td>필수</td>
              <td>• 이름(닉네임), 성별, 나이, 키, 시작 체중, 목표 체중, 평소 활동량</td>
            </tr>
            <tr>
              <td>기기정보</td>
              <td>필수</td>
              <td>
                • 기기 식별자, 운영체제, 하드웨어 버전, 기기설정, 전화번호
                <br />• 국가정보, 암호화된 동일인 식별정보(CI), 중복가입확인정보(DI)
              </td>
            </tr>
            <tr>
              <td>로그정보</td>
              <td>필수</td>
              <td>
                • 서비스 이용 기록, IP 주소, 접속 로그, 쿠키정보, 모바일 기기정보, 방문/결제 기록,
                불량 이용 기록, 광고식별자
              </td>
            </tr>
            <tr>
              <td>기타정보</td>
              <td>선택</td>
              <td>• 식단, 운동, 체중 등의 건강 기록과 주소, 연락처</td>
            </tr>
          </tbody>
        </table>
        <br />
        또한 회원님의 동의를 기반으로 제 3자(서비스)가 제공한 데이터를 추가로 기록하고 저장합니다.
        해당 공급자 및 데이터는 다음과 같습니다
        <br />
        <br /> • 공급자 : Google Fitness, Apple HealthKit
        <br /> • 데이터 : 다양한 피트니스, 영양 및 신체 데이터
        <br />
        <br />
        인아웃이 Google API에서 수신한 정보를 사용하거나 다른 앱으로 전송하는 경우, 제한 사용 요구
        사항을 포함하여 Google API 서비스 사용자 데이터 정책을 준수합니다.
        <br />
        <br />
        회사가 처리하는 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며, 목적이 변경될 시에는
        회원에게 사전 동의를 구할 예정입니다.
        <br />
        ① 본인 확인, 서비스 부정이용 방지 등의 회원 관리
        <br />
        ② 회원이 요구하는 서비스 제공에 관한 계약 이행, 불만/오류사항 처리, 요금 정산 등
        <br />
        ③ 기존 서비스의 개선 혹은 신규 서비스 개발, 맞춤형 콘텐츠 및 친구 추천 기능 개발 등에 활용
        <br />
        ④ 회사 웹사이트 또는 어플리케이션의 기능 또는 정책 변경사항에 대한 알림
        <br />
        ⑤ 기타 이용자의 사전 동의에 의한 이벤트 및 프로모션, 내부 통계, 광고 등에 활용
        <br />
        ⑥ 관련 준거법 또는 법적 의무의 준수
        <br />
        회원은 회사의 개인정보 수집에 대해 동의하지 않거나 개인정보를 기재하지 않음으로써 이를
        거부할 수 있습니다. 다만, 필수 항목에 대한 동의를 하지 않을 경우 회원에게 제공되는 서비스가
        제한될 수 있습니다.
        <br />
        <br />
        <b>제 2장. 개인정보의 보유 및 이용기간</b>
        <br />
        서비스 이용자가 당사의 회원으로서 서비스를 계속 이용하는 동안 당사는 이용자의 개인정보를
        계속 보유하며 서비스 제공 등을 위해 이용합니다.
        <br />
        서비스 이용자의 개인정보는 그 수집 및 이용 목적(설문조사, 이벤트 등 일시적인 목적을 포함)이
        달성되거나 이용자가 직접 삭제 또는 회원 탈퇴한 경우에 재생할 수 없는 방법으로 파기됩니다.
        "개인정보 유효기간제"에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리
        보관하고 있습니다.
        <br />
        당사는 이용자의 권리 남용, 악용 방지, 권리침해/명예훼손 분쟁 및 수사협조 등의 요청이 있었을
        경우에는 이의 재발에 대비하여 회원의 이용계약 해지 시로부터 1년 동안 회원의 개인정보를
        보관할 수 있습니다. 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에
        의하여 보존할 필요가 있는 경우 당사는 관계법령에서 정한 일정한 기간 동안 회원정보를
        보관합니다. 이 경우 당사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와
        같습니다.
        <br />
        <br />
        가. 회사 내부 방침에 의한 정보보유 사유회원탈퇴 시 개인정보 보존기간은 아래와 같습니다.
        <br /> • 보존근거 : 불량 이용자의 재가입 방지, 명예훼손 등 권리침해 분쟁 및 수사협조
        <br /> • 보존기간 : 회원탈퇴 후 1년
        <br />
        나. 관련법령에 의한 정보보유 사유상법, 전자상거래 등에서의 소비자보호에 관한 법률 등
        관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안
        회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며
        보존기간은 아래와 같습니다.
        <br />
        1. 계약 또는 청약철회 등에 관한 기록
        <br /> • 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
        <br /> • 보존 기간 : 5년
        <br />
        2. 대금결제 및 재화 등의 공급에 관한 기록
        <br /> • 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
        <br /> • 보존 기간 : 5년
        <br />
        3. 소비자의 불만 또는 분쟁처리에 관한 기록
        <br /> • 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
        <br /> • 보존 기간 : 3년
        <br />
        4. 본인확인에 관한 기록
        <br /> • 보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
        <br /> • 보존 기간 : 6개월
        <br />
        5. 방문에 관한 기록
        <br /> • 보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
        <br /> • 보존 기간 : 3개월
        <br />
        <br />
        <b>제 3장. 개인정보의 열람, 갱신, 수정 또는 삭제</b>
        <br />
        회원님은 등록되어 있는 본인의 개인정보를 열람하거나 수정하실 수 있으며, 회원탈퇴를 요청하실
        수 있습니다. 회원님의 개인정보 열람 및 수정은 사이트 내의 회원정보변경을 통해 직접 열람 또는
        수정하거나, 개인정보 보호책임자 및 담당자에게 전자우편 또는 서면으로 요청하신 경우 지체없이
        조치하겠습니다.
        <br />
        <br />
        <b>제 4장. 개인정보의 파기절차 및 방법</b>
        <br />
        이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및
        기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후
        파기됩니다.
        <br />
        전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
        삭제합니다.
        <br />
        <br />
        <b>제 5장. 수집한 개인정보의 위탁</b>
        <br />
        회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁
        처리되는 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 다하고 있습니다. 위탁 처리하는
        정보는 서비스 제공에 필요한 최소한의 범위에 국한되고, 모두 암호화되어 제공됩니다.
        <br />
        회사에서 위탁 처리되고 있는 업무와 수탁 업체는 아래와 같습니다. 서비스 제공 목적 및 이용자
        편의 증진을 위하여 국외 업체에 위탁, 보관하고 있으며 서비스 이용 시점에 네트워크를 통해
        전송됩니다.
        <br />
        <br />
        <table className={classes.tablePolicy}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>이전 받는 자</th>
              <th style={{ width: '20%' }}>이전되는 국가</th>
              <th style={{ width: '30%' }}>이용 목적</th>
              <th style={{ width: '20%' }}>이전되는 개인정보 항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google Analytics, Firebase</td>
              <td>미국</td>
              <td>서비스 이용에 대한 데이터 처리</td>
              <td>서비스 이용 기록 또는 수집된 개인정보</td>
            </tr>
            <tr>
              <td>Appsflyer</td>
              <td>미국</td>
              <td>사용자의 서비스 이용에 대한 통계 분석</td>
              <td>서비스 이용 기록</td>
            </tr>
            <tr>
              <td>Facebook, Google Ads, Apple</td>
              <td>미국</td>
              <td>타겟 고객군 대상 마케팅</td>
              <td>암호화된 개별 UID</td>
            </tr>
            <tr>
              <td>Amazon Web service</td>
              <td>미국</td>
              <td>데이터 처리 및 저장</td>
              <td>서비스 이용 기록 또는 수집된 개인정보</td>
            </tr>
          </tbody>
        </table>
        <br />
        위탁사항이 변경되는 경우, 본 개인정보처리방침을 통하여 해당 사실을 알려드리겠습니다.
        <br />
        <br />
        <b>제 6장. 이용자 및 법정대리인의 권리와 그 행사방법</b>
        <br />
        이용자 및 법정대리인은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 수정할 수 있으며,
        회원탈퇴 절차를 통하여 개인정보 이용에 대한 동의를 철회할 수 있습니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
        법정대리인의 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 권리를
        행사할 수 있습니다.
        <br />
        이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해
        개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한
        경우에는 정정 처리 결과를 제 3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
        <br />
        회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “개인정보의 보유 및
        이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
        <br />
        <br />
        <b>제 7장. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</b>
        <br />
        가. 쿠키(cookie)에 대하여
        <br />
        회사는 이용자별 맞춤서비스 등을 제공하기 위하여 쿠키(cookie)를 설치 및 운영합니다. 쿠키의
        사용 목적과 거부에 관한 사항은 아래와 같습니다.
        <br />
        1) 쿠키의 의미: 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주
        작은 텍스트 파일로서 이용자의 컴퓨터에 저장되어 운영됩니다.
        <br />
        2) 쿠키의 사용 목적: 이용자들의 접속관리, 오류 관리, 이용자 별 사용 환경 제공, 이용자
        활동정보 파악, a/b test, 이벤트 및 프로모션 통계 확인 등을 파악하여 최적화된 맞춤형 서비스를
        제공하기 위해 사용합니다.
        <br />
        3) 쿠키의 설치∙운영 및 거부: 설비를 이용함에 있어 이용자는 쿠키 설치에 대한 선택권을 가지고
        있습니다. 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될
        때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 다만 이용자가 쿠키를 거부할
        경우, 서비스 제공에 어려움이 있을 수 있습니다.
        <br />
        <br />
        나. 온라인 맞춤형 광고 서비스에 대하여
        <br />
        회사는 이용자의 온라인 이용 형태, 접속 기록 등을 분석하여 이용자의 특성을 고려한 서비스를
        제공하고자 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집하도록 허용하고 있습니다.
        <br />
        1) 행태정보를 수집 및 처리하는 광고 사업자: 페이스북, 구글, 애플
        <br />
        2) 행태정보 수집 방법: 이용자가 회사의 웹사이트를 방문하거나 앱을 실행할 때 자동 수집
        <br />
        이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을
        설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
        쿠키의 저장을 거부할 수도 있습니다. 단, 이용자께서 쿠키 설치를 거부하였을 경우 회사가
        제공하는 일부 서비스에 어려움이 있을 수 있습니다.
        <br />
        <br />
        <b>제 8장. 개인정보보호를 위한 기술적, 관리적 대책</b>
        <br />
        회원님의 개인정보는 암호화되어 보호되고 있습니다.
        <br />
        회원님 계정은 연동된 본인 명의의 SNS 계정(카카오, 네이버, 애플 등)으로만 접근할 수 있습니다.
        본인 명의의 기기 이외에는 사용 후 반드시 로그아웃하시고 완전 종료하시기를 권장합니다. 이용상
        부주의로 인한 개인정보 유출에 대해서 회사는 책임을 지지 않습니다.
        <br />
        회사는 이용자의 개인정보에 대한 보안을 매우 중요하게 생각합니다. 회사는 이용자 개인정보의
        무단 접근, 공개, 사용 및 수정을 막기 위해 다음과 같은 보안 조치를 구축하고 있습니다.
        <br />
        <br />
        [개인정보의 암호화]
        <br />
        1. 이용자의 개인정보를 암호화된 통신구간을 이용하여 전송
        <br />
        2. 중요 정보는 암호화하여 보관
        <br />
        3. 해킹 등에 대비한 대책 : 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나
        훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치
        <br />
        4. 내부관리계획의 수립 및 시행
        <br />
        5. 접근통제장치의 설치 및 운영
        <br />
        6. 접속기록의 위조, 변조 방지를 위한 조치
        <br />
        7. 개인정보에 대한 접근은 해당 업무 수행자, 업무 수행시 개인정보 취급이 불가피한 자로 제한
        <br />
        <br />
        <b>제 9장. 아동의 개인정보 보호</b>
        <br />
        회사는 원칙적으로 13 세 미만 또는 관할 법률상 이에 상응하는 최소 연령의 어린이로부터 정보를
        수집하지 않습니다. 다만 회사가 부득이 서비스 이용을 위하여 13 세 미만 또는 관할 법률상 이에
        상응하는 최소 연령의 어린이의 개인정보를 수집할 때에는, 어린이 개인정보 보호를 위해 다음과
        같은 절차를 추가적으로 거치게 됩니다.
        <br />
        1. 어린이 개인정보 수집 또는 회사의 제품, 서비스 정보를 어린이에게 직접 발송하기 위한 보호자
        동의 절차 진행
        <br />
        2. 수집한 개인정보 항목, 목적, 공유 여부를 포함한 회사의 어린이 개인정보보호방침에 대해여
        보호자에게 통보
        <br />
        3. 법정대리인에게 해당 아동의 개인정보에 대한 액세스, 개인정보의 정정 또는 삭제,
        개인정보처리의 일시정지, 기존에 제공한 동의의 철회를 요청할 수 있는 권한 부여
        <br />
        4. 서비스 활동 참여에 필수적으로 필요한 것 이외의 개인정보수집 범위 제한
        <br />
        <br />
        <b>제 10장. 광고성 정보의 전송 제한</b>
        <br />
        회사는 고객의 사전 동의 없이 영리목적의 광고성 정보를 전송하지 않습니다.
        <br />
        회사는 상품정보 안내 등 온라인 마케팅을 위해 광고성 정보를 전자우편 등으로 전송하는 경우에는
        광고성 정보 전송에 대한 고객의 사전 동의를 득하고 각 전송수단별로 아래와 같이 고객이 쉽게
        알아 볼 수 있도록 조치합니다.
        <br />
        1. 전자우편: 제목란에 ‘(광고)’라는 문구를 처음에 빈칸없이 한글로 표시하고 이어서 전자우편
        본문란의 주요내용을 표시합니다.
        <br />
        2. 팩스·휴대폰 문자전송 등 전자우편 이외의 방법: 전송자 명칭을 표기하는 등 필요한 조치를
        취합니다.
        <br />
        <br />
        <b>제 11장. 개인정보의 추가적인 제공·이용 판단기준</b>
        <br />
        회사는 관련 법령에 따라 고객의 동의없이 추가적인 개인정보의 이용 또는 제공을 하기 위한
        판단기준은 다음과 같습니다.
        <br />
        1. 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부
        <br />
        2. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측
        가능성이 있는지 여부
        <br />
        3. 정보주체의 이익을 부당하게 침해하는지 여부
        <br />
        4. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
        <br />
        <br />
        <b>제 12장. 개인정보보호 책임자의 소속, 성명 및 연락처</b>
        <br />
        회사 담당부서 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여
        아래와 같이 관련 부서 및 담당자를 지정하고 있습니다. 본 방침에 대하여 의견이 있거나 회사가
        보유한 이용자의 정보를 업데이트하고자 하는 경우, 아래 연락처로 연락 바랍니다.
        <br />
        <br />
        [개인정보보호 책임자]
        <br />
        이름: 홍순일
        <br />
        담당부서: 개발팀
        <br />
        이메일: sunil@mynormal.co
        <br />
        <br />
        기타 개인 정보 침해에 대한 신고나 상담이 필요한 경우, 아래 기관에 문의 가능합니다.
        <br />
        <br /> • 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
        <br /> • 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
        <br /> • 경찰청 사이버안전국 (police.go.kr / 국번없이 182)
        <br />
        <br />
        <b>제 13장. 고지의 의무</b>
        <br />
        정부의 정책 또는 회사의 정책에 따라 개인정보처리방침 내용의 추가, 삭제 및 수정이 있을 시에는
        개정 7일 전부터 홈페이지를 통해 고지할 것입니다. 다만, 이용자 권리의 중요한 변경이 있을
        경우에는 최소 30일 전에 고지합니다.
        <br />
        <br />
        본 개인정보처리방침은 2022년 10월 20일부터 적용되고, 종전의 개인정보처리방침은 본
        개인정보처리방침으로 대체됩니다.
        <br />
        <br />
        공고일자: 2022년 10월 20일
        <br />
        시행일자: 2022년 10월 20일
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  max-width: 428px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: ${COLORS.WHITE};
  z-index: 90000;
`;

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      padding: '75px 21px 20px',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: -0.77,
    },
    content: {
      fontSize: 14,
      lineHeight: 1.8,
      letterSpacing: -0.58,
      padding: '0 20px 20px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      textAlign: 'left',
    },
    img: {
      width: '100%',
      padding: '0 20px',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
    tablePolicy: {
      width: '100%',
      tableLayout: 'fixed',
      wordBreak: 'break-all',
      height: 'auto',
      border: '1px solid black',
      borderCollapse: 'collapse',

      '& tr, td, th': {
        border: '1px solid black',
        fontSize: '12px',
      },
    },
  }),
);
