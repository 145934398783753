import { Children, isValidElement, ReactElement, ReactNode, useMemo, useState } from 'react';

type NonEmptyArray<T> = readonly [T, ...T[]];

interface StepProps<Steps extends NonEmptyArray<string>> {
  name: Steps[number];
  children: ReactNode;
}

interface FunnelProps<Steps extends NonEmptyArray<string>> {
  children: Array<ReactElement<StepProps<Steps>>> | ReactElement<StepProps<Steps>>;
}

const useFunnel = <Steps extends NonEmptyArray<string>>(steps: Steps) => {
  const [step, setStep] = useState(steps[0]);

  const Step = ({ children }: StepProps<Steps>) => {
    return <>{children}</>;
  };

  const Funnel = ({ children }: FunnelProps<Steps>) => {
    const validChildren = Children.toArray(children)
      .filter(isValidElement)
      .filter((child) =>
        steps.includes((child.props as Partial<StepProps<Steps>>).name ?? ''),
      ) as Array<ReactElement<StepProps<Steps>>>;

    const targetStep = validChildren.find((child) => child.props.name === step);

    if (!targetStep) {
      console.log(`${step} 스텝 컴포넌트를 찾지 못했습니다.`);
    }

    return <>{targetStep}</>;
  };

  const FunnelComponent = useMemo(
    () =>
      Object.assign(Funnel, {
        Step,
      }),
    [step],
  );

  return [FunnelComponent, setStep, step] as const;
};

export default useFunnel;
