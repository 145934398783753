import { COLORS } from '@styles/constants/_colors';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  height: number;
}

const EmptySkeleton = ({ height }: Props) => {
  return (
    <Skeleton
      height={height}
      baseColor={COLORS.PRIMITIVES_GRAY_200}
      highlightColor={'#FAFAFA'}
      borderRadius={10}
    />
  );
};

export default EmptySkeleton;
