import React, { useEffect, useLayoutEffect, useState } from 'react';
import Portal from '@components/portal';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import styled from 'styled-components';
import LEVEL from '@styles/constants/_levels';
import { COLORS } from '@styles/constants/_colors';
import AttImg from '@assets/images/modal/photoPermission.png';
import BottomButton from '@components/BottomButton';
import useTopModal from '@hooks/useTopModal';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';

const PhotoRequirementModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { closeModal } = useTopModal();

  const handleSettingOpen = () => {
    sendReactNativeMessage({
      controller: 'link',
      type: 'openSetting',
      service: 'openSetting',
    });
    closeModal(PhotoRequirementModal);
  };

  const handleClose = () => {
    closeModal(PhotoRequirementModal);
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  useEffect(() => {
    if (isOpen) return;
    const timeoutId = setTimeout(() => setIsOpen(true), 100);

    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  return (
    <Portal>
      <DimmedWrapper onClose={handleClose}>
        <ModalField isOpen={isOpen}>
          <ModalBackground>
            <Img src={AttImg} />
            <ButtonBox>
              <BottomButton color="black" onClickEvent={handleSettingOpen}>
                설정하러 가기
              </BottomButton>
            </ButtonBox>
          </ModalBackground>
        </ModalField>
      </DimmedWrapper>
    </Portal>
  );
};

const ModalField = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 50%;
  top: ${({ isOpen }) => (isOpen ? '50%' : '100%')};
  transform: ${({ isOpen }) => (isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, 100%)')};
  display: flex;
  flex-direction: column;
  width: 295px;
  z-index: ${LEVEL.MODAL_BASIC};
  transition: all 200ms ease;
`;

const ModalBackground = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 26px;
  width: 100%;
`;

const Img = styled.img`
  width: 295px;
  height: 321px;
`;

const ButtonBox = styled.div`
  color: ${COLORS.WHITE};
  text-align: center;
  padding: 0px 30px 28px;

  button {
    font-family: 'Cafe24 Ssurround';
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.5px;
  }
`;

export default PhotoRequirementModal;
