import SecurityBiometricRegister from 'components/modal/splashModals/SecurityBiometricRegister';
import SecurityModal from 'components/modal/splashModals/SecurityModal';
import SecurityRegisterModal from 'components/modal/splashModals/SecurityRegisterModal';
import SecurityUnlockModal from 'components/modal/splashModals/SecurityUnlockModal';
import useSplashModal from 'hooks/useSplashModal';
import { ComponentProps, FunctionComponent, useEffect } from 'react';
import SplashModalContainer from './SplashModalContainer';
import { useLocation, useNavigationType } from 'react-router-dom';

export const splashModals = {
  security: SecurityModal as FunctionComponent<ComponentProps<typeof SecurityModal>>,
  securityRegister: SecurityRegisterModal as FunctionComponent<
    ComponentProps<typeof SecurityRegisterModal>
  >,
  securityUnlock: SecurityUnlockModal as FunctionComponent<
    ComponentProps<typeof SecurityUnlockModal>
  >,
  securityBiometricRegister: SecurityBiometricRegister as FunctionComponent<
    ComponentProps<typeof SecurityBiometricRegister>
  >,
};

const SplashModal = () => {
  const { modals, closeModal } = useSplashModal();
  const navigationType = useNavigationType();
  const { pathname } = useLocation();

  useEffect(() => {
    if (navigationType === 'POP') {
      // eslint-disable-next-line react/prop-types
      modals.map(({ Component, props }) => !props.isMaintain && closeModal(Component));
    }
  }, [pathname]);

  return (
    <>
      {modals.map(({ Component, props }, idx) => {
        const key = `splash-modal-${idx}`;
        return (
          <SplashModalContainer key={key}>
            <Component {...props} />
          </SplashModalContainer>
        );
      })}
    </>
  );
};

export default SplashModal;
