import { ComponentProps, FunctionComponent, ReactNode, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { timeoutModalState } from 'states/modalState';

const useTimeoutModal = () => {
  const [modal, setModal] = useRecoilState(timeoutModalState);

  const openModal = useCallback(
    (props: ComponentProps<FunctionComponent<{ children: ReactNode; millisecond: number }>>) => {
      if (modal.isPopup) return;

      setModal({
        isPopup: true,
        props: props,
      });
    },
    [modal, setModal],
  );

  const closeModal = useCallback(() => {
    setModal({
      isPopup: false,
      props: {
        children: null,
        millisecond: 2000,
      },
    });
  }, [setModal]);

  return {
    modal,
    openModal,
    closeModal,
  };
};

export default useTimeoutModal;
