import { FoodDetail } from '@models/searchResult';
import FoodRegisterEditInput from '@pages/search/atomic/input/FoodRegisterEditInput';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import { cx } from '@styles/index';
import { handleDecimalInputChange } from '@utils/numberInputControl';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';

interface Props {
  temp: FoodDetail;
  setTemp: React.Dispatch<React.SetStateAction<FoodDetail>>;
}

const FoodEditNutrient = ({ temp, setTemp }: Props) => {
  const handleInputMap = {
    metric_serving_amount: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleDecimalInputChange(e, 9999.1),
    calories: (e: React.ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e, 10000),
    cholesterol: (e: React.ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e, 10000),
    sodium: (e: React.ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e, 10000),
    potassium: (e: React.ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e, 10000),
    default: (e: React.ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e, 1000),
  } as { [key: string]: (e: React.ChangeEvent<HTMLInputElement>) => string | undefined };

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;

      const inputValue = (handleInputMap[name] || handleInputMap['default'])(e);

      if (inputValue === undefined) return;

      setTemp({ ...temp, [name]: inputValue });
    },
    [temp],
  );

  const onChangeUnit = (unit: 'g' | 'ml') => {
    setTemp({ ...temp, metric_serving_unit: unit });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Title>{'영양 정보를\n수정해 주세요'}</Title>
      <Section>
        <Box>
          <p className="require">
            단위 <span>*</span>
          </p>
          <ButtonContainer>
            <ButtonWrapper
              onClick={() => {
                onChangeUnit('g');
              }}
              className={cx([
                { isActive: temp.metric_serving_unit === 'g' },
                { isInActive: temp.metric_serving_unit !== 'g' },
              ])}
            >
              <button>g</button>
            </ButtonWrapper>
            <ButtonWrapper
              onClick={() => {
                onChangeUnit('ml');
              }}
              className={cx([
                { isActive: temp.metric_serving_unit === 'ml' },
                { isInActive: temp.metric_serving_unit !== 'ml' },
              ])}
            >
              <button>ml</button>
            </ButtonWrapper>
          </ButtonContainer>
        </Box>
        <Box>
          <p className="require">
            내용량 <span>*</span>
          </p>
          <FoodRegisterEditInput
            name="metric_serving_amount"
            mode="decimal"
            placeholder="0"
            unit={temp.metric_serving_unit}
            value={temp.metric_serving_amount}
            onChange={onChangeInput}
          />
        </Box>
      </Section>
      <Divider />
      <Section>
        <Box>
          <p className="require">
            칼로리 <span>*</span>
          </p>
          <FoodRegisterEditInput
            name="calories"
            mode="decimal"
            placeholder="0"
            unit="kcal"
            value={temp.calories}
            onChange={onChangeInput}
          />
        </Box>
      </Section>
      <Divider />
      <Section>
        <Box>
          <p className="require">
            탄수화물 <span>*</span>
          </p>
          <FoodRegisterEditInput
            name="carbohydrate"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.carbohydrate}
          />
        </Box>
        <Box>
          <p>- 당</p>
          <FoodRegisterEditInput
            name="sugar"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.sugar || ''}
          />
        </Box>
        <Box>
          <p>- 대체 감미료</p>
          <FoodRegisterEditInput
            name="sugar_sub"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.sugar_sub || ''}
          />
        </Box>
        <Box>
          <p>- 식이섬유</p>
          <FoodRegisterEditInput
            name="fiber"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.fiber || ''}
          />
        </Box>
      </Section>
      <Divider />
      <Section>
        <Box>
          <p className="require">
            단백질 <span>*</span>
          </p>
          <FoodRegisterEditInput
            name="protein"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.protein}
          />
        </Box>
      </Section>
      <Divider />
      <Section>
        <Box>
          <p className="require">
            지방 <span>*</span>
          </p>
          <FoodRegisterEditInput
            name="fat"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.fat}
          />
        </Box>
        <Box>
          <p>- 포화지방</p>
          <FoodRegisterEditInput
            name="saturated_fat"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.saturated_fat || ''}
          />
        </Box>
        <Box>
          <p>- 트랜스지방</p>
          <FoodRegisterEditInput
            name="trans_fat"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.trans_fat || ''}
          />
        </Box>
        <Box>
          <p>- 다불포화지방</p>
          <FoodRegisterEditInput
            name="polyunsaturated_fat"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.polyunsaturated_fat || ''}
          />
        </Box>
        <Box>
          <p>- 불포화지방</p>
          <FoodRegisterEditInput
            name="monounsaturated_fat"
            mode="decimal"
            placeholder="0"
            unit="g"
            onChange={onChangeInput}
            value={temp.monounsaturated_fat || ''}
          />
        </Box>
      </Section>
      <Divider />
      <Section>
        <Box>
          <p>콜레스테롤</p>
          <FoodRegisterEditInput
            name="cholesterol"
            mode="decimal"
            placeholder="0"
            unit="mg"
            onChange={onChangeInput}
            value={temp.cholesterol || ''}
          />
        </Box>
      </Section>
      <Divider />
      <Section>
        <Box>
          <p>나트륨</p>
          <FoodRegisterEditInput
            name="sodium"
            mode="decimal"
            placeholder="0"
            unit="mg"
            onChange={onChangeInput}
            value={temp.sodium || ''}
          />
        </Box>
      </Section>
      <Divider />
      <Section>
        <Box>
          <p>칼륨</p>
          <FoodRegisterEditInput
            name="potassium"
            mode="decimal"
            placeholder="0"
            unit="mg"
            onChange={onChangeInput}
            value={temp.potassium || ''}
          />
        </Box>
      </Section>
    </>
  );
};

export default FoodEditNutrient;

const Title = styled.div`
  font-family: 'Noto Sans KR';
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.5px;
  white-space: pre-line;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 0px;
`;

const Box = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: Campton, 'Noto Sans KR';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: ${COLORS.PRIMITIVES_GRAY_500};
  }

  & .require {
    color: ${COLORS.BLACK};
    span {
      color: ${FOUNDATION_COLOR.BASIC_RED};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 50%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding-bottom: 1px;
  border-radius: 120px;
  background: ${COLORS.PRIMITIVES_GRAY_60};

  button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 16px;
  }

  &.isActive {
    background: ${FOUNDATION_COLOR.BASIC_BLACK};
    button {
      color: ${FOUNDATION_COLOR.BASIC_WHITE};
    }
  }

  &.isInActive {
    background: ${COLORS.PRIMITIVES_GRAY_60};
    button {
      color: ${COLORS.PRIMITIVES_GRAY_450};
    }
  }
`;

const Divider = styled.div`
  background: ${COLORS.PRIMITIVES_GRAY_BLUE_100};
  height: 1px;
`;
