interface Props {
  color?: string;
  size?: 'small' | 'semi-medium' | 'medium' | 'semi-large' | 'large';
}
export default function Dinner({ color = '#000', size = 'small' }: Props) {
  const fragment = (() => {
    switch (size) {
      case 'small':
        return {
          size: 51,
          icon: (
            <svg width="51" height="51" viewBox="0 0 51 51" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M41.8 28.145c-2.266 6.356-8.34 10.905-15.474 10.905-9.072 0-16.426-7.354-16.426-16.425 0-7.135 4.55-13.208 10.906-15.475m-.425.23.425-.23a16.384 16.384 0 0 0-.951 5.52c0 9.072 7.354 16.425 16.426 16.425 1.936 0 3.794-.335 5.519-.95l-.298.779"
                stroke={color}
                strokeWidth="2.53"
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
      case 'semi-medium':
        return {
          size: 56,
          icon: (
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.0266 7.91302L24.1096 8.2997C24.2698 7.85109 24.1368 7.35016 23.7752 7.04007C23.4136 6.72997 22.8983 6.6749 22.4793 6.90159L23.0266 7.91302ZM46.2609 31.1486L47.3351 31.5592C47.4954 31.1399 47.397 30.6656 47.0831 30.3446C46.7693 30.0237 46.2974 29.9146 45.8745 30.0655L46.2609 31.1486ZM45.1777 30.7623C42.8276 37.3525 36.5305 42.0674 29.1351 42.0674V44.3674C37.5331 44.3674 44.6775 39.0122 47.3441 31.5349L45.1777 30.7623ZM29.1351 42.0674C19.7305 42.0674 12.1065 34.4436 12.1065 25.0393H9.80654C9.80654 35.7139 18.4603 44.3674 29.1351 44.3674V42.0674ZM12.1065 25.0393C12.1065 17.6442 16.8215 11.3473 23.4129 8.99619L22.6403 6.82986C15.1619 9.49727 9.80654 16.6414 9.80654 25.0393H12.1065ZM23.1032 9.17911L23.5739 8.92446L22.4793 6.90159L22.0087 7.15624L23.1032 9.17911ZM21.9436 7.52635C21.2179 9.55881 20.8238 11.7458 20.8238 14.0223H23.1238C23.1238 12.0129 23.4714 10.0873 24.1096 8.2997L21.9436 7.52635ZM20.8238 14.0223C20.8238 24.6969 29.4776 33.3504 40.1524 33.3504V31.0504C30.7478 31.0504 23.1238 23.4266 23.1238 14.0223H20.8238ZM40.1524 33.3504C42.4289 33.3504 44.6159 32.9563 46.6472 32.2318L45.8745 30.0655C44.0877 30.7028 42.1619 31.0504 40.1524 31.0504V33.3504ZM45.1867 30.738L44.8573 31.5997L47.0057 32.4209L47.3351 31.5592L45.1867 30.738Z"
                fill={color}
              />
            </svg>
          ),
        };
      case 'medium':
        return {
          size: 72,
          icon: (
            <svg width="72" height="72" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M59.478 42.048c-3.225 9.044-11.866 15.517-22.019 15.517-12.908 0-23.372-10.464-23.372-23.372 0-10.152 6.474-18.793 15.519-22.02m-.605.328.605-.327a23.314 23.314 0 0 0-1.354 7.855c0 12.908 10.464 23.372 23.372 23.372 2.756 0 5.4-.477 7.854-1.353l-.423 1.108"
                stroke={color}
                strokeWidth="2.9"
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
      case 'semi-large':
        return {
          size: 82,
          icon: (
            <svg
              width="82"
              height="82"
              viewBox="0 0 82 82"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.7174 13.8647L35.2726 14.42C35.5026 13.7758 35.3117 13.0565 34.7924 12.6112C34.2732 12.1659 33.5331 12.0868 32.9315 12.4123L33.7174 13.8647ZM67.739 47.8883L69.2816 48.4779C69.5117 47.8757 69.3705 47.1947 68.9198 46.7338C68.4691 46.2729 67.7914 46.1163 67.1842 46.3329L67.739 47.8883ZM66.1837 47.3336C62.7379 56.996 53.5053 63.909 42.662 63.909V67.2118C54.945 67.2118 65.3945 59.3794 69.2945 48.443L66.1837 47.3336ZM42.662 63.909C28.873 63.909 17.6948 52.7311 17.6948 38.9425H14.3921C14.3921 54.5553 27.049 67.2118 42.662 67.2118V63.909ZM17.6948 38.9425C17.6948 28.0998 24.6079 18.8673 34.2723 15.4202L33.1627 12.3094C22.2248 16.2107 14.3921 26.6598 14.3921 38.9425H17.6948ZM33.8141 15.69L34.5033 15.3172L32.9315 12.4123L32.2424 12.7852L33.8141 15.69ZM32.1622 13.3095C31.1008 16.2821 30.5245 19.4809 30.5245 22.8105H33.8272C33.8272 19.8644 34.3368 17.041 35.2726 14.42L32.1622 13.3095ZM30.5245 22.8105C30.5245 38.4232 43.1814 51.0798 58.7945 51.0798V47.777C45.0054 47.777 33.8272 36.5991 33.8272 22.8105H30.5245ZM58.7945 51.0798C62.1241 51.0798 65.3228 50.5034 68.2938 49.4437L67.1842 46.3329C64.5643 47.2674 61.7407 47.777 58.7945 47.777V51.0798ZM66.1965 47.2987L65.7142 48.5604L68.7993 49.7396L69.2816 48.4779L66.1965 47.2987Z"
                fill={color}
              />
            </svg>
          ),
        };
      case 'large':
        return {
          size: 100,
          icon: (
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.1191 16.9082L42.7201 17.4798C42.9569 16.8166 42.7603 16.0761 42.2258 15.6177C41.6912 15.1593 40.9294 15.0779 40.3101 15.413L41.1191 16.9082ZM82.6089 58.4003L84.1968 59.0073C84.4338 58.3874 84.2884 57.6863 83.8244 57.2118C83.3605 56.7373 82.6628 56.5762 82.0378 56.7991L82.6089 58.4003ZM81.0077 57.8293C76.7624 69.7338 65.3874 78.2517 52.0271 78.2517V81.6517C66.8695 81.6517 79.4971 72.1873 84.2101 58.9714L81.0077 57.8293ZM52.0271 78.2517C35.0379 78.2517 21.2654 64.4796 21.2654 47.4909H17.8654C17.8654 66.3574 33.1602 81.6517 52.0271 81.6517V78.2517ZM21.2654 47.4909C21.2654 34.1311 29.7834 22.7564 41.6902 18.5094L40.548 15.307C27.3301 20.0216 17.8654 32.6488 17.8654 47.4909H21.2654ZM41.0877 18.8581L41.9281 18.4034L40.3101 15.413L39.4697 15.8678L41.0877 18.8581ZM39.5181 16.3366C38.2357 19.9283 37.5392 23.7936 37.5392 27.8177H40.9392C40.9392 24.1884 41.567 20.7096 42.7201 17.4798L39.5181 16.3366ZM37.5392 27.8177C37.5392 46.6842 52.8339 61.9785 71.7009 61.9785V58.5785C54.7116 58.5785 40.9392 44.8063 40.9392 27.8177H37.5392ZM71.7009 61.9785C75.725 61.9785 79.5902 61.282 83.18 60.0015L82.0378 56.7991C78.8094 57.9507 75.3303 58.5785 71.7009 58.5785V61.9785ZM81.0209 57.7934L80.4328 59.332L83.6087 60.546L84.1968 59.0073L81.0209 57.7934Z"
                fill={color}
              />
            </svg>
          ),
        };
    }
  })();
  return <span style={{ height: fragment.size }}>{fragment.icon}</span>;
}
