import { ChangeEvent, ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import FoodRegisterInputWrapper from './FoodRegisterInputWrapper';

interface Props {
  unit: string;
  title?: string;
  value: any;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  mode?: 'none' | 'text' | 'search' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined;
  max?: number;
}

const Container = styled.div`
  display: flex;
  /* align-items: stretch; */
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 60px;
  padding: 18px 23px;

  & .unit {
    font-family: 'Campton';
    font-weight: 500;
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: -0.5px;
    color: ${COLORS.PRIMITIVES_GRAY_450};
  }

  input {
    border: none;
    outline: none;
    resize: none;
    padding: 0;
    width: calc(100% - 40px);

    font-family: 'Campton';
    font-weight: 500;
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: -0.5px;
    min-height: 24px;
    height: 24px;

    &::placeholder {
      font-family: 'Campton';
      color: ${COLORS.PRIMITIVES_GRAY_450};
    }
  }

  & .small {
    height: 52px;
  }
`;

const FoodRegisterUnitInput = ({
  unit,
  title,
  value,
  onChange,
  placeholder = '',
  mode,
  max,
}: Props) => {
  return (
    <FoodRegisterInputWrapper title={title}>
      <Container>
        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          inputMode={mode}
          maxLength={max}
        />
        <span className="unit">{unit}</span>
      </Container>
    </FoodRegisterInputWrapper>
  );
};

export default FoodRegisterUnitInput;
