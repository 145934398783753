interface Props {
  size?: 'large' | 'semiLarge' | 'medium' | 'small' | 'default';
  color?: string;
  onClick?: () => void;
}
export default function Add({ size = 'large', color = '#000', onClick = () => undefined }: Props) {
  const icon = (() => {
    switch (size) {
      case 'large':
        return (
          <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h40v40H0z" />
              <g stroke={color} strokeWidth="2.2">
                <path d="M2 20h36M20 38V2" />
              </g>
            </g>
          </svg>
        );
      case 'semiLarge':
        return (
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.70068 18.0002H33.3007"
              stroke={color}
              strokeWidth="4.3"
              strokeLinecap="round"
            />
            <path
              d="M18.0005 33.3007V2.70068"
              stroke={color}
              strokeWidth="4.3"
              strokeLinecap="round"
            />
          </svg>
        );
      case 'medium':
        return (
          <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h25v25H0z" />
              <g stroke={color} strokeWidth="1.6">
                <path d="M1.25 12.5h22.5M12.5 23.75V1.25" />
              </g>
            </g>
          </svg>
        );
      case 'small':
        return (
          <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h17v17H0z" />
              <g stroke={color} strokeWidth="1.6">
                <path d="M.85 8.5h15.3M8.5 16.15V.85" />
              </g>
            </g>
          </svg>
        );
      case 'default':
        return (
          <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h36v36H0z" />
              <g stroke={color} strokeWidth="2">
                <path d="M1.8 18h32.4M18 34.2V1.8" />
              </g>
            </g>
          </svg>
        );
    }
  })();

  return <span onClick={onClick}>{icon}</span>;
}
