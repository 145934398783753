import styled from 'styled-components';

interface Props {
  color: string;
  background: string;
  children: React.ReactNode;
}

const Container = styled.li<Omit<Props, 'children'>>`
  display: flex;
  align-items: center;
  height: 18px;
  justify-content: center;
  padding: 0px 4px 4px;
  background: ${(props) => props.background};
  border-radius: 4px;

  & span {
    padding-top: 3px;
    font-family: 'Campton';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: ${(props) => props.color};
  }

  & p {
    padding-top: 2px;
    font-family: 'Noto Sans KR';
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: ${(props) => props.color};
  }
`;

const FoodTag = ({ background, color, children }: Props) => {
  return (
    <Container background={background} color={color}>
      {children}
    </Container>
  );
};

export default FoodTag;
