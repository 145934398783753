import axios from 'axios';
import { BlockMapType } from 'react-notion';

export async function getNotionPage(notionPageId: string): Promise<BlockMapType> {
  const url = `/v1/page/${notionPageId}`;
  const { data } = await notionApiAxios.get<BlockMapType>(url);

  return data;
}

const notionApiAxios = axios.create({
  baseURL: 'https://notion-api.splitbee.io',
});

notionApiAxios.interceptors.request.use((config) => {
  config.headers.Pragma = 'no-cache';

  return config;
});
