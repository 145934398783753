import { LightningIcon } from '@assets/svgs/_Icons';
import RoundCheckMotion from '@components/Atoms/RoundCheckMotion/RoundCheckMotion';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import useTimeoutModal from '@hooks/useTimeoutModal';
import useToastModal from '@hooks/useToastModal';
import { FoodBasketCartItem, FoodDetail } from '@models/searchResult';
import { calculateNumberPerUnit } from '@modules/calorieCalculator';
import { handleRound } from '@pages/basket/components/utils/foodCalculate';
import useFoodQuickAmount from '@pages/search/hooks/useFoodQuickAmount';
import { foodBasketListState } from '@states/BasketState';
import { foodDetailAmountState, foodDetailMetricTypeState } from '@states/FoodState';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

interface Props {
  item: FoodDetail;
  onClickAdd: () => void;
  onClose: () => void;
}

const FoodQuickButtons = ({ item, onClickAdd, onClose }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sendAdBrixData } = useAdBrix();
  const mealId = location.pathname.split('/')[3];

  const { openModal } = useToastModal();
  const { openModal: timeoutOpenModal } = useTimeoutModal();
  const { amount, servingAmount } = useFoodQuickAmount(Number(item.metric_serving_amount ?? 0));

  const type = useRecoilValue(foodDetailMetricTypeState);
  const count = useRecoilValue(foodDetailAmountState);
  const setBasket = useSetRecoilState(foodBasketListState);

  const onClickDetail = () => {
    const isRecipe = item.creator === 3;
    const path = isRecipe
      ? `/recipe/detail/${mealId}/${item.food_idx}`
      : `/search/detail/${mealId}/${item.food_idx ?? 0}`;
    navigate(path);
    onClose();
  };

  const handleCheckFood = () => {
    const CALORIES = item.calories ?? 0;
    const totalKcal = calculateNumberPerUnit(amount, CALORIES);

    if (count === 0) {
      openModal({ children: <p>섭취량 입력이 잘못 되었어요</p> });
      return false;
    }

    if (totalKcal >= 10000) {
      openModal({ children: <p>10,000kcal 이상은 기록 불가해요</p> });
      return false;
    }

    return true;
  };

  const handleNewItem = () => {
    const newItem = {
      ...item,
      save_type: type === 'serving' ? 0 : 1,
      number_of_units: handleRound(amount),
      metric_serving_amount: servingAmount,
      temp_number_of_unit: amount,
      menu_reg_idx: -1,

      /* 영양소 */
      calories: calculateNumberPerUnit(amount, item?.calories ?? 0),
      carbohydrate: calculateNumberPerUnit(amount, item?.carbohydrate ?? 0),
      net_carbohydrate: calculateNumberPerUnit(amount, item?.net_carbohydrate ?? 0),
      sugar: calculateNumberPerUnit(amount, item?.sugar ?? 0),
      sugar_sub: calculateNumberPerUnit(amount, item?.sugar_sub ?? 0),
      fiber: calculateNumberPerUnit(amount, item?.fiber ?? 0),
      protein: calculateNumberPerUnit(amount, item?.protein ?? 0),
      fat: calculateNumberPerUnit(amount, item?.fat ?? 0),
      saturated_fat: calculateNumberPerUnit(amount, item?.saturated_fat ?? 0),
      monounsaturated_fat: calculateNumberPerUnit(amount, item?.monounsaturated_fat ?? 0),
      polyunsaturated_fat: calculateNumberPerUnit(amount, item?.polyunsaturated_fat ?? 0),
      trans_fat: calculateNumberPerUnit(amount, item?.trans_fat ?? 0),
      sodium: calculateNumberPerUnit(amount, item?.sodium ?? 0),
      cholesterol: calculateNumberPerUnit(amount, item?.cholesterol ?? 0),
      potassium: calculateNumberPerUnit(amount, item?.potassium ?? 0),
    } as FoodBasketCartItem;
    return newItem;
  };

  const handleAddBasket = () => {
    const newItem = handleNewItem();
    setBasket((prev) => {
      const itemExists = prev.some((item) => item.food_idx === newItem.food_idx);

      if (itemExists) {
        return prev.map((item) => (item.food_idx === newItem.food_idx ? newItem : item));
      }
      return [...prev, newItem];
    });

    timeoutOpenModal({ children: <RoundCheckMotion />, millisecond: 2000 });
    onClickAdd();
    onClose();
  };

  const onClickQuick = () => {
    const isCheck = handleCheckFood();

    if (isCheck) {
      handleAddBasket();
      sendAdBrixFoodQuick();
    }
  };

  const sendAdBrixFoodQuick = () => {
    sendAdBrixData('customEvent', {
      eventKey: 'add_food_quick',
    });
  };

  return (
    <ButtonsBox>
      <button onClick={onClickDetail} className="detail-button">
        <p>음식 상세</p>
      </button>
      <button onClick={onClickQuick} className="quick-button">
        <LightningIcon />
        <p>빠른 추가</p>
      </button>
    </ButtonsBox>
  );
};

const ButtonsBox = styled.div`
  display: flex;
  gap: 9px;
  padding: 12px;

  .detail-button {
    min-width: 104px;
    background-color: ${COLORS.BLACK};
  }

  .quick-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
    background-color: ${FOUNDATION_COLOR.BASIC_BLUE};
  }

  button {
    border-radius: 120px;
    height: 54px;

    color: ${COLORS.WHITE};
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.6px;
  }

  p {
    padding-bottom: 1px;
  }
`;

export default FoodQuickButtons;
