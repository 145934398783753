import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { FoodBasketCartItem } from '@models/searchResult';
import { getRecentRegisterFood } from '@modules/storage/food/foodLocalStorage';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FoodResultNone from './FoodResultNone';
import FoodResultRecentlyWrapper from './FoodResultRecentlyWrapper';

const FoodResultRecently = () => {
  const navigate = useNavigate();
  const { mealId } = useParams() as { mealId: string };
  const { data: user } = useUserInfoQuery();

  const { data } = useQuery(
    ['#food', '#search', '#recently', user?.member_idx ?? 0],
    () => getRecentRegisterFood(user?.member_idx ?? 0),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const onClick = (food: FoodBasketCartItem) => {
    const isRecipe = food.creator === 3;
    const URL = isRecipe
      ? `/recipe/detail/${mealId}/${food.food_idx}`
      : `/search/detail/${mealId}/${food.food_idx}`;
    navigate(URL);
  };

  const items = data?.map((item, index) => {
    const hasBrand = !!item.food_brand && item.food_brand !== '0';
    return (
      <ItemBox key={index} onClick={() => onClick(item)}>
        <p className="name">{item.food_name}</p>
        {hasBrand && <p className="brand">{item.food_brand}</p>}
      </ItemBox>
    );
  });

  if (!data || data.length === 0) {
    return (
      <FoodResultRecentlyWrapper>
        <FoodResultNone paddingTop={221} text={'최근 먹은 음식이 없어요'} />
      </FoodResultRecentlyWrapper>
    );
  }

  return (
    <FoodResultRecentlyWrapper>
      <ItemField>{items}</ItemField>
    </FoodResultRecentlyWrapper>
  );
};

const ItemField = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 12px;
  padding: 0 20px;
`;

const ItemBox = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 14px 11px;
  height: 36px;
  border-radius: 120px;
  background-color: ${COLORS.WHITE};

  .name {
    max-width: 109px;
    text-align: center;
    font-family: Campton, Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: ${COLORS.BLACK};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .brand {
    max-width: 84px;
    font-size: 11px;
    font-family: Campton, Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: ${FOUNDATION_COLOR.PRIMARY_GRAY};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default FoodResultRecently;
