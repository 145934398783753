import {
  FastingRecordFormType,
  FastingRecordResponseType,
  FastingRoutineFormType,
  FastingRoutineRequestType,
  FastingRoutineResponseType,
  FastingStatisticsResponseType,
  FastingStopwatchPostType,
  FastingStopwatchResponseType,
  FastingStopwatchUpdateType,
  FastingTimerFormType,
  FastingTimerResponseType,
} from '@models/fasintg';
import { get, post, del, patch } from '@modules/HttpWebClient/default';

/**
 * 단식 타이머 조회 api
 */
export async function getFastingTimerList(memberIdx: number) {
  const { data } = await get<
    FastingTimerResponseType | FastingRoutineResponseType | FastingStopwatchResponseType
  >(`/members/${memberIdx}/fasting/timer`);
  return data;
}

/**
 * 일회용 단식 타이머 생성 api
 */
interface RequestFastingTimerType {
  memberIdx: number;
  fastingTimerForm: FastingTimerFormType;
}
export async function postingTimer(request: RequestFastingTimerType) {
  const { data } = await post<FastingTimerFormType>(
    `/members/${request.memberIdx}/fasting/timer/onetime`,
    { ...request.fastingTimerForm },
  );
  return data;
}

/**
 * 일회용 단식 타이머 삭제 api
 */
export async function deleteTimer(memberIdx: number) {
  const { data } = await del(`/members/${memberIdx}/fasting/timer/onetime`);

  return data;
}

/**
 * 일회용 단식 타이머 수정 api
 */
export async function updateTimer(request: RequestFastingTimerType) {
  const { data } = await patch(`/members/${request.memberIdx}/fasting/timer/onetime`, {
    ...request.fastingTimerForm,
  });
  return data;
}

/**
 * 스톱워치 타이머 생성 api
 */
interface RequestFastingStopwatchType {
  memberIdx: number;
  fastingStopwatchForm: FastingStopwatchPostType;
}
export async function postingStopwatch(request: RequestFastingStopwatchType) {
  const { data } = await post(`/members/${request.memberIdx}/fasting/timer/stopwatch`, {
    ...request.fastingStopwatchForm,
  });
  return data;
}

/**
 * 스톱워치 타이머 삭제 api
 */
export async function deleteStopwatch(memberIdx: number) {
  const { data } = await del(`/members/${memberIdx}/fasting/timer/stopwatch`);

  return data;
}

/**
 * 스톱워치 타이머 수정 api
 */
interface RequestFastingStopwatchUpdateType {
  memberIdx: number;
  fastingStopwatchForm: FastingStopwatchUpdateType;
}
export async function updateStopwatch(request: RequestFastingStopwatchUpdateType) {
  const { data } = await patch(`/members/${request.memberIdx}/fasting/timer/stopwatch`, {
    ...request.fastingStopwatchForm,
  });

  return data;
}

/**
 * 요일별 루틴 타이머 생성 api
 */
interface RequestFastingRoutineTimerType {
  memberIdx: number;
  fastingRoutineForm: FastingRoutineRequestType[];
}
export async function postingRoutineTimer(request: RequestFastingRoutineTimerType) {
  const { data } = await post(`/members/${request.memberIdx}/fasting/timer/routine`, [
    ...request.fastingRoutineForm,
  ]);
  return data;
}

/**
 * 요일별 루틴 타이머 수정 api
 */
interface RequestFastingRoutineTimerUpdateType {
  memberIdx: number;
  tagIdx: number;
  fastingRoutineForm: FastingRoutineFormType;
}
export async function updateRoutineTimer(request: RequestFastingRoutineTimerUpdateType) {
  const { data } = await patch(
    `/members/${request.memberIdx}/fasting/timer/routine/${request.tagIdx}`,
    {
      ...request.fastingRoutineForm,
    },
  );
  return data;
}

/**
 * 요일별 루틴 타이머 삭제 api
 * 전체삭제시 tag idx 를  0 으로 보내기
 */
export async function deleteRoutineTimer({
  memberIdx,
  tagIdx,
}: {
  memberIdx: number;
  tagIdx: number;
}) {
  const { data } = await del(`/members/${memberIdx}/fasting/timer/routine/${tagIdx}`);

  return data;
}

/**
 * 요일별 루틴 유저 종료 체크 api
 */
export async function checkRoutineEnd({
  memberIdx,
  tagIdx,
  day,
  noti_check,
}: {
  memberIdx: number;
  tagIdx: number;
  day: string;
  noti_check: number;
}) {
  const { data } = await patch(`/members/${memberIdx}/fasting/timer/routine/noti/${tagIdx}`, {
    day,
    noti_check,
  });
  return data;
}

/**
 * 단식 기록 생성 api
 */
export async function postingFastingRecord({
  memberIdx,
  recordForm,
}: {
  memberIdx: number;
  recordForm: FastingRecordFormType;
}) {
  const { data } = await post(`/members/${memberIdx}/fasting`, {
    ...recordForm,
  });
  return data;
}

/**
 * 단식 기록 조회 api
 */
export async function getTimerRecordList({
  memberIdx,
  createdAt,
}: {
  memberIdx: number;
  createdAt: string;
}) {
  const { data } = await get<FastingRecordResponseType[]>(
    `/members/${memberIdx}/fasting/${createdAt}`,
  );
  return data;
}

/**
 * 단식 기록 삭제 api
 */
export async function deleteFastingRecord({
  memberIdx,
  regIdx,
}: {
  memberIdx: number;
  regIdx: string;
}) {
  const { data } = await del(`/members/${memberIdx}/fasting/${regIdx}`);
  return data;
}

/**
 * 단식 기록 수정 api
 */
export async function updateFastingRecord({
  memberIdx,
  regIdx,
  recordForm,
}: {
  memberIdx: number;
  regIdx: string;
  recordForm: Omit<FastingRecordFormType, 'fasting_method'>;
}) {
  const { data } = await patch(`/members/${memberIdx}/fasting/${regIdx}`, {
    ...recordForm,
  });
  return data;
}

/**
 * 단식 통계 조회 api
 */
/**
 * 단식 기록 조회 api
 */
export async function getStatisticsList({
  memberIdx,
  type,
  createdAt,
}: {
  memberIdx: number;
  type: string;
  createdAt: string;
}) {
  const { data } = await get<FastingStatisticsResponseType>(
    `/members/${memberIdx}/statistics/fasting/${type}/${createdAt}`,
  );
  return data;
}
