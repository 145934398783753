import { Children, isValidElement, ReactNode } from 'react';
import { getTabHeaderType, TabHeaderForSpinner } from './TabHeaderForSpinner';
import styled from 'styled-components';
import SpinnerInBox from '@components/SpinnerInBox';

interface Props {
  children?: React.ReactNode;
}

const getChildren = (children: ReactNode, type: any) => {
  const childrenArray = Children.toArray(children);
  return childrenArray.filter((child) => isValidElement(child) && child.type === type).slice(0, 2);
};

const FallBackSpinner = ({ children }: Props) => {
  const tabHeader = getChildren(children, getTabHeaderType);

  return (
    <Container>
      {tabHeader && tabHeader}
      <SpinnerInBox height={'100vh'} />
    </Container>
  );
};

export default Object.assign(FallBackSpinner, {
  TabHeader: TabHeaderForSpinner,
});

const Container = styled.div`
  width: 100vw;
  max-width: 428px;
  height: 100dvh;
`;
