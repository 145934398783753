import { Grid, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  active: boolean;
  size: 'medium' | 'small';
}

export default function PurpleSmileWithStar({ active, size }: Props) {
  const classes = useStyles();

  const fragment = (() => {
    switch (size) {
      case 'medium':
        return {
          icon: active ? (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="27.5" cy="29.3438" r="27.5" fill="#AAA5FA" />
              <path
                d="M21.6713 34.8354C22.3182 38.1037 24.9503 39.8409 27.7072 39.8409C30.7624 39.8409 33.1668 38.1137 33.757 34.8414"
                stroke="black"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <ellipse cx="18.5137" cy="26.7344" rx="2.75" ry="2.76563" fill="black" />
              <path
                d="M37.335 23.9688L34.1084 27.6562H40.1006"
                stroke="black"
                strokeWidth="2.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g clipPath="url(#clip0_100_2995)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M41.9647 15.606C43.8476 14.4458 45.3719 13.1051 46.5375 11.5839C47.703 10.0627 48.8446 7.78313 49.9622 4.74512C50.9301 7.52748 52.0537 9.80708 53.3331 11.5839C54.6125 13.3607 56.1994 14.7014 58.0938 15.606C56.237 16.8342 54.6939 18.3182 53.4646 20.0581C52.2352 21.798 51.0901 23.9342 50.0293 26.4669C49.1271 23.9095 48.0663 21.7732 46.8468 20.0581C45.6274 18.343 44 16.859 41.9647 15.606Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_100_2995">
                  <rect
                    width="16.225"
                    height="22.0196"
                    fill="white"
                    transform="translate(41.9 4.6001)"
                  />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="27.5" cy="29.3438" r="27.5" fill="#E6E7EA" />
              <path
                d="M21.6713 34.8354C22.3182 38.1037 24.9503 39.8409 27.7072 39.8409C30.7624 39.8409 33.1668 38.1137 33.757 34.8414"
                stroke="#999FAB"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <ellipse cx="18.5137" cy="26.7344" rx="2.75" ry="2.76563" fill="#999FAB" />
              <path
                d="M37.335 23.9688L34.1084 27.6562H40.1006"
                stroke="#999FAB"
                strokeWidth="2.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g clipPath="url(#clip0_100_2997)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M41.9647 15.606C43.8476 14.4458 45.3719 13.1051 46.5375 11.5839C47.703 10.0627 48.8446 7.78313 49.9622 4.74512C50.9301 7.52748 52.0537 9.80708 53.3331 11.5839C54.6125 13.3607 56.1994 14.7014 58.0938 15.606C56.237 16.8342 54.6939 18.3182 53.4646 20.0581C52.2352 21.798 51.0901 23.9342 50.0293 26.4669C49.1271 23.9095 48.0663 21.7732 46.8468 20.0581C45.6274 18.343 44 16.859 41.9647 15.606Z"
                  fill="#999FAB"
                />
              </g>
              <defs>
                <clipPath id="clip0_100_2997">
                  <rect
                    width="16.225"
                    height="22.0196"
                    fill="white"
                    transform="translate(41.9 4.6001)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14.9153" cy="15.9153" r="14.9153" fill="#AAA5FA" />
              <path
                d="M11.7542 18.8936C12.105 20.6662 13.5326 21.6084 15.0279 21.6084C16.6849 21.6084 17.989 20.6716 18.3092 18.8968"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="10.0415" cy="14.5" rx="1.49153" ry="1.5" fill="black" />
              <path
                d="M20.2495 13L18.4995 15H21.7495"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g clipPath="url(#clip0_100_2998)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.7613 8.46437C23.7825 7.83511 24.6092 7.10795 25.2414 6.2829C25.8736 5.45786 26.4928 4.22146 27.0989 2.57373C27.6239 4.08281 28.2333 5.3192 28.9272 6.2829C29.6211 7.24661 30.4818 7.97376 31.5093 8.46437C30.5022 9.13051 29.6652 9.93541 28.9985 10.8791C28.3317 11.8227 27.7107 12.9814 27.1353 14.355C26.646 12.9679 26.0706 11.8093 25.4092 10.8791C24.7478 9.94885 23.8652 9.14395 22.7613 8.46437Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_100_2998">
                  <rect
                    width="8.8"
                    height="11.9429"
                    fill="white"
                    transform="translate(22.7255 2.49512)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
      default:
        return {
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14.9153" cy="15.9153" r="14.9153" fill="#AAA5FA" />
              <path
                d="M11.7542 18.8936C12.105 20.6662 13.5326 21.6084 15.0279 21.6084C16.6849 21.6084 17.989 20.6716 18.3092 18.8968"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="10.0415" cy="14.5" rx="1.49153" ry="1.5" fill="black" />
              <path
                d="M20.2495 13L18.4995 15H21.7495"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g clipPath="url(#clip0_100_2998)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.7613 8.46437C23.7825 7.83511 24.6092 7.10795 25.2414 6.2829C25.8736 5.45786 26.4928 4.22146 27.0989 2.57373C27.6239 4.08281 28.2333 5.3192 28.9272 6.2829C29.6211 7.24661 30.4818 7.97376 31.5093 8.46437C30.5022 9.13051 29.6652 9.93541 28.9985 10.8791C28.3317 11.8227 27.7107 12.9814 27.1353 14.355C26.646 12.9679 26.0706 11.8093 25.4092 10.8791C24.7478 9.94885 23.8652 9.14395 22.7613 8.46437Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_100_2998">
                  <rect
                    width="8.8"
                    height="11.9429"
                    fill="white"
                    transform="translate(22.7255 2.49512)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}

const useStyles = makeStyles(() => createStyles({}));
