import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AdContent } from '@models/ad';
import { useMutation } from 'react-query';
import { countClickBanner } from '@apis/adApi';

interface Props {
  contents: AdContent;
}

const SubBanner = ({ contents }: Props) => {
  const navigate = useNavigate();
  const { link, img, ad_idx } = contents;

  const { mutate: postPromotionClick } = useMutation((ad_idx: number) => countClickBanner(ad_idx));

  const handleBannerClick = () => {
    const isExternal = link.includes('http') ? true : false;

    if (isExternal) {
      window.open(link, '_blank');
    } else {
      navigate(link);
      scrollTo(0, 0);
    }

    postPromotionClick(ad_idx);
  };

  return <ImageUnit src={img} onClick={handleBannerClick} />;
};

const ImageUnit = styled.img`
  width: 100%;
  height: 100%;
  vertical-align: bottom;
`;

export default SubBanner;
