import { FoodDetail } from '@models/searchResult';
import FoodTag from '@pages/search/atomic/Tag/FoodTag';
import { handleFoodItemTag } from '@pages/search/atomic/Tag/handleTagColor';
import { COLORS } from '@styles/constants/_colors';
import { useMemo } from 'react';
import styled from 'styled-components';

interface Props {
  item: FoodDetail;
}

const FoodQuickHeader = ({ item }: Props) => {
  const { food_name, food_brand, food_hit, creator } = item;
  const {
    background: tagBackground,
    color: tagColor,
    element: tagElement,
  } = handleFoodItemTag(food_hit ?? 0);
  const isUser = creator === 2;
  const isRecipe = creator === 3;

  const tags = useMemo(() => {
    if (isRecipe) {
      return (
        <FoodTag color={COLORS.PRIMITIVES_NAVY_250} background="#D7DDE5">
          <p>나만의 레시피</p>
        </FoodTag>
      );
    }

    return (
      <>
        <FoodTag color={tagColor} background={tagBackground}>
          {tagElement}
        </FoodTag>
        {isUser && (
          <FoodTag color={COLORS.PRIMITIVES_NAVY_250} background="#D7DDE5">
            <p>유저등록</p>
          </FoodTag>
        )}
      </>
    );
  }, [item]);

  return (
    <TitleField>
      <TagBox>{tags}</TagBox>
      <TitleUnit>
        {food_name ?? ''}
        <span className="brand">{food_brand ?? ''}</span>
      </TitleUnit>
    </TitleField>
  );
};

const TitleField = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 20px 22px;
  gap: 12px;
`;

const TagBox = styled.ul`
  display: flex;
  gap: 8px;
`;

const TitleUnit = styled.p`
  font-family: 'Campton', Noto Sans KR;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.88px;

  .brand {
    position: relative;
    top: -3px;
    margin-left: 8px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${COLORS.PRIMITIVES_GRAY_450};
    text-align: center;
    word-break: break-all;
  }
`;

export default FoodQuickHeader;
