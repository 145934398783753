const COLORS = {
  PRIMITIVES_GRAY_600: '#6D727B',
  PRIMITIVES_GRAY_550: '#8D949F',
  PRIMITIVES_GRAY_500: '#999FAB',
  PRIMITIVES_GRAY_450: '#BBBCC0',
  PRIMITIVES_GRAY_400: '#D0D2D6',
  PRIMITIVES_GRAY_350: '#D6D9DD',
  PRIMITIVES_GRAY_300: '#E6E7EA',
  PRIMITIVES_GRAY_200: '#EAECEE',
  PRIMITIVES_GRAY_150: '#E8ECEE',
  PRIMITIVES_GRAY_100: '#EEF1F3',
  PRIMITIVES_GRAY_70: '#EFF0F2',
  PRIMITIVES_GRAY_60: '#F3F4F5',
  PRIMITIVES_GRAY_50: '#F5F6F7',

  PRIMITIVES_BLUE_400: '#007AFF',
  PRIMITIVES_BLUE_100: '#CCE4FF',
  PRIMITIVES_BLUE_50: '#E5EFFD',

  PRIMITIVES_GRAY_BLUE_300: '#D3DBE0',
  PRIMITIVES_GRAY_BLUE_100: '#ECF0F2',
  PRIMITIVES_GRAY_BLUE_50: '#F7F9FB',

  PRIMITIVES_SKY_BLUE_700: '#4E8796',
  PRIMITIVES_SKY_BLUE_500: '#69B9C4',
  PRIMITIVES_SKY_BLUE_400: '#82C8D2',

  PRIMITIVES_SORA_550: '#4A85BC',
  PRIMITIVES_SORA_500: '#669ACB',
  PRIMITIVES_SORA_400: '#7FABD4',

  PRIMITIVES_PURPLE_700: '#5B689F',
  PRIMITIVES_PURPLE_600: '#7584C3',
  PRIMITIVES_PURPLE_500: '#7283CB',
  PRIMITIVES_PURPLE_400: '#8796D7',
  PRIMITIVES_PURPLE_300: '#A5B0E1',

  PRIMITIVES_LIGHT_PURPLE_500: '#867FF8',
  PRIMITIVES_LIGHT_PURPLE_400: '#AAA5FA',

  PRIMITIVES_LINEAR_BLUE: 'linear-gradient(99.56deg, #82C8D2 1.2%, #867FF8 115.9%);',

  PRIMITIVES_PINK_500: '#F2455A',
  PRIMITIVES_PINK_400: '#FA5064',
  PRIMITIVES_PINK_300: '#FC8AA6',
  PRIMITIVES_PINK_200: '#FC8AA6',

  PRIMITIVES_ORANGE_700: '#E46A28',
  PRIMITIVES_ORANGE_650: '#F17836',
  PRIMITIVES_ORANGE_600: '#F88648',
  PRIMITIVES_ORANGE_500: '#FA8C50',
  PRIMITIVES_ORANGE_400: '#FFA064',
  PRIMITIVES_ORANGE_50: '#FFE2D0',

  PRIMITIVES_APRICOT_400: '#FFA37C',
  PRIMITIVES_APRICOT_500: '#FF8A59',
  PRIMITIVES_APRICOT_600: '#F37040',

  PRIMITIVES_NAVY_600: '#131D2D',
  PRIMITIVES_NAVY_500: '#252F40',
  PRIMITIVES_NAVY_480: '#0F2442',
  PRIMITIVES_NAVY_450: '#1C3558',
  PRIMITIVES_NAVY_400: '#284164',
  PRIMITIVES_NAVY_350: '#4B5D78',
  PRIMITIVES_NAVY_300: '#5E708B',
  PRIMITIVES_NAVY_250: '#697C9A',

  PRIMITIVES_BLUE_NAVY_350: '#355E98',
  PRIMITIVES_BLUE_NAVY_400: '#36598A',
  PRIMITIVES_BLUE_NAVY_450: '#22487D',
  PRIMITIVES_BLUE_NAVY_500: '#16396B',
  PRIMITIVES_BLUE_NAVY_600: '#092C5B',

  PRIMITIVES_GREEN_500: '#30CE7C',
  PRIMITIVES_GREEN_400: '#50DC82',
  PRIMITIVES_GREEN_350: '#4FE685',

  PRIMITIVES_KHAKI_400: '#4F7955',
  PRIMITIVES_KHAKI_450: '#3A6741',
  PRIMITIVES_KHAKI_500: '#2D4E31',

  PRIMITIVES_LIGHT_GREEN_500: '#97E27A',
  PRIMITIVES_LIGHT_GREEN_450: '#9CE376',
  PRIMITIVES_LIGHT_GREEN_400: '#AFEB8C',

  PRIMITIVES_YELLOW_GREEN_500: '#C1D865',
  PRIMITIVES_YELLOW_GREEN_400: '#D2E682',

  PRIMITIVES_YELLOW_500: '#FFCD00',
  PRIMITIVES_YELLOW_450: '#FFF384',
  PRIMITIVES_YELLOW_400: '#FEFF92',

  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLUE: '#007AFF',
  RED: '#FF4800',
} as const;

const FOUNDATION_COLOR = {
  BASIC_WHITE: COLORS.WHITE,
  BASIC_BLACK: COLORS.BLACK,
  BASIC_BLUE: COLORS.BLUE,
  BASIC_RED: COLORS.RED,

  PRIMARY_GRAY: COLORS.PRIMITIVES_GRAY_500,
  PRIMARY_SKYBLUE: COLORS.PRIMITIVES_SKY_BLUE_400,
  PRIMARY_PURPLE: COLORS.PRIMITIVES_PURPLE_400,
  PRIMARY_LINEAR: COLORS.PRIMITIVES_LINEAR_BLUE,
  PRIMARY_PINK: COLORS.PRIMITIVES_PINK_400,
  PRIMARY_ORANGE: COLORS.PRIMITIVES_ORANGE_400,
  PRIMARY_NAVY: COLORS.PRIMITIVES_NAVY_400,
  PRIMARY_DARK_NAVY: COLORS.PRIMITIVES_NAVY_500,
  PRIMARY_GREEN: COLORS.PRIMITIVES_GREEN_400,
  PRIMARY_LIGHT_GREEN: COLORS.PRIMITIVES_LIGHT_GREEN_400,
  PRIMARY_YELLOW_GREEN: COLORS.PRIMITIVES_YELLOW_GREEN_400,
  PRIMARY_YELLOW: COLORS.PRIMITIVES_YELLOW_400,
};

const EXTRA_COLOR = {
  POST_BOX_SECRET_COLOR: '#EFF3FA',
};

export { COLORS, FOUNDATION_COLOR, EXTRA_COLOR };
