export default function InoutCharacterLightPink() {
  return (
    <svg width="61" height="45" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g fill="#FC8AA6">
          <path d="M49.499 39.967c3.555-4.218 5.687-9.602 5.687-15.467C55.186 10.969 43.84 0 29.843 0 15.847 0 4.5 10.969 4.5 24.5c0 5.899 2.156 11.31 5.748 15.539l39.25-.072z" />
          <path d="M43.352 23.8c-4.085 10.73-6.585 17.371-7.5 19.922-.915 2.552 4.301 1.244 15.648-3.922l-8.148-16zM8.352 23.8C4.267 34.53 1.767 41.171.852 43.722-.063 46.274 5.153 44.966 16.5 39.8l-8.148-16z" />
          <path d="M54.543 18.957 60.5 40.04H47.423z" />
        </g>
        <g transform="translate(16 17)">
          <ellipse fill="#000" cx="2.429" cy="2.47" rx="2.429" ry="2.47" />
          <ellipse fill="#000" cx="17.571" cy="2.47" rx="2.429" ry="2.47" />
          <path stroke="#000" strokeWidth="2.6" strokeLinecap="round" d="M4.581 10.273h10.763" />
        </g>
      </g>
    </svg>
  );
}
