import useAdBrix from '@hooks/adBrix/useAdBrix';
import useToastModal from '@hooks/useToastModal';
import { userInfoState } from '@states/UserInfoState';
import { setCustomFoodDetail } from 'apis/searchApi';
import ArrowBack from 'assets/images/icon/GNB/ArrowBack';
import ButtonSet from 'components/Atoms/Button/ButtonSet';
import ToastPageMsg from 'components/elements/ToastPageMsg';
import PopUpPage from 'components/PopupPage';
import Stepper from 'components/Stepper';
import FoodRegisterEditInput from 'pages/search/atomic/input/FoodRegisterEditInput';
import React, { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { dateState } from 'states/DateState';
import { customFoodRegisterFetchState } from 'states/SearchResultState';
import styled from 'styled-components';
import { COLORS, FOUNDATION_COLOR } from 'styles/constants/_colors';
import { swipeRight } from 'styles/mixins/_animations';
import { RequestCustomFoodDetail } from 'types/searchResult';
import { handleDecimalInputChange } from 'utils/numberInputControl';

interface Props {
  food: RequestCustomFoodDetail;
  setFood: React.Dispatch<React.SetStateAction<RequestCustomFoodDetail>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
}

const FoodRegisterStepFour = ({ setStep, setFood, food, onClose }: Props) => {
  const [msg, setMsg] = useState('');
  const { member_idx } = useRecoilValue(userInfoState);
  const [isToast, setIsToast] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const { openModal } = useToastModal();
  const { sendAdBrixData } = useAdBrix();

  const date = useRecoilValue(dateState);
  const setRegisterFetch = useSetRecoilState(customFoodRegisterFetchState);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = handleDecimalInputChange(e, 10000);
      if (inputValue === undefined) return;
      setFood({ ...food, [e.target.name]: inputValue });
    },
    [food],
  );

  const onBack = () => {
    setStep(2);
    return;
  };

  const isRequired = (() => {
    if (food.calories && food.carbohydrate && food.protein && food.fat) {
      return true;
    }
    return false;
  })();

  const isTotalCarboCalc = (() => {
    const total = Number(food.sugar) + Number(food.sugar_sub) + Number(food.fiber);
    return Number(food.carbohydrate) >= total;
  })();

  const isTotalFatCalc = (() => {
    const total =
      Number(food.saturated_fat) +
      Number(food.trans_fat) +
      Number(food.polyunsaturated_fat) +
      Number(food.monounsaturated_fat);

    return Number(food.fat) >= total;
  })();

  const onClick = () => {
    if (!isRequired) {
      setMsg('필수 정보를 입력해 주세요');
      setIsToast(true);
      return;
    }

    if (!isTotalCarboCalc) {
      setMsg(`당, 대체 감미료, 식이섬유의 합은\n 탄수화물 총량을 넘길 수 없어요`);
      setIsToast(true);
      return;
    }

    if (!isTotalFatCalc) {
      setMsg(`기타 지방의 합은\n 지방 총량을 넘길 수 없어요`);
      setIsToast(true);
      return;
    }

    setIsPopup(true);
    return;
  };

  const onSubmit = () => {
    setCustomFoodDetail(member_idx, { ...food, created_at: date }).then((data) => {
      const isDuplicate = data?.msg === '003';
      if (isDuplicate) {
        setMsg(`같은 이름의 음식이\n 이미 등록되어 있어요`);
        setIsToast(true);
        return;
      }
      setRegisterFetch((prev) => !prev);
      openModal({ children: <p>음식 등록이 완료되었어요</p> });
      onClose();
      sendAdBrixData('customEvent', {
        eventKey: 'register_customfood',
      });
    });
  };

  return (
    <Container>
      <ToastPageMsg onToast={isToast} setOnToast={setIsToast}>
        {msg}
      </ToastPageMsg>
      <PopUpPage
        headText={'음식 등록을 완료하시겠어요?'}
        subText1={'등록한 음식은 모든 유저에게 공개되며,'}
        subText2={'잘못된 정보로 신고를 받을 경우 삭제될 수 있어요'}
        buttonText1={'다음에'}
        buttonText2={'네.좋아요!'}
        popupOpen={isPopup}
        setPopupOpen={setIsPopup}
        onClick={onSubmit}
      />
      <Header>
        <span onClick={onBack}>
          <ArrowBack color={COLORS.BLACK} />
        </span>
      </Header>
      <Body>
        <Stepper focusedIndex={3} length={3} paddingTop={0} />
        <p className="title">{`영양 정보를\n 입력해 주세요`}</p>
        <Content>
          <Section>
            <Box>
              <p className="require">
                칼로리 <span>*</span>
              </p>
              <FoodRegisterEditInput
                name="calories"
                mode="decimal"
                placeholder="0"
                unit="kcal"
                value={food.calories}
                onChange={onChangeInput}
              />
            </Box>
          </Section>
          <Divider />
          <Section>
            <Box>
              <p className="require">
                탄수화물 <span>*</span>
              </p>
              <FoodRegisterEditInput
                name="carbohydrate"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.carbohydrate}
              />
            </Box>
            <Box>
              <p>- 당</p>
              <FoodRegisterEditInput
                name="sugar"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.sugar}
              />
            </Box>
            <Box>
              <p>- 대체 감미료</p>
              <FoodRegisterEditInput
                name="sugar_sub"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.sugar_sub}
              />
            </Box>
            <Box>
              <p>- 식이섬유</p>
              <FoodRegisterEditInput
                name="fiber"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.fiber}
              />
            </Box>
          </Section>
          <Divider />
          <Section>
            <Box>
              <p className="require">
                단백질 <span>*</span>
              </p>
              <FoodRegisterEditInput
                name="protein"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.protein}
              />
            </Box>
          </Section>
          <Divider />
          <Section>
            <Box>
              <p className="require">
                지방 <span>*</span>
              </p>
              <FoodRegisterEditInput
                name="fat"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.fat}
              />
            </Box>
            <Box>
              <p>- 포화지방</p>
              <FoodRegisterEditInput
                name="saturated_fat"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.saturated_fat}
              />
            </Box>
            <Box>
              <p>- 트랜스지방</p>
              <FoodRegisterEditInput
                name="trans_fat"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.trans_fat}
              />
            </Box>
            <Box>
              <p>- 다불포화지방</p>
              <FoodRegisterEditInput
                name="polyunsaturated_fat"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.polyunsaturated_fat}
              />
            </Box>
            <Box>
              <p>- 불포화지방</p>
              <FoodRegisterEditInput
                name="monounsaturated_fat"
                mode="decimal"
                placeholder="0"
                unit="g"
                onChange={onChangeInput}
                value={food.monounsaturated_fat}
              />
            </Box>
          </Section>
          <Divider />
          <Section>
            <Box>
              <p className="require">콜레스테롤</p>
              <FoodRegisterEditInput
                name="cholesterol"
                mode="decimal"
                placeholder="0"
                unit="mg"
                onChange={onChangeInput}
                value={food.cholesterol}
              />
            </Box>
          </Section>
          <Divider />
          <Section>
            <Box>
              <p className="require">나트륨</p>
              <FoodRegisterEditInput
                name="sodium"
                mode="decimal"
                placeholder="0"
                unit="mg"
                onChange={onChangeInput}
                value={food.sodium}
              />
            </Box>
          </Section>
          <Divider />
          <Section>
            <Box>
              <p className="require">칼륨</p>
              <FoodRegisterEditInput
                name="potassium"
                mode="decimal"
                placeholder="0"
                unit="mg"
                onChange={onChangeInput}
                value={food.potassium}
              />
            </Box>
          </Section>
        </Content>
      </Body>
      <Footer>
        <ButtonSet
          text="등록 완료"
          size="long"
          type="button"
          color={isRequired ? 'black' : 'isInActive'}
          isActive
          onClick={onClick}
        />
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: ${COLORS.WHITE};
`;

const Header = styled.header`
  padding: 17px 0px 0px 20px;
`;

const Body = styled.div`
  padding: 28px 20px 78px 20px;

  & .title {
    margin-top: 20px;
    font-family: 'Noto Sans KR';
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.5px;
    white-space: pre-line;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${swipeRight} 600ms ease;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 0px;
`;

const Box = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: ${COLORS.PRIMITIVES_GRAY_500};
  }

  & .require {
    color: ${COLORS.BLACK};
    span {
      color: ${FOUNDATION_COLOR.BASIC_RED};
    }
  }
`;

const Divider = styled.div`
  background: ${COLORS.PRIMITIVES_GRAY_BLUE_100};
  height: 1px;
`;

const Footer = styled.footer`
  position: fixed;
  max-width: 428px;
  width: 100%;
  height: 78px;
  padding: 12px 20px;
  bottom: 0;
  background: ${COLORS.WHITE};
`;

export default FoodRegisterStepFour;
