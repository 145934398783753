import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import FallBackSpinner from '@components/spinner/FallBackSpinner';

const ExerciseCategory = lazy(() => import('@pages/exercise/new/exerciseCategory'));
const ExerciseSearch = lazy(() => import('@pages/exercise/new/exerciseSearch'));
const ExerciseDetail = lazy(() => import('@pages/exercise/new/exerciseDetail'));
const ExerciseSearchResult = lazy(() => import('@pages/exercise/new/exerciseSearchResult'));
const ExerciseSetDetailForm = lazy(() => import('@pages/exercise/new/exerciseSetDetailForm'));
const ExerciseReport = lazy(() => import('@pages/exercise/new/exerciseReport'));

const ExerciseRouter = () => {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path="/search" element={<ExerciseSearch />} />
        <Route path="/search/result" element={<ExerciseSearchResult />} />
        <Route path="/category/:mainCategory" element={<ExerciseCategory />} />
        <Route path="/detail/:exerciseInfoIdx/:bookmark?" element={<ExerciseDetail />} />
        <Route path="/setDetail/:idx/:detailType" element={<ExerciseSetDetailForm />} />
        <Route path="/report" element={<ExerciseReport />} />
      </Routes>
    </Suspense>
  );
};
export default ExerciseRouter;
