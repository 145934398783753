import { Grid, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  active: boolean;
  size: 'medium' | 'small';
}

export default function GreenSmileWithStar({ active, size }: Props) {
  const classes = useStyles();

  const fragment = (() => {
    switch (size) {
      case 'medium':
        return {
          icon: active ? (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="27.5" cy="29.3438" r="27.5" fill="#4FE685" />
              <path
                d="M21.6714 34.8354C22.3183 38.1037 24.9504 39.8409 27.7073 39.8409C30.7625 39.8409 33.1669 38.1137 33.7572 34.8414"
                stroke="black"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <ellipse cx="18.5137" cy="26.7344" rx="2.75" ry="2.76563" fill="black" />
              <path
                d="M37.335 23.9688L34.1084 27.6562H40.1006"
                stroke="black"
                strokeWidth="2.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g clipPath="url(#clip0_515_6389)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M42.9646 13.606C44.8475 12.4458 46.3717 11.1051 47.5373 9.58391C48.7029 8.06272 49.8445 5.78313 50.9621 2.74512C51.93 5.52748 53.0536 7.80708 54.333 9.58391C55.6124 11.3607 57.1993 12.7014 59.0937 13.606C57.2368 14.8342 55.6938 16.3182 54.4644 18.0581C53.2351 19.798 52.09 21.9342 51.0291 24.4669C50.127 21.9095 49.0662 19.7732 47.8467 18.0581C46.6272 16.343 44.9999 14.859 42.9646 13.606Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_515_6389">
                  <rect
                    width="16.225"
                    height="22.0196"
                    fill="white"
                    transform="translate(42.8999 2.6001)"
                  />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_596_6853)">
                <circle cx="27.5" cy="29.3438" r="27.5" fill="#E6E7EA" />
                <path
                  d="M21.6714 34.8354C22.3183 38.1037 24.9504 39.8409 27.7073 39.8409C30.7625 39.8409 33.1669 38.1137 33.7572 34.8414"
                  stroke="#999FAB"
                  strokeWidth="2.8"
                  strokeLinecap="round"
                />
                <ellipse cx="18.5137" cy="26.7344" rx="2.75" ry="2.76563" fill="#999FAB" />
                <path
                  d="M37.335 23.9688L34.1084 27.6562H40.1006"
                  stroke="#999FAB"
                  strokeWidth="2.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g clipPath="url(#clip1_596_6853)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.9646 13.606C44.8475 12.4458 46.3717 11.1051 47.5373 9.58391C48.7029 8.06272 49.8445 5.78313 50.9621 2.74512C51.93 5.52748 53.0536 7.80708 54.333 9.58391C55.6124 11.3607 57.1993 12.7014 59.0937 13.606C57.2368 14.8342 55.6938 16.3182 54.4644 18.0581C53.2351 19.798 52.09 21.9342 51.0291 24.4669C50.127 21.9095 49.0662 19.7732 47.8467 18.0581C46.6272 16.343 44.9999 14.859 42.9646 13.606Z"
                    fill="#999FAB"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_596_6853">
                  <rect width="59" height="59" fill="white" />
                </clipPath>
                <clipPath id="clip1_596_6853">
                  <rect
                    width="16.225"
                    height="22.0196"
                    fill="white"
                    transform="translate(42.8999 2.6001)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14.9153" cy="15.9153" r="14.9153" fill="#4FE685" />
              <path
                d="M11.754 18.894C12.1049 20.6666 13.5325 21.6089 15.0277 21.6089C16.6848 21.6089 17.9889 20.6721 18.309 18.8973"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="10.0413" cy="14.5" rx="1.49153" ry="1.5" fill="black" />
              <path
                d="M20.2495 13L18.4995 15H21.7495"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g clipPath="url(#clip0_516_6437)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.3027 7.37941C24.324 6.75015 25.1507 6.02299 25.7829 5.19794C26.415 4.3729 27.0342 3.1365 27.6403 1.48877C28.1653 2.99785 28.7747 4.23424 29.4686 5.19794C30.1625 6.16165 31.0232 6.8888 32.0507 7.37941C31.0436 8.04555 30.2067 8.85045 29.5399 9.79412C28.8732 10.7378 28.2521 11.8964 27.6767 13.2701C27.1874 11.883 26.6121 10.7243 25.9507 9.79412C25.2892 8.86389 24.4066 8.05899 23.3027 7.37941Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_516_6437">
                  <rect
                    width="8.8"
                    height="11.9429"
                    fill="white"
                    transform="translate(23.2677 1.41016)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
      default:
        return {
          icon: (
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14.9153" cy="15.9153" r="14.9153" fill="#4FE685" />
              <path
                d="M11.754 18.894C12.1049 20.6666 13.5325 21.6089 15.0277 21.6089C16.6848 21.6089 17.9889 20.6721 18.309 18.8973"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="10.0413" cy="14.5" rx="1.49153" ry="1.5" fill="black" />
              <path
                d="M20.2495 13L18.4995 15H21.7495"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g clipPath="url(#clip0_516_6437)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.3027 7.37941C24.324 6.75015 25.1507 6.02299 25.7829 5.19794C26.415 4.3729 27.0342 3.1365 27.6403 1.48877C28.1653 2.99785 28.7747 4.23424 29.4686 5.19794C30.1625 6.16165 31.0232 6.8888 32.0507 7.37941C31.0436 8.04555 30.2067 8.85045 29.5399 9.79412C28.8732 10.7378 28.2521 11.8964 27.6767 13.2701C27.1874 11.883 26.6121 10.7243 25.9507 9.79412C25.2892 8.86389 24.4066 8.05899 23.3027 7.37941Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_516_6437">
                  <rect
                    width="8.8"
                    height="11.9429"
                    fill="white"
                    transform="translate(23.2677 1.41016)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}

const useStyles = makeStyles(() => createStyles({}));
