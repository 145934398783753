import { useEffect, useLayoutEffect } from 'react';
import BackArrowGnb from '@components/elements/BackArrowGnb';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import Terms1 from '@assets/images/terms/terms_1.png';
import Terms2 from '@assets/images/terms/terms_2.png';
import Terms3 from '@assets/images/terms/terms_3.png';
import Terms4 from '@assets/images/terms/terms_4.png';
import Terms5 from '@assets/images/terms/terms_5.png';

interface Props {
  onClose: () => void;
}

export default function InoutTermsViewModal({ onClose }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    document.body.style.removeProperty('overflow');
    return () => {
      document.body.style.overflow = 'hidden';
    };
  }, []);

  return (
    <Wrapper>
      <BackArrowGnb onClick={onClose} />
      <img src={Terms1} width="100%" />
      <img src={Terms2} width="100%" />
      <img src={Terms3} width="100%" />
      <img src={Terms4} width="100%" />
      <img src={Terms5} width="100%" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  max-width: 428px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: ${COLORS.WHITE};
  z-index: 90000;

  img:first-of-type {
    margin-top: 50px;
  }
`;
