import { getSearchFood } from '@apis/searchApi';
import { useDidMountEffect } from '@hooks/useDidmountEffect';
import useQueryString from '@hooks/useQueryString';
import { InfinityResponse } from '@models/groupBattle';
import { FoodDetail } from '@models/searchResult';
import { AxiosError } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { useNavigationType } from 'react-router-dom';

type QueryReturnType = Omit<InfinityResponse<FoodDetail[]>, 'lastIndex'>;

const useFoodSearchQuery = (
  options?: UseInfiniteQueryOptions<QueryReturnType, AxiosError, QueryReturnType>,
) => {
  const navigationType = useNavigationType();
  const {
    queries: [query, filter],
  } = useQueryString(['keyword', 'filter']);

  const { data, isLoading, isFetching, fetchNextPage, remove, refetch } = useInfiniteQuery<
    QueryReturnType,
    AxiosError,
    QueryReturnType
  >(
    ['search', 'food', 'result', { query }],
    ({
      pageParam = {
        from: 0,
      },
    }) =>
      getSearchFood({
        query,
        from: pageParam.from,
        size: 20,
        includeByUser: filter === '',
      }),
    {
      enabled: !!query,
      refetchOnMount: navigationType !== 'POP',
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPage) => {
        return {
          from: allPage.length * 20,
        };
      },
      ...options,
    },
  );

  useDidMountEffect(() => {
    remove();
    refetch();
  }, [filter]);

  return { data, isLoading, isFetching, fetchNextPage, refetch };
};

export default useFoodSearchQuery;
