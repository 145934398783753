const setCookie = (name: string, value: string): void => {
  localStorage.setItem(name, value);
};

// fcmtoken
const setCookieApp = (name: string, value: string): void => {
  localStorage.setItem(name, value.split('/')[0]);
};

// fcmtoken
const setCookieMonth = (name: string, value: string): void => {
  localStorage.setItem(name, value.split('/')[0]);
};

const getCookie = (name: string) => {
  return localStorage.getItem(name);
};

const deleteCookie = (name: string): void => {
  localStorage.removeItem(name);
};

export { setCookie, getCookie, deleteCookie, setCookieApp, setCookieMonth };
