import styled from 'styled-components';
import { COLORS, FOUNDATION_COLOR } from 'styles/constants/_colors';

interface Props {
  onClick: () => void;
}

const BigIconForceUpdateModal = ({ onClick }: Props) => {
  return (
    <SystemMaintenanceModalContainer>
      <Body>
        <Title>인아웃 새 버전 출시!</Title>
        <InfoText>
          더 나은 서비스 이용을 위해 <br />
          업데이트가 꼭 필요해요
        </InfoText>
      </Body>
      <UpdateButton onClick={onClick}>업데이트 하기</UpdateButton>
    </SystemMaintenanceModalContainer>
  );
};

export default BigIconForceUpdateModal;

const SystemMaintenanceModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 315px;
  height: 415px;
  background: ${COLORS.WHITE};
  border-radius: 20px;
  padding: 39px 0 52px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin: 21px 0 39px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.5px;
`;

const InfoText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: ${FOUNDATION_COLOR.PRIMARY_GRAY};

  .blue {
    color: ${FOUNDATION_COLOR.BASIC_BLUE};
  }
`;

const UpdateButton = styled.button`
  background-color: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  padding: 19px 93px 20px 89px;
  border-radius: 120px;

  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.6153846383094788px;
`;
