import { useSearchParams } from 'react-router-dom';

const useQueryString = (keys: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queries = keys.map((key) => searchParams.get(key) ?? '');

  return { queries, setSearchParams };
};

export default useQueryString;
