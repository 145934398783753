import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { MyPagePath } from '@constants/internalPath';

const FeedDetail = lazy(() => import('@pages/communication/ketogram/FeedDetail'));
const AlarmPostingPage = lazy(() => import('@pages/setting/AlarmPostingPage'));
const SettingMain = lazy(() => import('@pages/setting/SettingMain'));
const SettingAlarmCustom = lazy(() => import('@pages/setting/SettingAlarmCustom'));
const MyInoutSettingAlarm = lazy(() => import('@pages/setting/SettingAlarmMain'));
const SettingLogoutSignout = lazy(() => import('@pages/setting/SettingLogoutSignout'));
const SettingTermsPrivacy = lazy(() => import('@pages/setting/SettingTermsPrivacy'));
const SettingCommunication = lazy(() => import('@pages/setting/SettingCommunication'));
const ModifyMyProfile = lazy(() => import('@pages/newMyPage/ModifyMyProfile'));
const MyNews = lazy(() => import('@pages/newMyPage/MyNews'));

const MyHomePage = lazy(() => import('@pages/my/MyHomePage'));

const UserSearchPage = lazy(() => import('@pages/newMyPage/UserSearchPage'));
const SettingAccount = lazy(() => import('@pages/setting/SettingAccount'));
const SettingAccountManagementPage = lazy(() => import('@pages/setting/settingAccountManagement'));
const SettingAccountFollowRequestPage = lazy(
  () => import('@pages/setting/settingAccountFollowRequest'),
);
const SettingAccountUserBanPage = lazy(() => import('@pages/setting/settingAccountUserBan'));
const SettingPassword = lazy(() => import('@pages/setting/SettingPassword'));
const InoutTeam = lazy(() => import('@pages/setting/InoutTeam'));
const ManagePost = lazy(() => import('@pages/setting/ManagePost'));
const ManageScrap = lazy(() => import('@pages/setting/ManageScrap'));
const MyFoodReview = lazy(() => import('@pages/setting/myFoodReview'));
const SettingAdRemovePage = lazy(() => import('@pages/setting/settingAdRemove'));

export default function MyPageRouter() {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path={`${MyPagePath.MAIN}/:menu?/:tab?`} element={<MyHomePage />} />

        <Route path={MyPagePath.FOOD_DETAIL} element={<FeedDetail />} />
        <Route path={MyPagePath.NEWS} element={<MyNews />} />
        <Route path={MyPagePath.USER_SEARCH} element={<UserSearchPage />} />

        <Route path={MyPagePath.PROFILE} element={<ModifyMyProfile />} />
        <Route path={MyPagePath.SETTING} element={<SettingMain />} />
        <Route path={MyPagePath.ACCOUNT} element={<SettingAccount />} />
        <Route path={MyPagePath.ACCOUNT_MANAGEMENT} element={<SettingAccountManagementPage />} />
        <Route
          path={MyPagePath.ACCOUNT_FOLLOW_REQUEST}
          element={<SettingAccountFollowRequestPage />}
        />
        <Route path={MyPagePath.ACCOUNT_USER_BAN} element={<SettingAccountUserBanPage />} />
        <Route path={MyPagePath.LOCK} element={<SettingPassword />} />
        <Route path={MyPagePath.ALARM} element={<MyInoutSettingAlarm />} />
        <Route path={MyPagePath.ALARM_CUSTOM} element={<SettingAlarmCustom />} />
        <Route path={MyPagePath.ALARM_CUSTOM_POSTING} element={<AlarmPostingPage />} />
        <Route path={MyPagePath.ALARM_CUSTOM_EDIT} element={<AlarmPostingPage />} />
        <Route path={MyPagePath.INOUT_TEAM} element={<InoutTeam />} />
        <Route path={MyPagePath.TERMS_PRIVACY} element={<SettingTermsPrivacy />} />
        <Route path={MyPagePath.AUTH_INFO} element={<SettingLogoutSignout />} />
        <Route path={MyPagePath.MANAGE_POSTS} element={<ManagePost />} />
        <Route path={MyPagePath.MANAGE_SCRAPS} element={<ManageScrap />} />
        <Route path={MyPagePath.MY_FOOD_REVIEW} element={<MyFoodReview />} />
        <Route path={MyPagePath.COMMUNICATION} element={<SettingCommunication />} />
        <Route path={MyPagePath.AD_REMOVE} element={<SettingAdRemovePage />} />
      </Routes>
    </Suspense>
  );
}
