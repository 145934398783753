import { FoodDetail } from '@models/searchResult';
import { fixFloatingPoint } from '@modules/calorieCalculator';

export const handleMetricButtonName = (data?: FoodDetail) => {
  const measurement = data?.measurement_description || '';
  const metric = data?.metric_serving_unit || '';
  const additional =
    data?.metric_serving_unit !== '0'
      ? ` (${fixFloatingPoint(Number(data?.metric_serving_amount))}${data?.metric_serving_unit})`
      : '';
  const measurementAdditional = measurement + additional;
  const metricUnit = metric === '0' ? 'g' : metric;
  return {
    measurementAdditional,
    metricUnit,
  };
};
