import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import Portal from '@components/portal';
import { FoodDetail, SearchResult } from '@models/searchResult';
import { foodDetailAmountState, foodDetailMetricTypeState } from '@states/FoodState';
import { Suspense, useLayoutEffect } from 'react';
import { useResetRecoilState } from 'recoil';
import FoodQuickButtons from './FoodQuickButtons';
import FoodQuickContents from './FoodQuickContents';
import FoodQuickHeader from './FoodQuickHeader';
import FoodQuickSkeleton from './FoodQuickSkeleton';

interface Props {
  food: FoodDetail;
  onClickAdd: () => void;
  onClose: () => void;
}

const FoodQuickModal = ({ food, onClickAdd, onClose }: Props) => {
  const resetType = useResetRecoilState(foodDetailMetricTypeState);
  const resetCount = useResetRecoilState(foodDetailAmountState);

  const onUnmount = () => {
    resetType();
    resetCount();
    document.body.style.removeProperty('overflow');
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      onUnmount();
    };
  }, []);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <Suspense fallback={<FoodQuickSkeleton onClose={onClose} />}>
          <BottomSheet onClose={onClose}>
            <BottomSheet.Headers>
              <FoodQuickHeader item={food} />
            </BottomSheet.Headers>
            <BottomSheet.Contents>
              <FoodQuickContents item={food} />
            </BottomSheet.Contents>
            <BottomSheet.Buttons>
              <FoodQuickButtons item={food} onClickAdd={onClickAdd} onClose={onClose} />
            </BottomSheet.Buttons>
          </BottomSheet>
        </Suspense>
      </DimmedWrapper>
    </Portal>
  );
};

export default FoodQuickModal;
