export default function InoutCharacterPink() {
  return (
    <svg width="61" height="45" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g fill="#FA5064">
          <path d="M49.499 39.967c3.555-4.218 5.687-9.602 5.687-15.467C55.186 10.969 43.84 0 29.843 0 15.847 0 4.5 10.969 4.5 24.5c0 5.899 2.156 11.31 5.748 15.539l39.25-.072z" />
          <path d="M43.352 23.8c-4.085 10.73-6.585 17.371-7.5 19.922-.915 2.552 4.301 1.244 15.648-3.922l-8.148-16zM8.352 23.8C4.267 34.53 1.767 41.171.852 43.722-.063 46.274 5.153 44.966 16.5 39.8l-8.148-16z" />
          <path d="M54.543 18.957 60.5 40.04H47.423z" />
        </g>
        <g stroke="#000" strokeLinecap="round">
          <path
            d="M21.186 29c1.658-1.22 3.418-1.83 5.277-1.83 1.86 0 3.619.61 5.277 1.83"
            strokeWidth="2.6"
          />
          <path
            strokeWidth="2.4"
            d="m16.319 17.33 4.952 5.136M16.315 22.47l4.96-5.144M31.729 17.33l4.952 5.136M31.725 22.47l4.96-5.143"
          />
        </g>
      </g>
    </svg>
  );
}
