import { FastingRoutineFormType } from '@models/fasintg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DefaultValue, atom, selector } from 'recoil';
dayjs.extend(utc);
dayjs.extend(timezone);

export const timeZoneState = atom({
  key: '#timeZoneState',
  default: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const dateForRecordState = atom({
  key: '#dateForRecordState',
  default: dayjs().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-01'),
});

export const fastingRoutineItemFormState = atom<FastingRoutineFormType>({
  key: '#fastingRoutineItemFormState',
  default: {
    id: 0,
    hour: '',
    minute: '',
    duration: '',
    days: [],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    created_at: dayjs().format('YYYY-MM-DD'),
  },
});

export const fastingRoutineItemFormListState = atom<FastingRoutineFormType[]>({
  key: '#fastingRoutineItemFormListState',
  default: [],
});

export const fastingRoutineListState = selector({
  key: '#fastingRoutineListState',
  get: ({ get }) => {
    return get(fastingRoutineItemFormListState);
  },
  set: ({ get, set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(fastingRoutineItemFormListState);
      return;
    }

    set(fastingRoutineItemFormListState, newValue);
  },
});

export const staticsDateState = atom({
  key: '#staticsDateState',
  default: dayjs().format('YYYY-MM-DD'),
});
