import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import LazyLoad from 'react-lazyload';

import './index.css';
import App from './shared';

import GlobalStyle from './styles';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Provider } from 'react-redux';
import { store } from './redux/config/store';

import { RecoilRoot } from 'recoil';
import { HashRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import FallBackSpinner from '@components/spinner/FallBackSpinner';
import { Metric } from 'web-vitals';

// axios.defaults.baseURL = BACKURL;
export const queryClient = new QueryClient();

const logVitals = (metric: Metric) => {
  console.log(`${metric.name}: ${metric.value.toFixed(1)}ms`);
};

ReactDOM.render(
  <>
    <StrictMode>
      <HashRouter>
        <GlobalStyle />
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <Suspense fallback={<FallBackSpinner />}>
                <LazyLoad>
                  <App />
                </LazyLoad>
              </Suspense>
              <ReactQueryDevtools position="bottom-right" />
            </Provider>
          </QueryClientProvider>
        </RecoilRoot>
      </HashRouter>
    </StrictMode>
  </>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(logVitals);
