import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

const BottomSheetHeaders = ({ children }: Props) => {
  return <>{children}</>;
};

const getBottomSheetHeaders = (<BottomSheetHeaders />).type;

export { BottomSheetHeaders, getBottomSheetHeaders };
