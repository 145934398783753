import { LightningIcon } from '@assets/svgs/_Icons';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import styled from 'styled-components';

interface Props {
  onClose: () => void;
}

const FoodQuickSkeleton = ({ onClose }: Props) => {
  return (
    <BottomSheet onClose={onClose}>
      <BottomSheet.Headers>
        <HeaderField>
          <Skeleton width={'76px'} height={'18px'} borderRadius={'120px'} />
          <Skeleton width={'195px'} height={'34px'} borderRadius={'120px'} />
        </HeaderField>
      </BottomSheet.Headers>
      <BottomSheet.Contents>
        <ContentsField>
          <Skeleton width={'100%'} height={'112px'} borderRadius={'16px'} />
          <SkeletonBox gap={'12px'}>
            <Skeleton width={'100%'} height={'60px'} borderRadius={'120px'} />
            <Skeleton width={'100%'} height={'60px'} borderRadius={'120px'} />
          </SkeletonBox>
        </ContentsField>
      </BottomSheet.Contents>
      <BottomSheet.Buttons>
        <ButtonsBox>
          <button disabled className="detail-button">
            음식 상세
          </button>
          <button disabled className="quick-button">
            <LightningIcon />
            빠른 추가
          </button>
        </ButtonsBox>
      </BottomSheet.Buttons>
    </BottomSheet>
  );
};

const HeaderField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 26px 20px 0px;
`;

const ContentsField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 19px 20px 14px;
`;

const Skeleton = styled.div<{ width: string; height: string; borderRadius: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
`;

const SkeletonBox = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;

const ButtonsBox = styled.div`
  display: flex;
  gap: 9px;
  padding: 12px;

  .detail-button {
    min-width: 104px;
    background-color: ${COLORS.BLACK};
  }

  .quick-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
    background-color: ${FOUNDATION_COLOR.BASIC_BLUE};
  }

  button {
    border-radius: 120px;
    height: 54px;

    color: ${COLORS.WHITE};
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.6px;
  }
`;

export default FoodQuickSkeleton;
